import { useCallback } from 'react';
import axios from 'axios';

const useCreateJiraTicket = () => {
    const createJiraTicket = useCallback(async ({
        cargoResults,
        env,
        packagingSerialNumber,
        shipmentEnd,
        shipmentNumber,
        shipmentStart,
        user,
    }) => {
        const jiraApiUrl = 'https://test-data.dev.skymind.com/jira-api';

        try {
            const response = await axios.post(jiraApiUrl, {
                cargoResults,
                env,
                packagingSerialNumber,
                shipmentEnd,
                shipmentNumber,
                shipmentStart,
                user,
            });

            global.console.log('Jira ticket created:', response.data);
        } catch (error) {
            global.console.error('Failed to create Jira ticket:', error);
            throw error;
        }
    }, []);

    return createJiraTicket;
};

export default useCreateJiraTicket;
