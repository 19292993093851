import React from 'react';
import RowWithIcon from 'shared-components/CompanyInfoComponents/RowWithIcon';
import icons from 'shared-components/icons';
import { BROWSER_LANGUAGE } from 'shared-components/constants';
import useClasses from 'hooks/useClasses/index';

const styles = theme => ({
    iconContainer: {
        marginTop: '12px',
    },
    infoTitle: {
        color: theme.palette.secondary[500],
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        opacity: 1,
        textAlign: 'start',
    },
    row: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        justifyContent: 'space-between',
        letterSpacing: '0.49px',
        marginTop: '12px',
        opacity: 1,
        textAlign: 'start',
    },
});

type Props = {
    auditedBy: string,
    auditedOn: string,
    auditType: string
}

const AuditInfoRow = ({
    auditedBy = '',
    auditedOn = '',
    auditType,
} : Props) => {
    const classes = useClasses(styles);
    const auditDate = new Date(auditedOn).toLocaleDateString(BROWSER_LANGUAGE);

    return (
        <div className={classes.row}>
            <div className={classes.iconContainer}>
                <RowWithIcon
                    icon={icons.prize}
                    label={
                        auditType === 'REMOTE'
                            ? 'Location audited remotely.'
                            : 'Location audited on-site.'
                    }
                />
            </div>
            <div>
                <div className={classes.infoTitle}>Audited On</div>
                <div>{auditDate}</div>
            </div>
            <div>
                <div className={classes.infoTitle}>Audited By</div>
                <div>{auditedBy}</div>
            </div>
        </div>
    );
};

export default AuditInfoRow;
