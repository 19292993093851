const mapGatewaysToLocations = (gateways, facets, airports) => {
    const gatewayFacet = facets.find(f => f.filterName === 'gatewayImeiMacs');

    if (!gatewayFacet || !gatewayFacet.counts) return [];

    return gatewayFacet.counts.map(facet => {
        const gateway = gateways.find(g => {
            return g.gatewayImeiMac === facet.filterValue;
        });

        if (!gateway) return null;

        const airport = airports.find(a => a.code === gateway.iataCode);

        return {
            ...gateway,
            code: gateway.iataCode || gateway.gatewayImeiMac,
            count: facet.count,
            geolocation: {
                latitude: gateway.geolocation.latitude,
                longitude: gateway.geolocation.longitude,
            },
            isAirport: !!airport,
            location: airport ? airport.iataCode : gateway.locationName,
        };
    }).filter(Boolean);
};

export default mapGatewaysToLocations;
