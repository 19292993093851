import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import ServiceProviders from './ServiceProviders';
import Overview from './pages/Overview';
import Capabilities from './pages/Capabilities';
import ParentCompany from './pages/ParentCompany';
import Gallery from './pages/Gallery';

const ServiceProvidersRouter: RouteItem[] = [
    {
        element: <ServiceProviders
            rowLinkTemplate="/lane-management/service-providers/:id/overview"
            showTitle
        />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers',
    },
    {
        element: <Overview />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/overview',
    },
    {
        element: <Capabilities />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/capabilities',
    },
    {
        element: <ParentCompany />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/parent-company',
    },
    {
        element: <Gallery />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/gallery',
    },
    {
        element: <div>Documents</div>,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/documents',
    },
    {
        element: <div>Lanes</div>,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/service-providers/:providerId/lanes',
    },
];

export default ServiceProvidersRouter;
