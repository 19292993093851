import React, { useEffect } from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import CircularProgress from '@mui/material/CircularProgress';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import {
    EntitySelectorItem,
    PackagingsInThisShipment,
} from 'SensorDataCommon/dataTypes';
import ShipmentPackagingUnit from 'SensorDataCommon/components/ShipmentPackagingUnit';
import NoOpenEntity from '../NoOpenEntity';
import AssetUnit from '../AssetUnit';
import LoggerUnit from '../LoggerUnit';
// import PackagingUnit from '../PackagingUnit';

type Props = {
    core?: string,
    entitySelectorItem: EntitySelectorItem,
    getSensorData?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    isAdmin?: boolean,
    isLoading?: boolean,
    localTimezone: boolean,
    requestBody: SensorDataRequestBody,
    setShipmentsActivePackagingSerialNumber?: (shipmentsActivePackagingSerialNumber?: string) => void,
    shipmentsActivePackaging?: PackagingsInThisShipment,
    shipmentsActivePackagingSerialNumber?: string,
    showMap: boolean,
    timeRange: TimeRange
}

const EntityController = ({
    core = 'track-and-trace',
    entitySelectorItem,
    getSensorData,
    isAdmin = false,
    isLoading = false,
    localTimezone,
    requestBody,
    setShipmentsActivePackagingSerialNumber,
    shipmentsActivePackagingSerialNumber = null,
    showMap,
    timeRange,
} : Props) => {
    const {
        additionalData = null,
        entityId,
        entityNumber,
        entityType,
    } = entitySelectorItem || {};

    const {
        packagingsInThisShipment = [],
    } = additionalData || {};

    useEffect(() => {
        if (packagingsInThisShipment.length === 1) {
            setShipmentsActivePackagingSerialNumber(packagingsInThisShipment[0].serialNumber);
        }
    }, [packagingsInThisShipment]);

    if (entityType) {
        if (entityType === 'shipment') {
            return (
                <>
                    {
                        packagingsInThisShipment.map(packaging => {
                            const { serialNumber } = packaging;

                            return (
                                <ShipmentPackagingUnit
                                    key={serialNumber}
                                    isActivePackaging={serialNumber === shipmentsActivePackagingSerialNumber}
                                    isOnlyPackagingInTheShipment={packagingsInThisShipment.length === 1}
                                    isSelectedAnyPackaging={shipmentsActivePackagingSerialNumber !== null}
                                    localTimezone={localTimezone}
                                    requestBody={requestBody}
                                    serialNumber={serialNumber}
                                    setShipmentsActivePackagingSerialNumber={
                                        setShipmentsActivePackagingSerialNumber
                                    }
                                    shipmentNumber={entitySelectorItem.entityNumber}
                                    showMap={showMap}
                                    temperatureRange={additionalData.temperatureRange}
                                    timeRange={timeRange}
                                />
                            );
                        })
                    }
                </>
            );
        } else if (entityType === 'loggers') {
            return (
                <LoggerUnit
                    core={core}
                    getSensorData={getSensorData}
                    localTimezone={localTimezone}
                    loggerNumber={entityNumber}
                    options={requestBody}
                    showMap={showMap}
                    timeRange={timeRange}
                />
            );
        } else {
            return (
                <AssetUnit
                    entityId={entityId}
                    entityType={entitySelectorItem.additionalData.assetType}
                    isAdmin={isAdmin}
                    localTimezone={localTimezone}
                    loggerNumber={entityNumber}
                    options={requestBody}
                    showMap={showMap}
                    timeRange={timeRange}
                />
            );
        }
    }

    if (!isLoading) {
        return (
            <NoOpenEntity />
        );
    } else {
        return <CircularProgress />;
    }
};

export default EntityController;
