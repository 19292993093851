import DataPaginationContext from 'Contexts/DataPaginationContext';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    useCallback, useContext,
    useEffect, useMemo,
    useState,
} from 'react';
import { FacetDTO } from 'shared-components/dataTypes';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import StandalonePagination from 'shared-components/StandalonePagination';
import ShipmentFilterPanel from 'TrackAndTrace/GenericShipments/components/ShipmentFilters';
import {
    GenericShipmentData,
    GenericShipmentDTO,
    getShipmentData,
    initialShipmentFilterOptions,
} from 'TrackAndTrace/GenericShipments/lib';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import useFilter, { PAGES } from 'Contexts/useFilter';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import { MAP_CONFIG } from 'shared-components/constants';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import tableColumns from './tableColumns';
import { styles } from './GenericShipments.style';
import MapView from './MapView';

const PAGE_SIZE = 10;

const GenericShipments = () => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { getFilter, setFilter } = useFilter(PAGES.GENERIC_SHIPMENTS);
    const filter = getFilter();
    const hasAccess = useHasAccess();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const {
        setHasOnboardingTour,
        setOnboardingTooltipInfo,
    } = useContext(QuickHelpContext);
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [shipments, setShipments] = useState<GenericShipmentData[]>([]);
    const [highlightedText, setHighlightedText] = useState('');
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);

    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('v2/shipments/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialShipmentFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('v2/shipments/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawShipments, status: shipmentsStatus,
    } = useGetCommonData<GenericShipmentDTO>('v2/shipments/search', {
        cache: true,
        enabled: facetsStatus === 'SUCCESS',
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort)),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        setHasOnboardingTour(view === 'map');
        return () => {
            setHasOnboardingTour(false);
        };
    }, [view]);
    useEffect(() => {
        if (shipmentsStatus === 'SUCCESS' && rawShipments?.resultList) {
            const allShipments = getShipmentData(rawShipments?.resultList);

            setTotalElements(rawShipments.totalElements);
            setShipments(allShipments);
        }
    }, [shipmentsStatus, rawShipments]);

    useEffect(() => {
        setOnboardingTooltipInfo({
            core: ONBOARDING_TYPE.SHIPMENT,
            order: 5,
            padding: 0,
            position: 'auto',
            text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
            title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
            uid: 'supportTicketCreationQuickHelp',
        });
        return () => {
            setOnboardingTooltipInfo(null);
        };
    }, []);
    const minMaxTempArray = useMemo<[number, number]>(() => {
        const allTemps = shipments.map(({ packagings }) => packagings.map((p) => [
            p?.temperatureCheckResult?.temperatureMax,
            p?.temperatureCheckResult?.temperatureMin,
        ]))
            .flat().flat()
            .filter(it => it)
            .sort((a, b) => a - b);

        return [allTemps[0], allTemps[allTemps.length - 1]];
    }, [shipments]);

    const hasAccessToEnergy = useMemo(() => hasAccess(userRoles.INTELLIGENT_MONITORING), [userRoles]);

    const columns = useMemo(() => tableColumns(t, minMaxTempArray, hasAccessToEnergy), [t, minMaxTempArray]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setHighlightedText(query);
    }, [query]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            showAirportsInfo,
            sort,
            totalElements,
        });
    }, [page, totalElements, query, filterOptions, sort, showAirportsInfo]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: shipmentsStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <ShipmentFilterPanel
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    initialFacets={rawInitialFacets}
                    isTableView={view === 'table'}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setQuery={setQuery}
                    setShowAirportsInfo={setShowAirportsInfo}
                    showAirportsInfo={showAirportsInfo}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    overflow: 'auto',
                    width: '100%',
                }}
                >
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname="/track-and-trace/generic-shipments"
                        tooltipInfo={{
                            core: ONBOARDING_TYPE.SHIPMENT,
                            isMain: true,
                            order: 4,
                            position: 'center',
                            text: t('ONBOARDING.TRACK_AND_TRACE.LEGEND_DESC'),
                            title: t('ONBOARDING.TRACK_AND_TRACE.LEGEND'),
                            uid: 'GenericShipmentsFilterSearch',
                        }}
                    />
                    {
                        view === 'table'
                            ? (
                                <Table
                                    classNames={{
                                        tableContainerClassName: classes.filteredTable,
                                    }}
                                    columns={columns}
                                    currentSort={sort}
                                    data={shipments}
                                    maskForHighlight={highlightedText}
                                    rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                                    title={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                                    onSort={updateSort}
                                />
                            )
                            : (
                                <MapView
                                    error={null}
                                    setZoom={setZoom}
                                    shipments={shipments}
                                    showAirportsInfo={showAirportsInfo}
                                    zoom={zoom}
                                />
                            )
                    }
                    <div style={{ flex: 1 }} />
                    {
                        view === 'table' && (
                            <StandalonePagination
                                detached
                                entity={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                            />
                        )
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default GenericShipments;
