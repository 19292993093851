import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';
import styles from './PhoneNumberTextField.style';

type Props = {
    className?: string,
    fieldTitlePropsClassName?: string,
    handleContactPhoneNumber?: (string) => void,
    inputPropsClassName?: string,
    isFieldTitleShown?: boolean,
    label?: string,
    noMargin?: boolean,
    phoneNumber: string,
    placeholder?: string
}

const PhoneNumberTextField = ({
    className = '',
    fieldTitlePropsClassName = '',
    handleContactPhoneNumber,
    inputPropsClassName,
    isFieldTitleShown = false,
    label,
    noMargin = false,
    phoneNumber = '',
    placeholder = '',
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const inputLabel = label || t('SERVICE_PROVIDERS.CONTACT_PHONE');

    interface Country {
        countryCode: string;
        dialCode: string;
        format: string;
        iso2: string;
        name: string;
        priority: number;
        regions: string[];
    }

    return (
        <CommonTooltip
            borderColor="red"
            description={t('MY_COMPANY.ERROR_INVALID_COUNTRY_CODE')}
            open={!isValid}
        >
            <div>
                {isFieldTitleShown && <div className={fieldTitlePropsClassName}>{inputLabel}</div>}
                <PhoneInput
                    containerClass={[
                        'MuiInputBase-root',
                        'MuiInput-root',
                        handleContactPhoneNumber ? 'MuiInput-underline' : '',
                        'MuiInputBase-formControl',
                        'MuiInput-formControl',
                        'MuiInputBase-marginDense',
                        'MuiInput-marginDense',
                        isFocused ? 'Mui-focused' : '',
                        classes.fieldWrapper,
                        noMargin ? classes.noMargin : '',
                        !handleContactPhoneNumber ? classes.disabled : '',
                        className,
                    ].join(' ')}
                    disableDropdown
                    disabled={!handleContactPhoneNumber}
                    inputClass={[classes.input, inputPropsClassName].join(' ')}
                    isValid={(inputNumber, _, countries: Country[]) => {
                        const isValidCheck = countries.some((country) => {
                            return inputNumber.startsWith(country.dialCode)
                                || country.dialCode.startsWith(inputNumber);
                        });

                        setIsValid(isValidCheck);
                        return isValidCheck;
                    }}
                    placeholder={placeholder}
                    value={phoneNumber}
                    onBlur={() => setIsFocused(false)}
                    onChange={handleContactPhoneNumber}
                    onFocus={() => setIsFocused(true)}
                />
            </div>
        </CommonTooltip>
    );
};

export default PhoneNumberTextField;
