import React, { forwardRef, useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import EditMode from 'shared-components/GoogleAddress';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess from 'hooks/useHasAccess/useHasAccess';
import { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import ReadMode from './ReadMode';
import styles from './AddressCard.style';

type Props = {
    className?: string,
    currentAddressLine1?: string,
    currentAddressLine2?: string,
    currentAddressLine3?: string,
    currentCity?: string,
    currentCountry?: string,
    currentCountryCode?: string,
    currentEditedCard?: string,
    currentGeolocation?: {
        latitude?: number,
        longitude?: number,
    },
    currentZipCode?: string,
    geofenceEnabled?: boolean,
    geofenceSide?: number,
    isCompanyPage?: boolean,
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setFilter?: (filter) => void,
    setGeofenceEnabled?: (geofenceEnabled: boolean) => void,
    setGeofenceSide?: (geofenceSide: number) => void,
    type?: 'new' | 'editable' | 'readOnly'
}

const cardName = 'AddressCard';

const AddressCard = forwardRef<HTMLDivElement, Props>(({
    className = '',
    currentAddressLine1 = '',
    currentAddressLine2 = '',
    currentAddressLine3 = '',
    currentCity = '',
    currentCountry = '',
    currentCountryCode = '',
    currentEditedCard = null,
    currentGeolocation = { latitude: null, longitude: null },
    currentZipCode = '',
    geofenceEnabled,
    geofenceSide,
    isCompanyPage = false,
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setFilter = () => {},
    setGeofenceEnabled,
    setGeofenceSide = () => {},
    type = 'readOnly',
}: Props, ref) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const rolesCanEdit = [userRoles.CUSTOMER_ADMIN, userRoles.SKYCELL_ADMIN];

    if (!isCompanyPage) rolesCanEdit.push(userRoles.SITE_MANAGEMENT);
    const isCurrentUserAdmin = hasAccess(...rolesCanEdit);

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setEditMode(false);
        setCurrentEditedCard(null);
    };

    return (
        <CardWithEditableContent
            ref={ref}
            className={`${className} ${loading ? classes.loading : ''}`}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            showEditButton={type === 'editable' && isCurrentUserAdmin && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            title={t('COMMON.ADDRESS')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
        >
            {
                type === 'new' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            currentAddressLine1={currentAddressLine1}
                            currentAddressLine2={currentAddressLine2}
                            currentAddressLine3={currentAddressLine3}
                            currentCity={currentCity}
                            currentCountry={currentCountry}
                            currentCountryCode={currentCountryCode}
                            currentGeolocation={currentGeolocation}
                            currentZipCode={currentZipCode}
                            geofenceEnabled={geofenceEnabled}
                            geofenceSide={geofenceSide}
                            setFilter={setFilter}
                            setGeofenceEnabled={setGeofenceEnabled}
                            setGeofenceSide={setGeofenceSide}
                            type={type}
                        />
                    )
                    : (
                        <ReadMode
                            currentAddressLine1={currentAddressLine1}
                            currentAddressLine2={currentAddressLine2}
                            currentAddressLine3={currentAddressLine3}
                            currentCity={currentCity}
                            currentCountry={currentCountry}
                            currentCountryCode={currentCountryCode}
                            currentGeolocation={currentGeolocation}
                            currentZipCode={currentZipCode}
                        />
                    )
            }
        </CardWithEditableContent>
    );
});

AddressCard.displayName = 'AddressCard';

export default AddressCard;
