import { css } from '@emotion/css';
import { Theme } from '@emotion/react';

export const generateStyles = (theme: Theme) => {
    const modalWindow = css({
        '.MuiSelect-iconStandard': {
            color: '#4B4B4B',
        },
        background: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
        padding: '28px 35px 19px 35px',
        position: 'relative',
        width: '562px',
        zIndex: 101,
    });

    const modalWrapper = css({
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
        width: '100vw',
    });

    const modalTitle = css({
        color: theme.palette.secondary[800],
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '17px',
    });

    const closeIcon = css({
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        position: 'absolute',
        right: '8px',
        top: '10px',
    });

    const backdrop = css({
        background: '#0000001F',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 100,
    });

    const buttonHolder = css({
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    });

    const addAccessRequestButton = css({

    });
    const error = css({
        color: theme.palette.error.main,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        marginBottom: '5px',
        marginTop: '5px',
    });

    const select = css({
        width: '100%',
    });
    const caretIcon = css({
        display: 'block',
    });

    return {
        addAccessRequestButton,
        backdrop,
        buttonHolder,
        caretIcon,
        closeIcon,
        error,
        modalTitle,
        modalWindow,
        modalWrapper,
        select,
    };
};
