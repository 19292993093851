import React, {
    ChangeEvent,
    DragEvent,
    useEffect,
    useState,
} from 'react';

import FileUpload from './FileUpload';

type Props = {
    /**
     * Is it possible to select multiple files?
     */
    multiple?: boolean,
    /**
     * Function to set selected file/files for upload
     */
    setFormData: (formData: FormData) => void
}

/**
 * Component for preparing files for uploading to BE
 */
const FileUploadContainer = ({
    multiple = false,
    setFormData,
} : Props) => {
    const [fileList, setFileList] = useState<FileList>(null);
    const [fileArray, setFileArray] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [indexToDelete, setIndexToDelete] = useState<number>(null);

    useEffect(() => {
        if (fileList && fileList.length) {
            const newArr = [];

            for (let i = 0; i < fileList.length; i++) {
                newArr.push(fileList[i]);
            }
            if (multiple) {
                setFileArray((prev) => prev.concat(newArr));
            } else {
                setFileArray(newArr);
            }
        }
    }, [fileList]);

    useEffect(() => {
        if (indexToDelete !== null) {
            setFileArray((prev) => prev.filter((item, index) => index !== indexToDelete));
            setIndexToDelete(null);
        }
    }, [indexToDelete]);

    useEffect(() => {
        setFileNames(fileArray.map((item) => item.name));

        if (fileArray.length > 0) {
            const formData = new FormData();

            if (fileArray.length === 1) {
                formData.append('file', fileArray[0]);
            } else {
                for (let i = 0; i < fileArray.length; i++) {
                    formData.append(`file${i + 1}`, fileArray[i]);
                }
            }
            setFormData(formData);
        } else {
            setFormData(null);
        }
    }, [fileArray]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target?.files?.length > 0) {
            const selectedFiles:HTMLInputElement = document.querySelector('input[type=file]');

            setFileList(selectedFiles.files);
        }
    };

    const onDrop = (event: DragEvent<HTMLElement>) => {
        event.preventDefault();

        if (multiple) {
            setFileList(event.dataTransfer.files);
        } else {
            setFileList(Array.prototype.slice.call(event.dataTransfer.files, 0, 1));
        }
    };

    return (
        <FileUpload
            fileNames={fileNames}
            multiple={multiple}
            setIndexToDelete={setIndexToDelete}
            onChange={onChange}
            onDrop={onDrop}
        />
    );
};

export default FileUploadContainer;
