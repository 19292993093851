import React from 'react';
import { Link } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useCacheContext from 'hooks/useCacheContext';

import useClasses from 'hooks/useClasses';
import styles from './BackToLink.style';

type Props = {
    isGeneric?: boolean,
    marginBottom?: string | number,
    marginLeft?: string | number,
    marginRight?: string | number,
    marginTop?: string | number,
    title?: string,
    to?: string
}

const BackToLink = ({
    isGeneric = false, // '/shipments',
    marginBottom = 10,
    marginLeft = 12,
    marginRight = 0,
    marginTop = 10,
    title,
    to = null,
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const { getCacheValue } = useCacheContext();

    const pathTo = to
        || `/track-and-trace/${isGeneric ? 'generic-' : ''}shipments?view=${getCacheValue('VIEW_TYPE') || 'map'}`;

    return (
        <Link
            className={classes.link}
            style={{
                marginBottom,
                marginLeft,
                marginRight,
                marginTop,
            }}
            to={pathTo}
        >
            ←&nbsp;
            {title || t('COMMON.SHIPMENTS_LIST')}
        </Link>
    );
};

export default BackToLink;
