const styles = (theme) => ({
    card: {
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        marginLeft: 'auto',
        maxWidth: '14%',
        minWidth: '14%',
    },
    cardHeader: {
        font: 'normal normal normal 16px/19px Roboto!important',
    },
    cardTitle: {
        color: `${theme.palette.common.black}!important`,
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    contactItem: {
        marginBottom: '5px',
    },
    fieldInfo: {
        color: `${theme.palette.common.black}!important`,
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
    },
    fieldInfoAndIconContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    fieldInfoLineBreak: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-line',
    },
    fieldTitle: {
        color: theme.palette.common.black,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '5px',
        opacity: 1,
        textAlign: 'left',
    },
    icon: {
        color: theme.palette.secondary[100],
        filter: 'brightness(0) saturate(100%)',
        height: '13px',
        marginRight: '3px',
    },
});

export default styles;
