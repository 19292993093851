import { css } from '@emotion/css';

const generateStyles = (theme, props: { searchBoxLeft: number }) => {
    const open = css({
        '&&': {
            opacity: '1!important',
            pointerEvents: 'unset',
        },
    });
    const closed = css({
        '& *': {
            pointerEvents: 'none',
        },
    });
    const preview = css({
        '&&': {
            position: 'absolute',
            top: '79px',
        },
        marginLeft: '270px',
        marginTop: '-40px',
        maxWidth: `calc(100vw - ${props.searchBoxLeft + 40}px)`,
        opacity: 0,
        pointerEvents: 'none',
        transition: '100ms ease',
        width: 'auto',
        zIndex: 200,
        [`&.${open}`]: {
            pointerEvents: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
    });

    const paddedTable = css({
        padding: '0 10px 10px 10px',
    });

    const buttonContainer = css({
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 10px 0 0',
    });

    const clearSearchButton = css({
        marginRight: '16px',
        textTransform: 'none',
    });

    return {
        buttonContainer,
        clearSearchButton,
        closed,
        open,
        paddedTable,
        preview,
    };
};

export default generateStyles;
