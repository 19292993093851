import React from 'react';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';

type Props = {
    color: string,
    small?: boolean,
    title?: string
}

const Box = ({
    color,
    small = false,
    title = '',
}: Props) => {
    return (
        <CommonTooltip
            description={title}
            placement="bottom"
            position="absolute"
            type={TOOLTIP_TYPE.SHRINK}
            whiteSpace="nowrap"
        >
            <div
                style={{
                    background: color,
                    borderRadius: '5px',
                    display: 'flex',
                    height: small ? '6px' : '24px',
                    width: small ? '6px' : '24px',
                }}
            />
        </CommonTooltip>
    );
};

export default Box;
