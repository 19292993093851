import React from 'react';
import useClasses from 'hooks/useClasses';
import styles from './IconWithTooltip.style';

type Props = {
    description: string,
    descriptionTitle?: string,
    iconSrc?: string
}

const CustomTitle = ({
    description,
    descriptionTitle = null,
    iconSrc = null,
} : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.tooltipContainer}>
            {
                Boolean(iconSrc && descriptionTitle) && (
                    <div className={classes.titleContainer}>
                        <img
                            alt={descriptionTitle}
                            className={classes.iconWithRightMargin}
                            src={iconSrc}
                        />
                        <div className={classes.title}>{descriptionTitle}</div>
                    </div>
                )
            }
            <div className={classes.tooltipBody}>{description}</div>
        </div>
    );
};

export default CustomTitle;
