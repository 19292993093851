import useClasses from 'hooks/useClasses';
import React, {
    useEffect,
    useMemo,
    useState, useCallback, useContext,
} from 'react';
import { LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import useGetCommonData from 'hooks/useGetCommonData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { PageWithFilter } from 'Layout';
import { FacetDTO } from 'shared-components/dataTypes';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import Table from 'shared-components/Table';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';

import { styles } from 'TrackAndTrace/Loggers/Loggers.style';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import StandalonePagination from 'shared-components/StandalonePagination';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { MAP_CONFIG } from 'shared-components/constants';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import {
    extractLoggerData,
    ExtractedLoggerData,
    initialFilterOptions,
} from './lib';

import LoggerFilterPanel from './components/LoggerFilterPanel';
import MapView from './MapView';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;

const Loggers = ({ core = 'track-and-trace' }) => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const { getFilter, setFilter } = useFilter(PAGES.LOGGERS);
    const filter = getFilter();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const pairingStatusLabels = useGetTranslationGroup('PAIRING_STATUS');
    const [highlightedText, setHighlightedText] = useState('');
    const classes = useClasses(styles);
    const [query, setQuery] = useState(filter?.query || '');
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialFilterOptions);

    const {
        setHasOnboardingTour,
        setOnboardingTooltipInfo,
    } = useContext(QuickHelpContext);
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    useEffect(() => {
        setZoom(MAP_CONFIG.zoom);
    }, [view]);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawLoggers, status: loggersStatus,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            cache: true,
            enabled: facetsStatus === 'SUCCESS',
            query: {
                page: page - 1,
                pageSize: PAGE_SIZE,
                ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            },
            queryWrap: false,
            refetchOnMount: true,
        });

    const changeHighlightedText = (newHighlightedText: string) => {
        setHighlightedText(newHighlightedText);
    };

    const [loggers, setLoggers] = useState<ExtractedLoggerData[]>([]);
    const [showLoggerTypes, setShowLoggerTypes] = useState(filter?.showLoggerTypes || false);
    const [showLastConnection, setShowLastConnection] = useState(filter?.showLastConnection || false);
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);
    const [loggersFromPreviewTable, setLoggersFromPreviewTable] = useState<ExtractedLoggerData[]>(null);

    useEffect(() => {
        if (loggersStatus === 'SUCCESS' && rawLoggers?.resultList) {
            const allLoggers = extractLoggerData(rawLoggers?.resultList, loggerTypeLabels, pairingStatusLabels);

            setTotalElements(rawLoggers.totalElements);
            setLoggers(allLoggers);
        }
    }, [loggersStatus, rawLoggers, loggerTypeLabels]);

    const columns = useMemo(() => tableColumns(
        t,
        core === 'track-and-trace',
    ), [t, core]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.max(Math.ceil(totalElements / PAGE_SIZE), 1));
    }, [page, totalElements]);

    const withoutGeolocationCount = useMemo(() => {
        return loggers?.filter(logger => !logger.lastMeasuredLatitude || !logger.lastMeasuredLongitude)?.length || 0;
    }, [loggers]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            showAirportsInfo,
            showLastConnection,
            showLoggerTypes,
            sort,
            totalElements,
        });
    },
    [
        page,
        totalElements,
        query,
        filterOptions,
        sort,
        showLoggerTypes,
        showLastConnection,
        showAirportsInfo,
    ]);

    useEffect(() => {
        setOnboardingTooltipInfo({
            core: ONBOARDING_TYPE.LOGGER,
            order: 5,
            padding: 0,
            position: 'auto',
            text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
            title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
            uid: 'supportTicketCreationQuickHelpAsset',
        });
        setHasOnboardingTour(view === 'map');
        return () => {
            setOnboardingTooltipInfo(null);
            setHasOnboardingTour(false);
        };
    }, [view]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: loggersStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <LoggerFilterPanel
                    changeHighlightedText={changeHighlightedText}
                    core={core}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    initialFacets={rawInitialFacets}
                    isTableView={view === 'table'}
                    loggersFromPreviewTable={loggersFromPreviewTable}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setLoggersFromPreviewTable={setLoggersFromPreviewTable}
                    setQuery={setQuery}
                    setShowAirportsInfo={setShowAirportsInfo}
                    setShowLastConnection={setShowLastConnection}
                    setShowLoggerTypes={setShowLoggerTypes}
                    showAirports={showAirportsInfo}
                    showLastConnection={showLastConnection}
                    showLoggerTypes={showLoggerTypes}
                />
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname={`/${core}/loggers`}
                        tooltipInfo={{
                            core: ONBOARDING_TYPE.LOGGER,
                            isMain: true,
                            order: 4,
                            position: 'center',
                            text: t('ONBOARDING.LOGGERS.LEGEND_DESC'),
                            title: t('ONBOARDING.LOGGERS.LEGEND_TITLE'),
                            uid: 'LOGGER_SWITCH_MAP_TABLE',
                        }}
                    />
                    {view === 'table'
                        ? (
                            <Table
                                classNames={{
                                    tableContainerClassName: classes.filteredTable,
                                }}
                                columns={columns}
                                currentSort={sort}
                                data={loggers}
                                maskForHighlight={highlightedText}
                                rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                                title={t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS')}
                                onSort={updateSort}
                            />
                        )
                        : (
                            <MapView
                                core={core}
                                countOfLoggersWithoutGeolocation={withoutGeolocationCount}
                                loggers={loggers.filter(it => it.lastMeasuredLongitude && it.lastMeasuredLatitude)}
                                setZoom={setZoom}
                                showAirportsInfo={showAirportsInfo}
                                showLastConnection={showLastConnection}
                                showLoggerTypes={showLoggerTypes}
                                zoom={zoom}
                            />
                        )}
                    {
                        view === 'table' && <StandalonePagination detached entity="Loggers" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Loggers;
