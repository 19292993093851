import {
    CommonTableItem,
    TABLE_INPUT_TYPE,
} from 'LaneManagement/LaneDetails/StepsWrapper/CommonStepTable/CommonTableTypes';
import { secondsToDurationStringHHMM } from 'LaneManagement/LaneDetails/tools';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    description: CommonTableItem,
    value: any
}

const TableInputRender = ({
    description,
    value,
}: Props) => {
    const { t } = useCustomTranslation();

    switch (description.inputType) {
    case TABLE_INPUT_TYPE.TEMPERATURE:
        return `${value} °C`;
    case TABLE_INPUT_TYPE.TIME_24:
        return secondsToDurationStringHHMM(value, t);
    case TABLE_INPUT_TYPE.SELECT:
        return description?.selectData?.find(it => it.value === value)?.render;
    default:
        return value;
    }
};

export default TableInputRender;
