import { css } from '@emotion/css';

const generateStyles = (theme) => {
    const box = css({
        alignItems: 'flex-end',
        color: theme.palette.secondary[600],
        display: 'flex',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
        width: '234px',
    });

    const title = css({
        color: theme.palette.secondary[900],
        font: 'normal normal normal 16px/20px Roboto',
        letterSpacing: '0.26px',
        marginBottom: '12px',
        opacity: 1,
    });

    const input = css({
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
        width: '206px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    });

    const expandButtonText = css({});

    const searchPreview = css({});

    const searchPreviewShipmentContainer = css({
        maxWidth: '210px',
    });

    const shipmentsContainer = css({
        display: 'grid',
        gridGap: '4px',
        gridTemplateColumns: '1fr 1fr',
    });

    const searchPreviewShipmentTitle = css({
        color: '#009CCF !important',
        font: 'normal normal normal 12px/19px Roboto',
        letterSpacing: '0.25px',
        margin: '12px 0',
        whiteSpace: 'normal',
    });

    const searchPreviewShipment = css({
        alignItems: 'center',
        background: '#F4F4F4 0% 0% no-repeat padding-box',
        borderRadius: '16px',
        color: '#000000DE',
        display: 'flex',
        font: 'normal normal normal 12px/19px Roboto',
        justifyContent: 'center',
        letterSpacing: '0.25px',
        opacity: 1,
        padding: '3px 8px',
        textAlign: 'left',
    });

    const tooltipPlacementTop = css({
        font: 'normal normal normal 12px/14px Roboto',
        marginTop: '4px!important',
    });

    const filterBox = css({
        '&>*': {
            width: '100%',
        },
        padding: '16px',
    });

    const divider = css({
        background: theme.palette.secondary[200],
        height: '1px',
        width: '100%',
    });

    return {
        box,
        divider,
        expandButtonText,
        filterBox,
        input,
        searchPreview,
        searchPreviewShipment,
        searchPreviewShipmentContainer,
        searchPreviewShipmentTitle,
        shipmentsContainer,
        title,
        tooltipPlacementTop,
    };
};

export default generateStyles;
