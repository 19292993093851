import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    addCompanyPageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '4fr 2fr',
        margin: 'auto',
        padding: '12px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
    },
    addEditCompanyApproveButton: {
        textTransform: 'uppercase',
    },
    addEditCompanyButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '28px',
    },
    addEditCompanyCardContentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 2fr 1fr',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    addEditCompanyFieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '5px',
        marginTop: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    addEditCompanyRejectButton: {
        textTransform: 'uppercase',
    },
    addEditCompanyTextField: {
        marginTop: '12px',
        width: '100%',
    },
    cell: {
        padding: '15px',
    },
    editCompanyPageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr',
        padding: '12px',
    },
    hcell: {
        padding: '0 0 0 15px',
    },
    paddedTable: {
        padding: '0 15px 15px 15px',
    },
});

export default styles;
