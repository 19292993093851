import React, { ReactElement } from 'react';

import useClasses from 'hooks/useClasses';
import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';
import CustomTitle from './CustomTitle';
import styles from './IconWithTooltip.style';

type Props = {
    children?: ReactElement,
    className?: string,
    description: string,
    descriptionTitle: string,
    iconSrc?: string,
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

const IconWithTooltip = ({
    children = null,
    className = '',
    description,
    descriptionTitle,
    iconSrc,
    placement = 'bottom-start',
} : Props) => {
    const classes = useClasses(styles);

    return (
        <CommonTooltip
            description={(
                <CustomTitle
                    description={description}
                    descriptionTitle={descriptionTitle}
                    iconSrc={iconSrc}
                />
            )}
            placement={placement}
        >
            <div>
                {
                    !children && (
                        <img
                            alt={descriptionTitle}
                            className={className || classes.icon}
                            src={iconSrc}
                        />
                    )
                }
                {children}
            </div>
        </CommonTooltip>
    );
};

export default IconWithTooltip;
