import React from 'react';
import { Classes } from 'jss';
import SelectFieldSearch from 'shared-components/SelectFieldSearch';
import SelectFieldSearchClient from 'shared-components/SelectFieldSearchClient';
import { InformationCardField } from '../fieldMapper';

type Props = {
    classes: Classes,
    clientSide?: boolean,
    field: InformationCardField,
    filter?: any,
    filterName?: string,
    font?: 'small' | 'medium',
    onChange: (value: string, id?: string) => void,
    readOnly?: boolean,
    request: string,
    value: string
}

const CompanySelectorField = ({
    classes,
    clientSide = false,
    field,
    filter = null,
    filterName = 'name',
    font = null,
    onChange,
    readOnly = false,
    request,
    value,
}: Props) => {
    return (
        clientSide ? (
            <SelectFieldSearchClient
                className={classes.select}
                filterList={filter}
                filterName={filterName}
                font={font}
                label={field.label}
                mandatory={field.mandatory}
                readOnly={readOnly}
                url={request}
                value={value}
                onChange={onChange}
            />
        )
            : (
                <SelectFieldSearch
                    className={classes.select}
                    filterName={filterName}
                    font={font}
                    label={field.label}
                    mandatory={field.mandatory}
                    readOnly={readOnly}
                    requestObject={request}
                    value={value}
                    onChange={onChange}
                />
            )
    );
};

export default CompanySelectorField;
