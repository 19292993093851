import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import moment from 'moment';
import { TIME_IN_MS } from 'shared-components/constants';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import styles from './ShipmentSteps.style';

type Props = {
    dash?: boolean,
    milestoneInfo?: Milestones,
    stepStatus: string,
};

const ShipmentStepLocation = ({
    dash = false,
    milestoneInfo,
    stepStatus,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const theme = useTheme<SkycellThemeInterface>();
    const {
        deliveryLocation,
        name,
        pickupLocation,
        progress,
    } = milestoneInfo || {};
    const {
        actualDelivery,
        actualPickup,
        plannedDelivery,
        plannedPickup,
    } = progress || {};

    // Determine the correct geolocation and timestamp to use based on the dash flag
    const geolocation = dash ? (deliveryLocation?.geolocation) : (pickupLocation?.geolocation);
    const timestamp = dash ? (actualDelivery || plannedDelivery) : (actualPickup || plannedPickup);

    // Get timezone information for the relevant geolocation
    const { gmtOffsetInMs, loading } = useGetTimeZoneInfoByGeolocation(geolocation, timestamp, true);

    // Format the timestamp based on the timezone offset
    const formatDate = (timestamp) => {
        if (loading) {
            // Optionally handle the loading state by returning null or some placeholder
            return t('ORDER_DETAILS.LOADING_TIMEZONE_DATA');
        }

        if (!timestamp) {
            return t('ORDER_DETAILS.NO_DATE_TIME_YET');
        }

        return moment(timestamp)
            .utc(true)
            .utcOffset(gmtOffsetInMs / TIME_IN_MS.minute)
            .format('DD.MM.YYYY HH:mm Z');
    };

    const displayDate = formatDate(timestamp);

    // Determine the color based on the step status
    const color = stepStatus === 'CLOSED' || stepStatus === 'COMPLETED'
        ? theme.palette.primary.deepBlue : theme.palette.common.black;

    return (
        <div className={classes.orderStepLocation}>
            <div className={classes.timeline}>
                {dash && (
                    <div className={classes.dashWrapper}>
                        <svg height="65" width="10">
                            <rect
                                fill="transparent"
                                height="100"
                                stroke={color}
                                strokeDasharray="5 3"
                                strokeWidth="1"
                                width="10"
                                x="5"
                                y="-4"
                            />
                        </svg>
                    </div>
                )}
                <div className={classes.dataDot} style={{ background: color }} />
            </div>
            <div className={classes.locationData}>
                <div className={classes.locationDataContent}>
                    <div className={classes.dataPart}>
                        <span className={`${classes.dataText}`}>
                            {!dash ? (pickupLocation?.name || name) : (deliveryLocation?.name || name)}
                        </span>
                    </div>
                    <div className={classes.dataPart}>
                        <img
                            alt="status"
                            className={classes.dataIcon}
                            height={25}
                            src={icons.clock}
                            style={{ filter: color === theme.palette.primary.deepBlue
                                ? 'invert(64%) sepia(54%) saturate(6823%)'
                                    + 'hue-rotate(167deg) brightness(93%) contrast(139%)'
                                : 'brightness(0)' }}
                            width={25}
                        />
                        <div className={classes.dataTextClock}>
                            {displayDate}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShipmentStepLocation;
