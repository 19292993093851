import React, {
    MouseEventHandler,
    useMemo,
    useRef,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import CommonButton from 'shared-components/CommonButton';
import generateStyles from 'TrackAndTrace/commonComponents/PreviewTable/PreviewTable.style';

type Props = {
    columns: ColumnsType[],
    data: { [key: string]: any }[],
    isCardOpen?: boolean,
    maskForHighlight: string,
    onCardClick: MouseEventHandler<HTMLDivElement>,
    onClickButtonClearSearch: MouseEventHandler<HTMLButtonElement>,
    onClickButtonShowResults: MouseEventHandler<HTMLButtonElement>,
    rowLinkTemplate: string,
}

const PreviewTable = ({
    columns,
    data,
    isCardOpen = false,
    maskForHighlight,
    onCardClick,
    onClickButtonClearSearch,
    onClickButtonShowResults,
    rowLinkTemplate = '',
} : Props) => {
    const ref = useRef(null);
    const searchBoxLeft = useMemo(() => ref.current?.getBoundingClientRect()?.x || 0, [ref.current]);
    const classes = useGeneratedStyles(generateStyles, { searchBoxLeft });
    const { t } = useCustomTranslation();

    return (
        <Card
            ref={ref}
            className={[isCardOpen ? classes.open : classes.closed, classes.preview].join(' ')}
            title={t('COMMON.SEARCH_RESULTS')}
            zeroSidePadding
            onClick={onCardClick}
        >
            <Table
                classNames={{
                    tableContainerClassName: classes.paddedTable,
                }}
                columns={columns}
                data={(data || [])}
                embed
                maskForHighlight={maskForHighlight}
                rowLinkTemplate={rowLinkTemplate}
                tableMaxHeight="30vh"
            />
            <div className={classes.buttonContainer}>
                <CommonButton
                    className={classes.clearSearchButton}
                    variant="text"
                    onClick={onClickButtonClearSearch}
                >
                    Clear Search
                </CommonButton>
                <CommonButton
                    disabled={data?.length === 0 || data?.length > 20}
                    variant="contained"
                    onClick={onClickButtonShowResults}
                >
                    Show Results
                </CommonButton>
            </div>
        </Card>
    );
};

export default PreviewTable;
