import { StepValidation } from 'LaneManagement/LaneDetails/LaneDetails';
import React from 'react';
import { Milestone, AddressDTO } from 'shared-components/dataTypes';

export enum CardType {
    MILESTONE,
    SUMMARY_LEFT,
    SUMMARY_RIGHT,
    PRODUCTS,
    FLIGHTS,
    SKYCELL_INFO,
    LOADING,
    IN_TRANSIT,
    UNLOADING,
}
export type EditingCardInfo = {
    id: number,
    type: CardType,
}
interface LaneCardsContextInterface {
    deleteMilestone?: Function,
    deliveryLocation?: AddressDTO,
    editingCard: EditingCardInfo,
    pickupLocation?: AddressDTO,
    setEditingCard: Function,
    setMilestonesLoading: (arg: boolean) => void,
    setSteps: Function,
    shouldUpdateLane?: number,
    steps: Milestone[],
    stepValidation: StepValidation[],
    updateLane?: Function,
    updateMilestone?: Function,
    updateMilestones?: Function

}

const initialState = {
    deleteMilestone: () => {},
    deliveryLocation: { },
    editingCard: null,
    pickupLocation: { },
    setEditingCard: () => {},
    setMilestonesLoading: () => {},
    setSteps: () => {},
    stepValidation: [],
    steps: [],
    updateMilestone: () => {},
    updateMilestones: () => {},

};
const LaneCardsContext = React.createContext<LaneCardsContextInterface>(initialState);

export default LaneCardsContext;
