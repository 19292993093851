import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import ShipmentsReport from 'Insights/ShipmentsReport';
import AssetInventory from 'Insights/AssetInventory';
import LaneRisk from 'Insights/LaneRisk/LaneRisk';

const getInsightsRouter = (hasAccess: (...accessRoles: string[]) => boolean): RouteItem[] => {
    const redirectPath = (hasAccess(userRoles.INSIGHTS || userRoles.ASSET_MANAGEMENT))
        && '/insights/shipments';

    return [
        {
            element: <div><Navigate to={redirectPath} /></div>,
            path: '/insights',
        },
        {
            element: <ShipmentsReport />,
            necessaryRoles: [userRoles.INSIGHTS],
            path: '/insights/shipments',
        },
        {
            element: <AssetInventory />,
            path: '/insights/asset-inventory',
            sufficientRoles: [userRoles.INSIGHTS, userRoles.ASSET_MANAGEMENT],
        },
        {
            element: <LaneRisk />,
            necessaryRoles: [userRoles.INSIGHTS],
            path: '/insights/lane-risk',
        },
    ];
};

export default getInsightsRouter;
