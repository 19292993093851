import React, {
    CSSProperties,
    MouseEventHandler,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { useCalculatedRectStyle } from 'Contexts/QuickHelpContext/useCalculatedRectStyle';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

type RectangleProps = {
    index: number,
    mask?: boolean,
    screenSize: {
        height: number,
        scale: number,
        width: number
    },
    tooltipInfo: QuickHelpTooltipInfo
}

const TooltipInfoToRectangle = ({
    index,
    mask = true,
    screenSize,
    tooltipInfo,
}: RectangleProps) => {
    const {
        childOffsetPercent,
        childRef,
        customRectSize,
        offsetPx,
        padding,
        radius,
    } = tooltipInfo || {};
    const {
        enableTooltips,
    } = useContext(QuickHelpContext);

    const [delayedUpdate, setDelayedUpdate] = useState(Date.now());
    const timeoutRef = useRef(null);

    const updateDelayed = useCallback(() => {
        if (!enableTooltips) {
            return;
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setDelayedUpdate(Date.now());
        }, 200);
    }, [enableTooltips, screenSize]);

    useEffect(() => {
        updateDelayed();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [enableTooltips, screenSize, updateDelayed]);
    const childRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = childRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipInfo, screenSize, delayedUpdate]);

    const PADDING = padding !== null && padding !== undefined ? padding : TOOLTIP_PADDING;
    const rectStyle = useCalculatedRectStyle({
        childOffsetPercent,
        childRect,
        customRectSize,
        offsetPx,
        padding: enableTooltips ? PADDING : 25,
        radius,
        screenSize,
        shouldUpdate: enableTooltips ? 1 : 2,
    });

    return (
        <rect
            key={`rect_${tooltipInfo?.uid}_border`}
            height={`${rectStyle?.height}px`}
            rx={radius || PADDING}
            style={mask ? {
                stroke: 'black',
                strokeWidth: 3,
                transition: 'stroke 100ms ease, opacity 100ms ease, transform 100ms ease',
                transitionDelay: `${index * 200}ms`,
            } : {
                fill: 'transparent',
                stroke: '#EDAE49FF',
                strokeWidth: 3,
                transition: 'stroke 100ms ease, opacity 100ms ease, transform 100ms ease',
                transitionDelay: `${index * 200}ms`,
            }}
            width={`${rectStyle?.width}px`}
            x={rectStyle?.left}
            y={rectStyle?.top}
        />
    );
};

type Props = {
    onClick: MouseEventHandler<SVGSVGElement>,
    screenSize: {
        height: number,
        scale: number,
        width: number
    },
    style: CSSProperties,
    tooltipInfos: QuickHelpTooltipInfo[]
}

export const SvgWithCutouts = ({
    onClick,
    screenSize,
    style = {},
    tooltipInfos = [],
}: Props) => {
    return useMemo(() => {
        return (
            <svg
                height={`${screenSize.height }px`}
                style={{
                    height: `${screenSize.height}px`,
                    opacity: 1,
                    position: 'absolute',
                    width: `${screenSize.width}px`,
                    zIndex: 10000,
                    ...style,
                }}
                width={`${screenSize.width }px`}
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick}
            >
                {
                    tooltipInfos
                        .map((tooltipInfo, index) => (
                            <TooltipInfoToRectangle
                                key={`rect_${tooltipInfo?.uid}`}
                                index={index}
                                mask={false}
                                screenSize={screenSize}
                                tooltipInfo={tooltipInfo}
                            />
                        ))
                }

                <g>
                    <rect
                        fill="rgba(0, 0, 0, 0.3)"
                        height={`${screenSize.height }px`}
                        mask="url(#cutout-mask)"
                        width={`${screenSize.width }px`}
                        x="0"
                        y="0"
                    />
                    <mask id="cutout-mask">
                        <rect
                            fill="white" height="100%" width="100%" x="0"
                            y="0"
                        />
                        {
                            tooltipInfos
                                .map((tooltipInfo, index) => (
                                    <TooltipInfoToRectangle
                                        key={`rect_${tooltipInfo?.uid}`}
                                        index={index}
                                        screenSize={screenSize}
                                        tooltipInfo={tooltipInfo}
                                    />
                                ))
                        }
                    </mask>
                </g>

            </svg>
        );
    }, [screenSize.width, screenSize.height, tooltipInfos, style, onClick]);
};
