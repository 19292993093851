import useClasses from 'hooks/useClasses';
import React, {
    useEffect,
    useState,
} from 'react';
import CheckboxRow from 'shared-components/CheckboxRow';

const NONE_OF_THE_ABOVE = 'NONE_OF_THE_ABOVE';

const styles = theme => ({
    checkboxStyle: {
        margin: '0 9px',
        padding: '0',
    },
    container: {
        display: 'flex',
    },
    icon: {
        marginRight: '5px',
    },
    textFieldLabel: {
        color: '#B7B7B7',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 0.5,
        textAlign: 'left',
    },
    title: {
        color: theme.palette.secondary[500],
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        opacity: 1,
        textAlign: 'left',
    },
});

type Props = {
    availableAnswerOptions: string[],
    defaultValues: string[],
    disabled?: boolean,
    setAnswerUpdate: (answerUpdate: string[]) => void,
}

const MultipleChoiceType = ({
    availableAnswerOptions = [],
    defaultValues = [],
    disabled = false,
    setAnswerUpdate,
} : Props) => {
    const classes = useClasses(styles);
    const [values, setValues] = useState([...defaultValues]);

    useEffect(() => {
        if (values.length > 1) {
            if (values[values.length - 1] === NONE_OF_THE_ABOVE) {
                setValues([NONE_OF_THE_ABOVE]);
            } else if (values.includes(NONE_OF_THE_ABOVE)) {
                setValues(prev => prev.filter(value => value !== NONE_OF_THE_ABOVE));
            }
        }
    }, [values.length]);

    useEffect(() => {
        if (values.length) {
            setTimeout(() => {
                setAnswerUpdate(values);
            }, 150);
        }
    }, [values.length]);

    return (
        <div>
            {
                availableAnswerOptions.map((option, index) => {
                    return (
                        <CheckboxRow
                            key={`CheckboxRow-${index}`}
                            classes={classes}
                            disabled={disabled}
                            filter={values}
                            initialValue={values.includes(option)}
                            label={<div>{option}</div>}
                            setFilter={setValues}
                            value={option}
                        />
                    );
                })
            }
        </div>
    );
};

export default MultipleChoiceType;
