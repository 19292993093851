import React from 'react';

interface CustomThemeContextInterface {
    page?: number,
    paginationLoading?: boolean,
    perPage?: number,
    setPage: (page: number) => void,
    setPerPage?: (perPage: number) => void,
    totalElements?: number
}

const initialState = {
    page: 0,
    paginationLoading: false,
    perPage: 10,
    setPage: () => {},
    setPerPage: () => {},
    totalElements: 0,
};

const DataPaginationContext = React.createContext<CustomThemeContextInterface>(initialState);

export default DataPaginationContext;
