const styles = theme => ({
    error: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #D44848',
        borderRadius: '3px',
        opacity: 1,
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '5px',
        marginTop: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    filedInfo: {
        color: '#747474',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        marginLeft: '6px',
        opacity: 1,
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
    },
    iconContainer: {
        display: 'flex',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
    },
    textField: {
        marginTop: '12px',
        width: '100%',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        paddingRight: '0!important',
        textAlign: 'left',
    },
});

export default styles;
