import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    background: {
        alignItems: 'center',
        borderRadius: '3px',
        color: '#747474',
        display: 'inline-flex',
        gap: '2px',
        opacity: 1,
        padding: '0 8px 0px 4px',
        whiteSpace: 'nowrap',
    },
    backgroundBlue: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
    },
    backgroundGreen: {
        background: '#EAF9EF 0% 0% no-repeat padding-box',
    },
    backgroundRed: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
    },
    backgroundYellow: {
        background: '#F3EAD9 0% 0% no-repeat padding-box',
    },
    large: {
        font: 'normal normal normal 14px/19px Roboto',
    },
    medium: {
        font: 'normal normal normal 14px/17px Roboto',
    },
    small: {
        font: 'normal normal normal 10px/13px Roboto',
    },
    statusDot: {
        borderRadius: '50%',
        display: 'inline-flex',
        height: '7px',
        width: '7px',
    },
    statusDotBackgroundBlue: {
        background: '#2C5969 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundGreen: {
        background: '#17921F 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundRed: {
        background: '#D44848 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundYellow: {
        background: '#EDAE49 0% 0% no-repeat padding-box',
    },
    text: {
        color: theme.palette.common.black,
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.18px',
        opacity: 1,
        padding: '1px 0',
        textAlign: 'left',
    },
});

export default styles;
