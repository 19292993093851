const styles = theme => ({
    card: {
        borderRadius: '4px 0 0 4px',
    },
    contentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        // gridGap: '12px',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    disablePadding: {
        padding: '0px!important',
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 2px 0',
        opacity: 1,
        textAlign: 'left',
    },
    filedInfo: {
        color: '#747474',
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    mapContainer: {
        // @ts-ignore
        background: theme.palette.common.contrastWhite,
        borderRadius: '0 4px 4px 0!important',
        boxShadow: `0px 1px 3px ${theme.palette.secondary.main}`,
        right: '0',
        top: '0',
    },
});

export default styles;
