import useClasses from 'hooks/useClasses';
import React, { ChangeEvent } from 'react';
import Switch from '@mui/material/Switch';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip/QuickHelpTooltip';

const styles = {
    medium: {
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
    },
    showMarkersSwitchContainer: {
        alignItems: 'center',
        color: '#747474',
        display: 'flex',
        opacity: 1,
        textAlign: 'left',
    },
    small: {
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
    },
    switchFirstLabel: {
        marginLeft: '5px',
    },
    switchLabel: {
        margin: '0 5px 0 17px',
    },
};

type Props = {
    className?: string,
    color?: 'primary' | 'secondary' | 'default',
    disabled?: boolean,
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    // setValue: (value) => void,
    size?: 'small' | 'medium',
    switchFirst?: boolean,
    title: string,
    tooltipInfo?: QuickHelpTooltipInfo,
    value: boolean
}

const SwitchWithLabel = ({
    className = '',
    color = 'primary',
    disabled = false,
    onChange,
    // setValue,
    size = 'small',
    switchFirst = false,
    title,
    tooltipInfo = {
        text: null,
        uid: 'switchWithLabelTooltip',
    },
    value,
} : Props) => {
    const classes = useClasses(styles);

    // const handleChange = () => setValue(prev => !prev);

    const containerClass = `${classes.showMarkersSwitchContainer} ${classes[size]} ${className}`;

    return (
        <QuickHelpTooltip tooltipInfo={tooltipInfo}>
            <div>
                {switchFirst
                    ? (
                        <div className={containerClass}>
                            <Switch
                                checked={value}
                                color={color}
                                disabled={disabled}
                                size={size}
                                onChange={onChange}
                            />
                            <div className={classes.switchFirstLabel}>{title}</div>
                        </div>
                    )
                    : (
                        <div className={containerClass}>
                            <div className={classes.switchLabel}>{title}</div>
                            <Switch
                                checked={value}
                                color={color}
                                disabled={disabled}
                                size={size}
                                onChange={onChange}
                            />
                        </div>
                    )}
            </div>
        </QuickHelpTooltip>

    );
};

export default SwitchWithLabel;
