import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { PageWithFilter } from 'Layout';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import Table, { useTableSideFilter } from 'shared-components/Table';
import tableColumns from './tableColumns';

const PharmaSites = () => {
    const { t } = useCustomTranslation();
    const { getFilter, setFilter } = useFilter(PAGES.USERS);
    const filter = getFilter();
    const [pharmaSites, setPharmaSites] = useState([]);
    const [filteredPharmaSites, setFilteredPharmaSites] = useState(filter?.filteredData || []);
    const { GetAll: getAllPharmaSites } = useSkymindBackendEndpoints('pharma-sites').requests;

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllPharmaSites();

                setPharmaSites(response.data.resultList || []);
            } catch (error) {
                setPharmaSites([]);
            }
        })();
    }, []);

    useEffect(() => {
        setFilter({
            filteredData: filteredPharmaSites,
        });
    }, [filteredPharmaSites]);

    const updatedPharmaSitesInfo = useMemo(() => {
        if (pharmaSites.length === 0) {
            return [];
        }

        return pharmaSites.map(company => {
            const { address, ...restInfo } = company;

            return {
                ...restInfo,
                city: address?.city,
                countryName: address?.country?.name || '',
            };
        });
    }, [pharmaSites]);

    const columns = useMemo(() => tableColumns(t), []);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        data: updatedPharmaSitesInfo,
        setFilteredData: setFilteredPharmaSites,
        tableId: 'PharmaSitesTableFilter',
    });

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '10px',
                width: `calc(100% - ${filterFullWidth}px)`,
            }}
            >
                <Table
                    bodyCellVerticalAlign="middle"
                    columns={columns}
                    data={filteredPharmaSites}
                    equalColumnWidths
                    maskForHighlight={`${(selectedFilterOptions?.name?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.name?.[0])
                    || (selectedFilterOptions?.city?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.city?.[0])}`}
                    rowLinkTemplate="/administration/pharma-sites/:id"
                    tableMaxHeight="100%"
                    title={t('COMMON.PHARMA_SITES')}
                />
            </div>
        </PageWithFilter>
    );
};

export default PharmaSites;
