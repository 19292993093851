import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import AnswerUnit from 'shared-components/CompanyInfoComponents/AnswerUnit';
import { QuestionnaireItem } from 'shared-components/dataTypes';

const styles = theme => ({
    answerContainer: {
        display: 'flex',
        gap: '30px',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    modalBody: {
        backgroundColor: theme.palette.common['contrastWhite50'],
        boxShadow: '24',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modalData: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        padding: '20px',
        textAlign: 'left',
    },
    modalHeader: {
        alignItems: 'center',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        height: '35px',
        opacity: 1,
    },
    modalHeaderText: {
        color: theme.palette.common.white,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        opacity: 1,
        padding: '8px',
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    tooltipIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
        alignItems: 'center',
        backgroundColor: theme.palette.primary[200],
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        height: '18px',
        justifyContent: 'center',
        marginRight: '7px',
        width: '18px ',
    },
});

type Props = {
    questionnaire: QuestionnaireItem,
}

const CapabilityDetails = ({ questionnaire }: Props) => {
    const classes = useClasses(styles);
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <>
            <div onClick={handleClick} onKeyPress={handleClick}>
                <span className={classes.tooltipIcon}>?</span>
            </div>
            <Modal
                aria-describedby="modal-modal-description"
                aria-labelledby="modal-modal-title"
                open={open}
                onClose={handleClick}
            >
                <div className={classes.modalBody}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderText}>CAPABILITY DETAILS</div>
                    </div>
                    <div className={classes.modalData}>
                        <div>
                            This capability is available because of the answer to this question:
                        </div>
                        <div className={classes.answerContainer}>
                            <div>{questionnaire.question}</div>
                            <AnswerUnit
                                amount={questionnaire.amount}
                                availableAnswerOptions={questionnaire.availableAnswerOptions}
                                disabled
                                question={questionnaire.question}
                                questionType={questionnaire.questionType}
                                selectedAnswerOptions={questionnaire.selectedAnswerOptions}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CapabilityDetails;
