import useClasses from 'hooks/useClasses';
import React, { useCallback } from 'react';
import { MyLocation } from '@mui/icons-material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LatLng } from 'dataTypes/common';
import { useGoogleMap } from '@react-google-maps/api';
import CommonButton from 'shared-components/CommonButton';
import { styles } from './CenterPositionButton.style';

type Props = {
    position: LatLng,
}

const CenterPositionButton = ({
    position,
}:Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const map = useGoogleMap();

    const onCenter = useCallback(() => {
        if (map && position) {
            map.panTo(position);
        }
    }, [position, map]);

    return (
        <div className={classes.pane}>
            <CommonButton
                className={classes.button}
                size="small"
                onClick={onCenter}
            >
                <MyLocation style={{ marginRight: 5 }} />
                <span>
                    {t('COMMON.CENTER_ON_POSITION')}
                </span>
            </CommonButton>
        </div>
    );
};

export default CenterPositionButton;
