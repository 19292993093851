import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    approveButton: {
        '& svg': {
            maxWidth: '36px',
        },
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        height: '35px',
        textTransform: 'uppercase',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '28px',
    },
    pageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '2fr 1fr 1fr 2fr',
        padding: '12px',
    },
    pageContentButtons: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '3fr 3fr',
    },
    rejectButton: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #8BD1E8',
        borderRadius: '4px',
        color: '#8BD1E8',
        font: 'normal normal 500 14px/17px Roboto',
        height: '36px',
        letterSpacing: '1.25px',
        margin: '0 7px',
        opacity: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '90px',
    },
});

export default styles;
