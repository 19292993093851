// import { ReactNode } from 'react';
import icons from 'shared-components/icons';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';

export type ASSET_TYPES_ENUM = 'PALLET' | 'CONTAINER' | 'OTHER';

export type EntityType = 'shipment' | 'packagings' | 'loggers' | 'assets';

export interface PathParams {
    entityNumber: string,
    entityType: EntityType
}

export const entityTypeArr = ['shipment', 'packagings', 'loggers'];

export interface PackagingsInThisShipment {
    lastMeasuredGeolocation?: Geolocation,
    lastMeasuredTempInternal?: number,
    lastMeasuredTempInternalTimestamp?: number,
    packagingPictureUrl: string,
    packagingType: string,
    packagingTypeLabel: string,
    productReleaseId: number,
    serialNumber: string,
    temperatureRange: string,
}

export const initialActivePackaging = {
    lastMeasuredTempInternal: null,
    lastMeasuredTempInternalTimestamp: null,
    packagingPictureUrl: icons.default_packaging_icon,
    packagingType: '',
    packagingTypeLabel: '',
    productReleaseId: null,
    serialNumber: '',
    temperatureRange: '',
};
export interface EntitySelectorItem {
    additionalData?: {
        assetNumber?: string,
        assetPictureUrl?: string,
        assetType?: string,
        batteryLevel?: number,
        currentShipmentNumber?: string,
        hawbNumber?: string,
        iataCodeFrom?: string,
        iataCodeTo?: string,
        lastMeasuredGeolocation?: Geolocation,
        lastMeasuredTemp?: number,
        lastMeasuredTempAmbient?: number,
        lastMeasuredTempInternal?: number,
        lastMeasuredTempInternalTimestamp?: number,
        leaseEnd?: string,
        leaseEndExpected?: string,
        leaseStart?: string,
        loggerNumber?: string,
        loggerType?: string,
        loggerTypeLabel?: string,
        mawbNumber?: string,
        ownedByCompanyId?: number,
        ownedByCompanyName?: string,
        packagingCount?: number,
        packagingPictureUrl?: string,
        packagingsInThisShipment?: PackagingsInThisShipment[],
        packagingType?: string,
        packagingTypeLabel?: string,
        shipmentEnd?: string,
        shipmentStart?: string,
        shipmentStatus?: string,
        temperatureRange?: string
    },
    batteryLevel?: number,
    entityId?: number,
    entityNumber: string,
    entityType: EntityType,
}

export interface CachedEntitySelectorItems {
    items: EntitySelectorItem[],
    timestamp: number,
}

export const initialEntitySelectorItem: EntitySelectorItem = {
    additionalData: {},
    entityNumber: '',
    entityType: null,
};

export const initialRequestBody = {
    dataTypes: [],
    loggers: [],
    positions: [],
};

export const dataTypeOptions = [
    // 'DOOR',
    'LOCATION_LATITUDE',
    'LOCATION_LONGITUDE',
    'TEMPERATURE',
];

export const positionOptions = [
    'AMBIENT',
    'INTERNAL',
];

export const ASSET_TYPES = {
    CONTAINER: 'CONTAINER',
    OTHER: 'OTHER',
    PALLET: 'PALLET',
};
