import React, { useMemo } from 'react';
import { AddressLocation } from 'shared-components/dataTypes';
import { Classes } from 'jss';
import { LOCATION_OPTIONS } from 'shared-components/LocationSelector/LocationSelector';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    classes: Classes,
    data: AddressLocation,
    error: boolean,
    noIcon?: boolean,
    onClick: Function,
    short: boolean,
    type: string,
    wrap: boolean,
}

const PredefinedAddress = ({
    classes,
    data,
    error = false,
    noIcon = false,
    onClick,
    short = false,
    type,
    wrap = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const name = useMemo(() => {
        switch (type) {
        case 'pharmaSite':
        case 'serviceProviderSite':
            return data?.name;
        default:
            return `${data?.code || data?.pharmaCompanyName} | ${data?.name || data?.address?.city}`;
        }
    }, [type, data, short]);

    return (
        <div className={[classes.infoText].join(' ')}>
            <span
                className={[classes.line, error ? classes.error : ''].join(' ')}
            >
                {
                    short && !noIcon && (
                        <img
                            alt={type}
                            className={classes.iconShort}
                            src={LOCATION_OPTIONS(t)[type]?.icon}
                        />
                    )
                }
                <span
                    className={[onClick ? classes.editable : '', wrap ? classes.wrap : ''].join(' ')}
                    title={name}
                >
                    {name}
                </span>

                {
                    !short && !noIcon && (
                        <img
                            alt={type}
                            className={classes.icon}
                            src={LOCATION_OPTIONS(t)[type]?.icon}
                        />
                    )
                }
            </span>
        </div>
    );
};

export default PredefinedAddress;
