import { useState, useEffect, useMemo } from 'react';
import useGetCommonData from 'hooks/useGetCommonData';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import {
    fetchEntitiesFromShipments,
    fetchEntitiesFromLoggers,
    fetchAssets,
    initializeEntityList } from 'SensorDataCommon/lib';

const useLoadSensorData = (entitySelectorItem, exclusiveFor = null, entityNumber = null, isAdmin = false) => {
    const packagingCodeLabels = useGetTranslationGroup('PACKAGING_CODE_LABEL');
    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');

    const fetchParams = useMemo(() => ({
        assets: !exclusiveFor || exclusiveFor === 'assets' || isAdmin,
        loggers: !exclusiveFor || exclusiveFor === 'loggers',
        shipments: !exclusiveFor || exclusiveFor === 'shipments',
    }), [exclusiveFor]);

    const {
        data: rawShipments,
        status: shipmentsRequestStatus,
    } = useGetCommonData('shipment', {
        enabled: fetchParams.shipments,
        postProcess: it => it.resultList,
    });

    const {
        data: rawLoggers,
        status: loggerRequestStatus,
    } = useGetCommonData('loggers/search', {
        enabled: fetchParams.loggers,
        postProcess: it => it.resultList,
        query: entityNumber ? { q: entityNumber } : {},
    });

    const {
        data: rawAssets,
        status: assetRequestStatus,
    } = useGetCommonData(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: fetchParams.assets,
        postProcess: it => it.resultList,
        query: entityNumber ? { q: entityNumber } : {},
    });

    const [shipmentList, setShipmentList] = useState(initializeEntityList(entitySelectorItem, 'shipment'));
    const [loggerList, setLoggerList] = useState(initializeEntityList(entitySelectorItem, 'loggers'));
    const [assetList, setAssetList] = useState(initializeEntityList(entitySelectorItem, 'assets'));
    const [loggerListAll, setLoggerListAll] = useState(initializeEntityList(entitySelectorItem, 'loggers'));
    const [assetListAll, setAssetListAll] = useState(initializeEntityList(entitySelectorItem, 'assets'));

    const isFetchComplete = useMemo(() => ['SUCCESS']
        .includes(shipmentsRequestStatus) && ['SUCCESS']
        .includes(loggerRequestStatus) && ['SUCCESS']
        .includes(assetRequestStatus), [shipmentsRequestStatus, loggerRequestStatus, assetRequestStatus]);

    useEffect(() => {
        if (shipmentsRequestStatus === 'SUCCESS') {
            setShipmentList(fetchEntitiesFromShipments(rawShipments, packagingCodeLabels));
        }
    }, [rawShipments, shipmentsRequestStatus, packagingCodeLabels]);

    useEffect(() => {
        if (loggerRequestStatus === 'SUCCESS') {
            setLoggerList(fetchEntitiesFromLoggers(rawLoggers, loggerTypeLabels));
            setLoggerListAll(fetchEntitiesFromLoggers(rawLoggers, loggerTypeLabels));
        }
    }, [rawLoggers, loggerRequestStatus, loggerTypeLabels]);

    useEffect(() => {
        if (assetRequestStatus === 'SUCCESS') {
            setAssetList(fetchAssets(rawAssets, assetTypeLabels));
            setAssetListAll(fetchAssets(rawAssets, assetTypeLabels));
        }
    }, [rawAssets, assetRequestStatus, assetTypeLabels]);

    return {
        assetList,
        assetListAll,
        assetRequestStatus,
        isFetchComplete,
        loggerList,
        loggerListAll,
        loggerRequestStatus,
        shipmentList,
        shipmentsRequestStatus,
    };
};

export default useLoadSensorData;
