import React, {
    useMemo,
    useState,
} from 'react';
import useGetEnums from 'hooks/useGetEnums';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';

import useCustomTranslation from 'hooks/useCustomTranslation';
import SwitchItem from './SwitchItem';
import RoleDetails from './RoleDetails';

const loadingStyle = {
    filter: 'blur(2px)',
    pointerEvents: 'none',
    transition: '200ms ease',
};

type Props = {
    autoInclude?: string[],
    availableRoles?: string[],
    currentEditedCard?: string,
    currentRoles?: string[],
    disabledControlOnRoles?: string[],
    roles?: string[],
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setRoles: (roles: string[]) => void,
    type?: 'new' | 'editable' | 'readOnly'
}

const cardName = 'CompanyRolesCard';

const CompanyRolesCard = ({
    autoInclude = [],
    availableRoles = [],
    currentEditedCard = null,
    currentRoles = [],
    disabledControlOnRoles = [],
    roles = [],
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setRoles,
    type = 'readOnly',
}: Props) => {
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useCustomTranslation();
    const userRoleLabels = useGetTranslationGroup('USER_POLICY');
    const roleEnums = useGetEnums('UserRole');

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        try {
            setLoading(true);
            await saveInfo();
            setEditMode(false);
            setLoading(false);
            setCurrentEditedCard(null);
        } catch (error) {
            global.console.log('Failed to save roles');
            setRoles(currentRoles);
            setLoading(false);
        }
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setRoles(currentRoles);
        setEditMode(false);
    };

    const disabled = type === 'readOnly' || (type === 'editable' && !editMode);

    const sortedAndFilteredRoleEnums = useMemo(() => {
        const sortedRoleEnums = roleEnums.sort((a, b) => {
            return Object.keys(userRoleLabels).length === 0
                ? a?.localeCompare(b) // sorting by code
                : userRoleLabels[a]?.localeCompare(userRoleLabels[b]); // sorting by label
        });

        return availableRoles.length === 0
            ? sortedRoleEnums
            : sortedRoleEnums.filter(roles => availableRoles.includes(roles));
    }, [
        roleEnums,
        availableRoles,
        userRoleLabels,
    ]);

    return (
        <CardWithEditableContent
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            style={loading ? loadingStyle : {}}
            title={t('COMMON.ROLES')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ marginBottom: '80px' }}>
                    {
                        sortedAndFilteredRoleEnums.includes('BASIC_ACCESS')
                            ? '' : sortedAndFilteredRoleEnums.push('BASIC_ACCESS')
                    }
                    {
                        sortedAndFilteredRoleEnums.map(roleEnum => {
                            const autoIncluded = autoInclude.includes(roleEnum);

                            return (
                                <SwitchItem
                                    key={roleEnum}
                                    blocked={autoIncluded || disabledControlOnRoles.includes(roleEnum)}
                                    checked={roles.includes(roleEnum) || autoIncluded}
                                    conflictingRoles={['SHIPMENT_MANAGEMENT', 'TEMPERATURE_CHECK']}
                                    disabled={disabled || autoIncluded || disabledControlOnRoles.includes(roleEnum)}
                                    roleCode={roleEnum}
                                    setRoles={setRoles}
                                    title={userRoleLabels[roleEnum]}
                                />
                            );
                        })
                    }
                </div>
                <div style={{ bottom: '10px', position: 'absolute' }}>
                    <RoleDetails roleEnums={sortedAndFilteredRoleEnums} />
                </div>
            </div>
        </CardWithEditableContent>
    );
};

export default CompanyRolesCard;
