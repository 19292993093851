import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import { bakeLanes } from 'LaneManagement/Lanes/lib';
import React, { useEffect, useMemo, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import StatusInfo from 'shared-components/StatusInfo';
import Table, { useTableSideFilter } from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import useGetEntity from 'hooks/useGetEntity';
import LaneRowExpansion from './LaneRowExpansion';
import tableColumns from './tableColumns';
import { styles } from './LaneRowExpansion/LaneRowExpansion.style';

const AddShipment = () => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const {
        data: rawLanes = [],
        status,
    } = useGetCommonData('lanes', { postProcess: it => it.resultList });

    const {
        dataLoaded,
        entityFromAddressId,
        getEntity,
    } = useGetEntity();

    const [lanes, setLanes] = useState([]);
    const [laneData, setLaneData] = useState(null);
    const [filteredLanes, setFilteredLanes] = useState([]);
    const [intermediateFilteredLanes, setIntermediateFilteredLanes] = useState([]);
    const [searchString, setSearchString] = useState('');

    const rowClicked = (rowData) => {
        setLaneData(prev => (prev?.id === rowData?.id ? null : rowData));
    };

    useEffect(() => {
        const allRawLanes = rawLanes.map(it => ({ ...it, isShared: !!it?.skymindInfo }));

        setLanes(bakeLanes(allRawLanes, t, getEntity, entityFromAddressId));
    },
    [rawLanes, dataLoaded]);

    useEffect(() => {
        if (lanes.length === 0) {
            setIntermediateFilteredLanes([]);
        } else if (searchString.length === 0) {
            setIntermediateFilteredLanes(lanes);
        } else {
            const searchStringLowerCase = searchString.toLowerCase();
            const intermediateFiltered = lanes.filter(lane => {
                const {
                    fourthPartyLogistics = '',
                    laneNumber = '',
                    loadingLocation = '',
                    unloadingLocation = '',
                } = lane;

                return laneNumber.toLowerCase().includes(searchStringLowerCase)
                    || fourthPartyLogistics.toLowerCase().includes(searchStringLowerCase)
                    || loadingLocation.toLowerCase().includes(searchStringLowerCase)
                    || unloadingLocation.toLowerCase().includes(searchStringLowerCase);
            });

            setIntermediateFilteredLanes(intermediateFiltered);
        }
    }, [lanes, searchString]);

    const columns = useMemo(() => tableColumns(t), []);

    const {
        component: FilterComponent,
    } = useTableSideFilter({
        columns,
        data: intermediateFilteredLanes,
        externalTextFilterPlaceholder: t('COMMON.SEARCH'),
        externalTextFilterString: searchString,
        setExternalTextFilterString: setSearchString,
        setFilteredData: setFilteredLanes,
        tableId: 'GenericAddShipmentFilter',
    });

    return (
        <PageWithFilter>
            {
                status === 'SUCCESS'
                    ? FilterComponent
                    : <StatusInfo status={status} />
            }
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '10px' }}>
                {
                    status === 'SUCCESS'
                        ? (
                            <Table
                                classNames={{
                                    rowExtensionClassName: classes.expansionRoot,
                                }}
                                columns={columns}
                                data={filteredLanes}
                                rowExtension={
                                    laneData
                                        ? <LaneRowExpansion laneData={laneData} />
                                        : null
                                }
                                tableMaxHeight="100%"
                                title={t('TRACK_AND_TRACE.ADD_SHIPMENT.SELECT_LANE')}
                                onRowClick={rowClicked}
                            />
                        )
                        : <StatusInfo status={status} />
                }
            </div>
        </PageWithFilter>
    );
};

export default AddShipment;
