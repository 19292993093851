const styles = theme => ({
    card: {
        marginTop: '10px!important',
        padding: '16px!important',
    },
    loggerTitle: {
        color: theme.palette.secondary[800],
        display: 'flex',
        fontSize: '32px !important',
        justifyContent: 'space-between',
        letterSpacing: '0.26px',
        lineHeight: 1,
        marginBottom: '10px',
    },
    mapCard: {
        marginTop: theme.spacing(1.5),
    },
});

export default styles;
