import CompanyFilterPanel from 'Administration/Companies/CompanyFilters';
import {
    CompaniesDTO,
    ExtractedCompany,
    extractedCompanyData, initialCompanyFilterOptions,
} from 'Administration/Companies/lib';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { FacetDTO } from 'shared-components/dataTypes';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import Table from 'shared-components/Table';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { PageWithFilter } from 'Layout';
import { styles } from 'TrackAndTrace/Assets/Assets.style';
import StandalonePagination from 'shared-components/StandalonePagination';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { getOptionsFromFacets } from 'shared-components/common';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;
const Companies = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { getFilter, setFilter } = useFilter(PAGES.COMPANIES);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [companies, setCompanies] = useState<ExtractedCompany[]>([]);
    const [highlightedText, setHighlightedText] = useState('');
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('companies/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialCompanyFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('companies/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawCompanies, status: companiesStatus,
    } = useGetCommonData<CompaniesDTO>('companies/search', {
        cache: true,
        enabled: facetsStatus === 'SUCCESS',
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (companiesStatus === 'SUCCESS' && rawCompanies?.resultList) {
            const allCompanies = extractedCompanyData(rawCompanies?.resultList);

            setTotalElements(rawCompanies.totalElements);
            setCompanies(allCompanies);
        }
    }, [companiesStatus, rawCompanies]);

    useEffect(() => {
        if (rawInitialFacets) {
            setFilterOptions({
                companyRoles: filter?.filterOptions?.companyRoles
                    || getOptionsFromFacets(rawInitialFacets, 'companyRoles'),
                companyTypes: filter?.filterOptions?.companyTypes
                    || getOptionsFromFacets(rawInitialFacets, 'companyTypes'),
            });
        }
    }, [rawInitialFacets]);

    const columns = useMemo(() => tableColumns(t, highlightedText), [t, highlightedText]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            sort,
            totalElements,
        });
    }, [filterOptions, page, query, sort, totalElements]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setHighlightedText(query);
    }, [query]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: companiesStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <CompanyFilterPanel
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setQuery={setQuery}
                />
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <Table
                        bodyCellVerticalAlign="middle"
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={companies}
                        maskForHighlight={highlightedText}
                        rowLinkTemplate="/administration/companies/:id"
                        tableMaxHeight="100%"
                        title={t('MENU_ITEMS.COMPANIES')}
                        onSort={updateSort}
                    />

                    <StandalonePagination detached entity={t('MENU_ITEMS.COMPANIES')} />

                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Companies;
