import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const filtersWidth = 258;
const filterPad = 12;

export const filterFullWidth = filtersWidth + filterPad;

const styles = (theme: SkycellThemeInterface) => ({
    accordionDetailsStyle: {
        padding: '0!important',
    },
    // --------------- components style
    accordionStyle: {
        '& .Mui-expanded': {
            margin: 0,
            minHeight: 0,
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& > .Mui-expanded': {
            marginBottom: '16px',
        },
        '&:before': {
            display: 'none',
        },
        // @ts-ignore
        background: theme.palette.common.contrastWhite,
        boxShadow: 'none!important',

        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    accordionSummaryStyle: {
        minHeight: '0!important',
        padding: '0!important',
    },
    arrowSvg: {
        fontSize: '14px!important',
    },
    blockWithOverflow: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            borderRadius: '4px',
        },
        overflowX: 'hidden',
        overflowY: 'auto',
        transition: '200ms ease-in-out',
        [theme.breakpoints.up('md')]: {
            width: filtersWidth + filterPad,
        },
    },
    // Checkbox style
    checkboxRow: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '250px',
    },
    checkboxStyle: {
        margin: '0 5px 0 9px',
        padding: '0!important',
    },
    checked: {
        color: `${theme.palette.primary.deepBlue}!important`,
    },
    container: {
        '& > *': {
            margin: '0!important',
        },
        background: 'white',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        transition: '200ms ease-in-out',
        width: `${filtersWidth + filterPad}px`,
        zIndex: 29,
    },
    count: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'inline-flex',
        font: 'normal normal normal 14px/17px Roboto',
        justifyContent: 'flex-end',
        letterSpacing: '0.43px',
        marginRight: '7px',
        opacity: 1,
        textAlign: 'left',
    },
    divider: {
        background: theme.palette.secondary[200],
        height: '1px',
        width: '100%',
    },
    expandMoreStyle: {
        color: theme.palette.common.black,
        margin: '0',
        width: '24px',
    },
    filterBox: {
        '&>*': {
            width: '100%',
        },
        padding: '16px!important',
    },
    filtersPane: {
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            borderRadius: '4px',
        },
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        transition: '200ms ease-in-out',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('md')]: {
            width: filtersWidth + filterPad,
        },
    },
    filtersPaneOff: {
        opacity: 0,
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            height: '0px',
            overflow: 'hidden',
        },

    },
    formControlLabelStyle: {
        color: theme.palette.common.black,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.43px',
        maxWidth: '100%',
        opacity: 1,
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hidden: {
        background: 'transparent',
        boxShadow: 'unset',
    },
    hideButton: {
        alignItems: 'center',
        color: theme.palette.common.black,
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        userSelect: 'none',
        whiteSpace: 'nowrap',
    },
    hideButtonArrow: {
        alignItems: 'center',
        display: 'inline-flex',
        height: '20px',
        justifyContent: 'center',
        width: '20px',
        [theme.breakpoints.down('sm')]: {
            transform: 'rotate(90deg)',
        },
    },
    hideButtonText: {
        marginRight: '16px',
        whiteSpace: 'pre',
    },
    hideButtonWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        padding: '10px 0',
        transition: '200ms ease',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
    hideButtonWrapperClosed: {},
    labelEllipsis: {
        overflow: 'hidden',
        width: '100%',
    },
    marginRight: {
        marginRight: '10px',
    },
    multipleSelectionInput: {
        '&::placeholder': {
            color: theme.palette.secondary[900],
        },
    },
    root: {
        '&$checked': {
            color: theme.palette.primary.deepBlue,
        },
    },
    rotate: {
        transform: 'rotate(180deg)',
        [theme.breakpoints.down('sm')]: {
            transform: 'rotate(270deg)',
        },
    },
    searchTextFieldInput: {
        '& $input': {
            paddingLeft: '8px!important',
        },
        color: `${theme.palette.secondary[600]}!important`,
        font: 'normal normal normal 14px/17px Roboto!important',
        height: '31px!important',
        letterSpacing: '0.43px!important',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    selectDeselect: {
        color: theme.palette.primary.deepBlue,
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        letterSpacing: '0.03em',
        marginTop: '12px',
        textAlign: 'left',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    switchRow: {
        '& > span': {
            maxWidth: '75%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        justifyContent: 'space-between',
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    switchStyle: {

    },
    title: {
        color: theme.palette.common.black,
        font: 'normal normal normal 14px/16px Roboto',
        letterSpacing: '0.4px',
        opacity: 1,
        textAlign: 'left',
        whiteSpace: 'normal',
    },
    titleComponent: {
        color: theme.palette.secondary[900],
        font: 'normal normal normal 16px/20px Roboto',
        letterSpacing: '0.26px',
        marginBottom: '12px',
        opacity: 1,
    },
});

export default styles;
