import React, { useContext, useMemo } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Delete, Lock } from '@mui/icons-material';
import { Classes } from 'jss';
import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import { PackagingType } from 'LaneManagement/Lanes/tabs/TemperatureSimulation/TemperatureSimulation';
import moment from 'moment';
import useCustomTranslation from 'hooks/useCustomTranslation';
import tableColumns from '../../Packaging/tableColumns';
import { useGetSeasonalData } from './useGetSeasonalData';
import IconTooltip from '../../IconTooltip/IconTooltip';

type Props = {
    classes: Classes,
    edit: boolean,
    onDelete: Function,
    packagingType: PackagingType
}
const PackagingTypeRow = ({
    classes,
    edit,
    onDelete,
    packagingType,
}: Props) => {
    const {
        lane,
        loading,
        profileData,
        setProfileData,
    } = useContext(LaneTemperatureContext);
    const { t } = useCustomTranslation();
    const simulationData = useMemo(() => {
        if (!lane) return null;
        const temperatureData = (type = 'temperatureSummer') => ({
            simulationSteps: lane.milestones
                .map(it => it.activities)
                .flat()
                .map(it => ({
                    ambientTemperature: it[type],
                    intervalLengthMinutes: Math.floor(it.duration / 60),
                })),
            startTimestamp: moment().format('DD.MM.YYYY HH:mm ZZ'),
        });

        return {
            summer: temperatureData(),
            winter: temperatureData('temperatureWinter'),
        };
    }, [lane]);

    useGetSeasonalData({
        packagingType,
        packagingTypesLoading: loading,
        setProfileData,
        simulationData,
    });

    return (
        <TableRow
            className={classes.productsTableRow}
        >
            {
                tableColumns(profileData?.[packagingType?.type] || null, t)
                    .filter(it => !it?.skip)
                    .map((field, index) => {
                        let content;

                        if (typeof field.accessor === 'string') {
                            content = packagingType[field.accessor];
                        } else {
                            content = field.accessor(packagingType);
                        }

                        if (field.skip) return false;
                        return (
                            <TableCell
                                key={`cell_${field.Header}_${packagingType.type}_${index}`}
                                colSpan={field.colSpan || 1}
                            >
                                <span>
                                    {content}
                                </span>
                            </TableCell>
                        );
                    })
            }
            <TableCell className={classes.productsTableButtonCell}>
                {
                    edit && !packagingType?.isImmutable && (
                        <Delete
                            className={classes.icon}
                            onClick={
                                onDelete.bind(null, packagingType.type)
                            }
                        />
                    )
                }
                {
                    edit && packagingType?.isImmutable && (
                        <IconTooltip icon={<Lock />} text={t('LANE_MANAGEMENT.NOT_REMOVABLE_PACKAGING_TYPE')} />
                    )
                }
            </TableCell>
        </TableRow>
    );
};

export default PackagingTypeRow;
