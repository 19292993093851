import React, { ChangeEvent } from 'react';
import { Switch } from '@mui/material';
import useClasses from 'hooks/useClasses';
import OptionItemTooltip from './OptionItemTooltip';
import styles from '../SideFilter.style';

type Props = {
    defaultChecked?: boolean,
    label: string,
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    showTooltip?: boolean,
    tooltipDescription?: string
}

const SwitchOptionItem = ({
    defaultChecked,
    label,
    onChange,
    showTooltip = false,
    tooltipDescription = '',
} : Props) => {
    const classes = useClasses(styles);

    if (showTooltip) {
        return (
            <OptionItemTooltip
                description={tooltipDescription}
                descriptionTitle={label}
            >
                <div className={classes.switchRow}>
                    <span>{label}</span>
                    <Switch
                        className={classes.switchStyle}
                        color="primary"
                        defaultChecked={defaultChecked}
                        size="medium"
                        onChange={onChange}
                    />
                </div>
            </OptionItemTooltip>
        );
    }

    return (
        <div className={classes.switchRow}>
            <span>{label}</span>
            <Switch
                className={classes.switchStyle}
                color="primary"
                defaultChecked={defaultChecked}
                id={label}
                size="medium"
                onChange={onChange}
            />
        </div>
    );
};

export default SwitchOptionItem;
