export const styles = (theme) => ({
    control: {
        background: theme.palette.common.white,
    },
    hoverIconsRow: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 20px)',
        opacity: 0,
        pointerEvents: 'none',
        transition: '200ms ease-in-out',
        whiteSpace: 'nowrap',
    },
    hoverIconsWrapper: {
        padding: '6px 10px',
        position: 'absolute',
        right: '0px',
        top: '0px',

    },
    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
    },
    location: {
        background: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '16px',
        paddingBottom: '10px',
        paddingRight: '10px',
        paddingTop: '10px',
    },
    transport: {
        background: theme.palette.common.white,
    },
    visible: {
        opacity: '1!important',
        pointerEvents: 'auto',
    },
});
