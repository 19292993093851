export enum AccessRequestStatus {
    APPROVED= 'APPROVED',
    PENDING= 'PENDING',
    REJECTED= 'REJECTED'
}
export enum AccessRequestAction {
    APPROVE= 'approve',
    REJECT= 'reject',
}

export type AccessRequestDTO = {
    dataOwnerCompanyId: number,
    dataOwnerCompanyName: string,
    geofenceCoordinates?: {
        latitude: number,
        longitude: number,
    }[],
    geofenceId: number,
    geofenceName: string,
    id: number,
    requestingCompanyId: number,
    requestingCompanyName: string,
    siteId: number,
    siteName: string,
    status: AccessRequestStatus,
    updateComment: string,
}
export type AccessRequestsResponse = {
    resultList: AccessRequestDTO[];
    totalElements: number;
}

export type FilterFields = 'dataOwnerCompanyName' | 'statuse';

export const initialAccessRequestsFilterOptions: { [optionsGroupKey: string]: string[] } = {
    dataOwnerCompanyIds: [],
    statuses: [],
};
