import React, { useMemo, MouseEventHandler } from 'react';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { PolygonIconType } from 'shared-components/PolygonMarker/PolygonIcon';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    isSelected: boolean,
    loggerGroup: ExtractedLoggerData[],
    onHover: MouseEventHandler<HTMLDivElement>,
    showLastConnection?: boolean,
    showLoggerTypes?: boolean
}
const SKYMIND_LOGGER_TYPES = [
    'CARTASENSE',
    'CARTASENSE_M',
    'CL_BUTTERFLY',
    'CL_TT_7100',
    'SKYCELL_SAVY_BLE',
];

const isSkymindType = (family) => {
    return SKYMIND_LOGGER_TYPES.includes(family) || family.startsWith('MR_');
};

const LoggerMarker = ({
    isSelected = false,
    loggerGroup = [],
    onHover,
    showLastConnection = false,
    showLoggerTypes = false,
}: Props) => {
    const [firstEntry] = loggerGroup;
    const { t } = useCustomTranslation();
    const {
        lastMeasuredLatitude: lat,
        lastMeasuredLongitude: lng,
        lastMeasuredTimestamp,
    } = firstEntry || {};

    const icon = useMemo<PolygonIconType>(() => {
        const types = [...new Set(loggerGroup.map(it => (isSkymindType(it.loggerFamily) ? 'hexa' : 'tetra')))];

        return types.length === 1 ? types[0] : 'hexa';
    }, [loggerGroup]);

    const loggerTypes = useMemo<string>(() => {
        const loggerTypesSet = Array.from(new Set(loggerGroup.map(({ loggerFamilyLabel }) => loggerFamilyLabel)));

        return loggerTypesSet.length === 1 ? loggerTypesSet[0] : t('COMMON.VARIOUS');
    }, [loggerGroup]);

    return (
        <OverlayViewF
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{ lat, lng }}
            zIndex={20}
        >
            <div onClick={onHover} onMouseEnter={onHover}>
                <PolygonMarker
                    count={loggerGroup.length > 1 ? loggerGroup.length : 1}
                    filled={isSelected}
                    iconDiameter={35}
                    itemType={showLoggerTypes
                        ? loggerTypes : null}
                    lastConnected={
                        showLastConnection
                            ? lastMeasuredTimestamp
                            : null
                    }
                    lastConnectedIsVarious={loggerGroup.length > 1}
                    polygonIconColor="blue"
                    polygonIconType={icon}
                    theme="light"

                />

            </div>
        </OverlayViewF>
    );
};

export default LoggerMarker;
