import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    bottomWrapper: {
        // @ts-ignore
        background: theme.palette.common.contrastWhite,
        boxSizing: 'border-box',
        height: '110px',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            display: 'flex',
            height: 'auto',
            justifyContent: 'center',
        },
    },
    container: {
        border: `1px solid ${theme.palette.secondary[50]}`,
        borderRadius: 4,
        boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
        cursor: 'pointer',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-grid',
            gridAutoRows: '86px',
            gridTemplateColumns: '85px 1fr',
        },
    },
    iconWrapper: {
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            width: '100%',
        },

    },
    info: {
        color: theme.palette.secondary[600],
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    title: {
        color: theme.palette.secondary[900],
        fontSize: '20px',
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0px',
        },
    },
    topWrapper: {
        alignItems: 'center',
        background: theme.palette.primary.darkBlue,
        display: 'flex',
        height: '161px',
        justifyContent: 'space-between',
        padding: `0 ${theme.spacing(4)}`,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            padding: '10px',
        },
    },
    topWrapperIcon: {
        height: '80px',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            width: '100%',
        },
    },
});

export default styles;
