import { CSSProperties } from 'react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { getTempRulerStyleBackground } from '../TemperatureCheck/lib';

export const minRangeStyle: CSSProperties = {
    bottom: '0px',
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    marginTop: 'auto',
    position: 'relative',
    right: '-13px',
    textAlign: 'end',
    width: '31px',
    zIndex: 2,
};
export type BoundValues = { left: number, right: number, width: number };
export const maxRangeStyle: CSSProperties = {
    bottom: '0px',
    font: 'normal normal normal 12px/14px Roboto',
    left: '-19px',
    letterSpacing: '0.37px',
    marginTop: 'auto',
    position: 'relative',
    textAlign: 'end',
    width: '31px',
    zIndex: 2,
};
const BORDER_SIZE = 6;
const LABEL_WIDTH = 40;

export const getLineStyle = (lineCount: number, lastElement = false): CSSProperties => {
    return {
        borderLeft: '1px dashed #B7B7B7',
        height: '100%',
        marginRight: lastElement
            ? '0px'
            : lineCount === 7 ? '24px' : '14px',
    };
};

export const getNumberWidth = (temperature: number) => {
    const negativeNumber = temperature < 0 ? 4 : 0;
    const absolute = Math.abs(temperature);

    if (absolute >= 0 && absolute < 10 && absolute % 1 === 0) {
        return 32 + negativeNumber;
    } else if (absolute >= 0 && absolute < 10) {
        return 43 + negativeNumber;
    } else if (absolute >= 10 && absolute % 1 === 0) {
        return 43 + negativeNumber;
    }
    return 50 + negativeNumber;
};

export type TempStyleArgs = {
    boundValues?: BoundValues,
    isPotential: boolean,
    noData: boolean,
    tempMax?: number,
    tempMaxRange?: number,
    tempMin?: number,
    tempMinRange?: number,
    theme: SkycellThemeInterface,
}

export const getTempMinStyle = ({
    boundValues,
    isPotential,
    noData,
    tempMaxRange,
    tempMin,
    tempMinRange,
    theme,
}: TempStyleArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const dangerBack = isPotential ? theme.palette.secondary[50] : '#FFE5E5';
    const inRange = tempMin >= tempMinRange && tempMin <= tempMaxRange;

    const backgroundColor = tempMin === null
        ? '#EFEFEF'
        : inRange ? '#EAF9EF' : dangerBack;
    const color = tempMin === null
        ? '#747474'
        : inRange ? '#17921F' : danger;
    const {
        left,
    } = boundValues;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        boxSizing: 'content-box',
        color: noData ? '#747474' : color,
        font: 'normal normal normal 12px/14px Roboto',
        left: left - LABEL_WIDTH - BORDER_SIZE,
        letterSpacing: '0.37px',
        opacity: 1,
        padding: '0 4px',
        position: 'absolute',
        textAlign: 'center',
        top: '3px',
        width: LABEL_WIDTH,
    };
};

export const getTempMaxStyle = ({
    boundValues,
    isPotential,
    noData,
    tempMax,
    tempMaxRange,
    tempMinRange,
    theme,
}: TempStyleArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const dangerBack = isPotential ? theme.palette.secondary[50] : '#FFE5E5';

    const inRange = tempMax >= tempMinRange && tempMax <= tempMaxRange;

    const backgroundColor = tempMax === null
        ? '#EFEFEF'
        : inRange ? '#EAF9EF' : dangerBack;
    const color = tempMax === null
        ? '#747474'
        : inRange ? '#17921F' : danger;
    const {
        right,
    } = boundValues;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        boxSizing: 'content-box',
        color: noData ? '#747474' : color,
        font: 'normal normal normal 12px/14px Roboto',
        left: right + BORDER_SIZE * 3.5,
        letterSpacing: '0.37px',
        opacity: 1,
        padding: '0 4px',
        position: 'absolute',
        textAlign: 'center',
        top: '3px',
        width: LABEL_WIDTH,
    };
};

export type TempRulerArgs = {
    boundValues?: BoundValues,
    grid?: boolean,
    isPotential: boolean,
    noData: boolean,
    tempMax: number,
    tempMaxRange: number,
    tempMin: number,
    tempMinRange: number,
    theme: SkycellThemeInterface,
    width?: number
}

export const getTempRulerStyle = ({
    boundValues,
    isPotential,
    noData,
    tempMax,
    tempMaxRange,
    tempMin,
    tempMinRange,
    theme,
}: TempRulerArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const bothColor = isPotential ? '#F0DEBF' : '#FFE5E5';
    const minInRange = tempMin !== null && tempMin >= tempMinRange && tempMin <= tempMaxRange;
    const maxInRange = tempMax !== null && tempMax <= tempMaxRange && tempMax >= tempMinRange;
    const dangerBack = (!minInRange || !maxInRange) ? '#FFE5E5' : theme.palette.primary[100];

    const dangerGradient = isPotential ? '#F0DEBF' : dangerBack;

    const borderLeftColor = tempMin === null
        ? theme.palette.secondary[500]
        : minInRange ? theme.palette.primary[400] : danger;
    const borderRightColor = tempMax === null
        ? theme.palette.secondary[500]
        : maxInRange ? theme.palette.primary[400] : danger;
    const background = tempMin === null && tempMax === null
        ? `${theme.palette.secondary[100]} 0% 0% no-repeat padding-box`
        : getTempRulerStyleBackground(minInRange, maxInRange, dangerGradient, bothColor);
    const {
        left, width,
    } = boundValues;

    return {
        background: noData ? theme.palette.secondary[200] : background,
        borderLeft: `${BORDER_SIZE}px solid ${noData ? theme.palette.secondary[600] : borderLeftColor}`,
        borderRadius: '3px',
        borderRight: `${BORDER_SIZE}px solid ${noData ? theme.palette.secondary[600] : borderRightColor}`,
        boxSizing: 'content-box',
        height: '20px',
        left: left + BORDER_SIZE,
        position: 'absolute',
        top: '0',
        width,
        zIndex: 5,
    };
};
