import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import ManageBookings from './ManageBookings';
import PlaceBookings from './PlaceBookings';

const getBookingsRouter = (hasAccess: (...accessRoles: string[]) => boolean): RouteItem[] => {
    const redirectPath = (hasAccess(userRoles.BOOKING_MANAGEMENT))
        && '/bookings';

    return [
        {
            element: <div><Navigate to={redirectPath} /></div>,
            path: '/bookings',
        },
        {
            element: <ManageBookings />,
            necessaryRoles: [userRoles.BOOKING_MANAGEMENT],
            path: '/bookings/',
        },
        {
            element: <PlaceBookings />,
            path: '/bookings/new',
            sufficientRoles: [userRoles.BOOKING_MANAGEMENT],
        },
    ];
};

export default getBookingsRouter;
