import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import { PharmaSite, ServiceProviderSite } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';

import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useCustomTranslation from 'hooks/useCustomTranslation';
import ServiceProviderSiteCard from './components/ServiceProviderSite';
import PharmaSiteCard from './components/PharmaSite';
import useFilter, { PAGES } from '../../Contexts/useFilter';

const Sites = () => {
    const { GetAll: getPharmaSites } = useSkymindBackendEndpoints('pharma-sites').requests;
    const { company } = useCurrentUserContext();
    const { GetAll: getServiceProviderSites } = useSkymindBackendEndpoints(
        `service-provider-companies/${company.id}/service-provider-sites`)
        .requests;

    const {
        t,
    } = useCustomTranslation();
    const [sites, setSites] = useState<(PharmaSite | ServiceProviderSite)[]>([]);
    const { getFilter, setFilter } = useFilter(PAGES.COMPANY_DETAILS);
    const filter = getFilter();
    const hasAccess = useHasAccess();
    const {
        setHasOnboardingTour,
        setOnboardingTooltipInfo,
    } = useContext(QuickHelpContext);

    useEffect(() => {
        setFilter({
            ...filter,
            page: 1,
        });
        if (company?.companyType) {
            (async () => {
                try {
                    const response = company.companyType === 'PHARMA'
                        ? (await getPharmaSites()).data
                        : (await getServiceProviderSites()).data;

                    setSites(response?.resultList || response || []);
                } catch (error) {
                    setSites([]);
                    global.console.log(error);
                }
            })();
        }
    }, [company?.companyType]);

    useEffect(() => {
        const allowed = hasAccess(userRoles.SITE_MANAGEMENT) && hasAccess(userRoles.CUSTOMER_ADMIN);

        if (allowed) {
            setHasOnboardingTour(true);
            setOnboardingTooltipInfo({
                core: ONBOARDING_TYPE.SITE,
                order: 2,
                padding: 0,
                position: 'auto',
                text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
                title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
                uid: 'supportTicketCreationQuickHelpCompany',
            });

            return () => {
                setHasOnboardingTour(false);
                setOnboardingTooltipInfo(null);
            };
        }
    }, []);

    if (company.companyType === 'PHARMA') {
        return (
            <div
                style={{
                    display: 'grid',
                    gridGap: '12px',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    padding: '12px',
                }}
            >
                {
                    sites.map(site => {
                        return (
                            <PharmaSiteCard
                                key={site.id}
                                address={site.address}
                                link={`/my-company/sites/${site.id}/overview`}
                                name={site.name}
                            />
                        );
                    })

                }
            </div>
        );
    }

    if (company.companyType === 'SERVICE_PROVIDER') {
        return (
            <div style={{ padding: '12px' }}>
                {
                    sites.map(site => {
                        return (
                            <ServiceProviderSiteCard
                                key={site.id}
                                address={site.address}
                                certificates={[]}
                                link={`/my-company/sites/${site.id}/overview`}
                                name={site.name}
                            />
                        );
                    })

                }
            </div>
        );
    }

    return null;
};

export default Sites;
