import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme: SkycellThemeInterface) => ({
    divider: {
        background: theme.palette.secondary[300],
        flexBasis: '2px',
        marginInlineEnd: '12px',
        marginInlineStart: '12px',
    },
    options: {
        display: 'flex',
        flex: 2,
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    root: {
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        gap: '10px',
        minHeight: '54px',
        padding: '16px 16px',
        width: '100%',
        [theme.breakpoints.down(1500)]: {
            flexDirection: 'column',
        },
    },
    switch: {
        '& label': {
            cursor: 'pointer',
            userSelect: 'none',
            whiteSpace: 'nowrap',
        },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '12px',
        gap: '12px',
        maxHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
        },
    },
    switches: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    textInfo: {

    },
    title: {
        color: theme.palette.secondary[800],
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
        width: '144px',
    },
});
