import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    accordionDetailsRoot: {
        padding: '20px 20px 30px 10px',
    },
    accordionRoot: {
        borderBottom: '1px solid #DBDBDB',
        borderRadius: 0,
    },
    accordionTitle: {
        margin: 0,
    },
    accordionTitleExpanded: {
        minHeight: '26px',
    },
    accordionTitleRoot: {
        height: '60px!important',
        margin: 0,
        minHeight: '60px!important',
    },
    card: {
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        height: 'min-content',
        marginBottom: '12px',
    },
    cardTitle: {
        color: `${theme.palette.common.black}!important`,
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'capitalize',
        width: '100%',
    },
    dashWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '12px',
        },
    },
    dataDot: {
        borderRadius: '50%',
        height: '8px',
        width: '8px',
    },
    dataIcon: {
        '& #Box': {
            display: 'none',
        },
        minWidth: '20px',
        objectFit: 'contain',
        width: '20px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '12px',
            width: '12px',
        },
    },
    dataPart: {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',
    },
    dataRequestedClock: {
        color: theme.palette.secondary[500],
        fontSize: '12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px',
        },
    },
    dataText: {
        color: theme.palette.common.black,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    dataTextBlue: {
        color: `${theme.palette.primary[400]}!important`,
    },
    dataTextClock: {
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '13px',
    },
    flexContainer: {
        display: 'flex',
    },
    headerText: {
        color: (props) => `${props['closed'] ? theme.palette.primary.deepBlue
            : theme.palette.common.black}!important`,
        flex: 1,
        fontSize: '18px',
        letterSpacing: '0.26px',
    },
    inProgressIcon: {
        height: '21px',
        width: '21px',
    },
    loadingWrapper: {
        filter: 'blur(2px)',
    },
    locationData: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '10px',
        justifyItems: 'flex-start',
    },
    locationDataContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    orderStepLocation: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        gap: '11px',
    },
    scrollable: {
        overflowX: 'auto',
    },
    stepHeaderWrapper: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
    stepIcon: {
        maxWidth: '30px',
        objectFit: 'contain',
        width: 'inherit',
    },
    stepIconWrapper: {
        display: 'inline-block',
        height: '25px',
        marginRight: '5px',
        width: '40px',
    },
    timeline: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '35px',
    },
    title: {
        padding: '16px',
    },
});

export default styles;
