import React, { CSSProperties } from 'react';

const itemStyle: CSSProperties = {
    background: '#DFDFDF 0% 0% no-repeat padding-box',
    borderRadius: '16px',
    color: '#000000DE',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0.25px',
    marginRight: '12px',
    opacity: 1,
    padding: '3px 13px',
    textAlign: 'left',
};

type Props = {
    role: string[],
}

const RolesCell = ({ role = [] } : Props) => {
    return (
        role.length === 0
            ? null
            : (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '5px',
                    }}
                >
                    {
                        role.map((role, index) => (
                            <div key={`role-${index}`} style={itemStyle}>
                                {role}
                            </div>
                        ))
                    }
                </div>
            )
    );
};

export default RolesCell;
