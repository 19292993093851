import { OVERLAY_MOUSE_TARGET, OverlayViewF } from '@react-google-maps/api';
import React, { useState } from 'react';
import icons from 'shared-components/icons';

type Props = {
    isHighlighted?: boolean,
    label: string,
    position: {
        lat: number,
        lng: number,
    }
}

const GatewayMarker = ({
    isHighlighted = false,
    label,
    position,
}: Props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <OverlayViewF
            mapPaneName={OVERLAY_MOUSE_TARGET}
            position={position}
            zIndex={isHovered ? 1000 : 50}
        >
            <div
                style={{ display: 'inline-block', position: 'relative', textAlign: 'center' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <img alt="" src={isHighlighted ? icons.gateway_highlight : icons.gateway} />
                {isHovered && (
                    <div style={{
                        background: isHighlighted ? '#4790A8' : '#B16D00',
                        borderRadius: '4px',
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                        color: isHighlighted ? '#dbe0e3' : '#F0DEBF',
                        fontFamily: 'Roboto',
                        fontSize: '11px',
                        left: 'calc(50% + 13px)',
                        letterSpacing: '0.01em',
                        padding: '2px 4px',
                        position: 'absolute',
                        top: 'calc(100% - 4px)',
                        whiteSpace: 'nowrap',
                    }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </OverlayViewF>
    );
};

export default GatewayMarker;
