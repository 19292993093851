import React, { ReactChild, useCallback, useEffect, useMemo, useState } from 'react';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import {
    PackagingType,
    SimulationDataHolder,
} from 'LaneManagement/Lanes/tabs/TemperatureSimulation/TemperatureSimulation';
import { LaneObject } from 'shared-components/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { unwrapEnum } from 'LaneManagement/LaneDetails/tools';
import useGetPackagingTypes from 'hooks/useGetPackagingTypes';
import useGetEntity from 'hooks/useGetEntity';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { bakeLane } from 'LaneManagement/LaneDetails/Cards/SummaryCard/lib';
import LaneTemperatureContext from './LaneTemperatureContext';

type Props = {
    children: ReactChild,
    laneId: number | string,
}

const alignPackagingTypes: any = (
    packagingTypes: PackagingType[] = [],
    temperatureRange: string = 'CRT',
    t,
) => {
    return packagingTypes
        .map(pt => {
            const supportedTempRange = pt.temperatureRange === temperatureRange;

            if (!supportedTempRange) return null;
            const translatedName = t(`PACKAGING_CODE_LABEL.${pt.type}`);
            const translatedProvider = t(pt.provider);

            return {
                ...pt,
                temperatureSpecificName: `${translatedProvider} ${translatedName}`,
            };
        })
        .filter(pt => pt)
        .map(pt => unwrapEnum(pt));
};

export type ProfileDataHolder = {
    [key: string]: SimulationDataHolder
}
export const LaneTemperatureProvider = ({ children, laneId }: Props) => {
    const [lane, setLane] = useState<LaneObject>(null);

    const { t } = useCustomTranslation();
    const [profileData, setProfileData] = useState<ProfileDataHolder>(null);
    const {
        dataLoaded, entityFromAddressId, getEntity, replaceWithAddressDTO,
    } = useGetEntity();
    const allPackagingTypes = useGetPackagingTypes();
    const {
        Delete: deletePackagingTypeRequest,
        FlexibleRequest: updatePackagingTypeRequest,
    } = useSkymindBackendEndpoints(`lanes/${laneId}/packaging-types`).requests;
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();

    const [hasError, setHasError] = useState<boolean>(false);

    const { Get: getLaneById } = useSkymindBackendEndpoints('lanes').requests;

    useEffect(() => {
        (async () => {
            try {
                const laneResponse = await getLaneById(laneId);

                setLane(bakeLane(laneResponse?.data, replaceWithAddressDTO, getEntity, entityFromAddressId) || {});
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, [dataLoaded]);

    const packagingTypes = useMemo<PackagingType[]>(() => {
        if (!laneId) return [];
        if (!lane?.temperatureRange) return [];

        try {
            setHasError(false);

            return alignPackagingTypes(allPackagingTypes || [], lane?.temperatureRange, t) || [];
        } catch (error) {
            setHasError(true);
            global.console.log(error);
        }
    }, [lane, allPackagingTypes]);

    useEffect(() => {
    }, [lane, allPackagingTypes]);

    const addPackagingType = useCallback((code) => {
        (async () => {
            try {
                setHasError(false);
                const patchResponse = await updatePackagingTypeRequest('PATCH', '', {
                    code,
                }, {
                    'Content-Type': 'application/json',
                });

                if (patchResponse?.status === 200) {
                    setLane(prev => ({
                        ...prev,
                        packagingTypes: [...prev.packagingTypes, { code, isImmutable: false }],
                    }));
                }
                global.console.log({
                    patchResponse,
                });
            } catch (error) {
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
                setHasError(true);
                global.console.log(error);
            }
        })();
    }, [updatePackagingTypeRequest]);
    const deletePackagingType = useCallback((code) => {
        (async () => {
            try {
                setHasError(false);

                const deleteResponse = await deletePackagingTypeRequest(code);

                if (deleteResponse.status === 204) {
                    setLane(prevState => ({
                        ...prevState,
                        packagingTypes: prevState.packagingTypes.filter(pt => pt.code !== code),
                    }));
                }
                global.console.log({
                    deleteResponse,
                });
            } catch (error) {
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
                setHasError(true);
                global.console.log(error);
            }
        })();
    }, [deletePackagingTypeRequest]);

    return (
        <LaneTemperatureContext.Provider
            value={{
                addPackagingType,
                deletePackagingType,
                hasError,
                lane,
                loading: false,
                packagingTypes,
                profileData,
                setProfileData,
            }}
        >
            {children}
        </LaneTemperatureContext.Provider>
    );
};
