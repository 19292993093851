import React, { useEffect, useMemo, useRef, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import CommonButton from 'shared-components/CommonButton';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import { styles } from './HelloModal.style';

type Props = {
    close: () => void,
    isSuccess?: boolean,
    loading?: boolean,
    open: boolean,
    screenSize: {
        height: number,
        scale: number,
        width: number
    },
    shrinkFrom: DOMRect | null,
    start: () => void,
    subTitle?: string,
    text?: string,
    title?: string
}
const ConfettiComponent = ({ shouldRun }) => {
    const canvasRef = useRef(null);
    const [confetti, setConfetti] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const createConfettiPiece = () => ({
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
        d: Math.random() * 10,
        r: Math.random() * 4 + 1,
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
    });
    const updateConfettiPiece = (piece, index) => {
        piece.y += Math.cos(piece.d) + 1 + piece.r / 2;
        piece.x += Math.sin(piece.d) * 2;
        if (piece.x > window.innerWidth || piece.x < 0 || piece.y > window.innerHeight) {
            confetti[index] = createConfettiPiece();
            confetti[index].y = 0;
        }
    };

    const drawConfetti = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        confetti.forEach((piece, index) => {
            ctx.beginPath();
            ctx.arc(piece.x, piece.y, piece.r, 0, Math.PI * 2, false);
            ctx.fillStyle = piece.color;
            ctx.fill();
            updateConfettiPiece(piece, index);
        });
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const render = () => {
            drawConfetti(ctx);
            animationFrameId = requestAnimationFrame(render);
        };

        if (isRunning) {
            render();
        } else {
            cancelAnimationFrame(animationFrameId);
        }

        return () => cancelAnimationFrame(animationFrameId);
    }, [confetti, isRunning]);

    const runOnce = () => {
        setConfetti(Array.from({ length: 1000 }, createConfettiPiece));
        setIsRunning(true);
        setOpacity(1);
        setTimeout(() => {
            setIsRunning(false);
        }, 3000);
        setTimeout(() => {
            setOpacity(0);
        }, 2500);
    };

    useEffect(() => {
        if (shouldRun) runOnce();
    }, [shouldRun]);

    return (
        <div>
            <canvas
                ref={canvasRef}
                height={window.innerHeight}
                style={{
                    left: 0,
                    opacity,
                    pointerEvents: 'none',
                    position: 'fixed',
                    top: 0,
                    transition: 'opacity 0.5s' }}
                width={window.innerWidth}
            />
        </div>
    );
};

const HelloModal = ({
    close,
    isSuccess,
    loading,
    open,
    screenSize,
    shrinkFrom,
    start,
    subTitle,
    text,
    title,
}: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const contentHeight = useMemo(
        () => contentRef.current?.scrollHeight || 231, [contentRef.current, screenSize]);
    const classes = useClasses(styles, { contentHeight, open, screenSize, shrinkFrom });
    const { t } = useCustomTranslation();

    return (
        <div
            className={[classes.root, isSuccess && classes.rootSuccess].join(' ')}
        >
            <CommonTooltip
                description={isSuccess ? 'Finish' : 'Clear All'}
                placement="bottom"
                type={TOOLTIP_TYPE.SHRINK}
            >
                <div className={classes.closeButton} onClick={close}>×</div>
            </CommonTooltip>

            {
                !isSuccess && (
                    <div ref={contentRef} className={classes.modalContents}>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.subTitle}>{subTitle}</div>
                        <div className={classes.text}>{text}</div>
                        <div className={classes.buttonContainer}>
                            <CommonButton onClick={start}>Get started</CommonButton>
                            <CommonButton variant="outlined" onClick={close}>Skip</CommonButton>
                        </div>
                    </div>
                )
            }
            {
                isSuccess && (
                    <div ref={contentRef} className={classes.modalContentsSuccess}>
                        <div className={classes.icon}>
                            <img
                                alt="celebration" src={icons.confetti} style={{
                                    height: '60px',
                                    width: '60px',
                                }}
                            />
                        </div>
                        <div className={classes.boldTitle}>{t('ONBOARDING.COMMON.SUCCESS')}</div>
                        <div className={[classes.text, classes.successText].join(' ')}>
                            {t('ONBOARDING.COMMON.SUCCESS_DESC')}
                        </div>
                        <div className={classes.centeredButtonContainer}>
                            <CommonButton
                                loading={loading}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    fontSize: '18px',
                                    height: 'auto',
                                    justifyContent: 'center',
                                    opacity: loading ? 0.5 : 1,
                                    padding: '12px 21px',
                                }}
                                onClick={close}
                            >
                                Finish
                            </CommonButton>
                            <ConfettiComponent shouldRun={open} />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default HelloModal;
