import { useEffect, useState } from 'react';

const openIndexedDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('image-cache');

        request.onsuccess = (event) => {
            const { result } = (event.target as IDBRequest);

            resolve(result);
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;

            db.createObjectStore('images', { keyPath: 'url' });
        };
    });
};

const getCachedImage = (db: IDBDatabase, url: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('images', 'readonly');
        const store = transaction.objectStore('images');
        const request = store.get(url);

        request.onsuccess = (event) => {
            const { result } = (event.target as IDBRequest);

            if (result && result.blob) {
                const imageObjectURL = URL.createObjectURL(result.blob);

                resolve(imageObjectURL);
            } else {
                resolve(null);
            }
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
};

const cacheImage = (db: IDBDatabase, url: string, blob: Blob): Promise<void> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('images', 'readwrite');
        const store = transaction.objectStore('images');
        const request = store.put({ blob, url });

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
};

const useCachedImages = (urls: string[]): [(string | null)[], boolean] => {
    const [cachedImages, setCachedImages] = useState<(string | null)[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!urls || urls.length === 0) return;

        const fetchAndCacheImages = async () => {
            try {
                setLoading(true);
                const db = await openIndexedDB();
                const imagePromises = urls.map(async (url) => {
                    const cachedImage = await getCachedImage(db, url);

                    if (cachedImage) {
                        return cachedImage;
                    } else {
                        const response = await fetch(url);
                        const blob = await response.blob();

                        await cacheImage(db, url, blob);
                        return URL.createObjectURL(blob);
                    }
                });

                const images = await Promise.all(imagePromises);

                setCachedImages(images);
            } catch (error) {
                global.console.error('Failed to fetch and cache images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAndCacheImages()
            .then(r => r);
    }, [urls]);

    return [cachedImages, loading];
};

export default useCachedImages;
