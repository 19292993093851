import { Asset, Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import moment from 'moment';
import icons from 'shared-components/icons';
import { getUtcMoment } from 'utils/timeUtils';

export interface MapElement {
    code: string,
    count: number,
    geolocation: Geolocation,
    iataCode?: string,
    isAirport?: boolean,
    latitude?: number,
    locationName?: string,
    longitude?: number
}

export interface ExtractedAssetData {
    area: string,
    areaLyingSince?: number,
    areaLyingSinceTimestampUnit?: {
        unit: string,
        value: number,
    }
    assetNumber: string,
    assetTypeCode: string,
    batteryLevel?: number,
    batteryLevelThreshold?: number,
    city: string,
    country: string,
    iataCode: string,
    id: number,
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTimestamp?: number,
    lastMeasuredTimestampUnit?: {
        unit: string,
        value: number,
    },
    locationLyingSince?: number,
    locationLyingSinceTimestampUnit?: {
        unit: string,
        value: number,
    },
    locationName: string,
    loggerFamily?: string,
    loggerFamilyLabel?: string,
    loggerNumber?: string,
    ownedByCompanyId?: number,
    ownedByCompanyName?: string,
    pairingStatus?: 'PAIRED' | 'NOT_PAIRED',
    temperature: number,
    uldOwnerCode: string,
    uldType?: string,
}

export const extractAssetData = (rawData: Asset[]): ExtractedAssetData[] => {
    return rawData
        .map((item) => {
            const {
                areaLyingSince,
                areaLyingSinceTimestampUnit,
                assetNumber = '',
                assetTypeCode,
                id,
                lastMeasuredData = null,
                locationLyingSince,
                locationLyingSinceTimestampUnit,
                loggerNumber,
                ownedByCompanyId,
                ownedByCompanyName,
                pairingStatus,
                uldOwnerCode,
                uldType,
            } = item;

            const {
                area,
                batteryLevel,
                city,
                country,
                geolocation = null,
                iataCode,
                locationName = null,
                temperature,
                temperatureGeolocationTimestamp,
            } = lastMeasuredData || {};

            const {
                latitude = null,
                longitude = null,
            } = geolocation || {};

            return {
                area,
                areaLyingSince: getUtcMoment(areaLyingSince).valueOf(),
                areaLyingSinceTimestampUnit,
                assetNumber,
                assetTypeCode,
                batteryLevel,
                city,
                country,
                iataCode,
                id,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? getUtcMoment(temperatureGeolocationTimestamp).valueOf()
                    : null,
                locationLyingSince: getUtcMoment(locationLyingSince).valueOf(),
                locationLyingSinceTimestampUnit,
                locationName,
                loggerNumber,
                ownedByCompanyId,
                ownedByCompanyName,
                pairingStatus,
                temperature,
                uldOwnerCode,
                uldType,
            };
        });
};

export interface ActiveAsset {
    assetNumber: string,
    timestamp: number,
}

export const crossMarkerIconObj = {
    anchor: { x: 10, y: 10 },
    origin: { x: 0, y: 0 },
    scaledSize: { height: 20, width: 20 },
    url: icons.hex_with_cross,
};

export const getMapDataItem = (asset: ExtractedAssetData) => {
    return {
        geometry: {
            coordinates: [
                asset.lastMeasuredLongitude,
                asset.lastMeasuredLatitude,
            ],
            type: 'Point',
        },
        properties: {
            data: asset,
            marker: true,
        },
        type: 'Feature',
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom) => {
    return {
        bounds,
        options: {
            generateId: true,
            map: (item) => {
                return {
                    lastMeasuredTimestamp: item.data.lastMeasuredTimestamp,
                };
            },
            maxZoom: 15,
            radius: 75,
            reduce: (data, props) => {
                const { lastMeasuredTimes = [] } = data;

                if (!lastMeasuredTimes.includes(props.lastMeasuredTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTimestamp];
                }
                return data;
            },
        },
        points: mapData,
        zoom,
    };
};

export type FilterFields = 'assetTypeCode' | 'iataCode' | 'area' | 'uldOwnerCode';

export const getExistedOptions = (
    assets: ExtractedAssetData[], field: FilterFields,
): string[] => {
    return assets.reduce((data, { [field]: currentKey }) => {
        return !currentKey || data.includes(currentKey)
            ? data
            : [...data, currentKey];
    }, []);
};

export const initialAssetFilterOptions: { [optionsGroupKey: string]: string[] } = {
    areaLyingSince: [],
    areas: [],
    assetTypeCodes: [],
    iataCodes: [],
    ownedByCompanyIds: [],
    pairingStatuses: [],
    uldOwnerCodes: [],
    uldTypes: [],
};

// Please, use this one for any Marker on the map to center it
export const getCenteredPositionOffset = (width: number, height: number) => ({
    x: -width / 2,
    y: -height / 2,
});

export const calculateDiffMinutes = (timestamp: number, timestampUnit?: { unit: string, value: number }) => {
    let minutes = 0;

    if (timestampUnit) {
        const { unit, value } = timestampUnit;

        switch (unit) {
        case 'MINUTES':
            minutes = value;
            break;
        case 'HOURS':
            minutes = value * 60;
            break;
        case 'DAYS':
            minutes = value * 60 * 24;
            break;
        default:
            minutes = 0;
        }
    } else if (timestamp) {
        const now = moment().utcOffset(0, false).valueOf();
        const timePassed = Math.abs(now - timestamp);

        minutes = Math.round(timePassed / (1000 * 60));
    }

    return minutes;
};
