import { RequestStatus } from 'dataTypes/common';
import React from 'react';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import icons from 'shared-components/icons';

export const replaceSpecificLetters = (str: string): string => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const containsSpecificLetters = (str: string): boolean => {
    return /[\u0300-\u036f]/g.test(str.normalize('NFD'));
};

export const parseQuery: object = queryString => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');

        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export const convertTimezone = tz => {
    if (!tz) return tz;
    return tz
        ?.replace('MINUS_', '-')
        .replace('PLUS_', '+');
};

export const toHoursMinutes = (seconds:number) => {
    if (!seconds) return '00 00';
    const minutes = Math.min(seconds, 359999) / 60;
    const hours = minutes / 60;

    return `${Math.floor(hours).toString().padStart(2, '0')} ${Math.floor(minutes % 60).toString().padStart(2, '0')}`;
};

const getOriginPartByTolerantPart = (mainString: string, start: number, length: number): string => {
    return mainString.slice(start, start + length);
};

export const highlight = (mainString = '', query = '', color = '#f0debf', accentTolerance = false) => {
    const tolerantMainString = accentTolerance && mainString?.length > 0 && !containsSpecificLetters(query)
        ? replaceSpecificLetters(mainString)
        : '';
    const queryLowerCase = query?.toLowerCase();

    const subStrings = accentTolerance && tolerantMainString?.length > 0
        ? tolerantMainString?.split(new RegExp(`(${query})`, 'gi'))
        : String(mainString)?.split(new RegExp(`(${query})`, 'gi'));

    return (
        <span>
            {
                subStrings?.map((subString, i) => {
                    const label = accentTolerance
                        ? getOriginPartByTolerantPart(mainString,
                            subStrings?.slice(0, i)?.join('')?.length,
                            subString?.length)
                        : subString;

                    return (
                        <span
                            key={`token-${i}`}
                            style={
                                subString?.toLowerCase() === queryLowerCase
                                    ? { background: color, color: 'black' }
                                    : {}
                            }
                        >
                            {label}
                        </span>
                    );
                })
            }
        </span>
    );
};

export const uniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const capitalize = (string:string) => {
    if (!string) return '';
    return string[0].toUpperCase() + string.substring(1);
};

export const getRequestActualStatus = (...statuses: RequestStatus[]) => {
    if (statuses.includes('INIT')) return 'INIT';
    if (statuses.includes('PENDING')) return 'PENDING';
    if (statuses.includes('FAILURE')) return 'FAILURE';
    if (statuses.every(it => it === 'SUCCESS')) return 'SUCCESS';
    return 'PENDING';
};

export const getTemperatureStatusIcon = (temperatureStatus) => {
    switch (temperatureStatus) {
    case TEMPERATURE_STATUS.PREDICTED_EXCURSION:
        return icons.warning_triangle;
    case TEMPERATURE_STATUS.IN_RANGE:
        return icons.success;
    case TEMPERATURE_STATUS.EXCURSION:
    case TEMPERATURE_STATUS.UNKNOWN:
        return icons.warning_round;
    default:
        return icons.dots_round_grey;
    }
};

export const switchCase = str => {
    if (str.includes('_')) {
        return str.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
    } else {
        return str.replace(/([A-Z])/g, (match, p1, offset) => (offset > 0 ? '_' : '') + p1.toLowerCase());
    }
};

export const facetsConvert = (facets, include) => {
    return facets.map(facet => {
        if (include.includes(facet.filterName)) {
            return {
                counts: facet.counts.map(count => {
                    if (count.filterValue === null) {
                        return {
                            count: count.count,
                            filterValue: String(count.filterValue),
                        };
                    }
                    return count;
                }),
                filterName: facet.filterName,
            };
        }
        return facet;
    });
};
export const facetSorter = (a, b, showInBottom = []) => {
    const toBottom = [...showInBottom, 'null'];

    return (toBottom?.includes(a?.value) ? 1
        : (b?.count - a?.count || a?.label?.localeCompare(b?.label)));
};

export const adminApi = (url: string, isAdmin: boolean) => `${isAdmin ? 'admin/' : ''}${url}`;
