import React, { useMemo, CSSProperties, ReactElement } from 'react';
import useClasses from 'hooks/useClasses';
import styles from '../SideFilter.style';

type Props = {
    children?: ReactElement | ReactElement[],
    disabled?: boolean,
    height?: number,
    postDivider?: boolean,
    preDivider?: boolean
}

const FilterBlock = ({
    children,
    disabled = false,
    height = null,
    postDivider = true, // used for static components
    preDivider = false,
}: Props) => {
    const classes = useClasses(styles);

    const blockStyle = useMemo<CSSProperties>(() => ({
        ...(height ? {
            height,
        } : {}),
        ...(disabled ? {
            opacity: 0.5,
            pointerEvents: 'none',
        } : {}),
    }), [height, disabled]);

    return (
        <>
            {
                preDivider && <div className={classes.divider} />
            }
            {
                children && (
                    <div
                        className={classes.filterBox} style={blockStyle}
                    >
                        {children}
                    </div>
                )
            }
            {
                postDivider && <div className={classes.divider} />
            }
        </>
    );
};

export default FilterBlock;
