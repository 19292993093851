import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { getCenteredPositionOffset } from 'TrackAndTrace/Assets/lib';

type Props = {
    count?: number,
    itemType: string
}

const TooltipPolygonMarker = ({ count = null, itemType }: Props) => {
    return (
        <OverlayView
            getPixelPositionOffset={getCenteredPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat: Number.NaN,
                lng: Number.NaN,
            }}
        >
            <PolygonMarker
                count={count}
                iconDiameter={35}
                itemType={itemType}
                theme="light"
            />
        </OverlayView>
    );
};

export default TooltipPolygonMarker;
