import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import ProductRelease from './ProductRelease';

const ProductReleaseRouter: RouteItem[] = [
    {
        element: <ProductRelease />,
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
        path: '/product-release',
    },
];

export default ProductReleaseRouter;
