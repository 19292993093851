import useClasses from 'hooks/useClasses';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { highlight } from 'utils/stringTool';

import { Company } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';

const styles = theme => ({
    listBox: {
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
    },
    textFieldTextAutocomplete: {
        '& input': {
            padding: '3px 15px 3px 0!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        padding: '0!important',
        paddingRight: '10px!important',
        textAlign: 'left',
    },
});

type Props = {
    className?: string,
    company: any,
    companyType?: 'PHARMA' | 'SERVICE_PROVIDER',
    disabled?: boolean,
    label?: string,
    setCompany: (company: Company) => void,
}

const CompanySelector = ({
    className = '',
    company = null,
    companyType = null,
    disabled = false,
    label,
    setCompany,
} : Props) => {
    const classes = useClasses(styles);
    const { GetAll: getAllCompanies } = useSkymindBackendEndpoints('companies').requests;

    const [availableCompanies, setAvailableCompanies] = useState<Company[]>([company]);
    const [sampleForSearch, setSampleForSearch] = useState('');
    const { t } = useCustomTranslation();

    useEffect(() => {
        let isMounted = true;

        const getCompanies = async () => {
            if (isMounted) {
                try {
                    const response = await getAllCompanies();

                    const companies: Company[] = response?.data?.resultList || [];

                    const filteredCompanies = companies.filter(item => (
                        companyType === null || item.companyType === companyType));

                    setAvailableCompanies(filteredCompanies);
                } catch (error) {
                    global.console.log(error);
                    setAvailableCompanies([]);
                }
            }
        };

        (async () => {
            await getCompanies();
        })();

        return () => { isMounted = false; };
    }, []);

    const filteredOptions = useMemo(() => {
        return sampleForSearch
            ? availableCompanies.filter(({ name }) => name.toLowerCase().includes(sampleForSearch))
            : availableCompanies;
    }, [availableCompanies, sampleForSearch]);

    // const filterOptions = () => filteredOptions;
    const checkValue = (option: Company, value: Company) => option?.name === value?.name
        || company?.name === value?.name;
    const getOptionLabel = (option: Company) => option?.name;
    const handleChangeOnInput = (event) => setSampleForSearch(event.target.value.toLowerCase() || '');
    const handleChangeOnSelect : any = (event, value: Company) => setCompany(value);

    return (
        <Autocomplete
            autoSelect
            classes={{
                inputRoot: classes.textFieldTextAutocomplete,
                listbox: classes.listBox,
            }}
            clearOnEscape
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            id="CompanySelector"
            isOptionEqualToValue={checkValue}
            noOptionsText={t('COMMON.WRITE_TO_GET_OPTIONS')}
            options={filteredOptions}
            popupIcon={null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={className}
                    disabled={disabled}
                    label={label}
                    size="small"
                    value={sampleForSearch}
                    variant="standard"
                    onChange={handleChangeOnInput}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option?.name}>{highlight(option?.name, sampleForSearch)}</li>
            )}
            size="small"
            value={company}
            onChange={handleChangeOnSelect}
        />
    );
};

export default CompanySelector;
