import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import { TextField } from '@mui/material';
import SelectField from 'shared-components/SelectField';
import {
    CommonTableItem,
    TABLE_INPUT_TYPE,
} from 'LaneManagement/LaneDetails/StepsWrapper/CommonStepTable/CommonTableTypes';
import { styles } from './TableInput.style';
import MaskedTimeInputWithTooltip from './inputs/MaskedTimeInputWithTooltip';
import NumberSpinnerInput from './inputs/NumberSpinnerInput';

type Props = {
    description: CommonTableItem,
    onChange: (value: string | number, id?: string) => void,
    proposedValue: any,
    value: any
}

export const validValue = (value, type: TABLE_INPUT_TYPE) => {
    switch (type) {
    case TABLE_INPUT_TYPE.TIME_24:
        return value !== null && value !== undefined && value > 0;
    default:
        return value !== null && value !== undefined && value !== '';
    }
};
const TableInput = ({
    description,
    onChange,
    proposedValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
}: Props) => {
    const classes = useClasses(styles);
    const { required } = description;
    const inputClass = [required ? classes.required : classes.classic, classes.input,
        description.required && !validValue(value, description.inputType) ? classes.invalid : '',
        [
            TABLE_INPUT_TYPE.TIME_24,
            TABLE_INPUT_TYPE.TEMPERATURE,
        ].includes(description.inputType) ? classes.alignEnd : '',
        description.inputType === TABLE_INPUT_TYPE.TIME_24 ? classes.timeInput : '',
    ].join(' ');
    const [proposedDurationTooltip, setProposedDurationTooltip] = useState(false);

    switch (description.inputType) {
    case TABLE_INPUT_TYPE.TEXT:
        return (
            <TextField
                InputProps={{
                    disableUnderline: true,
                }}
                className={inputClass}
                inputProps={{
                    style: {
                        boxShadow: 'none',
                        outline: 'none',
                        padding: '4px 10px',
                    },
                }}
                value={value || ''}
                onChange={ev => onChange(ev.target.value)}
            />
        );
    case TABLE_INPUT_TYPE.TEMPERATURE:
        return (
            <NumberSpinnerInput
                inputClass={inputClass}
                proposedValue={proposedValue}
                value={value}
                onChange={onChange}
            />

        );
    case TABLE_INPUT_TYPE.TIME_24:
        return (
            <MaskedTimeInputWithTooltip
                inputClass={inputClass}
                proposedDurationTooltip={proposedDurationTooltip}
                proposedValue={proposedValue}
                setProposedDurationTooltip={setProposedDurationTooltip}
                value={value}
                onChange={onChange}
            />
        );
    case TABLE_INPUT_TYPE.SELECT:
        return (
            <SelectField
                className={inputClass}
                items={description.selectData}
                value={value}
                onChange={onChange}
            />
        );
    default:
        return <div />;
    }
};

export default TableInput;
