const styles = theme => ({
    mapCard: {
        marginBottom: '12px',
    },
    minimizedPackagingItemContent: {
        padding: '7px 12px 8px!important',
    },
    packagingItemCard: {
        marginBottom: '12px',
    },
    packagingItemContent: {
        padding: '16px!important',
    },
    packagingTitle: {
        color: theme.palette.secondary[800],
        display: 'flex',
        fontSize: '18px !important',
        justifyContent: 'space-between',
        letterSpacing: '0.26px',
    },
});

export default styles;
