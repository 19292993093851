import { SeaportExtended } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetSeaports = (postProcess = it => it): SeaportExtended[] => {
    const { GetAll: getSeaports } = useSkymindBackendEndpoints('seaports').requests;

    const {
        data: seaportList = [],
    } = useCachedQueryRequest({
        key: 'seaports',
        onCompleted: it => postProcess(it?.data || []),
        request: getSeaports,
    });

    return seaportList;
};

export default useGetSeaports;
