import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from 'shared-components/Table/Table.style';

type Props = {
    dateFrom: string,
    dateTo: string,
    filterOptions: number[],
    setDateFrom: (dateFrom: string) => void,
    setDateTo: (dateTo: string) => void,
}

const DateFilter = ({
    dateFrom,
    dateTo,
    filterOptions = [],
    setDateFrom,
    setDateTo,
}: Props) => {
    const classes = useGeneratedStyles(styles);
    const { t } = useCustomTranslation();

    const disabled = useMemo(() => {
        const [availableMin = null, availableMax = null] = filterOptions;

        return availableMin === null || availableMax === null;
    }, [filterOptions]);

    const handleOnChangeDateFrom = (value: Moment) => {
        setDateFrom(value === null ? null : value.format('yyyy-MM-DD'));
    };
    const handleOnChangeDateTo = (value: Moment) => {
        setDateTo(value === null ? null : value.format('yyyy-MM-DD'));
    };
    const handleOnClickClearFilter = () => {
        setDateFrom('');
        setDateTo('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.dateFilterContainer}>
                <div style={{ marginRight: '5px' }}>
                    <div className={classes.dateFilterContainerText}>{t('COMMON.FROM')}</div>
                    <DatePicker
                        disabled={disabled}
                        format="DD.MM.yyyy"
                        slotProps={{
                            dialog: { className: classes.datePicker },
                            textField: { className: classes.datePickerTextField },
                        }}
                        value={moment(dateFrom)}
                        onChange={handleOnChangeDateFrom}
                    />
                </div>
                <div style={{ marginLeft: '5px' }}>
                    <div className={classes.dateFilterContainerText}>{t('COMMON.TO')}</div>
                    <DatePicker
                        disabled={disabled}
                        format="DD.MM.yyyy"
                        slotProps={{
                            dialog: { className: classes.datePicker },
                            textField: { className: classes.datePickerTextField },
                        }}
                        value={moment(dateTo)}
                        onChange={handleOnChangeDateTo}
                    />
                </div>
                <IconButton
                    disabled={!dateFrom && !dateTo}
                    size="small"
                    style={{ marginLeft: '5px', padding: '0' }}
                    onClick={handleOnClickClearFilter}
                >
                    <FilterAltOffIcon color="action" fontSize="small" />
                </IconButton>
            </div>
        </LocalizationProvider>
    );
};

export default DateFilter;
