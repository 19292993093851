import React, { forwardRef, MouseEventHandler, ReactNode } from 'react';
import {
    Card as MaterialCard,
    CardContent,
    Typography,
    Icon,
} from '@mui/material';

import useClasses from 'hooks/useClasses';
import styles from './Card.style';

export type Props = {
    /**
     * Components/content, included to card
     */
    children?: ReactNode,
    /**
     * Additional className for add specified style
     */
    className?: string,
    /**
     * Additional className for content
     */
    contentClass?: string,
    /**
     * Disables interaction with the card
     */
   disabled?: boolean,
    /**
     * Full height or dependent on content?
     */
    fullHeight?: boolean,
    /**
     * Full width or dependent on content?
     */
    fullWidth?: boolean,
    /**
     * Highlights the card
     */
   highlight?: boolean,
    icon?: string,
    /**
    * Optional click handler
    */
   onClick?: MouseEventHandler<HTMLDivElement>,
    /**
    * Optional scrool handler
    */
    onScroll?: any,
   /**
     * Additional style object, passed to component
     */
    style?: any,
    /**
     * The title of card
     */
    title?: string,
   /**
     * Additional className for the title
     */
    titleClass?: string,
    /**
   * Optionally disables side padding (used for tables)
   */
    zeroSidePadding?: boolean,
    /**
   * Optionally disables vertical padding (used for tables)
   */
   zeroVerticalPadding?: boolean
}

/**
 * Cards are surfaces that display content and actions on a single topic.
 */
const Card = forwardRef<HTMLDivElement, Props>(({
    children,
    className = '',
    contentClass = '',
    disabled = false,
    fullHeight = false,
    fullWidth = false,
    highlight = false,
    icon = '',
    onClick = null,
    onScroll = null,
    style = null,
    title = '',
    titleClass = '',
    zeroSidePadding = false,
    zeroVerticalPadding = false,
    ...rest
}: Props, ref) => {
    const classes = useClasses(styles);

    return (
        <MaterialCard
            ref={ref}
            className={
                [
                    classes.card,
                    className,
                    fullHeight ? classes.fullHeight : '',
                    fullWidth ? classes.fullWidth : '',
                    onClick ? classes.hasHover : '',
                    disabled ? classes.disabled : '',
                    highlight ? classes.highlighted : '',
                ].join(' ')
            }
            style={style}
            onClick={onClick}
            onScroll={onScroll}
            {...rest}
        >
            <CardContent className={[
                contentClass,
                zeroSidePadding ? classes.zeroSidePadding : '',
                zeroVerticalPadding ? classes.zeroVerticalPadding : '',
            ].join(' ')}
            >
                {
                    title && (
                        <Typography className={[
                            classes.title,
                            titleClass,
                            zeroSidePadding ? classes.cardTitleWithZeroSidePadding : '',
                        ].join(' ')}
                        >
                            {title}
                            { icon && <Icon className={classes.icon}>{icon}</Icon> }
                        </Typography>
                    )
                }
                {children}
            </CardContent>
        </MaterialCard>
    );
});

Card.displayName = 'Card';

export default Card;
