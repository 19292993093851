import React, {
    useEffect,
    useState, useContext, useMemo,
} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCacheContext from 'hooks/useCacheContext';
import useLoadSensorData from 'SensorDataCommon/hooks/useLoadSensorData';
import parseQueryParams from 'utils/parsePathQueries';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useConfig from 'hooks/useConfig';
import useGetCommonData from 'hooks/useGetCommonData';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
} from './dataTypes';
import {
    initializeTimeRange,
    initializeRequestBody, fetchAssets, initializeEntityList,
} from './lib';
import AssetSensorData from './AssetSensorData';
import { Asset } from '../dataTypes/SecureBackend/apiResponse';

const AssetSensorDataContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const config = useConfig();
    const { getCacheValue } = useCacheContext();
    const {
        entityNumber: entityNumberInPath = '',
    } = useParams() as unknown as PathParams;
    const queryParams = parseQueryParams(location.search);
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);

    const [isCurrentTime, setIsCurrentTime] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(
        true,
        queryParams,
        config?.locationHistory?.timeFilterDays === 1,
        config ? config?.locationHistory?.timeFilterDays === 14 : true,
        config?.locationHistory?.timeFilterDays === 7,
    ));

    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );
    const { t } = useCustomTranslation();
    const [assetListAll, setAssetListAll] = useState(initializeEntityList(entitySelectorItem, 'assets'));

    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/location/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/location/${entitySelectorItem.entityNumber}`,
                title: t('MENU_ITEMS.LOCATION_HISTORY'),
            },
            {
                activePage: true,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/readout/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/readout/${entitySelectorItem.entityNumber}`,
                title: t('COMMON.TEMPERATURE'),
            },
            {
                activePage: false,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/pairings/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/pairings/${entitySelectorItem.entityNumber}`,
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [entitySelectorItem, isAdmin]);

    const {
        assetList,
        assetRequestStatus,
        isFetchComplete,
    } = useLoadSensorData(entitySelectorItem, 'assets', entityNumberInPath, isAdmin);

    useEffect(() => {
        if (
            entityNumberInPath
            && entitySelectorItem.entityNumber !== entityNumberInPath
        ) {
            if (assetList.length > 0) {
                const asset = assetList.find(item => item.entityNumber === entityNumberInPath) || null;

                if (asset) {
                    setEntitySelectorItem(asset);
                }
            }
        }
    }, [
        assetList,
    ]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            navigate({
                pathname: `/assets/readout/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    const {
        data: rawAssetsAll,
    } = useGetCommonData<Asset[]>(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: true,
        postProcess: it => it.resultList,
        query: {
            page: 0,
            pageSize: 100,
        },
    });

    useEffect(() => {
        if (rawAssetsAll) {
            setAssetListAll(fetchAssets(rawAssetsAll, assetTypeLabels));
        }
    }, [rawAssetsAll]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true,
                queryParams,
                config?.locationHistory?.timeFilterDays === 1,
                config ? config?.locationHistory?.timeFilterDays === 14 : true,
                config?.locationHistory?.timeFilterDays === 7,
            ));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    return (
        <AssetSensorData
            assetList={assetListAll}
            assetsFetched={assetRequestStatus === 'SUCCESS'}
            entitySelectorItem={entitySelectorItem}
            isAdmin={isAdmin}
            isCurrentTime={isCurrentTime}
            requestBody={requestBody}
            setEntitySelectorItem={setEntitySelectorItem}
            setIsCurrentTime={setIsCurrentTime}
            setRequestBody={setRequestBody}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
        />
    );
};

export default AssetSensorDataContainer;
