import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { ArrowBackIosNew } from '@mui/icons-material';
import useClasses from 'hooks/useClasses';
import styles from './MenuItemLink.style';

type Props = {
    icon?: string,
    isMobileDevice: boolean,
    isTextHidden: boolean,
    link: string,
    setSidebarState: (sidebarState: boolean) => void,
    title: string,
    type: 'activeChild' | 'activeDefault' | 'child' | 'default' | 'home'
}

const MenuItemLink = ({
    icon = null,
    isMobileDevice,
    isTextHidden,
    link,
    setSidebarState,
    title,
    type,
} : Props) => {
    const classes = useClasses(styles);
    const handleLinkClick = () => {
        if (isMobileDevice) {
            setSidebarState(false);
        }
    };

    return (
        <Link
            key={`link_to_${link}`}
            className={classes.a}
            to={`/${link}`}
            onClick={handleLinkClick}
        >
            <ListItem
                key={`${link}_button`}
                button
                className={[
                    classes[`${type}Item`],
                    type === 'activeDefault' ? classes.activeItem : '',
                ].join(' ')}
            >
                {
                    icon && (
                        <img
                            key={`icon_${link}`}
                            alt={title}
                            className={classes.icon}
                            src={icon}
                        />
                    )
                }
                <div
                    className={classes[`${type}ItemText`]}
                    hidden={isTextHidden}
                >
                    {title}
                </div>
                <div style={{ flex: 1 }} />
                {
                    !isTextHidden && (type === 'activeChild' || type === 'activeDefault')
                        ? <ArrowBackIosNew className={[classes.arrowIcon, classes.rotateIcon].join(' ')} />
                        : ''
                }
            </ListItem>
        </Link>
    );
};

export default MenuItemLink;
