import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme:SkycellThemeInterface) => ({
    approveButton: {
        '&:hover': {
            backgroundColor: '#4C9BB5!important',
        },
        background: '#8BD1E8 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        boxShadow: '0px 1px 3px #00000033',
        color: '#fff',
        font: 'normal normal 500 14px/17px Roboto',
        height: '36px',
        letterSpacing: '1.25px',
        margin: '0 7px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
        width: '90px',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '28px',
    },
    pageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '2fr 1fr 1fr 2fr',
        padding: '12px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '2fr 1fr 1fr',
        },
    },
    pageContentButtons: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '3fr 3fr',
    },
    rejectButton: {
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #8BD1E8',
        borderRadius: '4px',
        color: '#8BD1E8',
        font: 'normal normal 500 14px/17px Roboto',
        height: '36px',
        letterSpacing: '1.25px',
        margin: '0 7px',
        opacity: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '90px',
    },
});

export default styles;
