import { LatLng } from 'dataTypes/common';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useMemo } from 'react';
import GatewayMarker from 'shared-components/GatewayMarker';
import useSupercluster from 'use-supercluster';

type Props = {
    bounds: number[],
    gateways?: GatewayInfoDTO[],
    highlightBounds?: LatLng[],
    show: boolean,
    zoom: number
}
const isMarkerWithinPolygon = (marker: LatLng, polygon: LatLng[]): boolean => {
    let inside = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lng; const
            yi = polygon[i].lat;
        const xj = polygon[j].lng; const
            yj = polygon[j].lat;

        const intersect = ((yi > marker.lat) !== (yj > marker.lat))
            && (marker.lng < (((xj - xi) * (marker.lat - yi)) / (yj - yi) + xi));

        if (intersect) inside = !inside;
    }
    return inside;
};

interface GatewayProperties {
    category: string;
    cluster: boolean;
    code: string;
    highlighted: boolean;
}

const GatewaysOnMap = ({
    bounds,
    gateways,
    highlightBounds,
    show,
    zoom,
}: Props) => {
    const { t } = useCustomTranslation();

    const gatewaysWithHighlight = useMemo<(GatewayInfoDTO & {highlighted?: boolean})[]>(() => {
        if (!highlightBounds || highlightBounds.length === 0) return gateways;
        return gateways.map(gateway => ({
            ...gateway,
            highlighted: isMarkerWithinPolygon({
                lat: gateway.geolocation?.latitude,
                lng: gateway.geolocation?.longitude,
            }, highlightBounds),
        }));
    }, [gateways, highlightBounds]);
    const {
        clusters,
        supercluster,
    } = useSupercluster<GatewayProperties, any>({
        bounds: bounds as [number, number, number, number],
        options: {
            maxZoom: Math.max(Math.round(zoom), 1),
            radius: 200,
        },
        points: gatewaysWithHighlight.map(gateway => ({
            geometry: {
                coordinates: [gateway.geolocation?.longitude, gateway.geolocation?.latitude],
                type: 'Point',
            },
            properties: {
                category: 'mapElements',
                cluster: false,
                code: gateway.gatewayImeiMac,
                highlighted: gateway.highlighted,
            },
            type: 'Feature',
        })),
        zoom,
    });

    const processedClusters = useMemo(() => clusters.map(cluster => {
        if (cluster.properties.cluster) {
            const clusterId = cluster.id;

            // Get all points in the cluster
            const clusterPoints = supercluster.getLeaves(clusterId as number, Infinity);

            // Check if any point in the cluster is highlighted
            const isHighlighted = clusterPoints.some(point => point.properties.highlighted);

            return {
                ...cluster,
                properties: {
                    ...cluster.properties,
                    highlighted: isHighlighted,
                },
            };
        }

        return cluster;
    }), [clusters, supercluster]);

    return (
        <>
            {
                show && processedClusters.map((cluster) => {
                    const geokey: string = (cluster?.id
                        || cluster?.properties.code
                        || JSON.stringify(cluster.geometry.coordinates)).toString();

                    return (
                        <GatewayMarker
                            key={`${geokey}_gateway_${cluster.properties.highlighted ? 'highlighted' : 'normal'}`}
                            isHighlighted={cluster.properties.highlighted}
                            label={cluster.properties.cluster
                                ? t('TRACK_AND_TRACE.MULTIPLE_GATEWAYS')
                                : `ImeiMac: ${cluster.properties.code}`}
                            position={{
                                lat: cluster.geometry.coordinates[1],
                                lng: cluster.geometry.coordinates[0],
                            }}
                        />
                    );
                })
            }
        </>
    );
};

export default GatewaysOnMap;
