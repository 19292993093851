import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import { TIME_IN_MS } from 'shared-components/constants';
import { useMemo } from 'react';
import {
    getGeolocationRequestLink,
    getTimeZoneStr,
    initialValue,
    TimeZoneInfo,
} from './lib';
import useCachedQueryRequest from '../useCachedQueryRequest';

type Loading = {
    loading: boolean,
}

type RequestOptions = {
    cacheKey: string,
    link: string,
} | null

let cache = {};

const useGetTimeZoneInfoByGeolocation = (
    geolocation: Geolocation = null,
    time: string = null,
    utc: boolean = false,
): TimeZoneInfo & Loading => {
    const { FlexibleRequest: getTimeZoneInfo } = useSkymindBackendEndpoints('timezone').requests;
    const requestOptions: RequestOptions = useMemo(() => {
        if (geolocation && time) {
            return {
                cacheKey: `lat${geolocation.latitude}_lng${geolocation.longitude}`,
                link: getGeolocationRequestLink(geolocation, time, utc),
            };
        } else return null;
    }, [geolocation, time, utc]);

    const enabled = requestOptions !== null && !cache[requestOptions?.cacheKey];

    const {
        data: timeZoneInfo = initialValue,
        isLoading: loading,
    } = useCachedQueryRequest({
        key: requestOptions?.cacheKey,
        onCompleted: ({ data = null }) => {
            if (data) {
                const { countryName, gmtOffset = null } = data;

                const result = {
                    countryName,
                    gmtOffsetInMs: gmtOffset !== null
                        ? gmtOffset * TIME_IN_MS.second
                        : null,
                    timeZoneStr: getTimeZoneStr(gmtOffset),
                };

                if (requestOptions?.cacheKey) {
                    cache = {
                        ...cache,
                        [requestOptions?.cacheKey]: result,
                    };
                }

                return result;
            } else return null;
        },
        options: {
            enabled,
            retry: true,
        },
        request: getTimeZoneInfo.bind(null, 'GET', requestOptions?.link),
    });

    if (!enabled) return { ...cache[requestOptions?.cacheKey], loading: false };

    return { ...timeZoneInfo, loading };
};

export default useGetTimeZoneInfoByGeolocation;
