const styles = (theme) => ({
    card: {
        background: theme.palette.common.contrastWhite,
        borderRadius: '0px!important',
        boxShadow: `0px 1px 3px ${theme.palette.secondary.main}`,
        height: 'auto!important',
        minWidth: '0px',
        overflow: 'visible',
        position: 'relative',
    },
    cardRoot: {

    },
    cardTitleWithZeroSidePadding: {
        color: '#191919',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        opacity: 1,
        paddingLeft: '12px',
    },
    disabled: {
        opacity: 0.7,
        pointerEvents: 'none',
    },
    fullHeight: {
        height: '100%!important',
    },
    fullWidth: {
        width: '100%',
    },
    hasHover: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    highlighted: {
        boxShadow: `0px 1px 6px ${theme.palette.secondary[600]}`,
    },
    icon: {
        height: '26px',
        padding: '5px 0 0 5px',
        position: 'relative',
        top: '4px',
        width: '30px',
    },
    loading: {
        marginLeft: '6px',
        position: 'relative',
        width: '15px',
    },
    title: {
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '18px !important',
        letterSpacing: '0.26px',
    },
    zeroSidePadding: {
        paddingLeft: '0',
        paddingRight: '0',
    },
    zeroVerticalPadding: {
        paddingBottom: '0!important',
        paddingTop: '0!important',
    },
});

export default styles;
