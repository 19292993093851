import React, {
    useCallback,
    useMemo,
    CSSProperties,
} from 'react';
import { useTheme } from '@mui/material/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import Box from './Box';

const STATUS_PRIORITY = [
    TEMPERATURE_STATUS.IN_RANGE,
    TEMPERATURE_STATUS.WAITING_FOR_DATA,
    TEMPERATURE_STATUS.NOT_APPLICABLE,
    TEMPERATURE_STATUS.UNKNOWN,
    TEMPERATURE_STATUS.EXCURSION,
    TEMPERATURE_STATUS.PREDICTED_EXCURSION,
];

const containerStyle: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
    marginRight: '5px',
};

type Props = {
    statuses: string[],
}

const PackagingsBoxVisualization = ({ statuses = [] }: Props) => {
    const { t } = useCustomTranslation();
    const theme = useTheme<SkycellThemeInterface>();

    const sortedStatuses = useMemo(() => {
        const cp = [...statuses];

        cp.sort((a, b) => {
            return (cp[0] === TEMPERATURE_STATUS.PREDICTED_EXCURSION
                || cp[0] === TEMPERATURE_STATUS.POTENTIAL_EXCURSION)
                ? 0 : STATUS_PRIORITY.indexOf(a) - STATUS_PRIORITY.indexOf(b);
        });
        return cp;
    }, [statuses]);

    const otherColors = useMemo(() => {
        return [
            theme.palette.primary.deepBlue,
            theme.palette.primary.deepBlue,
            theme.palette.primary.deepBlue,
        ];
    }, [sortedStatuses, theme]);

    const statusToColor = useCallback(status => {
        switch (status) {
        case TEMPERATURE_STATUS.EXCURSION:
            return theme.palette.common.red;
        case TEMPERATURE_STATUS.NO_DATA:
            return theme.palette.secondary[400];
        case TEMPERATURE_STATUS.PREDICTED_EXCURSION:
        case TEMPERATURE_STATUS.POTENTIAL_EXCURSION:
            return theme.palette.common['yellow'];
        case TEMPERATURE_STATUS.IN_RANGE_PARTIAL_DATA:
            return `repeating-linear-gradient(90deg,${
                    theme.palette.primary['deepBlue']
                },${theme.palette.primary['deepBlue']} 3px,${
                    theme.palette.common.white
                } 3px,${
                    theme.palette.common.white
                } 5px)`;

        default:
            return theme.palette.primary['deepBlue'];
        }
    }, [theme]);

    const excursions = useMemo(() => Math.ceil((4 * statuses
        .filter(status => status === TEMPERATURE_STATUS.EXCURSION).length) / statuses.length),
    [statuses]);

    const lastBoxStatus = useMemo(() => {
        if (excursions >= 2) {
            return [TEMPERATURE_STATUS.EXCURSION, TEMPERATURE_STATUS.EXCURSION];
        } else if (excursions === 1) {
            return [TEMPERATURE_STATUS.IN_RANGE, TEMPERATURE_STATUS.EXCURSION];
        } else {
            return [TEMPERATURE_STATUS.IN_RANGE, TEMPERATURE_STATUS.IN_RANGE];
        }
    }, [excursions]);

    if (statuses.length <= 5) {
        return (
            <div style={containerStyle}>
                {
                    sortedStatuses.map((status, i) => (
                        <Box
                            key={`${i}_${status}`}
                            color={statusToColor(status)}
                            title={t(`TEMPERATURE_STATUS.${status}`)}
                        />
                    ))
                }
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <Box
                color={statusToColor(sortedStatuses[0])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[0]}`)}
            />

            <Box
                color={statusToColor(sortedStatuses[1])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[1]}`)}
            />
            {
                otherColors.map((color, i) => (
                    <Box
                        key={`others_${color}_ ${i}`}
                        color={color}
                        small
                    />
                ))
            }
            <Box
                color={statusToColor(lastBoxStatus[0])}
                title={t(`TEMPERATURE_STATUS.${lastBoxStatus[0]}`)}
            />
            <Box
                color={statusToColor(lastBoxStatus[1])}
                title={t(`TEMPERATURE_STATUS.${lastBoxStatus[1]}`)}
            />
        </div>
    );
};

export default PackagingsBoxVisualization;
