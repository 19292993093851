import React from 'react';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

type Props = {
    isSorted: boolean,
    isSortedDesc: boolean,
}

const SortingIcon = ({
    isSorted,
    isSortedDesc,
} : Props) => {
    if (isSorted) {
        return isSortedDesc
            ? <ArrowDownward fontSize="small" />
            : <ArrowUpward fontSize="small" />;
    }

    return <div style={{ height: '20px', width: '20px' }} />;
};

export default SortingIcon;
