/* eslint-disable  */
export const downloadIcon = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z" /></svg>';
export const downloadIconSVG = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">PNG</text>\
</svg>`;
export const downloadIconCSV = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">CSV</text>\
</svg>`;
export const downloadIcoSVG = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">SVG</text>\
</svg>`;
export const DownloadComponentIcon = (
    {
        color = 'inherit',
        size = 24,
        distorted = false,
    }
) => {
    return distorted ? <svg id="Download" xmlns="http://www.w3.org/2000/svg" width="15.293" height="12.531" viewBox="0 0 15.293 12.531" style={{ fill: color }}>
            <path id="download_FILL0_wght400_GRAD0_opsz48_1_" data-name="download_FILL0_wght400_GRAD0_opsz48 (1)" d="M173.859,268.531a1.536,1.536,0,0,0,1-.352,1.041,1.041,0,0,0,.43-.822v-2.8h-1.434v2.8H161.434v-2.8H160v2.8a1.041,1.041,0,0,0,.43.822,1.536,1.536,0,0,0,1,.352Zm-6.213-3,4.612-3.779-1.027-.842-2.867,2.35V256H166.93v7.264l-2.867-2.35-1.028.842Z" transform="translate(-160 -256)"/>
        </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" height={size.toString()} viewBox="0 96 960 960"
             width={size.toString()} style={{
            fill: color,
        }}>

            <path
                d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"/>
        </svg>
}
