import React, {
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import {
    ServiceProviderSite,
    servicesArr,
    commoditiesArr,
} from 'dataTypes/SecureBackend/apiResponse';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';
import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
// eslint-disable-next-line max-len
import ServiceProviderSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ServiceProviderSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import OperatingHoursCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OperatingHoursCard';
import OfferingsCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OfferingsCard';
import useClasses from 'hooks/useClasses';
import styles from './Overview.style';

type PathParams = { providerId: string }

const OverviewContainer = () => {
    const classes = useClasses(styles);
    const { providerId } = useParams() as unknown as PathParams;
    const { Get: getServiceProviderSite } = useSkymindBackendEndpoints('service-provider-sites').requests;
    const [availableData, setAvailableData] = useState<ServiceProviderSite>(null);
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();

    useEffect(() => {
        (async () => {
            try {
                const {
                    data: response = null,
                } : { data: ServiceProviderSite } = await getServiceProviderSite(providerId);

                if (response) {
                    setAvailableData(response);
                    setBreadcrumbData('customBreadcrumb', `${response.serviceProviderName} / ${response.name}`);
                    setTitle(response.serviceProviderName);
                }
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, []);

    return (
        availableData
            ? (
                <div className={classes.editCompanyCardContentData}>
                    <ServiceProviderSiteCard
                        currentCompanyLogo={availableData.logoImagePath}
                        currentSiteName={availableData?.name}
                        type="readOnly"
                    />
                    <AddressCard
                        currentAddressLine1={availableData?.address?.addressLine1}
                        currentAddressLine2={availableData?.address?.addressLine2}
                        currentAddressLine3={availableData?.address?.addressLine3}
                        currentCity={availableData?.address?.city}
                        currentCountry={availableData?.address?.country.name}
                        currentCountryCode={availableData?.address?.country.code}
                        currentGeolocation={availableData?.address?.geolocation}
                        currentZipCode={availableData?.address?.zipCode}
                        type="readOnly"
                    />
                    <ContactInformationCard
                        currentContactPerson={availableData?.contactInfo?.contactPerson}
                        currentEmail={availableData?.contactInfo?.email}
                        currentPhoneNumber={availableData?.contactInfo?.phoneNumber}
                        type="readOnly"
                    />
                    <OperatingHoursCard
                        currentOperatingHours={availableData?.operatingHours}
                        type="readOnly"
                    />
                    <OfferingsCard
                        currentCommodities={
                            (availableData.offers || []).filter(offer => commoditiesArr.includes(offer))
                        }
                        currentServices={
                            (availableData.offers || []).filter(offer => servicesArr.includes(offer))
                        }
                        type="readOnly"
                    />
                </div>
            )
            : null
    );
};

export default OverviewContainer;
