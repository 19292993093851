import React, { useEffect, useMemo, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { LatLngTimeTimestamp, TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useClasses from 'hooks/useClasses';
import useHasAccess from 'hooks/useHasAccess';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    Badge,
    middleStatus,
} from 'TrackAndTrace/GenericShipmentDetails/components/PackagingTemperatureInfo/lib';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomInMapOutlined';
import ZoomOutIcon from '@mui/icons-material/ZoomOutMapOutlined';
import moment from 'moment';
import { SensorDataItem, SensorPredictedDataResponse } from 'dataTypes/SecureBackend/apiResponse';
import TemperatureCheck from 'shared-components/TemperatureCheck';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import EnergyLevelBar from 'shared-components/EnergyLevelBar';
import { ZoomedDataLimits } from 'shared-components/ApexTemperatureChart';
import { HiddenSeries } from 'TrackAndTrace/GenericShipmentDetails/GenericShipmentDetails';
import { EnergyLevelStatus } from 'TrackAndTrace/lib';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import LastConnect from 'shared-components/LastConnect';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { styles } from './PackagingTemperatureInfo.style';
import PackagingTemperature from './PackagingTemperature';
import useLoadedInitialData from './useLoadedInitialData';
import { getTitleText } from './lib';

type Props = {
    cargo: GenericCargo,
    closed?: boolean,
    energyLevel?: number,
    exportPng?: (base64: string, aspectRatio: number) => void,
    exportTempData?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    hiddenSeries?: HiddenSeries,
    isAdmin?: boolean,
    maximized: boolean,
    milestones?: Milestones[],
    onMaximize: (serialNumber: string) => void,
    packaging: GenericPackaging,
    progressUpdateEnabled?: boolean,
    setHiddenSeries?: (hiddenSeries: HiddenSeries) => void,
    setIsSensorDataComplete?: Dispatch<SetStateAction<{
        [packagingNumber: string]: boolean,
    }>>,
    setRequiredOrderDataUpdate?: Dispatch<SetStateAction<boolean>>,
    shipmentEnd: string,
    shipmentId?: string,
    shipmentNumber: string,
    shipmentStart: string,
    showExpTemp: boolean,
    showInUTC: boolean,
    showMarkers: boolean,
    showMeasuredOnly: boolean,
    showTempRange: boolean,
    timeRange: TimeRange,
    transport: boolean,
    unloadingTimestamp?: string,
}
type MouseStateProps = {
    index: number,
    timestamp: number,
}
const PackagingTemperatureInfo = ({
    cargo = null,
    closed = false,
    energyLevel = null,
    exportPng,
    exportTempData,
    hiddenSeries,
    isAdmin = false,
    maximized = false,
    milestones = [],
    onMaximize,
    packaging = null,
    progressUpdateEnabled,
    setHiddenSeries,
    setIsSensorDataComplete,
    setRequiredOrderDataUpdate,
    shipmentEnd,
    shipmentId,
    shipmentNumber,
    shipmentStart,
    showExpTemp = false,
    showInUTC = false,
    showMarkers = false,
    showMeasuredOnly = false,
    showTempRange = false,
    transport = false,
    unloadingTimestamp,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const [coordinates, setCoordinates] = useState<LatLngTimeTimestamp[]>([]);
    const [zoomedDataLimits, setZoomedDataLimits] = useState<ZoomedDataLimits>({ max: null, min: null });
    const [mouseMoveDataIndex, setMouseMoveDataIndex] = useState<MouseStateProps>({ index: null, timestamp: null });
    const [predictedData, setPredictedData] = useState<SensorPredictedDataResponse>(null);
    const [reserveDuration, setReserveDuration] = useState('');
    const [timeDifference, setTimeDifference] = useState(moment.duration(0));
    const {
        jypId,
        serialNumber,
    } = packaging || {};
    const handleMouseMoveChart = useCallback((index, timestamp) => {
        setMouseMoveDataIndex(({
            index,
            timestamp,
        }));
    }, []);
    const {
        temperatureMax,
        temperatureMin,
        temperatureRangeMax,
        temperatureRangeMin,
        temperatureStatus,
    } = cargo?.temperatureCheckResult || {};
    const {
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
        sealStatus,
    } = cargo.skyCoreProductRelease || {};
    const {
        temperatureInternal,
        temperatureInternalTimestampUnit,
    } = cargo.lastMeasuredData || {};

    const temperatureRange = [
        temperatureRangeMin,
        temperatureRangeMax,
    ];

    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>({
        dataTypes: [],
        positions: [],
    });

    useEffect(() => {
        setRequestBody({
            dataTypes: [
                'TEMPERATURE',
                'DOOR',
                ...(['LOCATION_LATITUDE', 'LOCATION_LONGITUDE']),
            ],
            positions: [
                'AMBIENT',
                'INTERNAL',
            ],
        });
    }, []);
    const handleMaximize = useCallback(() => {
        onMaximize(packaging.serialNumber);
    }, [packaging.serialNumber]);
    const title = useMemo(() => getTitleText({ jypId, serialNumber, showInUTC, t }),
        [serialNumber, showInUTC, jypId]);
    const handleChange = useCallback(value => {
        setPredictedData(value);
    }, []);

    const {
        rawSensorData,
    } = useLoadedInitialData({
        cargoId: cargo.id,
        isPredictedExcursion: showExpTemp,
        requestType: 'packagings',
        serialNumber,
        shipmentId,
        shipmentNumber,
    });

    useEffect(() => {
        if (rawSensorData !== null && predictedData === null) {
            if (rawSensorData && rawSensorData?.data?.length > 0) {
                setPredictedData(rawSensorData);
            } else {
                setPredictedData(null);
            }
        }
    }, [rawSensorData]);
    const [timeCalculations, setTimeCalculations] = useState({
        isOverrun: false,
        predictedRuntime: null,
        reserveTime: null,
    });

    const findFirstOutOfRange = (data, min, max) => {
        if (!data || data.length === 0) {
            global.console.log('Data is empty or not initialized');
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const item of data) {
            // a const with the value of last index in item.d array
            const temperature = item.d[item.d.length - 1];

            if (temperature > max) {
                return moment(item.t);
            }
        }

        return null;
    };

    useEffect(() => {
        if (predictedData && predictedData.data && predictedData.data.length > 0) {
            const firstOutOfRangePoint = findFirstOutOfRange(
                predictedData.data,
                temperatureRangeMin,
                temperatureRangeMax,
            );

            if (firstOutOfRangePoint) {
                const lastDataPoint = moment(predictedData.data[predictedData.data.length - 1].t);
                const timeDiff = moment.duration(lastDataPoint.diff(firstOutOfRangePoint));
                const days = timeDiff.days();
                const hours = timeDiff.hours() + (days * 24);
                const minutes = timeDiff.minutes();

                setTimeDifference(timeDiff);
                setReserveDuration(`${hours}h ${minutes}min`);
            }
        }
    }, [predictedData, temperatureRangeMin, temperatureRangeMax]);

    useEffect(() => {
        if (predictedData && predictedData.data.length > 0) {
            const lastDataPoint = predictedData.data[predictedData.data.length - 1];
            const lastDataTimestamp = moment(lastDataPoint.t);

            let reserveTime;
            let predictedRuntime;
            let isOverrun = false;

            if (predictedData.calculationSummary) {
                const { timeLeftHours = 0, timeLeftMinutes = 0 } = predictedData.calculationSummary;

                reserveTime = (timeLeftHours * 60 + timeLeftMinutes) * 60000;
                predictedRuntime = lastDataTimestamp.clone().add(reserveTime, 'milliseconds');
                isOverrun = (timeLeftHours === 0 && timeLeftMinutes === 0);
            }

            setTimeCalculations({
                isOverrun,
                predictedRuntime: predictedRuntime.format(),
                reserveTime: isOverrun ? timeDifference : reserveTime,
            });
        }
    }, [predictedData]);

    const timeRange = useMemo(() => {
        if (closed) {
            return {
                from: shipmentStart,
                to: unloadingTimestamp || shipmentEnd,
            };
        } else if (showExpTemp) {
            return {
                from: shipmentStart,
                to: predictedData?.data?.[predictedData?.data?.length - 1]?.t,
            };
        }
        if (transport) {
            return {
                from: null,
                to: (showInUTC ? moment.utc() : moment()).format('YYYY-MM-DDTHH:mm'),
            };
        } else {
            return {
                from: shipmentStart,
                to: shipmentEnd,
            };
        }
    }, [shipmentStart, shipmentEnd, unloadingTimestamp, showInUTC, transport, showExpTemp, predictedData, closed]);

    const formatDuration = (duration) => {
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        return `${hours}h ${minutes}min`;
    };

    const predictedIndex = useMemo(() => {
        if (!predictedData) return -1;
        return predictedData
            ?.positions
            ?.findIndex((
                p, i,
            ) => p === 'INTERNAL' && predictedData.dataTypes[i] === 'TEMPERATURE_PREDICTED') || -1;
    }, [predictedData]);

    const predictedTemp = useMemo(() => {
        if (predictedIndex === -1) return null;
        const sorted = [...predictedData
            .data].map(it => it.d[predictedIndex])
            .filter(it => it !== null)
            .sort((a, b) => a - b);

        return {
            max: sorted[sorted.length - 1],
            min: sorted[0],
        };
    }, [predictedData, predictedIndex]);

    const predictedStatus = useMemo(() => {
        if (predictedTemp?.min === undefined || predictedTemp?.max === undefined) return TEMPERATURE_STATUS.NO_DATA;
        return (predictedTemp.min >= temperatureRangeMin && predictedTemp.min <= temperatureRangeMax
            && predictedTemp.max <= temperatureRangeMax && predictedTemp.max >= temperatureRangeMin)
            ? TEMPERATURE_STATUS.IN_RANGE : TEMPERATURE_STATUS.EXCURSION;
    }, [predictedTemp]);

    return (
        <div className={[classes.root, maximized && classes.maximized].join(' ')}>
            <div className={classes.map}>
                <div className={classes.titleWithMaximize}>
                    <div>{title}</div>
                    <IconButton
                        size="small"
                        onClick={handleMaximize}
                    >
                        {
                            maximized
                                ? <ZoomInIcon color="action" fontSize="small" />
                                : <ZoomOutIcon color="action" fontSize="small" />
                        }
                    </IconButton>
                </div>
                <PackagingTemperature
                    key={`PackagingTemperature_${serialNumber}`}
                    cargoId={cargo.id}
                    closed={closed}
                    coordinates={coordinates}
                    exportPng={exportPng}
                    extractTempData={exportTempData}
                    handleChange={handleChange}
                    hiddenSeries={hiddenSeries}
                    isAdmin={isAdmin}
                    mapDefaultUI
                    mapFullscreenIn
                    maximized={maximized}
                    milestones={milestones}
                    mouseMoveData={mouseMoveDataIndex}
                    options={requestBody}
                    progressUpdateEnabled={progressUpdateEnabled}
                    serialNumber={serialNumber}
                    setCoordinates={setCoordinates}
                    setHiddenSeries={setHiddenSeries}
                    setIsSensorDataComplete={setIsSensorDataComplete}
                    setMouseMoveDataIndex={handleMouseMoveChart}
                    setRequiredOrderDataUpdate={setRequiredOrderDataUpdate}
                    setZoomedDataLimits={setZoomedDataLimits}
                    shipmentId={shipmentId}
                    shipmentNumber={shipmentNumber}
                    showExpTemp={showExpTemp}
                    showInUTC={showInUTC}
                    showMap={maximized}
                    showMarkers={showMarkers}
                    showMeasuredOnly={showMeasuredOnly}
                    showTempRange={showTempRange}
                    temperatureRange={temperatureRange}
                    timeRange={timeRange}
                    zoomedDataLimits={zoomedDataLimits}
                    onFullscreenClick={() => onMaximize(serialNumber)}
                />
            </div>
            <div className={classes.divider} />
            <div className={classes.options}>
                <div className={classes.currentTemperature}>
                    {
                        transport && temperatureInternal && (
                            <>
                                <div className={classes.fieldTitle}>{t('SENSOR_DATA.CURRENT_TEMPERATURE')}</div>
                                <div className={classes.temperature}>
                                    {temperatureInternal?.toFixed(1) || 'N/A'}
                                    °C
                                </div>
                            </>
                        )
                    }

                    {
                        temperatureInternalTimestampUnit && (
                            <QuickHelpTooltip
                                tooltipInfo={{
                                    order: 4,
                                    text: t('QUICK_HELP.SENSOR_DATA.MEASURED_DATE'),
                                    uid: 'lastMeasuredDateTooltip',
                                }}
                            >
                                <div className={classes.lastConnectContainer}>
                                    <LastConnect
                                        fullText
                                        timestampUnit={temperatureInternalTimestampUnit}
                                    />
                                </div>
                            </QuickHelpTooltip>
                        )
                    }

                    {
                        temperatureMin && temperatureMax && (
                            <>
                                <div className={classes.fieldTitle}>
                                    {t('TRACK_AND_TRACE.TEMPERATURE_DURING_SHIPMENT')}
                                </div>
                                <div className={classes.tempRangeCheck}>
                                    <div style={{
                                        color: 'black',
                                        fontSize: '14px',
                                        justifyContent: 'space-between',
                                        marginBottom: 7,
                                        marginRight: '2.3ch',
                                        marginTop: 5,
                                        minWidth: '60px',
                                    }}
                                    >
                                        Actual
                                    </div>
                                    <TemperatureCheck
                                        grid={false}
                                        temperatureCheckStatus={temperatureStatus}
                                        temperatureMax={temperatureMax}
                                        temperatureMin={temperatureMin}
                                        temperatureRangeMax={temperatureRangeMax}
                                        temperatureRangeMin={temperatureRangeMin}
                                    />
                                </div>
                                {
                                    showExpTemp && (
                                        <div className={classes.tempRangeCheck}>
                                            <div style={{
                                                color: 'black',
                                                fontSize: '14px',
                                                justifyContent: 'space-between',
                                                marginBottom: 7,
                                                marginRight: '2.3ch',
                                                marginTop: 5,
                                                minWidth: '60px',
                                            }}
                                            >
                                                Expected
                                            </div>
                                            <TemperatureCheck
                                                grid={false}
                                                temperatureCheckStatus={predictedStatus}
                                                temperatureMax={predictedTemp?.max || temperatureMax}
                                                temperatureMin={predictedTemp?.min || temperatureMin}
                                                temperatureRangeMax={temperatureRangeMax}
                                                temperatureRangeMin={temperatureRangeMin}
                                            />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        showExpTemp && (
                            <div className={classes.fieldTitle}>
                                {t('TRACK_AND_TRACE.TIMELINES')}
                                <div style={{
                                    color: 'black',
                                    display: 'flex',
                                    fontSize: '14px',
                                    justifyContent: 'space-between',
                                    letterSpacing: '0.01em',
                                    marginBottom: 5,
                                    marginTop: 5,
                                }}
                                >
                                    <div>
                                        Expected Arrival:
                                    </div>
                                    <div style={{ marginRight: 15 }}>
                                        {moment(predictedData?.data[predictedData?.data.length - 1].t)
                                            .format('DD.MM. HH:mm')}
                                    </div>
                                </div>
                                <div style={{
                                    color: 'black',
                                    display: 'flex',
                                    fontSize: '14px',
                                    justifyContent: 'space-between',
                                    letterSpacing: '0.01em',
                                    marginBottom: 5,
                                    marginTop: 5,
                                }}
                                >
                                    <div>Predicted Runtime:</div>
                                    <div style={{ marginRight: 15 }}>
                                        {timeCalculations.isOverrun ? moment(timeCalculations.predictedRuntime)
                                            .subtract(timeDifference.asMilliseconds(), 'milliseconds')
                                            .format('DD.MM. HH:mm')
                                            : moment(timeCalculations.predictedRuntime)
                                                .subtract(timeDifference.asMilliseconds(), 'milliseconds')
                                                .format('DD.MM. HH:mm')}
                                    </div>
                                </div>
                                <div style={{
                                    color: 'black',
                                    display: 'flex',
                                    fontSize: '14px',
                                    justifyContent: 'space-between',
                                    letterSpacing: '0.01em',
                                    marginBottom: 5,
                                    marginTop: 5,
                                }}
                                >
                                    <div>
                                        {timeCalculations.isOverrun ? 'Overrun:' : 'Reserve:'}
                                    </div>
                                    <div style={{
                                        color: timeCalculations.isOverrun ? 'red' : 'green',
                                        marginRight: 15,
                                    }}
                                    >
                                        {
                                            timeCalculations.isOverrun
                                                ? reserveDuration
                                                : formatDuration(moment.duration(timeCalculations.reserveTime))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div style={{ flex: 1 }} />
                    {
                        hasAccess('PRODUCT_RELEASE') && (
                            <>
                                <div className={classes.fieldTitle}>{t('TRACK_AND_TRACE.PACKAGING_INTEGRITY')}</div>
                                <div className={classes.integrity}>
                                    <Badge
                                        status={
                                            middleStatus(palletStatus, palletStatus1, palletStatus2)
                                        } title={t('ASSET_TYPE_LABEL.PALLET')}
                                    />
                                    <Badge status={containerStatus} title={t('COMMON.PACKAGING')} />
                                    <Badge status={sealStatus} title={t('PACKAGING_PICTURE_SIDES.SEAL')} />
                                </div>
                            </>
                        )
                    }
                    <div>
                        {hasAccess('INTELLIGENT_MONITORING') ? (
                            <>
                                {' '}
                                <div className={classes.fieldTitle}>{t('TRACK_AND_TRACE.ENERGY_LEVEL')}</div>
                                <EnergyLevelBar
                                    breach={cargo
                                        ?.energyLevelCheckResult
                                        ?.energyLevelStatus === EnergyLevelStatus.ENERGY_LEVEL_BREACH}
                                    energyLevel={energyLevel}
                                />
                            </>
                        ) : ''}
                    </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.displayOptions}>
                    {
                        !maximized && (
                            <PackagingTemperature
                                key={`PackagingTemperatureMiniMap_${serialNumber}`}
                                coordinates={coordinates}
                                handleChange={handleChange}
                                isAdmin={isAdmin}
                                maximized={false}
                                milestones={milestones}
                                mouseMoveData={mouseMoveDataIndex}
                                noTooltip
                                showInUTC={showInUTC}
                                showMap
                                zoomedDataLimits={zoomedDataLimits}
                                onFullscreenClick={() => onMaximize(serialNumber)}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default PackagingTemperatureInfo;
