import React, { CSSProperties, ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import { ColumnDataType } from 'shared-components/Table/dataTypes';

import useClasses from 'hooks/useClasses';
import DataTypes from './DataTypes';
import styles from './BodyCell.style';

type Props = {
    bodyCellProps?: any,
    bodyData: string | number | ReactNode,
    className?: string,
    colSpan?: number,
    customCellStyle?: CSSProperties,
    dataType?: ColumnDataType,
    maskForHighlight?: string,
    onClick?: (...args: any) => any,
    verticalAlign?: 'top' | 'middle' | 'bottom',
}

const BodyCell = ({
    bodyCellProps = null,
    bodyData = null,
    className = '',
    colSpan,
    customCellStyle = {},
    dataType = 'text',
    maskForHighlight = '',
    onClick = null,
    verticalAlign = 'top',
} : Props) => {
    const classes = useClasses(styles);
    const { key, ...rest } = bodyCellProps || {};

    return (
        <TableCell
            {...rest}
            key={key}
            className={[
                classes.bodyCell,
                classes[`bodyCell${verticalAlign.charAt(0).toUpperCase() + verticalAlign.slice(1)}`],
                className,
            ].join(' ')}
            colSpan={colSpan}
            style={customCellStyle}
            width={colSpan ? 0 : null}
            onClick={onClick}
        >
            <DataTypes
                data={bodyData}
                dataType={dataType}
                maskForHighlight={maskForHighlight}
            />
        </TableCell>
    );
};

export default BodyCell;
