export const styles = () => ({
    button: {
        '> p > span': {
            whiteSpace: 'wrap !important',
        },
        maxWidth: '100%',
        textTransform: 'uppercase',
    },
    pane: {
        left: '10px',
        position: 'absolute',
        top: '10px',
    },
});
