import { useMemo } from 'react';
import { TIME_IN_MS } from 'shared-components/constants';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import { dateToNumber } from 'utils/timeUtils';

type Props = {
    dateTimeFrom: string,
    dateTimeTo: string,
    inLocalTimeZone?: boolean,
    temperatureData: SensorDataItem[]
}
const useChartLimits = ({
    dateTimeFrom,
    dateTimeTo,
    inLocalTimeZone = false,
    temperatureData = [],
}: Props) => {
    return useMemo(() => {
        const itemStep = 10 * TIME_IN_MS.minute;

        const globalFromMillis = dateToNumber(dateTimeFrom, inLocalTimeZone);
        const globalToMillis = dateToNumber(dateTimeTo, inLocalTimeZone);

        if (!temperatureData || temperatureData.length === 0) {
            return {
                xMax: globalToMillis,
                xMin: globalFromMillis,
            };
        }

        const sensorDataFirstDate = dateToNumber(temperatureData[0].t, inLocalTimeZone);
        const sensorDataLastDate = dateToNumber(temperatureData.at(-1).t, inLocalTimeZone);

        const xMin = (globalFromMillis + itemStep < sensorDataFirstDate)
            ? globalFromMillis
            : sensorDataFirstDate;

        const xMax = (sensorDataLastDate + itemStep < globalToMillis)
            ? globalToMillis
            : sensorDataLastDate;

        return {
            xMax: xMax + TIME_IN_MS.minute, xMin,
        };
    }, [
        temperatureData,
        dateTimeFrom,
        dateTimeTo,
    ]);
};

export default useChartLimits;
