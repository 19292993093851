import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const COMMON_PADDING = 16;

export const styles = (theme: SkycellThemeInterface) => ({
    accordionDetails: {
        gap: '3px',
    },
    chart: {
        minHeight: '250px',
        width: '100%',
    },
    currentTemperature: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${COMMON_PADDING}px`,
    },
    displayOptions: {
        padding: `${COMMON_PADDING - 9}px ${COMMON_PADDING}px ${COMMON_PADDING}px ${COMMON_PADDING}px`,
    },
    divider: {
        backgroundColor: theme.palette.secondary[200],
        flexBasis: '1px',
    },
    fieldTitle: {
        color: '#000',
        font: 'normal normal normal 14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 4px 0',
        opacity: 1,
        textAlign: 'left',
    },
    gmap: {

    },
    integrity: {
        display: 'flex',
        fontSize: '14px',
        gap: '10px',
        marginTop: '8px',
    },
    lastConnectContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'start',
        marginBottom: '8px',
    },
    map: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
        gap: `${COMMON_PADDING}px`,
        maxWidth: '100%',
        padding: `${COMMON_PADDING}px`,
    },
    maximized: {
        height: '100%',
    },
    options: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minWidth: '300px',
    },
    root: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        minHeight: '150px',
        width: '100%',
    },
    switchChecked: {
        '&+.MuiSwitch-track': {
            backgroundColor: 'white',
        },
        color: theme.palette.primary.deepBlue,
    },
    switchDisabled: {
        '&+.MuiSwitch-track': {
            backgroundColor: theme.palette.secondary[600],
        },
        color: theme.palette.secondary[900],
    },
    switchRow: {
        '& > label': {
            maxWidth: '75%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        },
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        justifyContent: 'space-between',
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    switchTrack: {
        backgroundColor: theme.palette.secondary[500],
    },
    tempRangeCheck: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        flex: 1,
        fontSize: '16px',
        gap: '12px',
        justifyContent: 'space-between',
        letterSpacing: '0.01em',
        paddingRight: '10px',
        width: '100%',
    },
    temperature: {
        color: theme.palette.common.black,
        fontSize: '32px',
        letterSpacing: '0.01em',
        margin: '2px 0 14px 0',
    },
    title: {
        color: theme.palette.secondary[800],
        fontSize: '18px',
        letterSpacing: '0.01em',
    },
    titleWithMaximize: {
        color: theme.palette.secondary[800],
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'space-between',
        letterSpacing: '0.01em',
    },
});
