import React from 'react';
import { statuses } from 'TrackAndTrace/GenericShipments/lib';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import moment from 'moment';
import icons from 'shared-components/icons';
import { STATUS, CargoResult } from 'Administration/Shipments/lib';
import InternalDataCheck from './components/InternalDataCheck/InternalDataCheck';
import TemperatureCheckRelative from '../../shared-components/TemperatureCheckRelative';

const marginBottom = 8;

const tableColumns: (
    t,
) => ColumnsType[] = (
    t,
) => [
    {
        Header: t('TRACK_AND_TRACE.ORDER'),
        accessor: (row) => {
            const { dataCompleteCheck, externalId } = row;
            const isRed = dataCompleteCheck?.cargoResults?.some(
                (cargo: CargoResult) => (
                    cargo.dataCompleteCheckResult?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE
                ),
            );

            return isRed ? (
                <div style={{ color: '#B92C2C' }}>
                    {externalId}
                </div>
            ) : externalId;
        },
        dataType: 'highlightedText',
        id: 'externalId',
    },
    {
        Header: t('TRACK_AND_TRACE.STATUS'),
        accessor: ({ status }) => statuses[status],
        dataType: 'text',
        id: 'status',
    },
    {
        Header: t('COMMON.FROM'),
        accessor: 'originAirport',
        dataType: 'text',
    },
    {
        Header: t('COMMON.TO'),
        accessor: 'destinationAirport',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
        accessor: 'dataOwner',
        dataType: 'text',
    },
    {
        Header: t('COMMON.PACKAGINGS'),
        accessor: (row) => row?.cargo.map((item) => {
            const { dataCompleteCheckResult } = row;
            const packagingDataCompleteCheck = dataCompleteCheckResult?.dataCompleteCheckForCargos?.find(
                cargo => cargo.packagingNumber === item.packaging.serialNumber,
            );
            const isRed = packagingDataCompleteCheck
                ?.dataCompleteCheckResult
                ?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE;

            return isRed ? (
                <div style={{ color: '#B92C2C', display: 'block' }}>
                    {item.packaging.serialNumber}
                </div>
            ) : `${item.packaging.serialNumber}\n`;
        }),
        customCellStyle: {
            maxWidth: '230px',
            whiteSpace: 'pre-wrap',
        },
        dataType: 'highlightedText',
        disableSortBy: true,
    },
    {
        Header: t('COMMON.INTERNAL_DATA_CHECK'),
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.cargoResults
                ? (
                    <InternalDataCheck
                        cargos={dataCompleteCheck?.cargoResults}
                        marginBottom={marginBottom}
                    />
                )
                : null
        ),
        dataType: 'text',
        disableSortBy: true,
    },
    {
        Header: t('COMMON.DATA_CHECK_TIMESTAMP_UTC'),
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.cargoResults?.map(
                ({ dataCompleteCheckResult }, i, arr) => (
                    <div
                        key={dataCompleteCheck.id}
                        style={{ marginBottom: i !== arr.length - 1 ? marginBottom : 0 }}
                    >
                        {
                            dataCompleteCheckResult?.executionTimestamp
                                ? moment(dataCompleteCheckResult.executionTimestamp).format('DD-MM-YYYY HH:mm')
                                : ' '
                        }
                    </div>
                )) || ' '
        ),
        dataType: 'text',
        disableSortBy: true,
    },
    {
        Header: t('COMMON.DATA_CHECK_OVERRIDE'),
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.overrideOptions?.override
                ? (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <img alt="tasks" src={icons.checked_green} style={{ marginRight: 8 }} />
                        {t('COMMON.OVERRIDDEN')}
                    </div>
                )
                : ' '
        ),
        customCellStyle: {
            alignItems: 'center',
        },
        dataType: 'text',
        id: 'dataCheckOverride',
    },
    {
        Header: `${t('PRODUCT_RELEASE.TEMPERATURE_STATUS')}`,
        accessor: (row) => (
            <div
                style={{
                    columnGap: '20px',
                    display: 'grid',
                    rowGap: '10px',
                }}
            >
                {' '}
                {row?.cargo.map((packaging) => {
                    const {
                        temperatureMax,
                        temperatureMin,
                        temperatureRangeMax,
                        temperatureRangeMin,
                        temperatureStatus,
                    } = packaging.temperatureCheckResult || {};

                    return (
                        <span
                            key={packaging.packaging.serialNumber}
                            style={{
                                paddingRight: '10px',
                                position: 'relative',
                                top: '5px',
                            }}
                        >
                            <TemperatureCheckRelative
                                bounds={[temperatureMin, temperatureMax] || null}
                                grid={false}
                                temperatureCheckStatus={temperatureStatus || null}
                                temperatureMax={temperatureMax || null}
                                temperatureMin={temperatureMin || null}
                                temperatureRangeMax={temperatureRangeMax || null}
                                temperatureRangeMin={temperatureRangeMin || null}
                            />
                        </span>
                    );
                })}
            </div>
        ),
        customWidth: '320px',
        dataType: 'custom',
        disableSortBy: true,
    },
    {
        Header: t('TRACK_AND_TRACE.TEMPERATURE_CHECK_TIMESTAMP_UTC'),
        accessor: (row) => (
            <div key={row.id}>
                {row?.temperatureCheckExecutionTimestamp
                    ? moment(row.temperatureCheckExecutionTimestamp).format('DD-MM-YYYY HH:mm')
                    : ' '}
            </div>
        ),
        dataType: 'custom',
        id: 'temperatureCheckExecutionTimestamp',
    },
].filter(Boolean);

export default tableColumns;
