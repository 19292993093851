import { CSSProperties } from 'react';
import { TempRulerArgs, TempStyleArgs } from '../TemperatureCheckRelative/lib';

export const minRangeStyle: CSSProperties = {
    bottom: '0px',
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    marginTop: 'auto',
    position: 'relative',
    right: '-13px',
    textAlign: 'end',
    width: '31px',
    zIndex: 2,
};

export const maxRangeStyle: CSSProperties = {
    bottom: '0px',
    font: 'normal normal normal 12px/14px Roboto',
    left: '-19px',
    letterSpacing: '0.37px',
    marginTop: 'auto',
    position: 'relative',
    textAlign: 'end',
    width: '31px',
    zIndex: 2,
};

export const getLineStyle = (lineCount: number, lastElement = false): CSSProperties => {
    return {
        borderLeft: '1px dashed #B7B7B7',
        height: '100%',
        marginRight: lastElement
            ? '0px'
            : lineCount === 7 ? '24px' : '14px',
    };
};

export const getNumberWidth = (temperature: number) => {
    const negativeNumber = temperature < 0 ? 4 : 0;
    const absolute = Math.abs(temperature);

    if (absolute >= 0 && absolute < 10 && absolute % 1 === 0) {
        return 32 + negativeNumber;
    } else if (absolute >= 0 && absolute < 10) {
        return 43 + negativeNumber;
    } else if (absolute >= 10 && absolute % 1 === 0) {
        return 43 + negativeNumber;
    }
    return 50 + negativeNumber;
};

export const getTempMinStyle = ({
    isPotential,
    noData,
    tempMaxRange,
    tempMin,
    tempMinRange,
    theme,
}: TempStyleArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const dangerBack = isPotential ? theme.palette.secondary[50] : '#FFE5E5';

    const inRange = tempMin >= tempMinRange && tempMin <= tempMaxRange;
    const backgroundColor = tempMin === null
        ? '#EFEFEF'
        : (inRange) ? '#EAF9EF' : dangerBack;
    const color = tempMin === null
        ? '#747474'
        : inRange ? '#17921F' : danger;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        margin: '10px 2px 16px 0',
        opacity: 1,
        padding: '0 4px',
        textAlign: 'right',
    };
};

export const getTempMaxStyle = ({
    isPotential, noData, tempMax, tempMaxRange, tempMinRange, theme,
}: TempStyleArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const dangerBack = isPotential ? theme.palette.secondary[50] : '#FFE5E5';

    const inRange = tempMax >= tempMinRange && tempMax <= tempMaxRange;

    const backgroundColor = tempMax === null
        ? '#EFEFEF'
        : inRange ? '#EAF9EF' : dangerBack;
    const color = tempMax === null
        ? '#747474'
        : inRange ? '#17921F' : danger;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        margin: '10px 0 16px 2px',
        opacity: 1,
        padding: '0 4px',
        textAlign: 'left',
    };
};

export const getTempRulerStyleBackground = (
    minInRange: boolean,
    maxInRange: boolean,
    gradColor: string,
    bothColor: string,
) => {
    if (minInRange && maxInRange) {
        return '#E0F7FF 0% 0% no-repeat padding-box';
    } else if (!minInRange && !maxInRange) {
        return `${bothColor} 0% 0% no-repeat padding-box`;
    }

    return `transparent linear-gradient(${
        minInRange ? 270 : 90}deg, ${gradColor} 0%, #E0F7FF 100%) 0% 0% no-repeat padding-box`;
};

export const getTempRulerStyle = ({
    grid,
    isPotential,
    noData,
    tempMax,
    tempMaxRange,
    tempMin,
    tempMinRange,
    theme,
    width,
}: TempRulerArgs): CSSProperties => {
    const danger = isPotential ? theme.palette.common.yellow : theme.palette.common.red;
    const minInRange = tempMin !== null && tempMin >= tempMinRange && tempMin <= tempMaxRange;
    const maxInRange = tempMax !== null && tempMax <= tempMaxRange && tempMax >= tempMinRange;
    const dangerBack = (!minInRange || !maxInRange) ? '#FFE5E5' : theme.palette.primary[100];
    const bothColor = isPotential ? '#F0DEBF' : '#FFE5E5';

    const dangerGradient = isPotential ? '#F0DEBF' : dangerBack;

    const borderLeftColor = tempMin === null
        ? theme.palette.secondary[500]
        : minInRange ? theme.palette.primary[400] : danger;
    const borderRightColor = tempMax === null
        ? theme.palette.secondary[500]
        : maxInRange ? theme.palette.primary[400] : danger;
    const background = tempMin === null && tempMax === null
        ? `${theme.palette.secondary[100]} 0% 0% no-repeat padding-box`
        : getTempRulerStyleBackground(minInRange, maxInRange, dangerGradient, bothColor);

    return {
        background: noData ? theme.palette.secondary[200] : background,
        borderLeft: `6px solid ${noData ? theme.palette.secondary[600] : borderLeftColor}`,
        borderRadius: '3px',
        borderRight: `6px solid ${noData ? theme.palette.secondary[600] : borderRightColor}`,
        height: '20px',
        margin: '7px 0 13px',
        width: !grid ? 'calc(100% - 10px)' : `${width + 3}px`,
        zIndex: 5,
    };
};
