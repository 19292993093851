export const styles = () => ({
    tempDownIcon: {
        marginLeft: '10px',
        marginRight: '7px',
    },
    tempUpIcon: {
        marginLeft: '20px',
        marginRight: '7px',
    },
});
