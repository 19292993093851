const styles = () => ({
    link: {
        alignItems: 'center',
        color: '#4C9BB5',
        display: 'flex',
        fontSize: '14px',
        justifyContent: 'start',
        textDecoration: 'none !important',
    },
});

export default styles;
