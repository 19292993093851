import Switch from '@mui/material/Switch';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import React, {
    useState, useMemo, useCallback, useRef, useEffect,
} from 'react';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LatLng } from 'dataTypes/common';
import useScreenSize from 'hooks/useScreenSize';
import { ModalDialog } from 'react-bootstrap';
import { LoadScriptNext, GoogleMap, DrawingManagerF, PolygonF } from '@react-google-maps/api';
import { LIBRARIES, MAP_CONFIG_DEFAULT, ZOOM_DEFAULTS } from 'shared-components/constants';
import { Modal, TextField, useTheme } from '@mui/material';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import CommonButton from 'shared-components/CommonButton';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import GatewaysOnMap from 'TrackAndTrace/commonComponents/GatewaysOnMap';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';
import useGetCommonData from 'hooks/useGetCommonData';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import { validatePolygon } from './lib';
import styles from '../GeofencesCard.style';

type Props = {
    geofenceData: Geofence,
    onGeofenceAdded: (path: LatLng[], name: string, id?: number) => void,
    open: boolean,
    setOpen: (arg: boolean) => void
}
const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

const GeofenceModal = ({
    geofenceData = null,
    onGeofenceAdded,
    open = false,
    setOpen,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [polygonPaths, setPolygonPaths] = useState<LatLng[]>([]);
    const { currentSize } = useScreenSize();
    const polygonRef = useRef(null);
    const [gMap, setGMap] = useState(null);
    const hasAccess = useHasAccess();
    const [showAirports, setShowAirports] = useState(false);
    const [showGateways, setShowGateways] = useState(hasAccess(userRoles.ASSET_MANAGEMENT));
    const [bounds, setBounds] = useState<number[]>([]);
    const [zoom, setZoom] = useState<number>(5);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });

    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const listenersRef = useRef([]);
    const [geofenceName, setGeofenceName] = useState('');
    const theme = useTheme<SkycellThemeInterface>();
    const handleClose = useCallback(() => {
        setPolygonPaths([]);
        setGeofenceName('');
        setOpen(false);
    }, [setOpen]);
    const handleClickAdd = useCallback(() => {
        onGeofenceAdded(polygonPaths, geofenceName);
        handleClose();
    }, [setOpen, handleClose, polygonPaths, geofenceName]);
    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    const defaultMapConfig = useMemo(() => {
        return {
            backgroundColor: 'unset',
            containerStyle: {
                height: '50vh',
                width: '100%',
            },
            zoom: 5,
        };
    }, []);
    const pathValidated = useMemo(() => {
        return polygonPaths.length !== 0 ? validatePolygon(polygonPaths, setErrorStatus) : true;
    }, [polygonPaths]);
    const polygonOptions = useMemo(() => ({
        draggable: true,
        editable: true,
        fillColor: pathValidated ? theme.palette.primary.deepBlue : theme.palette.common.red,
        fillOpacity: 0.4,
        strokeColor: pathValidated ? theme.palette.primary.deepBlue : theme.palette.common.red,
        strokeWeight: 3,
    }), [theme, pathValidated]);

    const {
        data: gateways = [],
    } = useGetCommonData<GatewayInfoDTO[]>('gateways', {
        enabled: hasAccess(userRoles.ASSET_MANAGEMENT),
        postProcess: data => data || [],
    });

    useEffect(() => {
        if (!open) {
            setPolygonPaths([]);
        }
    }, [open]);
    useEffect(() => {
        if (geofenceData && gMap) {
            const lats = geofenceData.coordinates.map(it => it?.latitude);
            const lngs = geofenceData.coordinates.map(it => it?.longitude);
            const bounds = {
                east: lngs.length ? Math.max(...lngs) : null,
                north: lats.length ? Math.max(...lats) : null,
                south: lats.length ? Math.min(...lats) : null,
                west: lngs.length ? Math.min(...lngs) : null,
            };

            gMap.fitBounds(bounds);

            setGeofenceName(geofenceData.name);
            setPolygonPaths(geofenceData?.coordinates
                ?.map(({ latitude: lat, longitude: lng }) => ({ lat, lng })));
        } else {
            setPolygonPaths([]);
            setGeofenceName('');
        }
    }, [geofenceData, gMap]);
    const onPolygonComplete = (polygon) => {
        const pathArray = polygon.getPath().getArray();
        const path: LatLng[] = pathArray.map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });

        if (validatePolygon(path, setErrorStatus)) {
            setPolygonPaths(path);
        }

        polygon.setMap(null);
    };
    const onDeletePolygon = () => {
        setPolygonPaths([]);
    };

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });

            setPolygonPaths(nextPath);
        }
    }, [setPolygonPaths, polygonRef.current]);

    const onLoad = useCallback(nextPolygon => {
        polygonRef.current = nextPolygon;
        const path = nextPolygon.getPath();

        listenersRef?.current?.push(
            path.addListener('set_at', onEdit),
            path.addListener('insert_at', onEdit),
            path.addListener('remove_at', onEdit),
        );
    },
    [onEdit]);

    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const handleLoad = useCallback((gMap) => {
        setGMap(gMap);
    }, []);

    const handleBoundsChanged = () => {
        if (gMap) {
            const newBounds = gMap.getBounds();

            setBounds([
                newBounds.getSouthWest()?.lng(),
                newBounds.getSouthWest()?.lat(),
                newBounds.getNorthEast()?.lng(),
                newBounds.getNorthEast()?.lat(),
            ]);
        }
    };

    const handleZoomChanged = () => {
        if (gMap && setZoom) {
            setZoom(gMap.getZoom());
        }
    };

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);
    const closeActiveTooltip = useCallback(() => {
        const delay = 300;
        const now = Date.now();

        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeAirportInfo]);

    useEffect(() => {
        closeActiveTooltip();
    }, [showAirports]);
    return (
        <Modal hideBackdrop open={open}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={() => setOpen(false)} />
                <div className={classes.modalWindow}>
                    <div className={classes.modalTitle}>
                        Add Geofence
                    </div>
                    <div className={classes.modalSubTitle}>
                        Please type the name of the new geofence and then draw its borders on the map.
                    </div>
                    <div className={classes.controlRow}>
                        <div className={classes.textFieldWrapper}>
                            <span className={classes.fieldTitle}>Geofence name</span>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                inputProps={{
                                    className: classes.textFieldText,
                                }}
                                size="small"
                                value={geofenceName}
                                variant="standard"
                                onChange={(event) => setGeofenceName(event.target.value)}
                            />
                        </div>

                        <div className={classes.controlOptions}>
                            <div className={classes.controlTitle}>
                                Display Options
                            </div>
                            <div>
                                <span className={classes.controlLabel}>Airports</span>
                                <Switch
                                    checked={showAirports}
                                    className={classes.switch}
                                    color="primary"
                                    disabled={false}
                                    size="medium"
                                    onChange={(event, checked) => {
                                        setShowAirports(checked);
                                    }}
                                />
                            </div>
                            {
                                hasAccess(userRoles.ASSET_MANAGEMENT) && (
                                    <div>
                                        <span className={classes.controlLabel}>Gateways</span>
                                        <Switch
                                            checked={showGateways}
                                            className={classes.switch}
                                            color="primary"
                                            disabled={false}
                                            size="medium"
                                            onChange={(event, checked) => {
                                                setShowGateways(checked);
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <LoadScriptNext
                        googleMapsApiKey={googleMapKey}
                        libraries={LIBRARIES}
                        mapIds={['c5af2a2e4049bce5']}
                    >
                        <GoogleMap
                            key="GoogleMapGeofence"
                            center={MAP_CONFIG_DEFAULT?.center || null}
                            mapContainerStyle={defaultMapConfig.containerStyle}
                            options={{
                                backgroundColor: MAP_CONFIG_DEFAULT.backgroundColor,
                                disableDefaultUI: true,
                                fullscreenControl: true,
                                mapId: 'c5af2a2e4049bce5',
                                minZoom: ZOOM_DEFAULTS[currentSize], // This will probably change,
                            }}
                            zoom={MAP_CONFIG_DEFAULT.zoom}
                            onBoundsChanged={handleBoundsChanged}
                            onClick={closeActiveTooltip}
                            onLoad={handleLoad}
                            onZoomChanged={handleZoomChanged}
                        >
                            <GatewaysOnMap
                                bounds={bounds}
                                gateways={gateways}
                                highlightBounds={polygonPaths}
                                show={showGateways}
                                zoom={zoom}
                            />

                            <DrawingManagerF
                                options={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        // @ts-ignore
                                        drawingModes: ['polygon'],
                                        // @ts-ignore
                                        position: window.google.maps.ControlPosition.TOP_CENTER,
                                    },
                                    polygonOptions,
                                    polylineOptions: {
                                        zIndex: 2000000,
                                    },
                                    rectangleOptions: {
                                        zIndex: 2000000,
                                    },
                                }}
                                onPolygonComplete={onPolygonComplete}
                            />
                            {
                                polygonPaths.length > 0 && (
                                    <PolygonF
                                        options={polygonOptions}
                                        paths={polygonPaths}
                                        onDragEnd={onEdit}
                                        onLoad={onLoad}
                                        onMouseUp={onEdit}
                                        onUnmount={onUnmount}
                                    />
                                )
                            }
                            {
                                polygonPaths.length !== 0 && (
                                    <CommonButton
                                        className={classes.mapButton}
                                        variant="outlined"
                                        onClick={onDeletePolygon}
                                    >
                                        {t('COMMON.RESET')}
                                    </CommonButton>
                                )
                            }

                            {
                                showAirports && airports.map(airport => {
                                    const handleClickAirportsInfo = () => {
                                        setActiveAirportInfo(prev => ({
                                            code: prev.code === airport.code
                                                ? null
                                                : airport.code,
                                            timestamp: Date.now(),
                                        }));
                                    };

                                    return (
                                        <AirportMarker
                                            key={airport.code}
                                            airportInfo={airport}
                                            isOpen={activeAirportInfo.code === airport.code}
                                            onClick={handleClickAirportsInfo}
                                        />
                                    );
                                })
                            }
                            {
                                showedAirportInfo !== null && (
                                    <AirportInfoTooltip
                                        airportInfo={showedAirportInfo}
                                        cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                                    />
                                )
                            }

                        </GoogleMap>

                    </LoadScriptNext>
                    <div className={classes.buttonContainer}>
                        <CommonButton
                            variant="outlined"
                            onClick={handleClose}
                        >
                            {t('COMMON.CANCEL')}
                        </CommonButton>
                        <CommonButton
                            disabled={geofenceName.length < 1 || polygonPaths.length < 3 || !pathValidated}
                            onClick={handleClickAdd}
                        >
                            {t('DATA_COMPLETE_CHECK.MODAL_BTN_CONFIRM')}
                        </CommonButton>
                    </div>
                </div>
            </ModalDialog>
        </Modal>

    );
};

export default GeofenceModal;
