export const styles = {
    card: {
        boxShadow: '0px 1px 3px #00000033',
        cursor: 'auto',
    },
    cardContents: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginRight: '60px',
        marginTop: '20px',
    },
    countText: {
        marginLeft: '20px',
        maxWidth: '66px',
        minWidth: '60px',
    },
    input: {
        font: 'normal normal normal 36px/43px Roboto',
        textAlign: 'center',
    },
    picture: {
        height: '76px',
        margin: '10px',
        width: '76px',
    },
};
