import useLocalStorage from 'hooks/uselocalStorage';
import React, {
    useCallback, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Marker,
    Polyline,
} from '@react-google-maps/api';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import { LatLng, LatLngAndTime } from 'dataTypes/common';
import { adjustBoundsForZoomIn, joinLoggers } from 'shared-components/common';
import LocationInfoTooltip from 'shared-components/Map/components/LocationInfoTooltip';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import CustomPolygon from 'TrackAndTrace/Assets/components/CustomPolygon';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import { isWithinBounds } from 'TrackAndTrace/lib';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';

import TooltipPolygonMarker from 'TrackAndTrace/commonComponents/TooltipPolygonMarker';
import useAvailableHeight from 'hooks/useAvailableHeight';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';
import LoggerTooltip from 'TrackAndTrace/Loggers/components/LoggerTooltip';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useGetCommonData from 'hooks/useGetCommonData';
import {
    Geofence,
} from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import { getQuickHelpAsset } from 'shared-components/icons';
import {
    ActiveLogger,
    ExtractedLoggerData,
    crossMarkerIconObj,
} from './lib';

import LegendPanel from './components/LegendPanel';

import BaseMap from '../commonComponents/BaseMap';

type Props = {
    core: string,
    countOfLoggersWithoutGeolocation?: number,
    loggers: ExtractedLoggerData[],
    setZoom: (arg: number) => void,
    showAirportsInfo: boolean,
    showLastConnection: boolean,
    showLoggerTypes: boolean,
    zoom: number
}
type LoggerGroups = {
    [key: string]: ExtractedLoggerData[],
};

const MapView = ({
    core = 'track-and-trace',
    countOfLoggersWithoutGeolocation = 0,
    loggers = [],
    setZoom,
    showAirportsInfo,
    showLastConnection,
    showLoggerTypes,
    zoom,
}:Props) => {
    const { t } = useCustomTranslation();
    const { currentStep, enableTooltips } = useContext(QuickHelpContext);
    const [gMap, setGMap] = useState(null);
    const {
        data: loggersMapCache,
        setItem: setCacheItem,
    } = useLocalStorage<{
        bounds: number[],
    }>('loggersMapCache');

    const [bounds, setBounds] = useState<number[]>(loggersMapCache.bounds || []);
    const [activeLogger, setActiveLogger] = useState<ActiveLogger>({
        loggerNumber: null,
        timestamp: Date.now(),
    });
    const theme = useTheme<SkycellThemeInterface>();
    const [hoveredGroupGeoKey, setHoveredGroupGeoKey] = useState<string>(null);
    const availableheight = useAvailableHeight();
    const [mapContainerRef, setMapContainerRef] = useState(null);
    const [polylineMarkerInfo, setPolylineMarkerInfo] = useState<LatLngAndTime>(null);
    const [polylinePath, setPolylinePath] = useState<LatLng[]>([]);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });

    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    useEffect(() => {
        setCacheItem('bounds', bounds);
    }, [bounds]);

    const closeActiveTooltip = useCallback(() => {
        const delay = 300;
        const now = Date.now();

        if (activeLogger.loggerNumber !== null && activeLogger.timestamp + delay < now) {
            setActiveLogger({
                loggerNumber: null,
                timestamp: now,
            });
        }
        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeLogger, activeAirportInfo]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    useEffect(() => {
        if (activeLogger.loggerNumber === null) {
            setPolylineMarkerInfo(null);
            setPolylinePath([]);
        }
    }, [activeLogger]);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    const groupedLoggers = useMemo<LoggerGroups>(() => joinLoggers<ExtractedLoggerData>(loggers), [loggers]);
    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('geofences');

    const filteredGeofences = useMemo(() => {
        if (zoom < 8) return [];

        const expansionInKm = 20;
        const latitudeAdjust = expansionInKm / 111;
        const midLatitude = (bounds[1] + bounds[3]) / 2;
        const longitudeAdjust = expansionInKm / (111 * Math.cos((midLatitude * Math.PI) / 180));

        const expandedBounds = {
            ne: { lat: bounds[3] + latitudeAdjust, lng: bounds[2] + longitudeAdjust },
            sw: { lat: bounds[1] - latitudeAdjust, lng: bounds[0] - longitudeAdjust },
        };

        return geofences.filter(geofence => {
            return geofence.coordinates.some(point => isWithinBounds(point, expandedBounds));
        });
    }, [bounds, geofences, zoom]);
    const isTour = useMemo(() => currentStep === 3 && enableTooltips, [currentStep, enableTooltips]);

    useEffect(() => {
        if (gMap?.fitBounds && bounds?.length === 4) {
            const originalBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(loggersMapCache.bounds[1], loggersMapCache.bounds[0]),
                new google.maps.LatLng(loggersMapCache.bounds[3], loggersMapCache.bounds[2]),
            );

            const adjustedBounds = adjustBoundsForZoomIn(originalBounds, 0.2);

            gMap.fitBounds(adjustedBounds);
            setTimeout(() => {
                gMap.setZoom(gMap.getZoom() + 0.001);
            }, 500);
        }
    }, [gMap]);

    return (
        <div style={{ display: 'flex' }}>
            <QuickHelpTooltip
                tooltipInfo={{
                    backgroundOpacity: 0.2,
                    childOffsetPercent: [50, 50],
                    customRectSize: [95, 95],
                    explicitChildRef: mapContainerRef,
                    image: getQuickHelpAsset('logger_details.gif'),
                    offsetPx: [65, 67],
                    order: 3,
                    padding: 0,
                    position: 'left',
                    positionDelta: [-20, 0],
                    radius: '50%',
                    text: t('ONBOARDING.LOGGERS.MAP_ELEMENTS_DESC'),
                    title: t('ONBOARDING.LOGGERS.MAP_ELEMENTS_TITLE'),
                    uid: 'onboardingMapElementsLOGGERS',
                }}
            >
                <BaseMap
                    gMap={gMap}
                    mapContainerStyle={{
                        height: `calc(${availableheight} - 60px)`,
                        width: '100%',
                    }}
                    setBounds={setBounds}
                    setGMap={setGMap}
                    setZoom={setZoom}
                    onClick={closeActiveTooltip}
                    onContainerRefLoad={(ref) => {
                        setMapContainerRef(ref);
                    }}
                >
                    {
                        isTour && (
                            <TooltipPolygonMarker itemType="Logger" />
                        )
                    }
                    {
                        !isTour && Object.keys(groupedLoggers).map((geoKey) => (
                            <LoggerMarker
                                key={geoKey}
                                isSelected={hoveredGroupGeoKey === geoKey}
                                loggerGroup={groupedLoggers[geoKey]}
                                showLastConnection={showLastConnection}
                                showLoggerTypes={showLoggerTypes}
                                onHover={() => setHoveredGroupGeoKey(geoKey)}
                            />
                        ))
                    }

                    {
                        !isTour && showAirportsInfo && airports.map(airport => {
                            const handleClickAirportsInfo = () => {
                                setActiveAirportInfo(prev => ({
                                    code: prev.code === airport.code
                                        ? null
                                        : airport.code,
                                    timestamp: Date.now(),
                                }));
                            };

                            return (
                                <AirportMarker
                                    key={airport.code}
                                    airportInfo={airport}
                                    isOpen={activeAirportInfo.code === airport.code}
                                    onClick={handleClickAirportsInfo}
                                />
                            );
                        })
                    }
                    {
                        !isTour && showedAirportInfo !== null && (
                            <AirportInfoTooltip
                                airportInfo={showedAirportInfo}
                                cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                            />
                        )
                    }
                    {
                        !isTour && hoveredGroupGeoKey && (
                            <LoggerTooltip
                                core={core}
                                loggerGroup={groupedLoggers[hoveredGroupGeoKey]}
                                setPolylinePath={setPolylinePath}
                                onMouseLeave={() => {
                                    setPolylinePath([]);
                                    setHoveredGroupGeoKey(null);
                                }}
                            />
                        )
                    }
                    {
                        !isTour && polylinePath.length > 0 && (
                            <Polyline
                                options={{
                                    geodesic: true,
                                    strokeColor: theme.palette.primary.deepBlue,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 3,
                                }}
                                path={polylinePath}
                            />
                        )
                    }
                    {
                        !isTour && polylineMarkerInfo && (
                            <Marker
                                icon={crossMarkerIconObj as any}
                                position={polylineMarkerInfo.location}
                            />
                        )
                    }
                    {
                        !isTour && polylineMarkerInfo && (
                            <LocationInfoTooltip
                                location={polylineMarkerInfo.location}
                                time={polylineMarkerInfo.time}
                            />
                        )
                    }
                    {
                        !isTour && filteredGeofences.map(({ coordinates, name, type }) => {
                            const latLngCoordinates = coordinates
                                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));

                            const geokey = `geofence-${
                                coordinates.map(({
                                    latitude, longitude,
                                }) => `${latitude}-${longitude}`).join('-')}`;

                            return (
                                <CustomPolygon
                                    key={geokey}
                                    isNameVisible={zoom > 15}
                                    map={gMap}
                                    name={name}
                                    paths={latLngCoordinates}
                                    position={latLngCoordinates[0]}
                                    systemGeofence={type === 'AIRPORT'}
                                />
                            );
                        })
                    }

                    <LegendPanel countOfLoggersWithoutGeolocation={countOfLoggersWithoutGeolocation} />
                </BaseMap>
            </QuickHelpTooltip>
        </div>
    );
};

export default MapView;
