import useClasses from 'hooks/useClasses';
import React from 'react';

const styles = theme => {
    const dark = theme.palette['mode'] === 'dark';

    return {
        item: {
            color: dark ? theme.palette.primary.light : theme.palette.primary[200],
            font: 'normal normal normal 12px/14px Roboto',
            letterSpacing: '1.07px',
            margin: '3px 6px',
            opacity: 1,
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        itemContainer: {
            background: `${
                dark
                    ? theme.palette.secondary[300]
                    : theme.palette.common.white} 0% 0% no-repeat padding-box`,
            border: `1px solid ${dark ? theme.palette.primary.light : theme.palette.primary[200]}`,
            borderRadius: '4px',
            margin: '2px 4px 2px 0',
            opacity: 1,
        },
        textContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    };
};

type Props = {
    textItems: string[],
}

const TextArray = ({ textItems = [] } : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.textContainer}>
            {
                textItems.map((text, index) => {
                    return (
                        <div key={`${text}-${index}`}>
                            <div className={classes.itemContainer}>
                                <div className={classes.item}>{text}</div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default TextArray;
