export const styles = (theme) => ({
    autoOverflow: {
        '& $line': {
            height: '10px',
            margin: '16px 0',
        },
        overflow: 'visible',
    },
    clickable: {
    },
    editable: {
        '&:after': {
            background: theme.palette.secondary[600],
            content: '""',
            display: 'block',
            height: '1px',
            transform: 'translateY(-2px)',
            width: '100%',
        },
        '&:hover': {
            '&:after': {
                background: theme.palette.secondary[500],
            },

            color: theme.palette.secondary[500],
        },
        cursor: 'pointer',
        transition: '200ms ease',
        width: 'max-content',
    },
    error: {
    // @ts-ignore
        background: `${theme.palette.common.red}2e`,
    },
    icon: {
        height: '18px',
        marginLeft: '10px',
    },
    iconShort: {
        height: '18px',
        marginRight: '10px',
        width: '22px',
    },
    infoText: {
        color: theme.palette.common.black,
        fontSize: '16px',
    },
    infoTextShort: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '16px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    line: {
        alignItems: 'center',
        display: 'flex',
    },
    mandatory: {
        background: `${theme.palette.primary[30]} 0% 0% no-repeat padding-box`,
        borderLeft: `6px solid ${theme.palette.primary[200]}`,
        borderRadius: '3px',
        opacity: 1,
    },
    mediumFont: {
        '& $line': {
            fontSize: '14px',
        },
        fontSize: '14px',
    },
    minWidth: {
        width: 'auto',
    },
    root: {
        height: '100%',
        width: '100%',
    },
    shrinkedContainer: {
        '& $editable': {
            whiteSpace: 'nowrap',
            width: '90%',
        },
        '& $line': {
            whiteSpace: 'nowrap',
            width: '100%',
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    smallFont: {
        '& $line': {
            fontSize: '12px',
        },
        fontSize: '12px',
    },
    wrap: {
        whiteSpace: 'pre-wrap',
    },
});
