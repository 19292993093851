import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Polyline } from '@react-google-maps/api';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';
import useConfig from 'hooks/useConfig';

type Props = {
    height: string,
    polylines: any[],
    selected?: any,
    widthPercent: number
}

const LocationHistoryMap = ({
    height,
    polylines = [],
    selected = null,
    widthPercent,
}: Props) => {
    const theme = useTheme();
    const config = useConfig();
    const [gMap, setGMap] = useState(null);
    const [, setBounds] = useState<number[]>([]);
    const mapId = config?.locationHistory?.mapId ?? 'c5af2a2e4049bce5';
    const tilt = config?.locationHistory?.mapId !== undefined ? 100 : 0;
    const heading = config?.locationHistory?.mapId !== undefined ? 60 : 0;

    return (
        <div style={{
            height,
            width: `${widthPercent}%`,
        }}
        >
            <BaseMap
                gMap={gMap}
                heading={heading}
                mapContainerStyle={{
                    height,
                    width: '100%',
                    zIndex: 1,
                }}
                mapId={mapId}
                setBounds={setBounds}
                setGMap={setGMap}
                tilt={tilt}
            >
                {
                    polylines.map((line, ind) => {
                        return line.lng && line.lat && (
                            <Polyline
                                key={`polyline${ind}_${line.lng}_${line.lat}`}
                                options={{
                                    strokeColor: theme.palette.primary['deepBlue'],
                                }}
                                path={line}
                            />
                        );
                    })
                }
                {selected && (
                    <LoggerMarker
                        key={selected}
                        isSelected={false}
                        loggerGroup={[selected]}
                        showLastConnection={false}
                        showLoggerTypes={false}
                        onHover={() => {}}
                    />
                )}
            </BaseMap>
        </div>
    );
};

export default LocationHistoryMap;
