import { TFunction } from 'i18next';
import { TABLE_INPUT_TYPE } from '../CommonStepTable/CommonTableTypes';

export const commonFields = (t:TFunction) => [
    {
        id: 'ambientControl',
        inputType: TABLE_INPUT_TYPE.SELECT,
        required: false,
        selectData: [
            {
                render: t('LANE_MANAGEMENT.CONTROLLED'),
                value: 'CONTROLLED',
            },
            {
                render: t('COMMON.AMBIENT'),
                value: 'NOT_CONTROLLED',
            },
        ],
        title: t('COMMON.AMBIENT'),
        width: '100px',
    },
    {
        id: 'temperatureSummer',
        inputType: TABLE_INPUT_TYPE.TEMPERATURE,
        required: true,
        title: t('COMMON.SUMMER'),
        width: '40px',
    },
    {
        id: 'temperatureWinter',
        inputType: TABLE_INPUT_TYPE.TEMPERATURE,
        required: true,
        title: t('COMMON.WINTER'),
        width: '40px',
    },
    {
        id: 'duration',
        inputType: TABLE_INPUT_TYPE.TIME_24,
        required: true,
        title: t('COMMON.DURATION'),
    },

];

export const staticRestData = {
    duration: '00:00',
    temperatureSummer: 0,
    temperatureWinter: 0,
};
