const styles = (theme) => ({
    active: {
        background: theme.palette.primary[700],
        borderRadius: '40px',
        color: theme.palette.primary[30],
    },
    customPeriod: {
        '&:hover': {
            background: theme.palette.primary[500],
            borderRadius: '40px',
            color: theme.palette.primary[50],
        },
        cursor: 'pointer',
        padding: '1px 6px',
        textAlign: 'center',
        width: 'auto',
    },
    periods: {
        alignItems: 'center',
        display: 'flex',
    },
    periodsWrapper: {
        '& > span': {
            marginRight: '19px',
        },
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'row' },
});

export default styles;
