import React, {
    useCallback,
    useRef,
    useState,
    ReactNode,
} from 'react';
import clsx from 'clsx';
import useClasses from 'hooks/useClasses';
import Header from '../Header';
import MenuBar from '../MenuBar';
import styles from './DashboardPage.style';

type Props = {
    children?: ReactNode,
}

const DashboardPage = ({
    children = null,
} : Props) => {
    const classes = useClasses(styles);
    const elm = useRef();
    const [isFixed, setIsFixed] = useState(false);
    const [barOpen, setBarOpen] = useState(false);

    const scrollHandler = useCallback((event) => {
        // @ts-ignore
        if (event.target.scrollTop >= elm.current.clientHeight && !isFixed) {
            setIsFixed(true);
        }

        // @ts-ignore
        if (event.target.scrollTop < elm.current.clientHeight && isFixed) {
            setIsFixed(false);
        }
    }, [
        isFixed,
        setIsFixed,
    ]);

    return (
        <div className={classes.main} onScroll={scrollHandler}>
            <div className={clsx(isFixed && classes.topContainer)}>
                <div ref={elm}>
                    <Header isDashboardPage toggleBar={() => setBarOpen((prev) => !prev)} />
                </div>
                <MenuBar
                    barOpen={barOpen}
                    isFixed={isFixed}
                />
            </div>
            <div className={classes.body}>
                {children}
            </div>
        </div>
    );
};

export default DashboardPage;
