const styles = {
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    offeringIconContainer: {
        marginRight: '5px',
        width: '30px',
    },
    offeringItemContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '7px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    offeringsCardContainer: {
        color: '#747474',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    title: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '10px 0 5px',
        opacity: 1,
        textAlign: 'left',
    },
};

export default styles;
