import React from 'react';
import Card from 'shared-components/Card';
import useClasses from 'hooks/useClasses';

const styles = {
    contentClass: {
        height: '100%',
        width: '100%',
    },
};

const REPORT_ID = process.env.REACT_APP_ASSET_INVENTORY_BI_ID;
const CLIENT_ID = process.env.REACT_APP_ASSET_INVENTORY_CLIENT_ID;

// eslint-disable-next-line max-len
const url = `https://app.powerbi.com/reportEmbed?reportId=${REPORT_ID}&autoAuth=true&ctid=${CLIENT_ID}`;

const AssetInventory = () => {
    const classes = useClasses(styles);

    return (
        <Card contentClass={classes.contentClass} fullHeight>
            <iframe
                allowFullScreen
                height="100%"
                src={url}
                title="Statistics"
                width="100%"
            />
        </Card>
    );
};

export default AssetInventory;
