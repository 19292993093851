import { SelectChangeEvent } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTitle from 'hooks/useCustomTitle';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import useGetCommonData from 'hooks/useGetCommonData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import CommonButton from 'shared-components/CommonButton';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import icons from 'shared-components/icons';
import { ModalDialog } from 'react-bootstrap';
import {
    Close,
} from '@mui/icons-material';
import MultiSelect from 'shared-components/MultiSelect';
import RegularSelect from 'shared-components/RegularSelect';
import { generateStyles } from './AddAccessRequest.style';

const GAP = 36;
const AddAccessRequests = ({
    allCompanies,
    companiesLoading,
    onUpdated,
}) => {
    const {
        setMenuTabs,
    } = useContext(LayoutContext);
    const { t } = useCustomTranslation();
    const [open, setOpen] = useState(false);
    const classes = useGeneratedStyles(generateStyles);
    const [geofences, setGeofences] = useState<number[]>([]);
    const [companies, setCompanies] = useState<number[]>([]);
    const [requestingCompany, setRequestingCompany] = useState<Company>(null);
    const [error, setError] = useState<string>('');
    const [requestInProgress, setRequestInProgress] = useState(false);
    const {
        setTitle,
    } = useCustomTitle();

    useEffect(() => {
        setTitle(t('MENU_ITEMS.ACCESS_REQUESTS'));
    }, []);

    const {
        setSuccessStatus,
    } = useStatusStateProcessOptions();
    const closeModal = useCallback(() => {
        if (requestInProgress) {
            return;
        }
        setOpen(false);
    }, [requestInProgress]);

    const {
        FlexibleRequest: accessRequestCreateRequest,
    } = useSkymindBackendEndpoints('admin/access-requests').requests;

    useEffect(() => {
        setMenuTabs([
            {
                buttonClass: classes.addAccessRequestButton,
                iconSrc: icons.addCircle,
                iconStyle: {
                    height: '15px',
                    marginRight: '8px',
                    width: '15px',
                },
                isAction: true,
                onClick: () => setOpen(true),
                title: 'ADD ACCESS REQUESTS',
            },
        ]);

        return () => setMenuTabs([]);
    }, []);

    const flushData = useCallback(() => {
        setRequestingCompany(null);
        setGeofences([]);
        setCompanies([]);
    }, []);
    const onAddAccessRequests = useCallback(async () => {
        setRequestInProgress(true);
        setError('');
        try {
            await accessRequestCreateRequest('POST', '', {
                dataOwnerCompanyIds: companies,
                geofenceIds: geofences,
                requestingCompanyId: requestingCompany.id,
            });
            setOpen(false);
            setSuccessStatus((geofences.length === 1 && companies.length === 1)
                ? t('MY_COMPANY.ACCESS_REQUEST_CREATED') : t('MY_COMPANY.ACCESS_REQUESTS_CREATED'));
            onUpdated();
        } catch (e) {
            const errorMessage = e.response?.data?.errorMessage?.split(':')?.[0];

            global.console.log('Error', e.response?.data?.errorMessage);
            setError(errorMessage || t('COMMON.UNFORTUNATELY_SOMETHING_WRONG'));
        } finally {
            setRequestInProgress(false);
        }
    }, [companies, geofences]);
    const handleChangeRequestingCompany = useCallback((id) => {
        setRequestingCompany(allCompanies.find(it => it.id === id));
        setGeofences([]);
    }, [allCompanies]);
    const handleChangeClearCompany = useCallback(() => {
        setGeofences([]);
        setRequestingCompany(null);
    }, [allCompanies]);
    const handleChangeGeofences = useCallback((event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        setGeofences(value as number[]);
    }, []);
    const handleChangeCompanies = useCallback((event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        setCompanies(value as number[]);
    }, []);
    const handleDeleteGeofence = useCallback((id) => {
        setGeofences(prev => prev.filter(it => it !== id));
    }, []);
    const handleDeleteCompany = useCallback((id) => {
        setCompanies(prev => prev.filter(it => it !== id));
    }, []);

    useEffect(() => {
        setGeofences([]);
        setCompanies([]);
        setError('');
    }, [open]);
    useEffect(() => {
        flushData();
    }, [open]);
    const {
        data: companyGeofences = [],
        isLoading: geofencesLoading,
        status: geofencesStatus,
    } = useGetCommonData<Geofence[]>('admin/geofences', {
        enabled: requestingCompany?.id !== undefined,
        query: {
            companyId: requestingCompany?.id,
        },
    });

    const geofenceData = useMemo<Geofence[]>(() => {
        if (geofencesStatus !== 'SUCCESS' || !requestingCompany) {
            return [];
        } else {
            return companyGeofences;
        }
    },
    [geofencesStatus, companyGeofences, requestingCompany]);
    const companyOptions = useMemo(() => {
        return allCompanies.map((it: Company) => {
            return {
                active: false,
                label: it.name,
                value: it.id,
            };
        });
    }, [allCompanies]);

    const dataComplete = useMemo(() => requestingCompany && companies.length > 0 && geofences.length > 0,
        [requestingCompany, companies, geofences]);

    useEffect(() => {
        if (dataComplete) {
            setError('');
        }
    }, [dataComplete]);
    return (
        <Modal hideBackdrop open={open}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={closeModal} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={closeModal} />
                    <div className={classes.modalTitle}>Add Access Requests</div>
                    <div>
                        <RegularSelect
                            customClasses={{
                                caretIcon: requestingCompany?.id ? '' : classes.caretIcon,
                                control: classes.select,
                            }}
                            options={companyOptions}
                            title={`${t('MY_COMPANY.REQUESTING_COMPANY')}`}
                            value={requestingCompany?.id?.toString()}
                            onChange={handleChangeRequestingCompany}
                            onClear={handleChangeClearCompany}
                        />
                    </div>
                    <div style={{
                        minHeight: GAP,
                    }}
                    >
                        <div className={classes.error}>
                            {
                                error && !requestingCompany && <span>Please select a requesting company</span>
                            }
                        </div>

                    </div>
                    <div>
                        <MultiSelect
                            data={allCompanies}
                            handleDelete={handleDeleteCompany}
                            labelId="name"
                            loading={companiesLoading}
                            selectedIds={companies}
                            titlePlural={`${t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER')}(s)`}
                            onChange={handleChangeCompanies}
                        />
                    </div>
                    <div style={{
                        minHeight: GAP,
                    }}
                    >
                        <div className={classes.error}>
                            {
                                error && dataComplete && <span>{error}</span>
                            }
                            {
                                error && companies.length === 0 && <span>Please select at least one data owner</span>
                            }
                        </div>
                    </div>
                    <div>
                        <MultiSelect
                            data={geofenceData}
                            disabled={!geofenceData || geofenceData.length === 0}
                            handleDelete={handleDeleteGeofence}
                            loading={geofencesLoading}
                            selectedIds={geofences}
                            titlePlural="Geofence(s)"
                            onChange={handleChangeGeofences}
                        />
                    </div>
                    <div style={{
                        minHeight: GAP,
                    }}
                    >
                        <div className={classes.error}>
                            {
                                geofenceData?.length === 0
                                    ? (!requestingCompany?.id
                                        ? 'Select a requesting company first' : 'No geofences for selected company')
                                    : ''
                            }
                            {
                                error && geofenceData.length > 0 && geofences.length === 0 && (
                                    <span>
                                        Please select at least one geofence
                                    </span>
                                )
                            }
                        </div>

                    </div>
                    <div className={classes.buttonHolder}>
                        <CommonButton
                            loading={requestInProgress}
                            onClick={onAddAccessRequests}
                        >
                            Add Access Requests
                        </CommonButton>
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default AddAccessRequests;
