import React, { useCallback, forwardRef, useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useClasses from 'hooks/useClasses';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import debounce from 'lodash.debounce';
import { styles } from './CommonSearch.style';

interface Props {
    externallyCleared?: boolean,
    handleClickInput?: (e: React.MouseEvent<HTMLDivElement>) => void,
    handleFocusInput?: () => void,
    minLength?: number,
    query: string,
    setQuery: React.Dispatch<React.SetStateAction<string>>
}

const DEFAULT_MIN_LENGTH = 3;

const CommonSearch = forwardRef<HTMLDivElement, Props>(({
    externallyCleared = false,
    handleClickInput = () => {},
    handleFocusInput = () => {},
    minLength = DEFAULT_MIN_LENGTH,
    query,
    setQuery,
    ...props
}, ref) => {
    const muiTheme = useTheme<SkycellThemeInterface>();
    const classes = useClasses(styles(muiTheme, minLength));

    const [searchString, setSearchString] = useState(query);

    useEffect(() => {
        if (externallyCleared) {
            setSearchString('');
        }
    }, [externallyCleared]);

    const debouncedSetQuery = useCallback(debounce((value) => {
        if (value === query) return;

        if (value.length >= minLength) {
            setQuery(value);
        } else {
            setQuery('');
        }
    }, 1000), [setQuery, query, minLength]);

    const handleChangeSearch = useCallback((event) => {
        const { value } = event.target;

        setSearchString(value);
        debouncedSetQuery(value);
    }, [debouncedSetQuery]);

    const handleClickClearSearch = useCallback(() => {
        setSearchString('');
        setQuery('');
    }, [setQuery]);

    return (
        <div
            ref={ref}
            className={[
                (searchString?.length >= minLength
                    ? classes.resetKeywordDescription
                    : classes.keywordLimitDescription),
                (searchString?.length > 0
                    ? classes.elementEnabled
                    : classes.elementDisabled),
            ].join(' ')}
            {...props}
        >
            <div className={classes.inputWrapper}>
                <TextField
                    InputProps={{
                        className: classes.input,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.input}
                    placeholder="Search"
                    value={searchString}
                    variant="outlined"
                    onChange={handleChangeSearch}
                    onClick={handleClickInput}
                    onFocus={handleFocusInput}
                />
                <Button
                    className={classes.clearSearchBtn}
                    disabled={!searchString}
                    onClick={handleClickClearSearch}
                >
                    <CloseIcon />
                </Button>
            </div>
        </div>
    );
});

CommonSearch.displayName = 'CommonSearch';

export default CommonSearch;
