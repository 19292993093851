import React, { useContext, useEffect, useState } from 'react';
import CardWrapper from 'LaneManagement/LaneDetails/StepsWrapper/CardWrapper';
import CommonStepTable from 'LaneManagement/LaneDetails/StepsWrapper/CommonStepTable';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Milestone } from 'shared-components/dataTypes';
import { roadTableDefaults, tableMetaData } from './tableMetaData';

type Props = {
    index?: number,
    onChange: Function,
    stepData: Milestone
}

const GroundHandling = ({
    onChange,
    stepData,
    ...rest
}: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groundHandlingState, setGroundHandlingState] = useState(null);
    const { steps } = useContext(LaneCardsContext);
    const { t } = useCustomTranslation();

    useEffect(() => {
        const firstIndex = steps.findIndex(it => it.type === 'GROUND_HANDLING');
        const { index } = rest;

        setGroundHandlingState(firstIndex === index ? 'first' : 'notFirst');
    }, []);

    return groundHandlingState && (
        <CardWrapper stepData={stepData} onChange={onChange} {...rest}>
            <CommonStepTable
                tableData={tableMetaData(t)}
                tableDefaults={roadTableDefaults}
            />
            <div />
        </CardWrapper>
    );
};

export default GroundHandling;
