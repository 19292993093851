export const styles = (theme) => ({
    chart: {
        '& .apexcharts-pan-icon.apexcharts-selected': {
            '&>svg': {
                stroke: `${theme.palette.primary[400]}!important`,

            },
        },
        '& .apexcharts-zoom-icon.apexcharts-selected': {
            '&>svg': {
                fill: `${theme.palette.primary[400]}!important`,
            },
        },
    },

});
