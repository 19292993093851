import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useGetCommonData from 'hooks/useGetCommonData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import CommonSearch from 'shared-components/CommonSearch';
import tableColumns from './tableColumns';

type Props = {
    isTableView: boolean,
    query: string,
    setQuery: Dispatch<SetStateAction<string>>,
    tooltipInfo?: QuickHelpTooltipInfo
}

const SearchPreviewPackages = ({
    isTableView,
    query,
    setQuery,
    tooltipInfo = {},
} : Props) => {
    const [open, setOpen] = useState(false);
    const [queryCleared, setQueryCleared] = useState(false);

    const {
        data,
    } = useGetCommonData('v2/shipments/search', {
        enabled: !isTableView,
        postProcess: it => it.resultList,
        query: {
            page: 0,
            perPage: 10,
            q: query,
        },
    });
    const { t } = useCustomTranslation();

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const handleClickShowResults = () => {
        setOpen(false);
    };

    const queryChanged = useCallback((value) => {
        setQuery(value);
        setQueryCleared(false);

        if (value.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);

            if (!isTableView) {
                setOpen(value.length !== 0);
            }
        }
    }, []);

    const handleClickClearSearch = useCallback(() => {
        setOpen(false);
        setQuery('');
        setQueryCleared(true);
    }, []);

    const columns = useMemo(() => tableColumns(t), [t]);

    const translatedShipments = useMemo(() => {
        if (query.length === 0) return [];

        return data.map((shipment) => {
            return {
                ...shipment,
                status: t(`SHIPMENT_STATUS.${shipment.status}`),
            };
        });
    }, [data, open, t]);

    const handleFocus = () => {
        setOpen(prev => prev || query?.length > 0 || translatedShipments?.length > 0);
    };

    return (
        <div>
            <div>
                <QuickHelpTooltip
                    tooltipInfo={tooltipInfo}
                >
                    <CommonSearch
                        externallyCleared={queryCleared}
                        handleClickInput={handleClick}
                        handleFocusInput={handleFocus}
                        query={query}
                        setQuery={queryChanged}
                    />
                </QuickHelpTooltip>
            </div>
            {
                !isTableView && (
                    <PreviewTable
                        columns={columns}
                        data={translatedShipments}
                        isCardOpen={open}
                        maskForHighlight={query}
                        rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                        onCardClick={handleClick}
                        onClickButtonClearSearch={handleClickClearSearch}
                        onClickButtonShowResults={handleClickShowResults}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewPackages;
