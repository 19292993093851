import React, { useState } from 'react';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';

import CardWithEditableContent from 'shared-components/CardWithEditableContent';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import styles from './UserInfoCard.style';

type Props = {
    admin?: boolean,
    currentBusinessPhone?: string,
    currentCompany?: Company,
    currentEditedCard?: string,
    currentEmail?: string,
    currentFirstName?: string,
    currentJobCategory?: string,
    currentJobTitle?: string,
    currentLastName?: string,
    currentMobilePhone?: string,
    disabledSaveButton?: boolean,
    saveInfo?: () => void,
    setCompanyId?: (companyId: number) => void,
    setCompanyRoles?: (roles: string[]) => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setUserInfo: (userInfo: AddUserInfo) => void,
    type?: 'new' | 'editable' | 'readOnly' | 'newMyCompany'
}

const cardName = 'UserInfoCard';

const UserInfoCard = ({
    admin = false,
    currentBusinessPhone = '',
    currentCompany = null,
    currentEditedCard = null,
    currentEmail = '',
    currentFirstName = '',
    currentJobCategory = '',
    currentJobTitle = '',
    currentLastName = '',
    currentMobilePhone = '',
    disabledSaveButton = false,
    saveInfo = () => {},
    setCompanyId = () => {},
    setCompanyRoles = () => {},
    setCurrentEditedCard = () => {},
    setUserInfo = () => {},
    type = 'readOnly',
}: Props) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useCustomTranslation();
    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        try {
            setLoading(true);
            await saveInfo();
            setEditMode(false);
            setLoading(false);
            setCurrentEditedCard(null);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setUserInfo({
            businessPhone: currentBusinessPhone,
            email: currentEmail,
            firstName: currentFirstName,
            jobCategory: currentJobCategory,
            jobTitle: currentJobTitle,
            lastName: currentLastName,
            mobilePhone: currentMobilePhone,
        });
        setEditMode(false);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            disabledSaveButton={disabledSaveButton}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            title={t('MY_COMPANY.USER_INFORMATION')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
        >
            {
                type === 'new' || type === 'newMyCompany' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            admin={admin}
                            currentBusinessPhone={currentBusinessPhone}
                            currentCompany={currentCompany}
                            currentEmail={currentEmail}
                            currentFirstName={currentFirstName}
                            currentJobCategory={currentJobCategory}
                            currentJobTitle={currentJobTitle}
                            currentLastName={currentLastName}
                            currentMobilePhone={currentMobilePhone}
                            setCompanyId={setCompanyId}
                            setCompanyRoles={setCompanyRoles}
                            setUserInfo={setUserInfo}
                            type={type}
                        />
                    )
                    : (
                        <ReadMode
                            currentBusinessPhone={currentBusinessPhone}
                            currentCompany={currentCompany?.name}
                            currentEmail={currentEmail}
                            currentFirstName={currentFirstName}
                            currentJobCategory={currentJobCategory}
                            currentJobTitle={currentJobTitle}
                            currentLastName={currentLastName}
                            currentMobilePhone={currentMobilePhone}
                        />
                    )
            }
        </CardWithEditableContent>
    );
};

export default UserInfoCard;
