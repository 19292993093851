import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import PhoneNumberTextField from 'shared-components/PhoneNumberTextField';
import useClasses from 'hooks/useClasses';
import styles from './UserInfoCard.style';

type Props = {
    currentBusinessPhone?: string,
    currentCompany?: string,
    currentEmail?: string,
    currentFirstName?: string,
    currentJobCategory?: string,
    currentJobTitle?: string,
    currentLastName?: string,
    currentMobilePhone?: string
}

const ReadMode = ({
    currentBusinessPhone = '',
    currentCompany = '',
    currentEmail = '',
    currentFirstName = '',
    currentJobCategory = '',
    currentJobTitle = '',
    currentLastName = '',
    currentMobilePhone = '',
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.cardContentData}>
            <div>
                <div className={classes.fieldsRow}>
                    <div>
                        <div className={classes.fieldTitle}>{t('MY_COMPANY.FIRST_NAME')}</div>
                        <div>{currentFirstName}</div>
                    </div>
                    <div>
                        <div className={classes.fieldTitle}>{t('MY_COMPANY.LAST_NAME')}</div>
                        <div>{currentLastName}</div>
                    </div>
                </div>
                <div>
                    <div className={classes.fieldTitle}>{t('MY_COMPANY.E_MAIL_ADDRESS')}</div>
                    <div>{currentEmail}</div>
                </div>
                <div>
                    <PhoneNumberTextField
                        className={[classes.textField, classes.textFieldMargin].join(' ')}
                        fieldTitlePropsClassName={classes.fieldTitle}
                        inputPropsClassName={classes.textFieldText}
                        isFieldTitleShown
                        label={t('MY_COMPANY.MOBILE_PHONE')}
                        phoneNumber={currentMobilePhone}
                    />
                </div>
                <div>
                    <PhoneNumberTextField
                        className={[classes.textField, classes.textFieldMargin].join(' ')}
                        fieldTitlePropsClassName={classes.fieldTitle}
                        inputPropsClassName={classes.textFieldText}
                        isFieldTitleShown
                        label={t('MY_COMPANY.BUSINESS_PHONE')}
                        phoneNumber={currentBusinessPhone}
                    />
                </div>
            </div>
            <div>
                <div>
                    <div className={classes.fieldTitle}>{t('COMMON.COMPANY')}</div>
                    <div>{currentCompany}</div>
                </div>
                <div>
                    <div className={classes.fieldTitle}>{t('MY_COMPANY.JOB_CATEGORY')}</div>
                    <div>{currentJobCategory ? t(`JOB_CATEGORY.${currentJobCategory}`) : ''}</div>
                </div>
                <div>
                    <div className={classes.fieldTitle}>{t('MY_COMPANY.JOB_TITLE')}</div>
                    <div>{currentJobTitle}</div>
                </div>
            </div>
        </div>
    );
};

export default ReadMode;
