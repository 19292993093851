import useClasses from 'hooks/useClasses';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { unwrapEnum } from 'LaneManagement/LaneDetails/tools';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import { Milestone } from 'shared-components/dataTypes';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { validValue } from 'LaneManagement/LaneDetails/StepsWrapper/CommonStepTable/TableInput/TableInput';
import { styles } from './CommonStepTable.style';
import { CommonTableItem, TABLE_INPUT_TYPE } from './CommonTableTypes';
import TableWrapper from './TableWrapper';

type Props = {
    edit?: boolean,
    setValidators?: any,
    stepData?: Milestone,
    tableData?: CommonTableItem[],
    tableDefaults?: any,
    validators?: any,
}

type PathParams = { laneId: string }

const CommonStepTable = ({
    setValidators,
    stepData,
    tableData,
    tableDefaults,
    validators,
}: Props) => {
    const classes = useClasses(styles);

    const { laneId } = useParams() as unknown as PathParams;

    const { updateLane } = useContext(LaneCardsContext);

    const milestoneId = useMemo(() => {
        return stepData?.uniqueId;
    }, [stepData]);
    const [editingData, setEditingData] = useState(null);
    const [activitiesLoading, setActivitiesLoading] = useState(false);
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();

    const primaryKey = tableData?.find(it => it.inputType === TABLE_INPUT_TYPE.PK)?.id || 'index';

    const {
        Create: postActivity,
        Delete: deleteActivity,
        Patch: updateActivity,
    } = useSkymindBackendEndpoints(`lanes/${laneId}/milestones/${stepData?.index}/activities`)
        .requests;

    const inputValidated = validators.activities;
    const activities = useMemo(() => {
        return (stepData?.activities || []).sort((a, b) => (a.index > b.index ? 1 : -1));
    }, [stepData?.activities]);

    const createItem = useCallback(() => {
        if (editingData) return;

        setEditingData({
            ...(tableDefaults || {}),
            duration: 0,
            temperatureSummer: 0,
            temperatureWinter: 0,
        });
    }, [tableDefaults, editingData]);

    const removeItem = useCallback((e, index) => {
        e.stopPropagation();
        (async () => {
            try {
                setActivitiesLoading(true);
                const result = await deleteActivity(index);

                if (result.status === 204) updateLane();
            } catch (error) {
                global.console.log(error);
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
            } finally {
                setActivitiesLoading(false);
            }
        })();
    }, []);

    const saveEdited = useCallback(() => {
        (async () => {
            try {
                setActivitiesLoading(true);

                let result;

                if (editingData?.index) {
                    result = await updateActivity(editingData.index, unwrapEnum(editingData));
                } else {
                    result = await postActivity(unwrapEnum(editingData));
                }
                if (result?.status === 200 || result?.status === 201) {
                    updateLane();
                }
            } catch (error) {
                global.console.log(error);
                if (error?.response?.status === 403) {
                    setErrorStatus(error?.response?.data?.errorMessage || 'Access Denied');
                }
            } finally {
                setActivitiesLoading(false);
            }
        })();
    }, [editingData]);

    const focusOut = useCallback(() => {
        if (inputValidated) {
            saveEdited();
        }
    }, [inputValidated, editingData]);

    const focusIn = useCallback((item) => {
        if (editingData) {
            if (!inputValidated) {
                return;
            } else {
                saveEdited();
            }
        }
        setEditingData(item);
    }, [inputValidated, activities, editingData]);

    useEffect(() => {
        setValidators(prev => ({
            ...prev,
            activities: editingData ? !tableData
                .filter(item => item?.required && (item.inputType !== TABLE_INPUT_TYPE.PK))
                .map(it => ({
                    item: it,
                    value: editingData?.[it?.id],
                })).some(({ item, value }) => !validValue(value, item.inputType)) : true,
        }));
    },
    [editingData, tableData]);

    useEffect(() => {
        setEditingData(null);
    }, [activities]);

    return (
        <div
            className={classes.commonStepTableParent}
        >
            {
                milestoneId ? (
                    <TableWrapper
                        activities={activities}
                        activitiesLoading={activitiesLoading}
                        classes={classes}
                        editingData={editingData}
                        focusIn={focusIn}
                        focusOut={focusOut}
                        milestoneId={milestoneId}
                        primaryKey={primaryKey}
                        removeItem={removeItem}
                        setEditingData={setEditingData}
                        tableData={tableData}
                        validated={validators.activities}
                    />
                ) : ''
            }

            <div className={classes.plusWrapper}>
                <div
                    className={[classes.plus, classes.icon].join(' ')}
                    onClick={(e) => {
                        e.stopPropagation();
                        createItem();
                    }}
                >
                    <AddCircle />
                </div>
            </div>

        </div>
    );
};

export default CommonStepTable;
