export const styles = () => ({
    app: {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
    },
    bar: {
        background: '#61C6E9',
        borderRadius: '4px',
        height: '19px',
        width: '15px',
    },
    barRed: {
        background: '#b92c2c',
        borderRadius: '4px',
        height: '19px',
        width: '15px',
    },
    button: {
        background: 'none',
        border: '1px solid #aaa',
        borderRadius: '50%',
        cursor: 'pointer',
        margin: '10px',
        outline: 'none',
        padding: '4px 8px',
    },
    innerDiv: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    shell: {
        background: '#E0F7FF',
        borderRadius: '5px',
        float: 'left',
        height: '20px',
        width: '100px',
    },
    shellRed: {
        background: '#fdcdcd',
        borderRadius: '5px',
        float: 'left',
        height: '20px',
        width: '100px',
    },
    span: {
        color: '#fff',
        float: 'right',
        fontSize: '1em',
        padding: '10px',
    },
});
