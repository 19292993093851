import useClasses from 'hooks/useClasses';
import React, {
    ChangeEvent,
    useEffect,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';

const styles = theme => ({
    conditionalNumericContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    label: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        marginLeft: '8px',
        opacity: 1,
        textAlign: 'left',
    },
    textField: {
        direction: 'rtl',
        width: '50px',
    },
});

type Props = {
    amount: number,
    disabled?: boolean,
    label?: 'm\xB2' | 'plug(s)',
    setAmountUpdate: (amountUpdate: number) => void,
}

const NumberInput = ({
    amount,
    disabled = false,
    label = 'm²',
    setAmountUpdate,
} : Props) => {
    const classes = useClasses(styles);
    const [value, setValue] = useState(amount);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const currentValue = Number.isNaN(Number(event.target.value)) ? value : Number(event.target.value);

        setValue(currentValue);
        setAmountUpdate(currentValue);
    };

    useEffect(() => {
        if (value) {
            setAmountUpdate(value);
        }
    }, [value]);

    return (
        <div className={classes.conditionalNumericContainer}>
            <TextField
                className={classes.textField}
                disabled={disabled}
                hiddenLabel
                size="small"
                value={value}
                variant="standard"
                onChange={handleChange}
            />
            <div className={classes.label}>{label}</div>
        </div>
    );
};

export default NumberInput;
