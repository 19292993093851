import React, { forwardRef, useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess from 'hooks/useHasAccess/useHasAccess';
import { userRoles } from 'hooks/useHasAccess';
import styles
    from
    'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard/ContactInformationCard.style';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';

type Props = {
    className?: string,
    currentContactPerson?: string,
    currentEditedCard?: string,
    currentEmail?: string,
    currentPhoneNumber?: string,
    isCompanyPage?: boolean,
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setFilter?: (filter) => void,
    type?: 'new' | 'editable' | 'readOnly'
}

const cardName = 'ContactInformationCard';

const ContactInformationCard = forwardRef<HTMLDivElement, Props>(({
    className = '',
    currentContactPerson = '',
    currentEditedCard = null,
    currentEmail = '',
    currentPhoneNumber = '',
    isCompanyPage = false,
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setFilter = () => {},
    type = 'readOnly',
}: Props, ref) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const rolesCanEdit = [userRoles.CUSTOMER_ADMIN, userRoles.SKYCELL_ADMIN];

    if (!isCompanyPage) rolesCanEdit.push(userRoles.SITE_MANAGEMENT);
    const isCurrentUserAdmin = hasAccess(...rolesCanEdit);

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setEditMode(false);
        setFilter({
            contactPerson: currentContactPerson,
            email: currentEmail,
            phoneNumber: currentPhoneNumber,
        });
        setCurrentEditedCard(null);
    };

    return (
        <CardWithEditableContent
            ref={ref}
            className={`${className} ${loading ? classes.loading : ''}`}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            showEditButton={type === 'editable' && isCurrentUserAdmin && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            title={t('COMMON.CONTACT_INFORMATION')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
        >
            {
                type === 'new' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            currentContactPerson={currentContactPerson}
                            currentEmail={currentEmail}
                            currentPhoneNumber={currentPhoneNumber}
                            setFilter={setFilter}
                        />
                    )
                    : (
                        <ReadMode
                            currentContactPerson={currentContactPerson}
                            currentEmail={currentEmail}
                            currentPhoneNumber={currentPhoneNumber}
                        />
                    )
            }
        </CardWithEditableContent>
    );
});

ContactInformationCard.displayName = 'ContactInformationCard';

export default ContactInformationCard;
