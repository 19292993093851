import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';
import SideFilter from 'shared-components/SideFilter';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { ClientSideFilterType, ColumnsType } from '../dataTypes';
import { getFilteredData, getSideFilterOptions } from '../lib';
import EnumFilter from './filterTypes/EnumFilter';
import ExternalTextFilter from './filterTypes/ExternalTextFilter';
import TextFilter from './filterTypes/TextFilter';
import AirportFilter from './filterTypes/AirportFilter';

type Props = {
    columns: ColumnsType[],
    data: { [key: string]: any }[],
    externalTextFilterPlaceholder?: string,
    externalTextFilterString?: string,
    onboarding?: QuickHelpTooltipInfo,
    setExternalTextFilterString?: Dispatch<SetStateAction<string>>,
    setFilteredData: Dispatch<SetStateAction<{ [key: string]: any }[]>>,
    setSelectedFilterOptionsExternal?: Dispatch<SetStateAction<ClientSideFilterType>>,
    tableId: string
}
const useTableSideFilter = ({
    columns,
    data,
    externalTextFilterPlaceholder = '',
    externalTextFilterString = '',
    onboarding = null,
    setExternalTextFilterString = null,
    setFilteredData,
    setSelectedFilterOptionsExternal = null,
    tableId,
} : Props) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<ClientSideFilterType>({});

    const filterOptions = useMemo(() => {
        return getSideFilterOptions(columns, data);
    }, [columns, data]);

    const airportColumns = useMemo(() => {
        const origin = columns.find(column => (
            column.sideFilterType === 'airportOrigin' && column.sideFilterKey)) || null;
        const destination = columns.find(column => (
            column.sideFilterType === 'airportDestination' && column.sideFilterKey)) || null;

        if (origin !== null && destination !== null) {
            return [origin, destination];
        }
        return [];
    }, [columns]);

    const airportOptions = useMemo(() => {
        if (airportColumns?.length !== 2) {
            return [];
        }

        const { [airportColumns[0].sideFilterKey]: originAirportOptions } = filterOptions;
        const { [airportColumns[1].sideFilterKey]: destinationAirportOptions } = filterOptions;

        return [originAirportOptions, destinationAirportOptions];
    }, [airportColumns, filterOptions, data]);

    const filteredColumns = useMemo(() => {
        return [...columns].sort((a, b) => {
            const { sideFilterPosition: positionA = columns?.length } = a;
            const { sideFilterPosition: positionB = columns?.length } = b;

            return positionA - positionB;
        });
    }, [columns]);

    useEffect(() => {
        if (setSelectedFilterOptionsExternal) setSelectedFilterOptionsExternal(selectedFilterOptions);
        else setFilteredData(getFilteredData(data, columns, selectedFilterOptions));
    }, [selectedFilterOptions, columns]);

    return {
        component: (
            <SideFilter id={tableId} rightSideMargin tooltipInfo={onboarding}>
                {
                    setExternalTextFilterString && (
                        <ExternalTextFilter
                            placeholder={externalTextFilterPlaceholder}
                            searchString={externalTextFilterString}
                            setSearchString={setExternalTextFilterString}
                        />
                    )
                }
                {
                    data?.length > 0 && filteredColumns
                        .map(column => {
                            if (column?.sideFilterType === 'enum' || column?.sideFilterType === 'enumList') {
                                const { [column.sideFilterKey]: enumOptions } = filterOptions;

                                return (
                                    <EnumFilter
                                        key={column?.sideFilterKey}
                                        column={column}
                                        options={enumOptions}
                                        setSelectedFilterOptions={setSelectedFilterOptions}
                                    />
                                );
                            } else if (column.sideFilterType === 'text' || column.sideFilterType === 'stringList') {
                                return (
                                    <TextFilter
                                        key={column?.sideFilterKey}
                                        column={column}
                                        setSelectedFilterOptions={setSelectedFilterOptions}
                                    />
                                );
                            }

                            return null;
                        })
                }
                {
                    data?.length > 0 && airportColumns?.length === 2 && airportOptions?.length === 2 && (
                        <AirportFilter
                            destinationAirportColumn={airportColumns[1]}
                            destinationAirportOptions={airportOptions[1]}
                            originAirportColumn={airportColumns[0]}
                            originAirportOptions={airportOptions[0]}
                            setSelectedFilterOptions={setSelectedFilterOptions}
                        />
                    )
                }
            </SideFilter>
        ),
        selectedFilterOptions,
    };
};

export default useTableSideFilter;
