import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import ImageSlider from 'shared-components/ImageSlider';

const IMAGE_HEIGHT = 104;
const IMAGE_WIDTH = 120;
const X_OFFSET = 9;
const Y_OFFSET = 7;

const styles = (theme) => ({
    closeIcon: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        position: 'absolute',
        right: '8px',
        top: '10px',
    },
    firstStackedImage: {
        left: 0,
        position: 'absolute',
        top: 0,
        zIndex: 3,
    },
    imageWrapper: {
        cursor: 'pointer',
        display: 'inline-block',
        position: 'relative',
    },
    noImageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '17px',
        justifyContent: 'center',
        padding: '17px 4px 19px 4px',
    },
    noPictureDescription: {
        color: theme.palette.secondary[400],
        font: 'normal normal normal 12px/14px Roboto',
        textAlign: 'center',
    },
    noPictureText: {
        color: theme.palette.secondary.gray,
        fontSize: '14px',
        textAlign: 'center',
    },
    previewImage: {
        borderRadius: '4px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        height: `${IMAGE_HEIGHT}px`,
        objectFit: 'cover',
        width: `${IMAGE_WIDTH}px`,
    },
    secondStackedImage: {
        left: `${X_OFFSET}px`,
        position: 'absolute',
        top: `${Y_OFFSET}px`,
        zIndex: 2,
    },
    thirdStackedImage: {
        left: `${X_OFFSET * 2}px`,
        position: 'absolute',
        top: `${Y_OFFSET * 2}px`,
        zIndex: 1,
    },
    thumbnailContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '17px',
    },
});

const ModalBody = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

interface Props {
    imageUrls: string[];
    modalClassName?: string;
}

const ImageThumbnails = ({
    imageUrls,
    modalClassName,
    ...props
}: Props) => {
    const classes = useClasses(styles);
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const { t } = useCustomTranslation();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            const childrenCount = containerRef.current.children.length;
            const height = IMAGE_HEIGHT + (childrenCount - 1) * Y_OFFSET;
            const width = IMAGE_WIDTH + (childrenCount - 1) * X_OFFSET;

            containerRef.current.style.height = `${height}px`;
            containerRef.current.style.width = `${width}px`;
        }
    }, [imageUrls, containerRef]);

    const thumbnailContent = useCallback(() => {
        if (imageUrls.length === 0 || imageUrls.every((url) => !url)) {
            return (
                <div className={classes.noImageContainer}>
                    <span className={classes.noPictureText}>
                        {t('TRACK_AND_TRACE.NO_PICTURE_AVAILABLE')}
                    </span>
                    <span className={classes.noPictureDescription}>
                        {t('TRACK_AND_TRACE.NO_PICTURE_AVAILABLE_DESCRIPTION')}
                    </span>
                </div>
            );
        }

        return (
            <div
                ref={containerRef}
                className={classes.imageWrapper}
                onClick={() => setOpen(true)}
            >
                {imageUrls.slice(0, 3).map((url, index) => (
                    <img
                        key={index}
                        alt={`thumbnail-${index}`}
                        className={`
                            ${classes.previewImage}
                            ${index === 0 ? classes.firstStackedImage : ''}
                            ${index === 1 ? classes.secondStackedImage : ''}
                            ${index === 2 ? classes.thirdStackedImage : ''}
                        `}
                        src={url}
                    />
                ))}
            </div>
        );
    }, [imageUrls, classes, t]);

    return (
        <>
            <div className={classes.thumbnailContainer} {...props}>
                {thumbnailContent()}
            </div>

            <Modal
                className={modalClassName}
                open={open}
                onClose={handleClose}
            >
                <ModalBody>
                    <Close className={classes.closeIcon} onClick={handleClose} />
                    <ImageSlider imageUrls={imageUrls} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default ImageThumbnails;
