import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme:SkycellThemeInterface) => ({
    approveButton: {
        textTransform: 'uppercase',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '28px',
    },
    pageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '2fr 1fr 3fr',
        padding: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    pageContentButtons: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '3fr 3fr',
    },
    rejectButton: {
        textTransform: 'uppercase',
    },
});

export default styles;
