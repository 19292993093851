import React, { useEffect, useRef } from 'react';
import Card from 'shared-components/Card';
import { UserInfo } from 'Contexts/AuthContext';
import useClasses from 'hooks/useClasses';
import { ProcessedNotification } from '../lib';
import styles from '../Notifications.style';

type Props = {
    notification: ProcessedNotification,
    userInfo: UserInfo,
}
const NotificationContent = ({ notification, userInfo } : Props) => {
    const classes = useClasses(styles);
    const bodyParsed = useRef(null);
    const { body } = notification;
    const { name } = userInfo;

    useEffect(() => {
        bodyParsed.current.innerHTML = body;
    }, [bodyParsed]);

    if (name) {
        return (
            <div className={classes.expansion}>
                <Card className={classes.contentCard}>
                    <div className={classes.messageContent}>
                        <div className={classes.messageContent}>
                            <div
                                ref={bodyParsed}
                                style={{
                                    whiteSpace: 'pre-wrap',
                                }}
                            />
                        </div>
                        <div className={classes.goodbye}>
                            <div>Best regards,</div>
                            <div>Your SkyMind team</div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    return null;
};

export default NotificationContent;
