import React, { Dispatch, SetStateAction, useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import GeofencesTable from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesTable';
import styles from './GeofencesCard.style';

export type Geofence = {
    coordinates: {
        latitude: number,
        longitude: number
    }[],
    id?: number,
    name: string,
    siteId?: string | number,
    type?: string,
}

type Props = {
    currentEditedCard?: string,
    currentGeofences: Geofence[],
    setCurrentEditedCard: (arg: string) => void,
    setGeofences: Dispatch<SetStateAction<Geofence[]>>,
    siteId: string,
}
const cardName = 'GeofencesCard';

const GeofencesCard = ({
    currentEditedCard,
    currentGeofences,
    setCurrentEditedCard,
    setGeofences,
    siteId,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            loadingIcon={false}
            showEditButton={!editMode}
            showSaveButton={editMode}
            title={t('COMMON.GEOFENCES')}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
        >
            <GeofencesTable
                edit={editMode}
                geofences={currentGeofences}
                setGeofences={setGeofences}
                siteId={siteId}
            />
        </CardWithEditableContent>
    );
};

export default GeofencesCard;
