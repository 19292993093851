import useAvailableHeight from 'hooks/useAvailableHeight';
import React, {
    ReactNode,
    useMemo,
    useRef,
    useState,
} from 'react';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import useClasses from 'hooks/useClasses';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import styles from './SideFilter.style';

const closedBarSize = 20;

type Props = {
    children: ReactNode,
    id: string,
    rightSideMargin?: boolean,
    tooltipInfo?: QuickHelpTooltipInfo,
}
const SideFilter = ({ children, id, rightSideMargin = false, tooltipInfo }: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const { checkScreenType, width } = useScreenSize();

    const filtersRef = useRef(null);
    const hideButtonWrapperRef = useRef(null);
    const [filtersOpen, setFiltersOpen] = useState(localStorage.getItem(`sideFilter_${id}_open`) !== 'false');

    const handleClick = () => setFiltersOpen(prev => {
        localStorage.setItem(`sideFilter_${id}_open`, String(!prev));
        return !prev;
    });

    const filterSizes = useMemo<Object>(() => {
        return {
            height: checkScreenType.up(ScreenType.SM)
                ? 'auto'
                : (filtersOpen
                    ? (filtersRef?.current?.firstElementChild?.scrollHeight
                        + (hideButtonWrapperRef?.current?.scrollHeight) || 'auto')
                    : closedBarSize + 24),
            width: checkScreenType.down(ScreenType.SM)
                ? 'auto'
                : (filtersOpen
                    ? (filtersRef?.current?.scrollWidth || 'auto')
                    : closedBarSize),
        };
    }, [filtersOpen, width]);

    const availableHeight = useAvailableHeight();

    return (
        <QuickHelpTooltip tooltipInfo={tooltipInfo}>
            <div
                ref={filtersRef}
                className={[
                    classes.container,
                    filtersOpen
                        ? rightSideMargin ? classes.marginRight : ''
                        : classes.hidden,
                ].join(' ')}
                style={{
                    ...filterSizes,
                    maxHeight: availableHeight,
                }}
            >
                <div className={[classes.filtersPane, filtersOpen ? '' : classes.filtersPaneOff].join(' ')}>
                    {children}
                </div>
                <div
                    ref={hideButtonWrapperRef}
                    className={[classes.hideButtonWrapper, filtersOpen
                        ? '' : classes.hideButtonWrapperClosed].join(' ')}
                >
                    <div
                        className={[classes.hideButton].join(' ')}
                        title={t('TRACK_AND_TRACE.FILTERS')}
                        onClick={handleClick}
                    >
                        <div
                            className={[classes.hideButtonArrow, filtersOpen ? '' : classes.rotate].join(' ')}
                        >
                            <ArrowBackIosNew className={classes.arrowSvg} />
                        </div>
                        {filtersOpen || checkScreenType.down(ScreenType.SM)
                            ? (
                                <span className={classes.hideButtonText}>
                                    {' '}
                                    {filtersOpen ? t('TRACK_AND_TRACE.HIDE_FILTERS')
                                        : t('TRACK_AND_TRACE.SHOW_FILTERS')}
                                </span>
                            )
                            : ''}
                    </div>
                </div>
            </div>
        </QuickHelpTooltip>
    );
};

export default SideFilter;
