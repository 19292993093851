import React from 'react';
import Card from 'shared-components/Card';
import GoogleAddress from 'shared-components/GoogleAddress';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    cardClassName: string,
    cardRootClassName?: string,
    initialData?: any,
    setFilter: (filter) => void,
    title?: string
}

const Address = ({
    cardClassName,
    cardRootClassName,
    initialData = null,
    setFilter,
    title,
} : Props) => {
    const { t } = useCustomTranslation();

    return (
        <Card
            className={cardClassName}
            contentClass={cardRootClassName || ''}
            title={(!title && title !== '') ? t('COMMON.ADDRESS') : title}
        >
            <GoogleAddress
                initialData={initialData}
                setFilter={setFilter}
            />
        </Card>
    );
};

export default Address;
