import useClasses from 'hooks/useClasses';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useMemo, MouseEventHandler } from 'react';
import icons from 'shared-components/icons';
import { LOCATION_OPTIONS } from 'shared-components/LocationSelector/LocationSelector';
import { AddressDTO } from 'shared-components/dataTypes';
import generateStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import { nonNullKey, nonNullObject } from 'shared-components/common';
import SingleAddressView from 'LaneManagement/LaneDetails/Cards/AddLocation/LocationInfo/SingleAddressView';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from './LocationInfo.style';
import ManualAddressView from './ManualAddressView';
import PredefinedAddress from './PredefinedAddress';

type Props = {
    autoOverflow?: boolean,
    emptyText?: string,
    error?: boolean,
    font?:'small' | 'medium',
    location?: AddressDTO,
    mandatory?: boolean,
    minWidth?: boolean,
    noIcon?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>,
    short?: boolean,
    singleLocation?: {
        [key: string]: any,
        name: string
    },
    wrap?: boolean
}

const LocationInfo = ({
    autoOverflow = false,
    emptyText,
    error = false,
    font = null,
    location,
    mandatory = false,
    minWidth = false,
    noIcon = false,
    onClick = null,
    short = false,
    singleLocation,
    wrap = false,
}: Props) => {
    const rootClasses = useGeneratedStyles(generateStyles);
    const classes = useClasses(styles);

    const { t } = useCustomTranslation();
    const locationType = useMemo(() => {
        if (!location && nonNullObject(location)) return null;

        return nonNullKey(location);
    }, [location]);

    const addressLabel = useMemo(() => {
        if (!location) return null;
        if (short) return null;

        return locationType === 'address' ? 'Address' : LOCATION_OPTIONS(t)?.[locationType]?.name;
    }, [short, locationType]);

    return (
        <div
            className={[
                classes.root,
                classes.clickable,
                mandatory ? classes.mandatory : '',
                minWidth ? classes.minWidth : '',
                short ? classes.shrinkedContainer : '',
                autoOverflow ? classes.autoOverflow : '',
                font === 'small' ? classes.smallFont : '',
                font === 'medium' ? classes.mediumFont : '',
            ].join(' ')}
            onClick={onClick || (() => {
            })}
        >
            {
                singleLocation?.name && (
                    <SingleAddressView
                        classes={classes}
                        name={singleLocation?.name}
                    />
                )
            }
            {
                !short && (
                    <span
                        className={[rootClasses.topText, wrap ? classes.wrap : ''].join(' ')}
                    >
                        {addressLabel}
                    </span>
                )
            }
            {
                location && locationType === 'address' && (
                    <ManualAddressView
                        classes={classes}
                        data={location?.[locationType]}
                        error={error}
                        noIcon={noIcon}
                        short={short}
                        onClick={onClick}
                    />
                )
            }
            {
                location && locationType && locationType !== 'address' && (
                    <PredefinedAddress
                        classes={classes}
                        data={location?.[locationType]}
                        error={error}
                        noIcon={noIcon}
                        short={short}
                        type={locationType}
                        wrap={wrap}
                        onClick={onClick}
                    />
                )
            }
            {
                !singleLocation && (!location || !locationType) && (
                    <div className={[classes.infoText].join(' ')}>
                        <span className={[classes.line, wrap ? classes.wrap : ''].join(' ')}>
                            {
                                !noIcon && (
                                    <img
                                        alt="no_icon"
                                        className={classes.iconShort}
                                        src={icons.location_type.ADDRESS}
                                    />
                                )
                            }

                            <span className={onClick ? classes.editable : ''}>
                                {emptyText || t('LANE_MANAGEMENT.ADDRESS.ADD_A_LOCATION')}
                            </span>
                        </span>
                    </div>
                )
            }
        </div>
    );
};

export default LocationInfo;
