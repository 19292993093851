import React, {
    CSSProperties, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import ShortPackagingInfo from 'shared-components/ShortPackagingInfo';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import { getAssetPicture } from 'shared-components/common';
import useConfig from 'hooks/useConfig';
import { LayoutContext } from '../Contexts/LayoutContext';
import {
    EntitySelectorItem,
} from './dataTypes';
import EntityController from './components/EntityController';
import Options from './components/Options';
import styles from './SensorData.style';

type Props = {
    assetList: EntitySelectorItem[],
    assetsFetched: boolean,
    entitySelectorItem: EntitySelectorItem,
    isAdmin?: boolean,
    isCurrentTime: boolean,
    requestBody: SensorDataRequestBody,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    setIsCurrentTime: (isCurrentTime?: boolean) => void,
    setRequestBody: (requestBody: SensorDataRequestBody) => void,
    setTimeRange: (timeRange: TimeRange) => void,
    timeRange: TimeRange
}

const AssetSensorData = ({
    assetList = [],
    assetsFetched,
    entitySelectorItem,
    isAdmin = false,
    isCurrentTime,
    requestBody,
    setEntitySelectorItem,
    setIsCurrentTime,
    setRequestBody,
    setTimeRange,
    timeRange,
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const config = useConfig();
    const [localTimezone, setLocalTimezone] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const {
        availableHeight,
    } = useContext(LayoutContext);

    const { entityType } = entitySelectorItem ?? {};

    useEffect(() => {
        setBreadcrumbData('entityType', t(({
            asset: t('TRACK_AND_TRACE.ASSET'),
            logger: t('TRACK_AND_TRACE.LOGGER'),
            shipment: t('COMMON.SHIPMENT'),
        })[entityType || 'assets']));
    },
    [entitySelectorItem]);

    const loadingWrapperStyle: CSSProperties = useMemo(() => (assetsFetched ? {} : {
        alignItems: 'center',
        display: 'flex',
        height: Number(availableHeight.replaceAll('px', '')) - 20,
        justifyContent: 'center',
    }), [assetsFetched, availableHeight]);

    const assetPicture = useMemo(() => getAssetPicture({
        assetType: entitySelectorItem.additionalData.assetType,
        configPictures: config?.locationHistory?.configPictures,
    }), [config, entitySelectorItem.additionalData.assetType]);

    return (
        <>
            <div className={classes.contentContainer}>
                <div style={loadingWrapperStyle}>
                    <EntityController
                        entitySelectorItem={entitySelectorItem}
                        isAdmin={isAdmin}
                        isLoading={!assetsFetched}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                    />
                </div>
                <div className={classes.contentRightColumn}>
                    <Options
                        assetList={assetList}
                        disabled={entityType === null}
                        entitySelectorItem={entitySelectorItem}
                        isCurrentTime={isCurrentTime}
                        localTimezone={localTimezone}
                        mapAvailable
                        requestBody={requestBody}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setIsCurrentTime={setIsCurrentTime}
                        setLocalTimezone={setLocalTimezone}
                        setRequestBody={setRequestBody}
                        setShowMap={setShowMap}
                        setTimeRange={setTimeRange}
                        timeRange={timeRange}
                    />
                    {
                        (entityType === 'assets') && (
                            <ShortPackagingInfo
                                model={
                                    entitySelectorItem.additionalData.assetType === 'CONTAINER'
                                        ? t('ASSET_TYPE_LABEL.CONTAINER')
                                        : entitySelectorItem.additionalData.assetType === 'PALLET'
                                            ? t('ASSET_TYPE_LABEL.PALLET')
                                            : t('ASSET_TYPE_LABEL.OTHER')
                                }
                                picture={assetPicture}
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default AssetSensorData;
