import { useTheme } from '@mui/material/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useMemo } from 'react';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

type Props = {
    size?: string,
    temperatureCheckStatus: string
}
const getColorMap = (theme: SkycellThemeInterface) => ({
    EXCURSION: theme.palette.common.red,
    IN_RANGE: theme.palette.primary.deepBlue,
    IN_RANGE_NOT_COMPLETED: theme.palette.primary.deepBlue,
    IN_RANGE_ONGOING: theme.palette.primary.deepBlue,
    IN_RANGE_PARTIAL_DATA: theme.palette.primary.deepBlue,
    NOT_APPLICABLE: theme.palette.primary.deepBlue,
    NO_DATA: theme.palette.secondary[600],
    POTENTIAL_EXCURSION: theme.palette.common.yellow,
    UNKNOWN: theme.palette.primary.deepBlue,
    WAITING_FOR_DATA: theme.palette.secondary[600],
});

export const getTempCheckIcon = (temperatureCheckStatus, theme, size, square = false) => {
    if (!temperatureCheckStatus) return null;

    const shapeStyle = {
        alignItems: 'center',
        display: 'flex',
        fontSize: size,
    };

    if (temperatureCheckStatus === 'IN_RANGE_PARTIAL_DATA') {
        return (
            <div style={shapeStyle}>
                <div
                    style={{
                        background: `repeating-linear-gradient(90deg,\
                         ${theme.palette.primary.deepBlue}, ${theme.palette.primary.deepBlue} 3px, \
                         ${theme.palette.common.white} 3px, ${theme.palette.common.white} 5px)`,
                        borderRadius: square ? '5px' : '50%',
                        height: '19px',
                        width: '19px',
                    }}
                />
            </div>
        );
    }

    return (
        <div style={shapeStyle}>
            <div
                style={{
                    background: getColorMap(theme)[temperatureCheckStatus],
                    borderRadius: square ? '5px' : '50%',
                    height: '19px',
                    width: '19px',
                }}
            />
        </div>
    );
};
const TemperatureCheckIcon = ({
    size = '20px',
    temperatureCheckStatus = null,
}: Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const {
        t,
    } = useCustomTranslation();

    const icon = useMemo(() => getTempCheckIcon(temperatureCheckStatus, theme, size), [temperatureCheckStatus]);

    if (temperatureCheckStatus === null) return <></>;

    return (
        <CommonTooltip
            description={(
                <div style={{
                    color: theme.palette.common.black,
                }}
                >
                    <span style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '6px',
                    }}
                    >
                        <span style={{
                            display: 'inline-block',
                            height: '20px',
                            margin: '6px 3px',
                            width: '20px',
                        }}
                        >
                            {icon}
                        </span>

                        {
                            temperatureCheckStatus === 'NO_DATA'
                                ? t('TEMPERATURE_STATUS.NOT_ENOUGH_DATA')
                                : t(`TEMPERATURE_STATUS.${temperatureCheckStatus}`)
                        }
                    </span>
                    {t(`TEMPERATURE_STATUS_TOOLTIP_DESCRIPTION.${temperatureCheckStatus}`)}
                </div>
            )}
            type={TOOLTIP_TYPE.REGULAR}
        >
            {icon}
        </CommonTooltip>

    );
};

export default TemperatureCheckIcon;
