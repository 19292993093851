import { AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetAirports = (postProcess = it => it): AirportExtended[] => {
    const { GetAll: getAirports } = useSkymindBackendEndpoints('airports').requests;

    const {
        data: airportList = [],
    } = useCachedQueryRequest({
        key: 'airports',
        onCompleted: it => postProcess(it?.data || []),
        request: () => getAirports({}),
    });

    return airportList;
};

export default useGetAirports;
