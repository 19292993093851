import icons from 'shared-components/icons';
import Loading from 'LaneManagement/LaneDetails/StepsWrapper/steps/Loading';
import Road from 'LaneManagement/LaneDetails/StepsWrapper/steps/Road';
import Unloading from 'LaneManagement/LaneDetails/StepsWrapper/steps/Unloading';
import Positioning from 'LaneManagement/LaneDetails/StepsWrapper/steps/Positioning';
import Sea from 'LaneManagement/LaneDetails/StepsWrapper/steps/Sea';
import Flight from 'LaneManagement/LaneDetails/StepsWrapper/steps/Flight';
import GroundHandling from 'LaneManagement/LaneDetails/StepsWrapper/steps/GroundHandling';
import Warehouse from 'LaneManagement/LaneDetails/StepsWrapper/steps/Warehouse';
import Customs from 'LaneManagement/LaneDetails/StepsWrapper/steps/Customs';
import { TFunction } from 'i18next';
import ReactComponent from 'react-styleguidist/lib/client/rsg-components/ReactComponent';

export const BUTTON_MAP: (t?: TFunction) => {
    component?: ReactComponent,
    icon?: string,
    label?: string,
    milestoneType?: string
}[] = (t) => [
    {
        component: Positioning,
        icon: icons.stepTypes.road,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.POSITIONING'),
        milestoneType: 'POSITIONING',
    },
    {
        component: Loading,
        icon: icons.stepTypes.confirmation,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.LOADING'),
        milestoneType: 'LOADING',
    },
    {
        component: Road,
        icon: icons.stepTypes.road,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.ROAD'),
        milestoneType: 'ROAD',
    },
    {
        component: Sea,
        icon: icons.stepTypes.sea,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.SEA'),
        milestoneType: 'SEA',
    },
    {
        component: Flight,
        icon: icons.stepTypes.air,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.AIR'),
        milestoneType: 'AIR',
    },
    {
        component: GroundHandling,
        icon: icons.stepTypes.ground_handling,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.GROUND_HANDLING'),
        milestoneType: 'GROUND_HANDLING',
    },
    {
        component: Warehouse,
        icon: icons.warehouse,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.WAREHOUSE'),
        milestoneType: 'WAREHOUSE',
    },
    {
        component: Customs,
        icon: icons.customs_grey,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.CUSTOMS'),
        milestoneType: 'CUSTOMS',
    },
    {
        component: Unloading,
        icon: icons.stepTypes.confirmation,
        label: t && typeof t === 'function' && t('LANE_MANAGEMENT.MILESTONE_TYPE.UNLOADING'),
        milestoneType: 'UNLOADING',
    },
];

export const allowedButtonsArray = [
    [0, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 1, 1, 1, 0, 1, 1, 0],
    [0, 0, 0, 1, 0, 1, 1, 1, 1],
    [0, 0, 1, 0, 1, 0, 1, 1, 1],
    [0, 0, 0, 0, 1, 1, 0, 1, 0],
    [0, 0, 1, 0, 1, 0, 1, 1, 0],
    [0, 0, 1, 1, 0, 1, 0, 1, 1],
    [0, 0, 1, 1, 1, 1, 1, 0, 1],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
];

export const getAllowedButtons = (lastMilestone, t) => {
    if (!lastMilestone) return [BUTTON_MAP(t)[0], BUTTON_MAP(t)[1]];
    const milestoneIndex = BUTTON_MAP(t).findIndex(it => it.milestoneType === lastMilestone);
    const allowed = allowedButtonsArray[milestoneIndex]
        .map((it, index) => (it === 1 ? BUTTON_MAP(t)[index] : null));

    return allowed.filter(it => !!it);
};
