import React from 'react';
import CheckboxRow from 'shared-components/CheckboxRow';
import icons from 'shared-components/icons';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import LabelComponent from './LabelComponent';
import styles from './OfferingsSelection.style';

type Props = {
    availableOfferings: string[],
    selectedOfferings: string[],
    setOfferings: (value: string[]) => void,
    title?: string,
}

const OfferingsSelection = ({
    availableOfferings,
    selectedOfferings,
    setOfferings,
    title,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div>
            {
                title && <div className={classes.title}>{title}</div>
            }
            {
                availableOfferings.map((offering) => {
                    return (
                        <CheckboxRow
                            key={offering}
                            classes={classes}
                            initialValue={selectedOfferings.includes(offering)}
                            label={(
                                <LabelComponent
                                    label={t(`SERVICE_PROVIDERS.${offering}`)}
                                    src={icons[offering.toLowerCase()]}
                                />
                            )}
                            setFilter={setOfferings}
                            value={offering}
                        />
                    );
                })
            }
        </div>
    );
};

export default OfferingsSelection;
