const styles = (theme) => ({
    staticTextStyle: {
        alignItems: 'center',
        color: theme?.palette?.common?.black,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 'normal',
        gap: '4px',
        letterSpacing: '0.005em',
        marginTop: '15px',
    },
});

export default styles;
