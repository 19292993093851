import React from 'react';
import TitleHeader from 'TitleHeader';
import BackToLink from 'shared-components/BackToLink';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import CategorySummary from './components/CategorySummary';
import CategoryInfo from './components/CategoryInfo';
import Legend from './components/Legend';
import SummaryCard from '../SummaryCard';

import styles from './Capabilities.style';

type Props = {
    buttonBack?: {
        label: string,
        to: string,
    },
    companyInfo: any,
    showTooltip?: boolean,
    titlePrefix?: string,
}

const Capabilities = ({
    buttonBack,
    companyInfo = null,
    showTooltip = false,
    titlePrefix = '',
} : Props) => {
    const classes = useClasses(styles);

    const { t } = useCustomTranslation();
    const buttonBackData = buttonBack || {
        label: t('SERVICE_PROVIDERS.BACK_TO_SERVICE_COMPANIES_INFO'),
        to: '/service-providers',
    };

    if (companyInfo) {
        return (
            <>
                <TitleHeader>{`${titlePrefix}${t('COMMON.SUMMARY')}`}</TitleHeader>
                <div className={classes.capabilities}>
                    <SummaryCard
                        definedBy={companyInfo.questionnaireAnsweredBy.fullName}
                        extendedProfile={companyInfo.questionnaireProfile === 'EXTENDED'}
                        id="SummaryCard"
                        reviewedBy={companyInfo.questionnaireReviewedBy.fullName}
                        totalStatistics={companyInfo.capabilities.totalStatistics}
                    />
                    {
                        Object.keys(companyInfo.capabilities.statisticsByCategory).map((category) => {
                            const certificates = companyInfo.certificates[category]
                                ? companyInfo.certificates[category].reduce((data, item) => {
                                    if (item.subCertificates?.length) {
                                        return [...data, ...item.subCertificates];
                                    }

                                    return [...data, item.mainCertificate];
                                }, [])
                                : [];

                            return (
                                <CategorySummary
                                    key={`${category}InfoCard`}
                                    categoryStatistics={
                                        companyInfo.capabilities.statisticsByCategory[category]
                                    }
                                    certificates={certificates}
                                    facilities={companyInfo.facilities[category]}
                                    id={`CategorySummary-${category}`}
                                    title={t(`SERVICE_PROVIDERS.${category}`)}
                                />
                            );
                        })
                    }
                </div>
                <Legend />
                {
                    Object.keys(companyInfo.capabilities.byCategoryAndSubCategory)
                        .map((category) => {
                            const certificates = companyInfo.certificates[category]
                                ? companyInfo.certificates[category].map((certificate) => {
                                    return {
                                        mainCertificate: certificate.mainCertificate,
                                        subCategory: certificate.subCategory,
                                        subCertificates: certificate.subCertificates,
                                    };
                                })
                                : [];

                            return (
                                <CategoryInfo
                                    key={`CategoryInfo-${category}`}
                                    capabilities={companyInfo.capabilities.byCategoryAndSubCategory[category]}
                                    certificates={certificates}
                                    facilities={companyInfo.facilities[category]}
                                    questionnaire={companyInfo.questionnaire.byCategoryAndSubCategory[category]}
                                    showTooltip={showTooltip}
                                    title={`${titlePrefix}${t(`SERVICE_PROVIDERS.${category}`)}`}
                                />
                            );
                        })
                }
                <div className={classes.overviewFooter}>
                    <BackToLink
                        title={buttonBackData.label}
                        to={buttonBackData.to}
                    />
                </div>
            </>
        );
    }

    return (
        <div className={classes.overviewFooter}>
            <BackToLink
                title={buttonBackData.label}
                to={buttonBackData.to}
            />
        </div>
    );
};

export default Capabilities;
