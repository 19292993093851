/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    createContext,
    ReactChild,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { RequestStatus } from 'dataTypes/common';
import { TIME_IN_MS } from 'shared-components/constants';
import StatusInfo from 'shared-components/StatusInfo';
import Keycloak from 'keycloak-js';

import {
    AuthContextInterface,
    ExtendedProfile,
    KeycloakProfile,
    UserInfo,
} from './dataTypes';

import {
    getUserInfo,
    initialExtendedProfile,
    initialUserInfo,
} from './lib';

const {
    REACT_APP_AUTH_SERVER_URL: url,
    REACT_APP_REALM: realm,
    REACT_APP_RESOURCE: clientId,
} = process.env;

type Props = {
    children: ReactChild,
}

export const AuthContext = createContext<AuthContextInterface>({
    logout: () => {},
    token: '',
    userInfo: initialUserInfo,
});

const Auth = ({ children } : Props) => {
    const [token, setToken] = useState<string>('');
    const [timeOutId, setTimeOutId] = useState(null);
    const [keycloak, setKeycloak] = useState<Keycloak>(null);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [isAuthLoading, setAuthIsLoading] = useState<boolean>(true);
    const [extendedProfile, setExtendedProfile] = useState<ExtendedProfile>(initialExtendedProfile);
    const didInit = useRef(false);

    useEffect(() => {
        setStatus('PENDING');

        (async () => {
            try {
                const keycloak = new Keycloak({
                    clientId,
                    realm,
                    url,
                });

                if (keycloak) {
                    if (didInit.current) {
                        return;
                    }

                    didInit.current = true;
                    await keycloak.init({
                        onLoad: 'login-required',
                    });

                    setKeycloak(keycloak);
                    const { token } = keycloak;

                    setToken(token);
                    setStatus('SUCCESS');
                } else {
                    setStatus('FAILURE');
                }
            } catch (error) {
                global.console.log(error);
                setStatus('FAILURE');
            }
        })();
    }, []);

    useEffect(() => {
        if (keycloak) {
            const { tokenParsed } = keycloak;
            const { exp } = tokenParsed;
            const timeout = exp * TIME_IN_MS.second - Date.now();
            const delay = 30 * TIME_IN_MS.second;

            if (timeOutId) {
                clearTimeout(timeOutId);
            }

            const newTimeOutId = setTimeout(() => {
                keycloak.updateToken(TIME_IN_MS.minute * 5)
                    .then((refreshed) => {
                        if (refreshed) {
                            const { token } = keycloak;

                            setToken(token);
                        }
                    })
                    .catch((err) => {
                        global.console.log(err);
                        keycloak.logout();
                    });
            }, timeout - delay);

            setTimeOutId(newTimeOutId);
        }
    }, [keycloak, token]);

    useEffect(() => {
        if (keycloak && !extendedProfile.email) {
            (async () => {
                try {
                    const profile: KeycloakProfile = await keycloak.loadUserProfile();
                    const { tokenParsed } = keycloak;
                    const { groups: assignedRoles = [], name } = tokenParsed;

                    setExtendedProfile({
                        ...profile,
                        assignedRoles,
                        name,
                    });
                } catch (error) {
                    setExtendedProfile(initialExtendedProfile);
                } finally {
                    setAuthIsLoading(false);
                }
            })();
        }
    }, [keycloak]);

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return initialUserInfo;
        }

        return getUserInfo(extendedProfile);
    }, [extendedProfile]);

    return (
        <>
            {
                !isAuthLoading && status === 'SUCCESS' && (
                    <AuthContext.Provider
                        value={{
                            logout: keycloak ? keycloak.logout : () => {},
                            token,
                            userInfo,
                        }}
                    >
                        {children}
                    </AuthContext.Provider>
                )
            }
            {
                !isAuthLoading && status === 'FAILURE' && (
                    <StatusInfo
                        error="An Error happened during login"
                        status={status}
                    />
                )
            }
            {
                (status === 'PENDING') && (
                    <StatusInfo
                        isAuthLoading={isAuthLoading}
                        status={status}
                    />
                )
            }
        </>
    );
};

export default Auth;
