import { useTheme } from '@emotion/react';
import Tooltip from '@mui/material/Tooltip';
import { Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import moment from 'moment/moment';
import React, { ReactElement, useMemo } from 'react';
import { TIME_IN_MS } from 'shared-components/constants';
import icons from 'shared-components/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const Description = ({ location, timestamp }) => {
    const theme = useTheme();

    const geolocation = location?.geolocation;
    // Get timezone information for the relevant geolocation
    const { gmtOffsetInMs, loading } = useGetTimeZoneInfoByGeolocation(geolocation, timestamp, true);
    const { t } = useCustomTranslation();

    // Format the timestamp based on the timezone offset

    const displayDate = useMemo(() => {
        if (loading) {
            // Optionally handle the loading state by returning null or some placeholder
            return t('ORDER_DETAILS.LOADING_TIMEZONE_DATA');
        }

        if (!timestamp) {
            return t('ORDER_DETAILS.NO_DATE_TIME_YET');
        }

        return moment(timestamp)
            .utc(true)
            .utcOffset(gmtOffsetInMs / TIME_IN_MS.minute)
            .format('DD.MM.YYYY HH:mm Z');
    }, [timestamp, gmtOffsetInMs]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
        }}
        >
            <div style={{
                color: theme.palette.common.black,
                fontSize: 16,
            }}
            >
                {location?.name}
            </div>
            <div style={{
                alignItems: 'center',
                color: theme.palette.common.black,
                display: 'flex',
                fontSize: 13,
                gap: 10,
            }}
            >
                <img
                    alt="status"
                    height={25}
                    src={icons.clock}
                    style={{
                        filter:
                            'invert(64%) sepia(54%) saturate(6823%) hue-rotate(167deg) brightness(93%) contrast(139%)',
                    }}
                    width={25}
                />
                {' '}
                {displayDate}
            </div>
        </div>
    );
};

export const styles = (theme: SkycellThemeInterface) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    popper: {
        filter: `drop-shadow(0px 0px 1px ${theme.palette.common.fourthShadeOfGray})`,
    },
    root: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
    },
});
const WrappedTooltip = ({ activeMilestone, children, open, range }: {
    activeMilestone: Milestones,
    children: ReactElement,
    open: boolean,
    range: [number, number]
}) => {
    const classes = useClasses(styles);

    if (open) {
        return (
            <Tooltip
                arrow
                classes={{
                    arrow: classes.arrow,
                    popper: classes.popper,
                    tooltip: classes.root,
                }}
                open
                placement="top-start"
                title={(
                    <Description
                        location={activeMilestone.pickupLocation}
                        timestamp={range[0]}
                    />
                )}
            >
                <Tooltip
                    arrow
                    classes={{
                        arrow: classes.arrow,
                        popper: classes.popper,
                        tooltip: classes.root,
                    }}
                    open
                    placement="bottom-end"
                    title={(
                        <Description
                            location={activeMilestone.deliveryLocation}
                            timestamp={range[1]}
                        />
                    )}
                >
                    {children}
                </Tooltip>
            </Tooltip>
        );
    } else return children;
};

export default WrappedTooltip;
