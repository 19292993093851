import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    addGeolocationDescription: {
        alignItems: 'center',
        color: '#747474',
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        marginBottom: '16px',
        opacity: 1,
        textAlign: 'left',
    },
    addGeolocationDescriptionIcon: {
        marginRight: '8px',
    },
    backdrop: {
        background: '#0000001F',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 100,
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '25px',
        justifyContent: 'flex-end',
        marginTop: '50px',
    },
    contentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    contentDataMargin: {
        marginTop: '15px',
    },
    controlLabel: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    controlOptions: {
        '& > div': {
            alignItems: 'center',
            display: 'flex',
            gap: '16px',
        },
        alignItems: 'center',
        display: 'flex',
        gap: '32px',
        justifyContent: 'flex-end',
    },
    controlRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        marginBottom: '15px',
    },
    controlTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    coordinatesFieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginTop: '-4px',
        opacity: 1,
        textAlign: 'left',
    },
    disabledIcon: {
        cursor: 'unset',
        opacity: 0.6,
    },
    error: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #D44848',
        borderRadius: '3px',
        opacity: 1,
    },
    fieldTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        marginBottom: '3px',
        textAlign: 'left',
    },
    flexContent: {
        alignItems: 'start',
        display: 'flex',
        justifyContent: 'space-between',
    },
    icon: {
        '&:hover': {
            color: theme.palette.secondary[800],
            cursor: 'pointer',
        },
        color: theme.palette.secondary[600],
    },
    infoIcon: {
        height: '26px',
        marginRight: '7px',
        opacity: 1,
        width: '26px',
    },
    infoText: {
        color: '#747474',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    latLngTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        opacity: 1,
        textAlign: 'left',
    },
    listBox: {
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    mapButton: {
        bottom: '1%',
        left: 'calc(50% - 50px)',
        position: 'absolute',
        width: '100px',
    },
    mapButtonContainer: {
        marginRight: '20px',
    },
    modalSubTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        letterSpacing: '0.03em',
        marginBottom: '18px',
        textAlign: 'left',
    },
    modalTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        marginBottom: '15px',
        textAlign: 'left',
    },
    modalWidth: {
        width: '75%',
    },
    modalWindow: {
        '.MuiSelect-iconStandard': {
            color: '#4B4B4B',
        },
        background: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
        minWidth: '485px',
        padding: '17px 30px 30px 24px',
        position: 'relative',
        width: '60vw',
        zIndex: 101,
    },
    modalWrapper: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
        width: '100vw',
    },
    plus: {
        padding: '10px',
    },
    plusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectShade: {
        background: theme.palette.primary[30],
    },
    selectStyle: {
        bakcground: 'purple!important',
    },
    switch: {
        '& .MuiSwitch-switchBase': {
            color: theme.palette.common.white,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            color: theme.palette.secondary.gray,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: theme.palette.primary.deepBlue,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.56)',
        },
    },
    table: {
        '& th, & td': {
            font: 'Roboto',
            fontSize: '16px',
            padding: '7px 10px',
        },
    },
    tableButtonCell: {
        padding: '0 10px!important',
        textAlign: 'end',
    },
    tableRow: {
        '& td': {
            color: theme.palette.secondary[600],
            minHeight: '40px',
        },
        '& th': {
            color: theme.palette.secondary[600],
            fontWeight: 'bold',
        },

    },
    textField: {
        '& > div': {
            height: 20,
        },
        '&::after': {
            background: theme.palette.primary[200],
            borderRadius: '3px',
            content: '""',
            height: '100%',
            left: -7,
            position: 'absolute',
            top: 0,
            width: '6px',
        },
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        minWidth: '242px',
        position: 'relative',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    textFieldWrapper: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'max-content',
    },
});

export default styles;
