import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import { LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import { getQuickHelpAsset } from 'shared-components/icons';
import CommonSearch from 'shared-components/CommonSearch';
import { ExtractedLoggerData, extractLoggerData } from 'TrackAndTrace/Loggers/lib';
import tableColumns from './tableColumns';

type Props = {
    changeHighlightedText: (newHighlightedText: any) => void,
    core?:string,
    isTableView: boolean,
    loggersFromPreviewTable: ExtractedLoggerData[],
    query?: string,
    setLoggersFromPreviewTable: Dispatch<SetStateAction<ExtractedLoggerData[]>>,
    setQuery?: Dispatch<SetStateAction<string>>
}

const SearchPreviewTable = ({
    changeHighlightedText,
    core = 'track-and-trace',
    isTableView,
    loggersFromPreviewTable = null,
    query = '',
    setLoggersFromPreviewTable,
    setQuery,
} : Props) => {
    const { t } = useCustomTranslation();

    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedLoggerData[]>([]);
    const [queryCleared, setQueryCleared] = useState(false);

    const {
        data: rawLoggers,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            enabled: !isTableView,
            query: {
                page: 0,
                pageSize: 10,
                q: query,
            },
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const queryChanged = (value) => {
        setQuery(value);
        setQueryCleared(false);
        changeHighlightedText(value.replace(/[\\/#!$%^&*;:{}=\-_`~()]/g, ''));

        if (value.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);

            if (isTableView) {
                setLoggersFromPreviewTable([]);
            }
        }
    };

    const handleFocus = () => {
        setOpen(prev => prev || query.length > 0 || loggersFromPreviewTable?.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);
    const handleClickShowResults = () => {
        setOpen(false);
    };
    const handleClickClearSearch = useCallback(() => {
        setLoggersFromPreviewTable([]);
        setOpen(false);
        setQuery('');
        setQueryCleared(true);
    }, []);

    const translatedLoggers = useMemo(() => {
        if (!!query && query.length === 0) {
            return [];
        }

        return data;
    }, [data, open, query]);

    useEffect(() => {
        if (rawLoggers?.resultList) {
            const extractedAssetData = extractLoggerData(rawLoggers.resultList.slice(0, 10));

            const processedAssetData = extractedAssetData.map(obj => {
                return { ...obj, loggerFamilyLabel: t(`LOGGER_TYPE_LABEL.${obj.loggerFamilyLabel}`) };
            });

            global.console.log('extractedAssetData', processedAssetData);
            setData(processedAssetData);
            if (isTableView) {
                setLoggersFromPreviewTable(processedAssetData);
            }
        }
    }, [rawLoggers]);

    const columns = useMemo(() => tableColumns(
        t,
        core === 'track-and-trace',
    ), [t, core]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    image: getQuickHelpAsset('logger_search.gif'),
                    order: 1,
                    position: 'right',
                    text: t('ONBOARDING.LOGGERS.SEARCH_DESC'),
                    title: t('ONBOARDING.LOGGERS.SEARCH_TITLE'),
                    uid: 'searchBySerial',
                }}
            >
                <CommonSearch
                    externallyCleared={queryCleared}
                    handleClickInput={handleClick}
                    handleFocusInput={handleFocus}
                    query={query}
                    setQuery={queryChanged}
                />
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        columns={columns}
                        data={translatedLoggers}
                        isCardOpen={open}
                        maskForHighlight={query}
                        rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                        onCardClick={handleClick}
                        onClickButtonClearSearch={handleClickClearSearch}
                        onClickButtonShowResults={handleClickShowResults}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewTable;
