import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useClasses from 'hooks/useClasses';
import React, { ReactElement } from 'react';
import { styles } from './SelectField.style';

type Props = {
    className?: string,
    defaultValue?: number | string,
    emptyValue?: boolean,
    id?: string,
    items: {
        render: number | string | ReactElement,
        value: number | string
    }[],
    label?: string,
    onChange?: (value: string, id: string) => any,
    value?: number | string

}

const SelectField = ({
    className,
    defaultValue,
    emptyValue = true,
    id,
    items,
    label,
    onChange,
    value,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <FormControl className={className || ''} fullWidth variant="standard">
            {label && (
                <InputLabel htmlFor="uncontrolled-native" variant="standard">
                    {label}
                </InputLabel>
            )}
            <Select
                MenuProps={{
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                    },
                    transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top',
                    },
                }}
                className={classes.itemText}
                defaultValue={emptyValue ? '' : defaultValue}
                inputProps={{
                    id: `${id}_controlled_native_select`,
                    name: id,
                }}
                value={value || ''}
                onChange={(ev: SelectChangeEvent) => onChange(ev.target.value, id)}
            >
                {
                    items.map(item => {
                        return <MenuItem key={`${id}_${item.value}`} value={item.value}>{item.render}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );
};

export default SelectField;
