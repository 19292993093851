import useClasses from 'hooks/useClasses';
import React from 'react';

const styles = theme => ({
    amountContainer: {
        whiteSpace: 'nowrap',
    },
    cellContainer: {
        display: 'flex',
        width: '100%',
    },
    facilityContainer: {
        display: 'flex',
        marginRight: '21px',
    },
    icon: {
        marginRight: '12px',
        maxHeight: '31px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '15px',
        },
    },
});

type Facility = {
    amount?: number,
    facility: string,
    iconSrc: string,
    unit?: number,
}

type Props = {
    facilities: Facility[],
}

const TableCellFacilities = ({ facilities = [] } : Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.cellContainer}>
            {
                facilities.map((facility) => {
                    return (
                        <div
                            key={facility.facility}
                            className={classes.facilityContainer}
                        >
                            <img
                                alt={facility.facility}
                                className={classes.icon}
                                src={facility.iconSrc}
                            />
                            <div className={classes.amountContainer}>
                                {`${facility.amount || ''} ${facility.unit || ''}`}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default TableCellFacilities;
