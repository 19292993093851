import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TimeRange } from 'dataTypes/common';
import icons from 'shared-components/icons';
import StandardLink from 'shared-components/StandardLink';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip/QuickHelpTooltip';

import useClasses from 'hooks/useClasses';
import {
    EntitySelectorItem,
    initialEntitySelectorItem,
} from '../../dataTypes';

import RenderOptionItem from './RenderOptionItem';
import styles from './EntitySelector.style';

type Props = {
    assetsChosen?: boolean,
    assetsCount?: number,
    entitySelectorItem: EntitySelectorItem,
    filteredOptions: EntitySelectorItem[],
    loggersChosen?: boolean,
    loggersCount?: number,
    sampleForSearch: string,
    setChooseAssets: (assetsChosen: boolean) => void,
    setChooseLoggers: (loggersChosen: boolean) => void,
    setChooseShipments: (shipmentsChosen: boolean) => void,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    setSampleForSearch: (sampleForSearch: string) => void,
    setTimeRange: (timeRange: TimeRange) => void,
    shipmentsActivePackagingSerialNumber?: string
}

const EntitySelector = ({
    assetsCount = 0,
    entitySelectorItem = initialEntitySelectorItem,
    filteredOptions = [],
    loggersCount = 0,
    sampleForSearch = '',
    setEntitySelectorItem,
    setSampleForSearch,
    setTimeRange,
} : Props) => {
    const classes = useClasses(styles);
    const filterOptions = () => filteredOptions;
    const getOptionLabel = (option: EntitySelectorItem) => option.entityNumber;
    const checkValue = (option: EntitySelectorItem, value: EntitySelectorItem) => {
        return option.entityNumber.toLowerCase() === value.entityNumber.toLowerCase()
            || entitySelectorItem.entityNumber.toLowerCase() === value.entityNumber.toLowerCase();
    };
    const { t } = useCustomTranslation();
    const handleChangeOnInput: React.ChangeEventHandler<HTMLInputElement> = (event,
    ) => setSampleForSearch(event.target.value || '');
    const handleChangeOnSelect: (_event: React.SyntheticEvent, value: EntitySelectorItem
    ) => void = (_event, value) => {
        setTimeRange({ from: null, to: null });
        setEntitySelectorItem(value || initialEntitySelectorItem);
        setSampleForSearch(value ? value.entityNumber : '');
    };

    const { additionalData, entityNumber = '',
        entityType = null } = entitySelectorItem;
    const { packagingsInThisShipment = [] } = additionalData || {};
    const translatedPT = (pt: string) => t(`PACKAGING_CODE_LABEL.${pt}`);

    return (
        <>
            <Autocomplete
                key={`Autocomplete_${entitySelectorItem.entityNumber}`}
                autoSelect
                className={classes.autocompleteClass}
                classes={{
                    inputRoot: classes.textFieldTextAutocomplete,
                    listbox: classes.listBox,
                }}
                clearOnEscape
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                groupBy={() => 'all'}
                id="EntitySelector"
                isOptionEqualToValue={checkValue}
                noOptionsText={t('SENSOR_DATA.NO_RESULT')}
                options={[{ entityNumber: sampleForSearch, entityType: null }, ...filteredOptions]}
                popupIcon={null}
                renderGroup={(params) => {
                    return (
                        <>
                            <div key={params.key}>{params.children}</div>
                            {
                                !sampleForSearch
                                && loggersCount + assetsCount > filteredOptions.length
                                    ? (
                                        <div style={{ textAlign: 'center' }}>
                                            {t('SENSOR_DATA.PLEASE_START_TYPING_TO_SEE_MORE')}
                                        </div>
                                    )
                                    : null
                            }
                        </>
                    );
                }}
                renderInput={(params) => (
                    <QuickHelpTooltip
                        tooltipInfo={{
                            order: 1,
                            text: t('QUICK_HELP.SENSOR_DATA.SEARCH_FOR_SHIPMENT'),
                            uid: 'searchQuickHelp',
                        }}
                    >
                        <TextField
                            {...params}
                            key={`TextField_${entitySelectorItem.entityNumber}`}
                            label={t('COMMON.SEARCH')}
                            placeholder={t('SENSOR_DATA.START_TYPING')}
                            size="small"
                            value={sampleForSearch}
                            variant="standard"
                            onChange={handleChangeOnInput}
                        />
                    </QuickHelpTooltip>

                )}
                renderOption={(props, option) => {
                    return (
                        <li
                            {...props}
                            key={option.entityNumber}
                            style={{ borderBottom: '1px solid #0000001C' }}
                        >
                            <RenderOptionItem
                                option={option}
                                sampleForSearch={sampleForSearch}
                                searchMode
                            />
                        </li>
                    );
                }}
                size="small"
                value={entitySelectorItem}
                onChange={handleChangeOnSelect}
            />

            {
                entityType
                && entityType !== 'assets'
                && entityType !== 'loggers'
                && (
                    <div className={classes.currentItemInfoContainer}>
                        <div className={classes.currentItemInfoTitle}>
                            {`${t('SENSOR_DATA.CURRENT')} ${entityType}`}
                        </div>
                        <RenderOptionItem
                            option={entitySelectorItem}
                        />
                        {
                            entityType === 'shipment' && (
                                <StandardLink
                                    className={classes.standardLinkShipmentDetails}
                                    label={t('SENSOR_DATA.SHIPMENT_DETAILS')}
                                    linkTo={`/track-and-trace/shipments/${entityNumber}`}
                                    noWrap
                                />
                            )
                        }
                        {
                            entityType === 'shipment' && (
                                <div>
                                    <div className={classes.currentItemInfoTitle}>
                                        {t('SENSOR_DATA.PACKAGINGS_IN_THIS_SHIPMENT')}
                                    </div>
                                    {
                                        packagingsInThisShipment.map(packaging => {
                                            return (
                                                <div
                                                    key={packaging.serialNumber}
                                                    className={classes.packaging}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <img
                                                            alt="packaging"
                                                            src={icons.container_grey}
                                                            style={{
                                                                height: '20px',
                                                                marginRight: '7px',
                                                                width: '24px',
                                                            }}
                                                        />
                                                        <div>
                                                            {`${packaging.serialNumber} | ${
                                                                translatedPT(packaging.packagingType)}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};

export default EntitySelector;
