import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TextField from '@mui/material/TextField';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from 'shared-components/Table/Table.style';

type Props = {
    filterOptions: number[],
    numberMax: string,
    numberMin: string,
    setNumberMax: (numberMax: string) => void,
    setNumberMin: (numberMin: string) => void
}

const NumberFilter = ({
    filterOptions,
    numberMax,
    numberMin,
    setNumberMax,
    setNumberMin,
}: Props) => {
    const classes = useGeneratedStyles(styles);
    const range = useMemo(() => {
        const [min = 0, max = 0] = filterOptions;

        return { max, min };
    }, [filterOptions]);

    const handleOnChangeNumberMin = (event) => setNumberMin(event.target.value);
    const handleOnChangeNumberMax = (event) => setNumberMax(event.target.value);
    const handleOnClickClearFilter = () => {
        setNumberMin('');
        setNumberMax('');
    };
    const { t } = useCustomTranslation();

    return (
        <div className={classes.dateFilterContainer}>
            <div style={{ marginRight: '5px' }}>
                <div className={classes.dateFilterContainerText}>{t('COMMON.MIN')}</div>
                <TextField
                    InputProps={{
                        className: classes.dateFilterContainerText,
                        inputProps: range,
                    }}
                    type="number"
                    value={numberMin}
                    onChange={handleOnChangeNumberMin}
                />
            </div>
            <div>
                <div className={classes.dateFilterContainerText}>{t('COMMON.MAX')}</div>
                <TextField
                    InputProps={{
                        className: classes.dateFilterContainerText,
                        inputProps: range,
                    }}
                    style={{ marginLeft: '5px' }}
                    type="number"
                    value={numberMax}
                    onChange={handleOnChangeNumberMax}
                />
            </div>
            <IconButton
                disabled={!numberMin && !numberMax}
                size="small"
                style={{ marginLeft: '5px', padding: '0' }}
                onClick={handleOnClickClearFilter}
            >
                <FilterAltOffIcon color="action" fontSize="small" />
            </IconButton>
        </div>
    );
};

export default NumberFilter;
