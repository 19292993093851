import React, { ReactNode } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';

import useClasses from 'hooks/useClasses';
import styles from 'StatusMessages/StatusMessages.style';

type Props = {
    alertColor?: AlertColor | 'info_static',
    alertTime: number,
    icon: ReactNode,
    message?: string,
    setAlertToDelete: (alertTime: number) => void,
}

const StatusMessage = ({
    alertColor = 'success',
    alertTime,
    icon = null,
    message = '',
    setAlertToDelete,
} : Props) => {
    const classes = useClasses(styles);
    const handleClose = () => setAlertToDelete(alertTime);

    return (
        <div className={classes.statusMessageItemContainer}>
            <div className={classes.message}>
                <Alert
                    className={[alertColor === 'info' ? classes.customInfoSeverity : '',
                        alertColor === 'info_static' && classes.infoStatic,
                    ].join(' ')}
                    icon={icon}
                    severity={alertColor === 'info_static' ? 'info' : alertColor}
                    variant="filled"
                    onClose={handleClose}
                >
                    {message}
                </Alert>
            </div>
        </div>
    );
};

export default StatusMessage;
