import { TextField } from '@mui/material';
import useClasses from 'hooks/useClasses';
import React, { useCallback, useMemo, useState } from 'react';
import Card from 'shared-components/Card';
import CommonButton from 'shared-components/CommonButton';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import { styles } from './ShipmentInformationCard.style';

type Props = {
    deliveryServiceType: string,
    handoverPoint: any,
    onSubmit: (...args: any) => any
}

const ShipmentInformationCard = ({
    deliveryServiceType,
    handoverPoint,
    onSubmit,
}: Props) => {
    const classes = useClasses(styles);
    const [requestedPointDate, setRequestedPointDate] = useState(null);
    const [customerReference, setCustomerReference] = useState('');
    const [orderComment, setOrderComment] = useState('');
    const [currentDate, setCurrentDate] = useState(null);
    const { t } = useCustomTranslation();

    const timezoneCheckDate = useMemo<string>(() => {
        if (!currentDate) {
            return null;
        } else {
            const nowDate = moment().utcOffset(0, true);

            return moment(currentDate, 'DD.MM.YYYY HH:mm').set({
                hours: nowDate.hours(),
                minutes: nowDate.minutes(),
            }).format('YYYY-MM-DDTHH:mm');
        }
    }, [currentDate]);

    const {
        loading,
        timeZoneStr = '+00:00',
    } = useGetTimeZoneInfoByGeolocation(
        handoverPoint?.geolocation,
        timezoneCheckDate,
    );

    const handleRequestPointDateChange = useCallback((date) => {
        setRequestedPointDate(date);
    },
    []);

    const minTime = useMemo(() => {
        const initTime = moment().set({ hours: 0, minutes: 0 });

        if (!currentDate) return initTime;
        const now = moment().utc(false);
        const currentDay = now
            .clone()
            .startOf('day')
            .valueOf() === currentDate
            ?.clone()
            .utc(true)
            ?.startOf('day')
            .valueOf();

        return currentDay ? now : initTime;
    }, [currentDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Card
                className={classes.card}
                title={t('TRACK_AND_TRACE.ADD_SHIPMENT.SHIPMENT_INFORMATION')}
            >
                <div className={classes.cardContents}>
                    <div className={classes.cardField}>
                        <div>
                            <div className={classes.fieldTitle}>
                                {
                                    ['DELIVERY_ROAD', 'DELIVERY_AIR'].includes(deliveryServiceType)
                                        ? t('TRACK_AND_TRACE.ADD_SHIPMENT.REQUESTED_DELIVERY')
                                        : t('TRACK_AND_TRACE.ADD_SHIPMENT.REQUESTED_PICK_UP')
                                }
                            </div>
                            <DateTimePicker
                                ampm={false}
                                disablePast
                                format="DD.MM.YYYY HH:mm"
                                minTime={minTime}
                                slotProps={{
                                    popper: {
                                        className: [classes.datePicker,
                                            loading ? classes.loadingPicker : ''].join(' '),
                                    },
                                    textField: {
                                        InputLabelProps: {
                                            error: false,
                                        },
                                        className: [classes.inputField, classes.shift].join(' '),
                                        inputProps: {
                                            readOnly: true,
                                            value: `${requestedPointDate
                                                ? `${requestedPointDate.format('DD.MM.YYYY HH:mm')} ${timeZoneStr}`
                                                : ''}`,
                                        },
                                    },
                                }}
                                value={requestedPointDate}
                                onAccept={handleRequestPointDateChange}
                                onChange={((date) => {
                                    setCurrentDate(date);
                                })}
                                onClose={() => {
                                    setRequestedPointDate(currentDate);
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.cardField}>
                        <div>
                            <div className={classes.fieldTitle}>
                                {t('TRACK_AND_TRACE.ADD_SHIPMENT.REFERENCE_PO_NUMBER')}
                            </div>
                            <TextField
                                className={`${classes.inputField} ${classes.mandatory}`}
                                value={customerReference}
                                onChange={(event => {
                                    setCustomerReference(event.target.value);
                                })}
                            />
                        </div>
                    </div>
                    <div className={classes.cardField}>
                        <TextField
                            className={classes.inputField}
                            label={t('COMMON.SHIPMENT_COMMENT')}
                            value={orderComment}
                            onChange={(event => {
                                setOrderComment(event.target.value);
                            })}
                        />
                    </div>
                    <div className={`${classes.cardFieldFlexCenter} ${classes.cardField}`}>
                        <CommonButton
                            className={classes.button}
                            onClick={() => onSubmit(
                                {
                                    customerReference,
                                    orderComment,
                                    requestedPoint: requestedPointDate
                                        ?.clone()
                                        .utcOffset(timeZoneStr, true),
                                },
                            )}
                        >
                            {t('MENU_ITEMS.ADD_SHIPMENT')}
                        </CommonButton>
                    </div>
                </div>
            </Card>
        </LocalizationProvider>
    );
};

export default ShipmentInformationCard;
