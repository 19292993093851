const styles = (theme) => ({
    checkboxGroup: {
        marginTop: '8px',
    },
    checkboxRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 0',
    },
    checkboxStyle: {
        margin: '0 9px',
        padding: '0!important',
    },
    container: {
        maxHeight: '448px',
        minWidth: '242px',
        padding: '8px',
    },
    count: {
        color: theme.palette.secondary[600],
        letterSpacing: '0.43px',
        marginRight: '7px',
        opacity: 1,
        textAlign: 'left',
    },
    formControlLabelStyle: {
        color: theme.palette.secondary[600],
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
    list: {
        display: 'flex',
        paddingBottom: '3px',
    },
});

export default styles;
