import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import IconList from 'shared-components/CompanyInfoComponents/IconList';
import TextArray from 'shared-components/CompanyInfoComponents/TextArray';
import icons from 'shared-components/icons';
import ParentCompany from 'shared-components/CompanyInfoComponents/ParentCompany';
import TableCellFacilities from 'shared-components/CompanyInfoComponents/TableCellFacilities';
import { highlight } from '../utils/stringTool';

const facilitiesIconAdapter = {
    ACT_PLUG: icons.act_plug,
    ANIMAL_STORAGE_AREA: icons.animal_storage_area,
    CAGE: icons.cage,
    CRT_AREA: icons.temperature.CRT,
    C_AREA: icons.temperature.C,
    DANGEROUS_GOODS_STORAGE: icons.dangerous_goods,
    FREEZER: icons.temperature.DF,
    F_AREA: icons.temperature.F,
    VAULT: icons.vault,
};

const tableColumns: (
    arg: ((t:string) => string),
    maskForHighlight?: string) => ColumnsType[] = (t, maskForHighlight) => [
        {
            Header: t('ADMINISTRATION.PARENT_COMPANY'),
            accessor: (row) => {
                return (
                    <ParentCompany
                        companyName={highlight(row.serviceProviderName, maskForHighlight)}
                        srcImage={row?.logoImagePath || icons.placeholder_company_logo}
                    />
                );
            },
            dataType: 'custom',
            sideFilterKey: 'serviceProviderName',
            sideFilterType: 'text',
            sortType(rowA, rowB) {
                const a = rowA.original.serviceProviderName;
                const b = rowB.original.serviceProviderName;

                return a.localeCompare(b);
            },
        },
        {
            Header: t('COMMON.SITE'),
            accessor: 'name',
            dataType: 'highlightedText',
            sideFilterKey: 'name',
            sideFilterType: 'text',
        },
        {
            Header: t('COMMON.CERTIFICATES'),
            accessor: (row) => {
                const { certificates = [] } = row;

                return <TextArray textItems={certificates} />;
            },
            dataType: 'custom',
            hide: true,
        },
        {
            Header: t('COMMON.SERVICES'),
            accessor: (row) => {
                return <IconList iconList={row.services} />;
            },
            dataType: 'custom',
            poeLabelMask: 'SERVICE_PROVIDERS',
            sideFilterKey: 'services',
            sideFilterType: 'enumList',
        },
        {
            Header: t('COMMON.COMMODITIES'),
            accessor: (row) => {
                return <IconList iconList={row.commodities} />;
            },
            dataType: 'custom',
            poeLabelMask: 'SERVICE_PROVIDERS',
            sideFilterKey: 'commodities',
            sideFilterType: 'enumList',
        },
        {
            Header: t('COMMON.FACILITIES'),
            accessor: (row) => {
                const { facilities = [] } = row;
                const facilitiesUpdated = facilities.map((item) => {
                    return {
                        amount: item.amount || null,
                        facility: item.facility,
                        iconSrc: facilitiesIconAdapter[item.facility],
                        unit: item.unit === 'SQUARE_METERS' ? 'm\xB2' : item.unit || null,
                    };
                });

                return <TableCellFacilities facilities={facilitiesUpdated} />;
            },
            dataType: 'custom',
            hide: true,
        },
    ];

export default tableColumns;
