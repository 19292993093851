const styles = (theme) => ({
    cursorPointer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    title: {
        borderBottom: `1px solid ${theme.palette.primary[400]}`,
        // @ts-ignore
        color: theme.palette.common.titleBlackText,
        fontSize: theme.typography.h1.fontSize,
        fontWeight: 200,
        marginBottom: theme.spacing(2),
    },
});

export default styles;
