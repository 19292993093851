import { linearProgressClasses } from '@mui/material/LinearProgress';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    answerItemAndDetails: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    barroot: {
        backgroundColor: theme.palette.primary[400],
    },
    cardContent: {
        padding: '16px 0 24px',
    },
    completedProgressIcon: {
        marginRight: '12px',
    },
    percent: {
    // @ts-ignore
        color: `${theme.palette.common.titleBlackText}!important`,
    },
    progressBar: {
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: theme.palette.primary[400],
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.primary.darkBlue,
        },
    },
    progressBarContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    progressBarGridContainer: {
        display: 'grid',
        gridGap: '6px',
        gridTemplateColumns: '4fr 2fr',
        marginBottom: '25px',
    },
    questionDetailsContainer: {
        display: 'none',
    },
    questionText: {
        alignSelf: 'center',
        paddingLeft: '16px',
    },
    questionUnitContainer: {
        '&:hover': {
            boxShadow: '0px 1px 3px #00000033',
        },
        '&:hover $questionDetailsContainer': {
            display: 'block',
        },
        color: theme.palette.secondary[600],
        display: 'grid',
        font: 'normal normal normal 16px/19px Roboto',
        gridGap: '6px',
        gridTemplateColumns: '4fr 2fr',
        letterSpacing: '0.49px',
        marginTop: '8px',
        opacity: 1,
        textAlign: 'left',
    },
    root: {
        backgroundColor: theme.palette.primary[400],
    },
    title: {
        // @ts-ignore
        color: theme.palette.common.titleBlackText,
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        opacity: 1,
        paddingLeft: '16px',
        textAlign: 'left',
    },
});

export default styles;
