import useFilter, { PAGES } from 'Contexts/useFilter';
import Requests from 'MyCompany/AccessRequests/AccessRequests';
import React from 'react';

const Incoming = () => {
    const memoFilter = useFilter(PAGES.ACCESS_REQUESTS_INCOMING);

    return (
        <Requests key="incoming_page" memoFilter={memoFilter} requestType="incoming" />
    );
};

export default Incoming;
