import useClasses from 'hooks/useClasses';
import React from 'react';
import Card from 'shared-components/Card';
import PercentageScale from 'shared-components/PercentageScale';
import icons from 'shared-components/icons';

import useCustomTranslation from 'hooks/useCustomTranslation';

const styles = theme => ({
    contactNameContainer: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    extendedProfileIcon: {
        height: '21px',
        marginRight: '7px',
        width: '21px',
    },
    firstPercentageScaleContainer: {
        marginBottom: '12px',
        marginTop: '18px',
    },
    percentageScaleContainer: {
        marginBottom: '12px',
    },
    personIcon: {
        marginRight: '7px',
    },
    profileContainer: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    title: {
        color: theme.palette.secondary[500],
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '1px',
        marginTop: '20px',
        opacity: 1,
        textAlign: 'left',
    },
});

type Props = {
    className?: string,
    definedBy?: string,
    extendedProfile?: boolean,
    id: string,
    reviewedBy?: string,
    totalStatistics: {
        all: number,
        core: number,
    },
}

const SummaryCard = ({
    className = '',
    definedBy = '',
    extendedProfile = false,
    id,
    reviewedBy = '',
    totalStatistics,
} : Props) => {
    const classes = useClasses(styles);
    const cardSize = document.getElementById(id)?.offsetWidth;
    const { t } = useCustomTranslation();

    return (
        <Card className={className} title={t('COMMON.SUMMARY')}>
            <div className={classes.firstPercentageScaleContainer} id={id}>
                <PercentageScale
                    label={`${totalStatistics.core}%`}
                    size={cardSize < 320 ? 20 : 30}
                    title={t('COMMON.CORE_CAPABILITIES_ANSWERED')}
                    value={totalStatistics.core}
                />
            </div>
            <div className={classes.percentageScaleContainer}>
                <PercentageScale
                    label={`${totalStatistics.all}%`}
                    size={cardSize < 320 ? 20 : 30}
                    title={t('COMMON.ALL_CAPABILITIES_ANSWERED')}
                    value={totalStatistics.all}
                />
            </div>
            {
                extendedProfile && (
                    <div className={classes.profileContainer}>
                        <img
                            alt="Extended Profile"
                            className={classes.extendedProfileIcon}
                            src={icons.star}
                        />
                        <div>{t('SERVICE_PROVIDERS.EXTENDED_PROFILE')}</div>
                    </div>
                )
            }
            <div className={classes.title}>{t('SERVICE_PROVIDERS.DEFINED_BY')}</div>
            <div className={classes.profileContainer}>
                <img
                    alt="Defined By"
                    className={classes.personIcon}
                    src={icons.a_gender_person}
                />
                <div>{definedBy}</div>
            </div>
            <div className={classes.title}>{t('SERVICE_PROVIDERS.REVIEWED_BY')}</div>
            <div className={classes.profileContainer}>
                <img
                    alt="Reviewed By"
                    className={classes.personIcon}
                    src={icons.a_gender_person}
                />
                <div>{reviewedBy}</div>
            </div>
        </Card>
    );
};

export default SummaryCard;
