const styles = (theme) => ({
    arrowIcon: {
        fontSize: '14px!important',

    },
    footerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '10px',
        marginTop: 'auto',
    },
    iconButton: {
        bottom: '20%',
        // @ts-ignore
        color: `${theme.palette.common.menuTextWhite}!important`,
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '-0.14px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '20px',
        justifyContent: 'center',
        marginRight: '4px',
        width: '20px',

    },
    rotateIcon: {
        transform: 'rotate(180deg)',
    },
});

export default styles;
