import React, {
    ChangeEvent,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
} from 'react';
import {
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import useClasses from 'hooks/useClasses';
import OptionItemTooltip from './OptionItemTooltip';
import styles from '../SideFilter.style';

type Props = {
    checked?: boolean,
    count?: number,
    disabled?: boolean,
    label: string,
    optionKey: string,
    setCheckboxGroupOptions: Dispatch<SetStateAction<string[]>>,
    showTooltip?: boolean,
    tooltipDescription?: string | ReactNode
}

const CheckboxOptionItem = ({
    checked = false,
    count = null,
    disabled = false,
    label = null,
    optionKey,
    setCheckboxGroupOptions,
    showTooltip = false,
    tooltipDescription = '',
} : Props) => {
    const classes = useClasses(styles);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckboxGroupOptions(prev => (prev.includes(optionKey) ? prev : [...prev, optionKey]));
        } else {
            setCheckboxGroupOptions(prev => prev.filter((valueItem) => valueItem !== optionKey));
        }
    }, []);

    if (showTooltip) {
        return (
            <OptionItemTooltip
                description={tooltipDescription}
                descriptionTitle={label}
            >
                <div className={classes.checkboxRow}>
                    <FormControlLabel
                        className={classes.formControlLabelStyle}
                        control={(
                            <Checkbox
                                checked={checked}
                                className={classes.checkboxStyle}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.root,
                                }}
                                size="small"
                                onChange={handleChange}
                            />
                        )}
                        disabled={disabled}
                        label={<div className={classes.formControlLabelStyle}>{label}</div>}
                    />
                    {
                        count !== null && <div className={classes.count}>{count}</div>
                    }
                </div>
            </OptionItemTooltip>
        );
    }

    return (
        <div className={classes.checkboxRow}>
            <FormControlLabel
                className={classes.formControlLabelStyle}
                control={(
                    <Checkbox
                        checked={checked}
                        className={classes.checkboxStyle}
                        classes={{
                            checked: classes.checked,
                            root: classes.root,
                        }}
                        size="small"
                        onChange={handleChange}
                    />
                )}
                disabled={disabled}
                label={<div className={classes.formControlLabelStyle}>{label}</div>}
            />
            {count !== null && <div className={classes.count}>{count}</div>}
        </div>
    );
};

export default CheckboxOptionItem;
