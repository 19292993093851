import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Modal, TextField } from '@mui/material';
import useClasses from 'hooks/useClasses';
import { Close } from '@mui/icons-material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import CommonButton from 'shared-components/CommonButton';

const styles = theme => ({
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeIcon: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    desc: {
        fontSize: 14,
        lineHeight: '19px',
    },
    error: {
        color: '#E22C2C',
    },
    modalBody: {
        backgroundColor: theme.palette.common.white,
        boxShadow: '24',
        boxSizing: 'border-box',
        left: '50%',
        padding: '20px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
    },
    textArea: {
        width: '100%',
    },
    textAreaWrapper: {
        marginTop: 12,
    },
    title: {
        fontSize: 24,
        marginBottom: 18,
    },
});

const DataCompleteCheckModal = ({
    handleClose = () => {},
    onOverride,
    open,
}) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedError, setIsCheckedError] = useState('');

    const handleReasonChange = (event) => {
        if (reasonError) {
            setReasonError('');
        }
        setReason(event.target.value);
    };

    const handleCheckChange = (event) => {
        if (isCheckedError) {
            setIsCheckedError('');
        }
        setIsChecked(event.target.checked);
    };

    const onConfirm = () => {
        if (!reason) {
            setReasonError(t('DATA_COMPLETE_CHECK.REASON_REQUIRED'));
        }

        if (!isChecked) {
            setIsCheckedError(t('DATA_COMPLETE_CHECK.CHECK_REQUIRED'));
        }

        if (reason && isChecked) {
            handleClose();
            onOverride(reason);
        }
    };

    return (
        <Modal
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            open={open}
            onClose={handleClose}
        >
            <div className={classes.modalBody}>
                <Close className={classes.closeIcon} onClick={handleClose} />

                <div className={classes.title}>{t('DATA_COMPLETE_CHECK.MODAL_TITLE')}</div>
                <div className={classes.desc}>{t('DATA_COMPLETE_CHECK.MODAL_DESCRIPTION_1')}</div>
                <div className={classes.desc}>{t('DATA_COMPLETE_CHECK.MODAL_DESCRIPTION_2')}</div>

                <div className={classes.textAreaWrapper}>
                    <TextField
                        className={classes.textArea}
                        color="primary"
                        error={!!reasonError}
                        minRows={3}
                        multiline
                        value={reason}
                        variant="outlined"
                        onInput={handleReasonChange}
                    />
                    {reasonError && (
                        <div
                            className={classes.error}
                            style={{ marginTop: 8 }}
                        >
                            {reasonError}
                        </div>
                    )}
                </div>

                <div>
                    <FormControlLabel
                        control={
                            (
                                <Checkbox
                                    checked={isChecked}
                                    className={isCheckedError ? classes.error : ''}
                                    color="primary"
                                    onChange={handleCheckChange}
                                />
                            )
                        }
                        label={t('DATA_COMPLETE_CHECK.MODAL_CHECK_CONFIRM')}
                    />
                    {isCheckedError && <div className={classes.error}>{isCheckedError}</div>}
                </div>

                <div className={classes.btnWrapper}>
                    <CommonButton
                        onClick={onConfirm}
                    >
                        {t('DATA_COMPLETE_CHECK.MODAL_BTN_CONFIRM')}
                    </CommonButton>
                </div>
            </div>
        </Modal>
    );
};

export default DataCompleteCheckModal;
