import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    a: {
        textDecoration: 'none',
    },
    activeChildItem: {
        background: `${theme.palette.primary.darkBlue} 0% 0% no-repeat padding-box`,
        float: 'right',
        height: '31px',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        padding: '8px 0!important',
        paddingLeft: '19%!important',
        width: '100%',
    },
    activeChildItemText: {
        '-webkit-font-smoothing': 'subpixel-antialiased',
        color: '#FFFFFFDE',
        font: 'normal normal 900 14px/17px Roboto',
        letterSpacing: '-0.14px',
        marginLeft: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    activeDefaultItem: {
        background: '#009CCF 0% 0% no-repeat padding-box!important',
        borderTop: '0.01em solid rgba(0, 0, 0, 0.1)',
        height: '42px',
        opacity: 1,
        padding: '8px!important',
    },
    activeDefaultItemText: {
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    activeItem: {
        background: `${theme.palette.primary.darkBlue}!important`,
    },
    arrowIcon: {
        color: 'white',
        fontSize: '14px!important',
    },
    arrowRight: {
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid white',
        borderTop: '10px solid transparent',
        height: 0,
        width: 0,
    },
    childItem: {
        background: `${theme.palette.primary.darkBlue} 0% 0% no-repeat padding-box`,
        float: 'right',
        height: '31px',
        marginLeft: 'auto',
        padding: '8px 0!important',
        paddingLeft: '19%!important',
        width: '100%',
    },
    childItemText: {
        '-webkit-font-smoothing': 'subpixel-antialiased',
        color: '#FFFFFFDE',
        font: 'normal normal 400 14px/17px Roboto',
        letterSpacing: '-0.14px',
        marginLeft: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    defaultItem: {
        height: '42px',
        padding: '8px!important',
    },
    defaultItemText: {
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    divider: {
        border: `0px solid ${theme.palette.common.white}`,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        float: 'right',
        margin: '0',
        width: '81%',
    },
    homeItem: {
        background: 'linear-gradient(rgba(0,134,179,0.27), rgba(0,115,154,0.27)) 0% 0% no-repeat padding-box',
        height: '36px',
        opacity: 1,
        padding: '8px!important',
    },
    homeItemText: {
        '-webkit-font-smoothing': 'subpixel-antialiased',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        marginLeft: '15px',
        opacity: 1,
        textAlign: 'left',
    },
    icon: {
        height: '26px',
        width: '31px',
    },
    rotateIcon: {
        transform: 'rotate(180deg)',
    },
});

export default styles;
