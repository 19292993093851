import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const styles = (theme: SkycellThemeInterface) => ({
    box: {
        height: '24px',
        margin: 'auto 0',
        minWidth: '24px',
    },
    divider: {
        background: theme.palette.secondary[300],
        flexBasis: '2px',
        marginInlineEnd: '12px',
        marginInlineStart: '12px',
    },
    health: {
        display: 'flex',
        flex: 3,
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    options: {
        display: 'flex',
        flex: 2,
        gap: '12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    packagingBox: {
        display: 'flex',
        gap: '12px',
    },
    packagings: {
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
        width: '100%',
    },
    root: {
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        gap: '10px',
        minHeight: '54px',
        padding: '16px 16px',
        width: '100%',
        [theme.breakpoints.down(1500)]: {
            flexDirection: 'column',
        },
    },
    serialNumber: {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        height: '100%',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
    },
    serialNumberWithPadding: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        padding: '4px 0 4px 0',
        whiteSpace: 'nowrap',
    },
    status: {
        color: theme.palette.common.red,
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
    },
    switch: {
        '& label': {
            cursor: 'pointer',
            userSelect: 'none',
            whiteSpace: 'nowrap',
        },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '12px',
        gap: '12px',
        maxHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
        },
    },
    switches: {
        display: 'flex',
        gap: '12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    textInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        alignItems: 'center',
        color: theme.palette.secondary[800],
        display: 'flex',
        flex: 1,
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        whiteSpace: 'nowrap',
    },
});
