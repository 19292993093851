import { SkycellThemeInterface } from '../../themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    addCompanyPageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '4fr 2fr',
        margin: 'auto',
        padding: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    addEditCompanyApproveButton: {
        textTransform: 'uppercase',
    },
    addEditCompanyButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        marginTop: '28px',
    },
    addEditCompanyCardContentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 2fr 1fr',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    addEditCompanyFieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginBottom: '5px',
        marginTop: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    addEditCompanyMandatoryField: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
    },
    addEditCompanyRejectButton: {
        textTransform: 'uppercase',
    },
    addEditCompanyTextField: {
        marginTop: '12px',
        width: '100%',
    },
    addEditCompanyTextFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        paddingRight: '0!important',
        textAlign: 'left',
    },
    editCompanyCardContentData: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr',
        padding: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    editCompanyPageContent: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '4fr 2fr',
        padding: '12px',
    },
    totalElements: {
        color: '#747474',
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        marginBottom: '5px',
        opacity: 1,
        textAlign: 'left',
    },
});

export default styles;
