export const styles = (theme) => ({
    noShadow: {
        boxShadow: 'unset',
    },
    picture: {
        height: '86px',
        marginRight: '10px',
        width: '86px',
    },
    progressBar: {
        backgroundColor: theme.palette.grey[300],
        height: '4px',
        transition: 'width 0.3s ease-in-out',
    },
    text: {
        color: theme.palette.secondary[800],
        fontSize: '24px',
        fontWeight: 100,
        letterSpacing: 0.31,
    },
    transportCard: {
        alignItems: 'center',
        display: 'flex',
    },
    transportFlow: {
        display: 'flex',
        flexDirection: 'row',
    },
});
