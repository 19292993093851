/* eslint-disable react/jsx-key */
import { useTheme } from '@emotion/react';
import { DragIndicatorOutlined } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, Modal } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MilestoneProgress, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { BUTTON_MAP } from 'LaneManagement/LaneDetails/Cards/AddLocation/BUTTON_MAP';
import { ProcessedMilestone } from 'LaneManagement/LaneDetails/Cards/PredictedTransportCard/PredictedTransportCard';
import moment from 'moment/moment';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import CommonButton from 'shared-components/CommonButton';
import WrappedTooltip from './WrappedTooltip';
import { styles as tooltipStyles } from './WrappedTooltip/WrappedTooltip';

const PADDING = 40;
const styles = (theme: SkycellThemeInterface) => ({
    button: {
        textTransform: 'uppercase',
    },
    modalBody: {
        // @ts-ignore
        backgroundColor: theme.palette.common.contrastWhite50,
        borderRadius: 8,
        boxShadow: '24',
        left: '50%',
        padding: PADDING,
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

export const LINE_HEIGHT = 20;
export const MIN_SIZE = 1;
export const DILATION = 4;

type Props = {
    close: () => void,
    initialMilestones: Milestones[],
    loading?: boolean,
    modalWidth?: number,
    onSave: (milestones: MilestoneProgress[], forAll: boolean) => void,
    open: boolean
};

const getInitialBreakpoints = (initialMilestones: ProcessedMilestone[]) => {
    const [first] = initialMilestones;
    const allDates = initialMilestones.reduce((acc, curr) => {
        acc.push(curr.end);
        return acc;
    }, [first.start]);

    const range = allDates.at(-1) - allDates[0];

    const now = moment().utc().valueOf();
    const nowPercentage = now < first.start
        ? 0 : now > allDates.at(-1) ? 100
            : ((now - first.start) / range) * 100;

    return {
        breakpoints: allDates.map((number) => {
            return ((number - allDates[0]) / range) * 100;
        }),
        now: nowPercentage,
    };
};

const getColorMap = (theme:SkycellThemeInterface) => ({
    active: [
        theme.palette.primary[100],
        theme.palette.primary[400],
        theme.palette.primary[700],
        theme.palette.primary[400],
    ],
    inactive: [
        theme.palette.common.secondShadeOfGray,
        theme.palette.common.thirdShadeOfGray,
        theme.palette.common.fourthShadeOfGray,
        theme.palette.common.thirdShadeOfGray,
    ],
});
const EditableTransportDialog = ({
    close,
    initialMilestones,
    loading = false,
    modalWidth = 800,
    onSave,
    open = false,
}: Props) => {
    const [breakpoints, setBreakpoints] = React.useState<number[]>([0]);
    const [editedBreakpoint, setEditedBreakpoint] = React.useState(-1);
    const [handleIndex, setHandleIndex] = React.useState(-1);
    const bodyRef = useRef<HTMLDivElement>(null);
    const classes = useClasses(styles);
    const tooltipClasses = useClasses(tooltipStyles);
    const [nowPercentage, setNowPercentage] = useState(null);
    const [currentMousePosition, setCurrentMousePosition] = useState(null);
    const theme = useTheme();
    const { t } = useCustomTranslation();
    const [activeMilestoneIndex, setActiveMilestoneIndex] = useState(-1);
    const processedMilestones = useMemo<ProcessedMilestone[]>(() => initialMilestones.map(step => {
        const pickup = step.progress.actualPickup
            || step.progress.plannedPickup;
        const delivery = step.progress.actualDelivery
            || step.progress.plannedDelivery;
        const start = moment.utc(pickup, 'YYYY-MM-DDTHH:mm:ss').valueOf();
        const end = moment.utc(delivery, 'YYYY-MM-DDTHH:mm:ss').valueOf();

        return {
            ...step,
            end,
            start,
        };
    }), [initialMilestones]);

    useEffect(() => {
        const initialData = getInitialBreakpoints(processedMilestones);

        setBreakpoints(initialData.breakpoints);
        setNowPercentage(initialData.now);
    }, [processedMilestones]);
    const pxTopercent = useCallback((px) => {
        if (bodyRef.current) {
            const { width } = bodyRef.current.getBoundingClientRect();

            return (px / width) * 100;
        }
        return 0;
    }, [bodyRef, modalWidth]);

    const colorMap = useMemo(() => {
        return getColorMap(theme);
    }, [theme]);
    const stepInfo = useMemo(() => BUTTON_MAP(), []);

    const handleMouseMove = useCallback((e) => {
        if (bodyRef.current) {
            const { left, width } = bodyRef.current.getBoundingClientRect();
            const x = e.clientX - left;
            const percentage = (x / width) * 100;

            setCurrentMousePosition(percentage);
        }
    }, [bodyRef, modalWidth]);

    const handleMouseDownHandle = useCallback((e, milestoneIndex, handleIndex) => {
        e.stopPropagation();
        e.preventDefault();
        setHandleIndex(handleIndex);
        setEditedBreakpoint(milestoneIndex);
    }, []);

    const handleMouseUpHandle = useCallback(() => {
        setEditedBreakpoint(-1);
        setHandleIndex(-1);
    }, [setBreakpoints, editedBreakpoint, handleIndex]);

    useEffect(() => {
        if (editedBreakpoint !== -1 && currentMousePosition && handleIndex !== -1) {
            setBreakpoints((prev => {
                const newBreakpoints = [...prev];
                const correctedMousePosition = currentMousePosition + Number(handleIndex === 0 ? pxTopercent(20) : 0);
                const limitedValue = Math.max(
                    0,
                    newBreakpoints[editedBreakpoint + handleIndex - 1] + MIN_SIZE,
                    Math.min(correctedMousePosition,
                        100,
                        newBreakpoints[editedBreakpoint + handleIndex + 1]) - MIN_SIZE,
                );

                newBreakpoints[editedBreakpoint + handleIndex] = limitedValue;
                return newBreakpoints;
            }));
        }
    }, [currentMousePosition, pxTopercent]);

    const onOutsideClick = useCallback(() => {
        handleMouseUpHandle();
        setActiveMilestoneIndex(-1);
    }, []);

    const percentageToMs = useCallback((percentage) => {
        const first = processedMilestones[0].start;
        const last = processedMilestones.at(-1).end;
        const range = last - first;

        return first + (percentage / 100) * range;
    }, [initialMilestones]);

    const milestoneInfo = useMemo<Milestones>(() => {
        return initialMilestones[activeMilestoneIndex] || null;
    }, [activeMilestoneIndex, initialMilestones]);

    const onBlockClick = useCallback((e, index) => {
        e.stopPropagation();
        setActiveMilestoneIndex(index);
    }, []);

    const saveClicked = useCallback(() => {
        const milestones:MilestoneProgress[] = processedMilestones.map((milestone, index) => {
            const [start, end] = [breakpoints[index], breakpoints[index + 1]];

            let plannedPickup = null;
            let actualPickup = null;
            let plannedDelivery = null;
            let actualDelivery = null;
            let { status } = milestone.progress;

            if (nowPercentage > start && nowPercentage < end) {
                actualPickup = moment.utc(percentageToMs(start)).format('YYYY-MM-DDTHH:mm:ss');
                plannedDelivery = moment.utc(percentageToMs(end)).format('YYYY-MM-DDTHH:mm:ss');
                status = 'IN_PROGRESS';
            } else if (nowPercentage < start) {
                plannedPickup = moment.utc(percentageToMs(start)).format('YYYY-MM-DDTHH:mm:ss');
                plannedDelivery = moment.utc(percentageToMs(end)).format('YYYY-MM-DDTHH:mm:ss');
                status = 'NOT_STARTED';
            } else {
                actualPickup = moment.utc(percentageToMs(start)).format('YYYY-MM-DDTHH:mm:ss');
                actualDelivery = moment.utc(percentageToMs(end)).format('YYYY-MM-DDTHH:mm:ss');
                status = 'COMPLETED';
            }

            return {
                milestoneId: milestone.id,
                progress: {
                    actualDelivery,
                    actualPickup,
                    plannedDelivery,
                    plannedPickup,
                    status,
                },
            };
        });

        onSave(milestones.filter(Boolean), false);
    }, [onSave, breakpoints, processedMilestones, percentageToMs]);

    if (Number.isNaN(breakpoints?.[0])) {
        return (
            <Modal
                open={open}
                onClose={close}
            >
                <div
                    className={classes.modalBody}
                    draggable={false}
                    style={{
                        width: modalWidth,
                    }}
                    onClick={() => onOutsideClick()}
                >
                    Milestones are not defined.
                </div>
            </Modal>
        );
    }
    return (
        <Modal
            open={open}
            onClose={close}
        >
            <div
                className={classes.modalBody} draggable={false}
                style={{
                    width: modalWidth,
                }}
                onClick={() => onOutsideClick()}
            >
                <div
                    ref={bodyRef}
                    draggable={false}
                    style={{
                        minHeight: PADDING * 2,
                        position: 'relative',
                    }}
                    onMouseLeave={() => setCurrentMousePosition(null)}
                    onMouseMove={(e) => handleMouseMove(e)}
                >
                    {/* Blocks */}
                    <div
                        draggable={false}
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                        }}
                    >
                        {
                            breakpoints.map((breakpoint, index) => {
                                const active = index === activeMilestoneIndex;
                                const afterNow = breakpoint > nowPercentage;
                                const containsNow = nowPercentage > breakpoint
                                    && nowPercentage < breakpoints[index + 1];

                                const currentRange: [number, number] = [percentageToMs(breakpoints[index]),
                                    percentageToMs(breakpoints[index + 1])];

                                return index !== breakpoints.length - 1
                                    && (
                                        <>
                                            <WrappedTooltip
                                                activeMilestone={milestoneInfo}
                                                open={active}
                                                range={currentRange}
                                            >
                                                <div
                                                    draggable={false}
                                                    style={{
                                                        backgroundColor:
                                                            colorMap[afterNow ? 'inactive' : 'active'][index % 4],
                                                        border: active
                                                            ? `1px solid ${theme.palette.secondary[900]}` : 'none',
                                                        borderRadius: active ? DILATION : 0,
                                                        cursor: 'pointer',
                                                        display: 'inline-flex',
                                                        height: LINE_HEIGHT + (active ? DILATION : 0),
                                                        transform: active ? `translateY(${-DILATION / 2}px)`
                                                            : 'none',
                                                        width: `${breakpoints[index + 1] - breakpoints[index]}%`,
                                                        zIndex: active
                                                            ? 20 : 10,
                                                    }}
                                                    onClick={(e) => onBlockClick(e, index)}
                                                />
                                            </WrappedTooltip>

                                            {
                                                containsNow && (
                                                    <div
                                                        style={{
                                                            backgroundColor: colorMap.inactive[index % 4],
                                                            borderColor: theme.palette.secondary[900],
                                                            borderRadius: active
                                                                ? `0px ${DILATION}px ${DILATION}px 0px`
                                                                : 'none',
                                                            borderStyle: 'solid',
                                                            borderWidth: active ? '1px' : '0px',
                                                            cursor: 'pointer',
                                                            height: LINE_HEIGHT + (active ? DILATION : 0),
                                                            left: `${nowPercentage}%`,
                                                            pointerEvents: 'none',
                                                            position: 'absolute',
                                                            top: 0,
                                                            transform: active ? `translateY(${-DILATION / 2}px)`
                                                                : 'none',
                                                            width: `${breakpoints[index + 1] - nowPercentage}%`,
                                                            zIndex: 31,
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveMilestoneIndex(index);
                                                        }}
                                                    />
                                                )
                                            }

                                        </>
                                    );
                            })
                        }
                        {/* HANDLES (draggable ones) */}
                        {
                            activeMilestoneIndex !== -1 && (
                                <>
                                    {
                                        activeMilestoneIndex !== 0 && (
                                            <div
                                                draggable={false}
                                                style={
                                                    {
                                                        cursor: 'e-resize',
                                                        display: 'block',
                                                        height: LINE_HEIGHT - 10,
                                                        left: `calc(${breakpoints[activeMilestoneIndex]}% - 20px)`,
                                                        position: 'absolute',
                                                        width: 40,
                                                        zIndex: 10002,
                                                    }
                                                }
                                                onClick={(e) => e.stopPropagation()}
                                                onDrag={e => e.preventDefault()}
                                                onMouseDown={(e) => handleMouseDownHandle(e, activeMilestoneIndex, 0)}
                                                onMouseUp={() => handleMouseUpHandle()}
                                                // onMouseLeave={() => handleMouseUpHandle()}
                                            >
                                                <DragIndicatorOutlined
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        activeMilestoneIndex !== processedMilestones.length - 1 && (
                                            <div
                                                draggable={false}
                                                style={
                                                    {
                                                        cursor: 'e-resize',
                                                        display: 'block',
                                                        height: LINE_HEIGHT - 10,
                                                        left: `${breakpoints[activeMilestoneIndex + 1]}%`,
                                                        position: 'absolute',
                                                        width: 20,
                                                        zIndex: 10002,
                                                    }
                                                }
                                                onClick={(e) => e.stopPropagation()}
                                                onDrag={e => e.preventDefault()}
                                                onMouseDown={(e) => handleMouseDownHandle(e, activeMilestoneIndex, 1)}
                                                onMouseUp={() => handleMouseUpHandle()}
                                                // onMouseLeave={() => handleMouseUpHandle()}
                                            >
                                                <DragIndicatorOutlined
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    {/* ICONS */}
                    <div
                        draggable={false}
                        style={{
                            display: 'flex',
                            pointerEvents: 'none',
                            position: 'absolute',
                            width: '100%',
                        }}
                    >
                        {
                            breakpoints.map((breakpoint, index) => index !== breakpoints.length - 1
                                && (
                                    <div
                                        draggable={false}
                                        style={{
                                            alignItems: 'center',
                                            display: 'inline-flex',
                                            height: LINE_HEIGHT,
                                            justifyContent: 'center',
                                            width: `${breakpoints[index + 1] - breakpoints[index]}%`,
                                            zIndex: 10000,
                                        }}
                                    >
                                        <img
                                            alt="icon"
                                            draggable={false}
                                            src={stepInfo.find(it => it
                                                .milestoneType === processedMilestones[index].type)?.icon}
                                            style={{
                                                filter: 'brightness(10)',
                                                maxHeight: '100%',
                                                maxWidth: '20px',
                                                width: '90%',
                                            }}
                                        />
                                    </div>
                                ))
                        }
                        {/*    NOW  CURSOR */}
                        {
                            nowPercentage && (
                                <Tooltip
                                    arrow
                                    classes={{
                                        arrow: tooltipClasses.arrow,
                                        popper: tooltipClasses.popper,
                                        tooltip: tooltipClasses.root,
                                    }}
                                    open
                                    placement="top"
                                    title="Now"
                                >
                                    <div
                                        draggable={false}
                                        style={{
                                            background: theme.palette.common.white,
                                            backgroundColor: 'wheat',
                                            border: `2px solid ${theme.palette.primary[400]}`,
                                            borderRadius: 4,
                                            height: 29,
                                            left: `calc(${nowPercentage}% - 2.5px)`,
                                            position: 'absolute',
                                            top: -4.5,
                                            width: 12,
                                            zIndex: 100000,

                                        }}
                                    />
                                </Tooltip>
                            )
                        }
                    </div>
                    {
                        activeMilestoneIndex === -1 && (
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 5,
                                position: 'absolute',
                                top: PADDING,
                            }}
                            >
                                <InfoIcon
                                    sx={{
                                        color: theme.palette.primary.deepBlue,
                                        fontSize: 20,
                                    }}
                                />
                                {' '}
                                Select a milestone to change the date and time
                            </div>
                        )
                    }

                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20,
                }}
                >
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    >
                        {
                            activeMilestoneIndex !== -1 && (
                                <>
                                    <DragIndicatorOutlined
                                        sx={{
                                            color: theme.palette.primary.deepBlue,
                                            fontSize: 20,
                                        }}
                                    />
                                    {' '}
                                    Use the move icon to move the (expected) delivery along time axis
                                </>
                            )
                        }
                    </div>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 10,
                        marginBottom: PADDING / 2,
                    }}
                    >
                        <CommonButton
                            className={classes.button}
                            variant="outlined"
                            onClick={close}
                        >
                            {t('COMMON.CANCEL')}
                        </CommonButton>
                        <CommonButton
                            className={classes.button}
                            disabled={loading}
                            loading={loading}
                            onClick={saveClicked}
                        >
                            {t('ORDER_DETAILS.SAVE')}
                        </CommonButton>
                    </div>
                    <div style={{
                        alignItems: 'center',
                        bottom: PADDING / 4,
                        display: 'flex',
                        position: 'absolute',
                        right: PADDING,
                    }}
                    >
                        <Checkbox />
                        Update for all packages
                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default EditableTransportDialog;
