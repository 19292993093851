import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';

type Props = {
    children: string | ReactElement,
    maxWidth?: number
}

const ShrinkedTextWithTooltip = ({
    children,
    maxWidth = 200,
}: Props) => {
    const childRef = useRef<HTMLSpanElement>(null);

    const [updatedSpan, setUpdatedSpan] = useState(Date.now());

    useEffect(() => {
        if (!childRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setUpdatedSpan(Date.now());
        });

        resizeObserver.observe(childRef.current);
        return () => resizeObserver.disconnect();
    }, [childRef.current]);
    const shouldShrink = useMemo<boolean>(() => {
        if (!childRef.current) return false;
        const childRect = childRef.current.getBoundingClientRect();

        return childRect && (maxWidth < childRect.width);
    }, [childRef.current, maxWidth, updatedSpan]);

    return (
        <div style={{
            maxWidth,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}
        >
            <CommonTooltip
                description={children}
                enabled={shouldShrink}
                type={TOOLTIP_TYPE.REGULAR}
            >
                <span ref={childRef}>
                    {children}
                </span>
            </CommonTooltip>
        </div>
    );
};

export default React.memo(ShrinkedTextWithTooltip);
