import React, { useRef, useState, useLayoutEffect } from 'react';
import { Classes } from 'jss';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';

type AirportTooltipItemProps = {
    classes: Classes;
    code: string;
    count: number;
    iataCode?: string;
    locationName?: string;
};

const AirportTooltipItem = ({
    classes,
    code,
    count,
    iataCode,
    locationName,
}: AirportTooltipItemProps) => {
    const ref = useRef<HTMLSpanElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const description = code.length > 3 ? iataCode || locationName : code;

    useLayoutEffect(() => {
        if (ref.current) {
            const observer = new ResizeObserver(() => {
                if (ref.current) {
                    const isOverflow = ref.current.scrollWidth > ref.current.clientWidth;

                    setIsOverflowing(isOverflow);
                }
            });

            observer.observe(ref.current);

            return () => {
                if (observer && ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }
    }, [description]);

    return (
        <div className={classes.airportCount}>
            <div>
                <CommonTooltip
                    description={description}
                    enabled={isOverflowing}
                    placement="left"
                    type={TOOLTIP_TYPE.SHRINK}
                >
                    <span
                        ref={ref}
                        style={{
                            display: 'block',
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {description}
                    </span>
                </CommonTooltip>
            </div>
            <div>
                {count}
            </div>
        </div>
    );
};

export default AirportTooltipItem;
