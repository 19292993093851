import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { AccordionOptionsGroup, FilterBlock } from 'shared-components/SideFilter';
import { useTheme } from '@mui/material/styles';
import useClasses from 'hooks/useClasses';
import { ClientSideFilterType, ColumnsType } from '../../dataTypes';
import styles from '../TableSideFilter.stylle';

type Props = {
    column: ColumnsType,
    setSelectedFilterOptions: Dispatch<SetStateAction<ClientSideFilterType>>
}
const TextFilter = ({ column, setSelectedFilterOptions } : Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();

    const [searchString, setSearchString] = useState('');

    const handleClick = useCallback((e) => e.stopPropagation(), []);
    const handleInput = useCallback((event) => setSearchString(event.target.value), []);

    useEffect(() => {
        const { sideFilterKey } = column;

        if (searchString.length >= 3) {
            setSelectedFilterOptions(prev => ({
                ...prev,
                [sideFilterKey]: [searchString.toLowerCase()],
            }));
        } else if (searchString.length === 0) {
            setSelectedFilterOptions(prev => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [sideFilterKey]: unnecessary, ...filtersWithoutCurrentKey } = prev;

                return filtersWithoutCurrentKey;
            });
        }
    }, [searchString, column, setSelectedFilterOptions]);

    return (
        <FilterBlock>
            <AccordionOptionsGroup
                className={classes.textAccordion}
                dynamic={false}
                title={column.sideFilterTitle || column.Header}
            >
                <TextField
                    InputProps={{
                        className: classes.input,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: theme.palette.common.black }} />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.input}
                    id="TextFieldInput"
                    placeholder={column.Header}
                    value={searchString}
                    variant="outlined"
                    onClick={handleClick}
                    onInput={handleInput}
                />
            </AccordionOptionsGroup>
        </FilterBlock>
    );
};

export default TextFilter;
