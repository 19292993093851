import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface, {
    hasNext,
    hasPrev,
}) => {
    const tooltipBodyWrapper = css({
        alignItems: 'center',
        display: 'flex',
        filter: 'drop-shadow(2px 2px 3px #747474)',
        width: '100%',
    });

    const tooltipBody = css({
        backgroundColor: theme.palette.common.white,
        border: `3px solid ${theme.palette.common.yellow}`,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 'normal',
        minHeight: '50px',
        padding: '20px',
        width: '100%',

    });

    const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

    const quickHelp = css({
        animation: `${fadeIn} 0.5s forwards`,
        color: theme.palette.secondary[600],
        display: 'flex',
        maxWidth: '600px',
        minWidth: '400px',
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 200ms ease',
        zIndex: 105,
    });

    const tooltipVisible = css({
        opacity: 1,
        pointerEvents: 'all',
    });

    const quickHelpRight = css({
        flexDirection: 'row-reverse',
        [`& .${tooltipBodyWrapper}`]: {
            flexDirection: 'row-reverse',
        },
    });

    const line = css({
        backgroundColor: theme.palette.primary[200],
        marginRight: '8px',
        width: '2px',
    });

    const content = css({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        lineHeight: '20px',
    });
    const title = css({
        color: theme.palette.common.yellow,
        fontSize: '20px',
        fontWeight: 500,
    });

    const linkHolder = css({
        marginTop: '20px',
        whiteSpace: 'nowrap',
    });

    const imageHolder = css({
        '& img': {
            borderRadius: '10px',
            height: '275px',
            maxWidth: '500px',
        },
        cursor: 'zoom-in',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        transition: '200ms ease',
    });
    const activeImg = css({
        '& img': {
            borderRadius: '10px',
            height: 'auto',
            left: '0',
            position: 'fixed',
            top: '0',
            width: '100wh',
        },
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    });
    const controls = css({
        display: 'flex',
        flexDirection: 'row',
        height: '20px',
        justifyContent: 'space-between',
        marginTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    });
    const dots = css({
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
    });
    const dot = css({
        backgroundColor: theme.palette.secondary[600],
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '7px',
        transition: 'all 600ms ease 100ms',
        width: '7px',
    });
    const active = css({
        backgroundColor: theme.palette.primary.deepBlue,
        height: '10px',
        width: '10px',
    });
    const previous = css({
        '&:after': {
            content: '"<"',
            left: '-20px',
            paddingRight: '20px',
            position: 'absolute',
        },
        color: hasPrev ? theme.palette.primary.deepBlue : theme.palette.secondary[400],
        cursor: 'pointer',
        position: 'relative',
    });
    const next = css({
        '&:after': {
            content: '">"',
            paddingLeft: '20px',
            position: 'absolute',
            right: '-20px',
        },
        color: hasNext ? theme.palette.primary.deepBlue : theme.palette.secondary[400],
        cursor: 'pointer',
        position: 'relative',
    });

    const closeButton = css({
        alignItems: 'center',
        color: '#6c757d',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '24px',
        height: '16px',
        justifyContent: 'center',
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '16px',
    });

    return {
        active,
        activeImg,
        closeButton,
        content,
        controls,
        dot,
        dots,
        imageHolder,
        line,
        linkHolder,
        next,
        previous,
        quickHelp,
        quickHelpRight,
        title,
        tooltipBody,
        tooltipBodyWrapper,
        tooltipVisible,
    };
};

export default generateStyles;
