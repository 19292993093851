import { useTheme } from '@emotion/react';
import AddCircle from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import useGetCommonData from 'hooks/useGetCommonData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import NoLocationsDefined from 'MyCompany/CompanyInfo/tabs/Overview/components/NoLocationsDefined';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import Table from 'shared-components/Table/TableContainer';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import { uniqueId } from 'utils/stringTool';
import { tableColumns } from './lib';
import generateStyles from './ShipmentAuthorizationCard.style';

const loadingStyle = {
    filter: 'blur(2px)',
    pointerEvents: 'none',
    transition: '200ms ease',
};

type Props = {
    loading: boolean,
    shipment: ShipmentDetailsInterface,
};
const ShipmentAuthorizationCard = ({
    loading,
    shipment,
}:Props) => {
    const { t } = useTranslation();
    const classes = useGeneratedStyles(generateStyles);
    const theme:SkycellThemeInterface = useTheme();
    const [workInProgress, setWorkInProgress] = useState<{
        [key: string]: boolean,
    }>({});
    const {
        data: companies = [],
        isLoading: dataLoading,
    } = useGetCommonData<Company[]>('companies', {
        postProcess: (data) => data.resultList,
    });

    const {
        setErrorStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const {
        FlexibleRequest: addAuthorizedCompany,
    } = useSkymindBackendEndpoints(`admin/v2/shipments/${shipment.id}/access`).requests;
    const {
        FlexibleRequest: removeAuthorizedCompany,
    } = useSkymindBackendEndpoints(`admin/v2/shipments/${shipment.id}/access`).requests;

    const [companyObjects, setCompanyObjects] = useState<{
        companyId: number,
        oldCompanyId?: number,
        uniqueId: string,
    }[]>(shipment.accessRecord.authorizedCompaniesIds
        .map(it => ({
            companyId: it,
            oldCompanyId: it,
            uniqueId: uniqueId(),
        })));

    const onSave = useCallback(async (uniqueId, companyId) => {
        try {
            let result;

            try {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: true,
                }));

                if (companyId) {
                    result = await addAuthorizedCompany('PATCH', '', {
                        id: companyId,
                    });
                }
            } catch (e) {
                setErrorStatus(t('ADMINISTRATION.AUTHORIZED_COMPANY_ADD_ERROR'));
                return;
            } finally {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: false,
                }));
            }
            if (result.status === 200 || result.status === 201 || result.status === 204) {
                setCompanyObjects(prev => prev.map(it => {
                    if (it.uniqueId === uniqueId) {
                        return {
                            ...it,
                            companyId,
                            oldCompanyId: companyId,
                        };
                    }
                    return it;
                }));
                setSuccessStatus(t('ADMINISTRATION.NEW_COMPANY_ADDED_SHIPMENT'), [], true);
            }
        } catch (e) {
            global.console.log(e);
        }
    }, [companyObjects, companies]);
    const onRemove = useCallback(async (uniqueId, companyId) => {
        const companyObject = companyObjects.find(it => it.uniqueId === uniqueId);

        if (!companyObject.oldCompanyId) {
            setCompanyObjects(prev => prev.filter(it => it.uniqueId !== uniqueId));
            return;
        }
        try {
            let result;

            try {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: true,
                }));
                result = await removeAuthorizedCompany('PATCH', companyId.toString(), {});
            } catch (e) {
                setErrorStatus(t('ADMINISTRATION.AUTHORIZED_COMPANY_DELETE_ERROR'));
                return;
            } finally {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: false,
                }));
            }

            if (result.status === 200 || result.status === 201 || result.status === 204) {
                setCompanyObjects(prev => prev.filter(it => it.uniqueId !== uniqueId));
                setSuccessStatus(t('ADMINISTRATION.COMPANY_REMOVED_SHIPMENT'), [], true);
            }
        } catch (e) {
            global.console.log(e);
        }
    }, [companyObjects, companies]);

    const onCompanyChane = useCallback((uniqueId, val) => {
        setCompanyObjects(prev => prev.map(it => {
            if (it.uniqueId === uniqueId) {
                return {
                    ...it,
                    companyId: val,
                };
            }
            return it;
        }));
    }, [companyObjects]);

    const columns = useMemo(() => tableColumns(
        {
            classes,
            companies,
            companyObjects,
            onChange: onCompanyChane,
            onRemove,
            onSave,
            t,
            theme,
            workInProgress,
        },
    ), [
        companyObjects,
        companies,
        workInProgress,
    ]);

    const appendGatewayObject = useCallback(() => {
        if (companyObjects.find(it => it.companyId === null)) {
            return;
        }
        setCompanyObjects(prev => [...prev, {
            companyId: null,
            oldCompanyId: null,
            uniqueId: uniqueId(),
        }]);
    }, [companyObjects]);

    const onSearchHandler = useCallback(() => {
        appendGatewayObject();
    }, []);

    return (
        <CardWithEditableContent
            loadingIcon={loading}
            style={loading ? loadingStyle : { }}
            title={t('ADMINISTRATION.SHIMPENT_AUTHORIZATION')}
            zeroSidePadding
        >
            {
                !dataLoading && (
                    <>
                        {
                            (companyObjects.length !== 0) && (
                                <div className={classes.tableDescription}>
                                    {t('ADMINISTRATION.SHIPMENT_AUTHORIZATION_DESC')}
                                </div>
                            )
                        }
                        {
                            (companyObjects.length > 0) && (
                                <Table
                                    classNames={{
                                        bodyCell: classes.cell,
                                        headerCell: classes.hcell,
                                        tableContainerClassName: classes.table,
                                    }}
                                    columns={columns}
                                    data={companyObjects}
                                    embed
                                />
                            )
                        }
                        {
                            (companyObjects.length === 0) && (
                                <NoLocationsDefined
                                    description={t('ADMINISTRATION.NO_COMPANIES_ASSIGNED_DESC')}
                                    title={t('ADMINISTRATION.NO_COMPANIES_ASSIGNED')}
                                    onSearch={onSearchHandler}
                                />
                            )
                        }
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '11px 8px 0',
                            }}
                        >
                            {
                                companyObjects.length >= 1 && (
                                    <AddCircle
                                        className={classes.icon}
                                        color="action"
                                        fontSize="medium"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={appendGatewayObject}
                                    />
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                dataLoading && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100px',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                    >
                        <CircularProgress
                            size={20}
                            style={{ color: theme.palette.secondary[600] }}
                        />
                    </div>
                )
            }

        </CardWithEditableContent>
    );
};

export default ShipmentAuthorizationCard;
