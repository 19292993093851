const styles = (theme) => ({
    input: {
        '& $input': {
            paddingLeft: '8px',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        height: '31px',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textAccordion: {
        overflow: 'hidden',
    },
});

export default styles;
