import { ContactInfo } from 'dataTypes/SecureBackend/apiResponse';
import {
    NotificationSettings,
} from 'MyProfile/components/NotificationChannelSettingsCard/NotificationChannelSettingsCard';

export type RawCompany = {
    address: {
        city: string,
        country: {
            name: string,
        }
    };
    companyRoles: string[];
    companyType: 'SERVICE_PROVIDER';
    contactInfo: ContactInfo;
    dataSharingAuthorized: boolean;
    energyLevelThreshold: number;
    id: number;
    // Assuming this is a constant in this context, adjust accordingly if it can vary
    logoImagePath: null | string;
    name: string;
    notificationSettings: NotificationSettings[];
    saveAllShipments: boolean;
    website: null | string;
}

export type ExtractedCompany = {
    companyRoles: string[],
    companyType: string;
    id: number;
    name: string;
}

export const initialCompanyFilterOptions: { [optionsGroupKey: string]: string[] } = {
    companyRoles: [],
    companyTypes: [],
};

export interface CompaniesDTO {
    resultList: RawCompany[],
    totalElements: number,
}

export const extractedCompanyData: (raws: RawCompany[]) => ExtractedCompany[] = (raws) => {
    return raws.map(raw => {
        const {
            address,
            companyRoles,
            companyType,
            id,
            logoImagePath,
            name,
        } = raw;

        return {
            city: address.city,
            companyRoles,
            companyType,
            country: address.country.name,
            id,
            logoImagePath,
            name,
        };
    });
};
