import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Location } from 'shared-components/dataTypes';

import Card from 'shared-components/Card';
import Map from 'shared-components/Map';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './OverviewInfoCard.style';

type Props = {
    address: {
        addressLine1?: string,
        addressLine2?: string,
        addressLine3?: string,
        city?: string,
        countryName?: string,
        zip?: string
    },
    companyLogo: string,
    companyName: string,
    contactEmail: string,
    contactName: string,
    contactPhone: string,
    location: Location,
    website: string,
}

const OverviewInfoCard = ({
    address = {},
    companyLogo,
    companyName,
    contactEmail = null,
    contactName = null,
    contactPhone = null,
    location,
    website = '',
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <Card fullHeight title={t('COMMON.OVERVIEW')}>
            <div className={classes.mainOverviewInfoContainer}>
                <div key="Column-1">
                    <img
                        alt="Company Logo"
                        className={classes.companyLogo}
                        src={companyLogo}
                    />
                    <div className={classes.groupTitle}>{t('COMMON.COMPANY_NAME')}</div>
                    <div>{companyName}</div>
                    <div className={classes.groupTitle}>{t('COMMON.CONTACT_INFORMATION')}</div>
                    {
                        contactName && (
                            <div className={classes.iconContainer}>
                                <PersonIcon fontSize="small" />
                                <div className={classes.contactInfo}>{contactName}</div>
                            </div>
                        )
                    }
                    {
                        contactPhone && (
                            <div className={classes.iconContainer}>
                                <LocalPhoneIcon fontSize="small" />
                                <div className={classes.contactInfo}>{contactPhone}</div>
                            </div>
                        )
                    }
                    {
                        contactEmail && (
                            <div className={classes.iconContainer}>
                                <MailOutlineIcon fontSize="small" />
                                <div className={classes.contactInfo}>{contactEmail}</div>
                            </div>
                        )
                    }
                    {
                        website && (
                            <div>
                                <div className={classes.groupTitle}>{t('COMMON.WEBSITE')}</div>
                                <div>{website}</div>
                            </div>
                        )
                    }
                </div>
                <div key="Column-2">
                    <div className={classes.groupTitleTop}>{t('COMMON.ADDRESS')}</div>
                    <div>{address.addressLine1}</div>
                    {
                        address.addressLine2 && <div>{address.addressLine2}</div>
                    }
                    {
                        address.addressLine3 && <div>{address.addressLine3}</div>
                    }
                    <div>{`${address.zip} ${address.city}`}</div>
                    <div>{address.countryName}</div>
                    <Map
                        mapConfig={{
                            backgroundColor: 'unset',
                            center: location,
                            containerStyle: {
                                height: '200px',
                                marginTop: '20px',
                                width: '100%',
                            },
                            zoom: 4,
                        }}
                        separateMarker={location}
                    />
                </div>
            </div>
        </Card>
    );
};

export default OverviewInfoCard;
