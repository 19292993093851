import { useTheme } from '@emotion/react';
import AccessRequestFilterPanel from 'Administration/AccessRequests/AccessRequestFilters';
import AddAccessRequests from 'Administration/AccessRequests/AddAccessRequests';
import DecisionModal from 'Administration/AccessRequests/DecisionModal/DecisionModal';
import {
    AccessRequestAction,
    AccessRequestDTO,
    AccessRequestsResponse,
    initialAccessRequestsFilterOptions,
} from 'Administration/AccessRequests/lib';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetCommonData from 'hooks/useGetCommonData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { PageWithFilter } from 'Layout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FacetDTO } from 'shared-components/dataTypes';
import CommonButton from 'shared-components/CommonButton';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import StandalonePagination from 'shared-components/StandalonePagination';
import Table from 'shared-components/Table/TableContainer';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { styles } from './AccessRequests.style';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;
const Requests = () => {
    const classes = useClasses(styles);

    const [page, setPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [query, setQuery] = useState('');
    const [accessRequests, setAccessRequests] = useState<AccessRequestDTO[]>([]);
    const availableHeight = useAvailableHeight();
    const { t } = useCustomTranslation();
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [checked, setChecked] = useState<number[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>(null);
    const theme = useTheme();
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const {
        FlexibleRequest: actionOnAccessRequests,
    } = useSkymindBackendEndpoints('admin/access-requests').requests;
    const {
        setErrorStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();
    const update = useCallback(() => {
        setLastUpdated(Date.now());
    }, []);

    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('admin/access-requests/facets',
        {
            cache: true,
            mutateParam: lastUpdated,
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(null);

    const {
        data: allCompanies = [],
        isLoading: companiesLoading,
    } = useGetCommonData<Company[]>('companies', {
        postProcess: (data) => data.resultList,
        query: {
            page: 0,
            pageSize: 9999,
        },
    });

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(initialAccessRequestsFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('admin/access-requests/facets',
        {
            cache: true,
            mutateParam: lastUpdated,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawAccessRequests, status: rawAccessRequestsStatus,
    } = useGetCommonData<AccessRequestsResponse>('admin/access-requests/search', {
        cache: true,
        enabled: facetsStatus === 'SUCCESS',
        mutateParam: lastUpdated,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort)),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (rawAccessRequestsStatus === 'SUCCESS' && rawAccessRequests?.resultList) {
            setTotalElements(rawAccessRequests.totalElements);
            setAccessRequests(rawAccessRequests?.resultList);
        }
    }, [rawAccessRequestsStatus, rawAccessRequests]);

    const handleCheckDecide = useCallback((...id: number[]) => {
        setSelectedItems(id);
    }, []);
    const handleCheckAll = useCallback(() => {
        setSelectedItems(checked);
    }, [checked]);
    const handleSend = useCallback(async (ids, comment, action = AccessRequestAction.APPROVE) => {
        try {
            setSendingInProgress(true);
            const result = await actionOnAccessRequests(
                'PATCH',
                action,
                {
                    accessRequestIds: ids,
                    comment,
                });

            if (result.status === 200) {
                setChecked([]);
                setSelectedItems(null);
                setSuccessStatus(action === AccessRequestAction.APPROVE
                    ? t('MY_COMPANY.ACCESS_REQUEST_APPROVED')
                    : t('MY_COMPANY.ACCESS_REQUEST_REJECTED'));
                update();
            }
        } catch (e) {
            global.console.log(e);
            setErrorStatus(t('COMMON.UNFORTUNATELY_SOMETHING_WRONG'));
        } finally {
            setSendingInProgress(false);
        }
    }, []);
    const handleCloseModal = useCallback(() => {
        setSelectedItems(null);
    }, []);

    const columns = useMemo(() => tableColumns(
        t,
        setChecked,
        checked,
        handleCheckDecide,
        theme,
    ),
    [t, checked, theme]);

    useEffect(() => {
        if (page === 1) return;
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const companiesDictionary = useMemo(() => {
        return allCompanies.map(it => ({
            id: it.id,
            label: it.name,
        }));
    }, [allCompanies]);

    return (
        <DataPaginationContext.Provider
            key="admin_incoming_provider"
            value={{
                page,
                paginationLoading: rawAccessRequestsStatus === 'PENDING',
                perPage: PAGE_SIZE,
                setPage,
                totalElements,
            }}
        >
            <DecisionModal
                accessRequests={accessRequests}
                loading={sendingInProgress}
                selected={selectedItems}
                onClose={handleCloseModal}
                onSend={handleSend}
            />
            <AddAccessRequests
                allCompanies={allCompanies}
                companiesLoading={companiesLoading}
                onUpdated={update}
            />
            <PageWithFilter key="admin_incoming_side_filter_wrap">
                <AccessRequestFilterPanel
                    key="admin_incoming_side_filter"
                    companiesDictionary={companiesDictionary}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    initialFacets={rawInitialFacets}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setQuery={setQuery}
                />
                <div style={{ display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <Table
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={accessRequests}
                        maskForHighlight={query}
                        tableMaxHeight="100%"
                        title={t('MENU_ITEMS.ACCESS_REQUESTS')}
                        onSort={updateSort}
                    />
                    <CommonButton
                        disabled={checked.length === 0}
                        sx={{
                            marginLeft: '33.42px',
                            marginTop: '20.5px',
                        }}
                        onClick={handleCheckAll}
                    >
                        Approve Selected
                    </CommonButton>
                    <StandalonePagination detached entity="Access Requests" />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Requests;
