import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Card from 'shared-components/Card';
import ImageThumbnails from 'shared-components/ImageThumbnails';
import CircularProgress from '@mui/material/CircularProgress';
import useCachedImages from 'hooks/useCachedImages';

interface Props {
    imageUrls: string[];
    loading: boolean;
}

const LatestPairingPictures = ({
    imageUrls,
    loading,
}: Props) => {
    const { t } = useCustomTranslation();

    const [cachedImageUrls, useCachedImagesLoading = true] = useCachedImages(imageUrls);

    return (
        <Card title={t('TRACK_AND_TRACE.LATEST_PAIRING_PICTURES')}>
            {
                loading || useCachedImagesLoading
                    ? (
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                height: '104px',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress
                                color="primary"
                                size={24}
                            />
                        </div>
                    )
                    : <ImageThumbnails imageUrls={cachedImageUrls} />
            }
        </Card>
    );
};

export default LatestPairingPictures;
