import React, { useMemo } from 'react';
import icons from 'shared-components/icons';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetCommonData from 'hooks/useGetCommonData';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { EnergyLevelStatus } from '../../TrackAndTrace/lib';

type Props = {
    energyLevel?: number,
    energyLevelStatus?: string,
    threshold?: boolean,

}
const EnergyLevel = ({
    energyLevel,
    energyLevelStatus,
    threshold = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const { company } = useCurrentUserContext();

    const {
        data: batteryLevelSettings,
    } = useGetCommonData<{
        batteryLevelThreshold: number,
    }>('assets/battery-level-settings', {
        enabled: threshold,
    });

    const energyLevelThreshold = threshold
        ? batteryLevelSettings.batteryLevelThreshold : company.energyLevelThreshold;

    const hasBreach = useMemo(() => (threshold ? Number(energyLevel) <= energyLevelThreshold
        : energyLevelStatus === EnergyLevelStatus
            .ENERGY_LEVEL_BREACH), [energyLevelStatus, threshold, energyLevel, energyLevelThreshold]);

    return (
        <div style={{
            alignItems: 'center',
            color: hasBreach ? '#D44848' : 'black',
            display: 'flex',
        }}
        >
            {energyLevel === null || energyLevel === undefined
                ? (
                    <div style={{
                        alignItems: 'center',
                        color: '#747474',
                        display: 'flex',
                        opacity: 1,
                        textAlign: 'left',
                    }}
                    >
                        {t('TEMPERATURE_STATUS.NO_DATA')}
                    </div>
                )
                : hasBreach
                    ? (
                        <img
                            key="icon_warning"
                            alt="warning"
                            src={Number(energyLevel) === 0
                                ? icons.red_triangle : hasBreach
                                    ? icons.warning_triangle : ''}
                            style={{
                                height: 14,
                                marginRight: 4,
                            }}
                        />
                    )
                    : ''}
            <div>
                {!(energyLevel === null || energyLevel === undefined)
                    && `${Number(energyLevel)}%`}
            </div>
            <br />
            <br />
        </div>
    );
};

export default EnergyLevel;
