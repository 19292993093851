const styles = theme => ({
    contentContainer: {
        display: 'grid',
        gridGap: theme.spacing(1.5),
        gridTemplateColumns: '8fr minmax(325px, 1fr)',
        justifyContent: 'flex-start',
        padding: '0 12px 12px 12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    contentContainerFullWidth: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 12px 12px 12px',
    },
    contentRightColumn: {
        alignContent: 'flex-start',
        display: 'grid',
        gridGap: theme.spacing(1.5),
    },
    title: {
        fontSize: '32px',
        fontWeight: 'normal',
        margin: '12px 0 15px',
    },
});

export default styles;
