import { useTheme } from '@mui/material/styles';
import React, { CSSProperties, useMemo } from 'react';
import {
    TEMPERATURE_RANGES_VALUES,
    TEMPERATURE_RANGES_OPTIONS,
} from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import CommonTooltip, { TooltipProps } from 'shared-components/CommonTooltip/CommonTooltip';

type Mode = 'full' | 'reduced';

const getRangeValues = (tempRange: string, mode: Mode, toSeparator: string): string => {
    const { tempRangeMax, tempRangeMin } = TEMPERATURE_RANGES_VALUES[tempRange] || {};
    const min = mode === 'reduced' ? tempRangeMin?.toString() : `${tempRangeMin}°C`;
    const max = mode === 'reduced' ? tempRangeMax?.toString() : `${tempRangeMax}°C`;

    if (tempRange === TEMPERATURE_RANGES_OPTIONS.F || tempRange === TEMPERATURE_RANGES_OPTIONS.DF) {
        return `(${min} ${toSeparator} ${max})`;
    }
    return `(${min} - ${max})`;
};

const containerStyle: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    font: 'normal normal normal 14px/19px Roboto',
    gap: '5px',
    letterSpacing: '0.18px',
    opacity: 1,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    // color: '#000000', // it is black in the specification, but it makes sense to get a common style in some places
};

// todo: I guess it makes sense to specify tempRange as type 'CRT' | 'C' | 'F' | 'DF' (in the whole project)
type Props = {
    circle?: boolean,
    mode?: Mode,
    tempRange: string,
    tooltip?: TooltipProps,
}

const TempRange = ({
    circle = false,
    mode = 'full',
    tempRange,
    tooltip,
} : Props) => {
    const { t } = useCustomTranslation();
    const theme = useTheme();

    const rangeKey = TEMPERATURE_RANGES_OPTIONS[tempRange];
    const rangeValues = getRangeValues(tempRange, mode, t('SENSOR_DATA.TO'));
    const rangeKeyColor = tempRange === TEMPERATURE_RANGES_OPTIONS.CRT
        ? theme.palette.common['yellow'] : theme.palette.primary['deepBlue'];

    const dotStyle = useMemo(() => ({
        background: rangeKeyColor,
        borderRadius: '50%',
        height: 15,
        width: 15,
    }),
    [rangeKeyColor]);
    const temperature = useMemo(() => {
        if (!Object.keys(TEMPERATURE_RANGES_OPTIONS).includes(tempRange)) {
            return <div>tempRange</div>;
        }

        return (
            <div style={containerStyle}>
                {
                    circle && (
                        <div style={dotStyle} />
                    )
                }
                <div style={{ color: rangeKeyColor }}>{rangeKey}</div>
                <div style={{ color: theme.palette.common.black }}>{rangeValues}</div>
            </div>
        );
    }, [rangeKey, rangeValues, tempRange]);

    return (
        tooltip ? (
            <CommonTooltip {...tooltip}>
                {temperature}
            </CommonTooltip>
        ) : temperature
    );
};

export default TempRange;
