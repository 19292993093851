import React, {
    Dispatch,
    SetStateAction,
    useCallback,
} from 'react';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useClasses from 'hooks/useClasses';
import styles from '../SideFilter.style';

type Props = {
    placeholder?: string,
    searchString: string,
    setSearchString: Dispatch<SetStateAction<string>>,
}

const SearchTextField = ({
    placeholder = '',
    searchString = '',
    setSearchString,
} : Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();

    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const searchStringChanged = (event) => {
        setSearchString(event.target.value);
    };

    return (
        <TextField
            InputProps={{
                className: classes.searchTextFieldInput,
                startAdornment: (
                    <InputAdornment position="start">
                        <Search style={{ color: theme.palette.common.black }} />
                    </InputAdornment>
                ),
            }}
            className={classes.searchTextFieldInput}
            id="TextFieldInput"
            placeholder={placeholder}
            value={searchString}
            variant="outlined"
            onClick={handleClick}
            onInput={searchStringChanged}
        />
    );
};

export default SearchTextField;
