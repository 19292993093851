import React, {
    useState,
    useEffect, useMemo, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { Company, User } from 'dataTypes/SecureBackend/apiResponse';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import UserInfoCard from 'Administration/Users/components/UserInfoCard';
import UserAccountCard from 'Administration/Users/components/UserAccountCard';
import { userRoles } from 'hooks/useHasAccess';
import { LayoutContext } from 'Contexts/LayoutContext';
import useClasses from 'hooks/useClasses';
import {
    extractOnlyNotNullFields,
    isReadyAddUserRequestBody,
} from 'Administration/lib';
import BackToLink from 'shared-components/BackToLink';
import useCustomTranslation from 'hooks/useCustomTranslation';
import styles from './EditUser.style';

const initialUser: User = {
    businessPhone: '',
    companyId: null,
    companyName: '',
    email: '',
    fullName: {
        firstName: '',
        lastName: '',
    },
    id: '',
    jobCategory: '',
    jobTitle: '',
    mobilePhone: '',
    userRoles: [],
};

type PathParams = {
    userId: string,
}
type Props = {
    admin?: boolean,
}
const EditUser = ({
    admin = false,
}:Props) => {
    const classes = useClasses(styles);
    const { Get: getCompany } = useSkymindBackendEndpoints('companies').requests;
    const { t } = useCustomTranslation();
    const { Get: getUsers } = useSkymindBackendEndpoints(`${admin ? 'admin/' : ''}users`).requests;
    const { FlexibleRequest: updateInfo } = useSkymindBackendEndpoints(`${admin ? 'admin/' : ''}users`)
        .statusNotificationRequests;
    const { userId } = useParams() as unknown as PathParams;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setCustomPageTitle } = useContext(LayoutContext);
    const { userInfo: currentUserInfo = null } = useCurrentUserContext();

    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [companyResponse, setCompanyResponse] = useState<Company>(null);
    const [userResponse, setUserResponse] = useState<User>(initialUser);
    const [userInfo, setUserInfo] = useState<AddUserInfo>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const isCurrentUserAdmin = useMemo(
        () => [userRoles.CUSTOMER_ADMIN, userRoles.SKYCELL_ADMIN]
            .some(role => currentUserInfo?.assignedRoles?.includes(role)),
        [currentUserInfo],
    );

    const requestToSaveUserInfo = async () => {
        const {
            status = null,
        } = await updateInfo('PATCH', `/${userId}/overview-info`, extractOnlyNotNullFields(userInfo));

        if (status === 200) {
            setRequiredUpdateData(true);
            setCustomPageTitle(`User Details: ${userInfo.firstName} ${userInfo.lastName}`);
        }
    };

    const requestToSaveRole = async () => {
        const { status = null } = await updateInfo('PATCH', `/${userId}/roles`, roles);

        if (status === 200) {
            setRequiredUpdateData(true);
        }
    };

    useEffect(() => {
        if (userResponse.id === initialUser.id || requiredUpdateData) {
            (async () => {
                try {
                    const userResponse: User = await (await getUsers(userId)).data || null;

                    if (userResponse) {
                        setUserResponse(userResponse);
                        setRoles(userResponse.userRoles);
                        setBreadcrumbData('customBreadcrumb',
                            `${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`);
                        setCustomPageTitle(
                            `User Details: ${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`,
                        );
                    }
                } catch (error) {
                    setUserResponse(initialUser);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData, setCustomPageTitle]);

    useEffect(() => {
        return () => setCustomPageTitle('');
    }, []);

    useEffect(() => {
        if (userResponse.companyId && !companyResponse) {
            (async () => {
                try {
                    const companyResponse: Company = await (await getCompany(userResponse.companyId)).data || null;

                    if (companyResponse) {
                        setCompanyResponse(companyResponse);
                    }
                } catch (error) {
                    setCompanyResponse(null);
                }
            })();
        }
    }, [userResponse]);

    return (
        <div>
            <BackToLink
                marginBottom={0}
                marginLeft={12}
                marginRight={12}
                marginTop={10}
                title={t('MENU_ITEMS.USERS')}
                to={admin ? '/administration/users' : '/my-company/users'}
            />
            <div className={classes.pageContent}>
                <UserInfoCard
                    admin={admin}
                    currentBusinessPhone={userResponse.businessPhone}
                    currentCompany={companyResponse}
                    currentEditedCard={currentEditedCard}
                    currentEmail={userResponse.email}
                    currentFirstName={userResponse.fullName.firstName}
                    currentJobCategory={userResponse.jobCategory}
                    currentJobTitle={userResponse.jobTitle}
                    currentLastName={userResponse.fullName.lastName}
                    currentMobilePhone={userResponse.mobilePhone}
                    disabledSaveButton={!isReadyAddUserRequestBody(userInfo)}
                    saveInfo={requestToSaveUserInfo}
                    setCurrentEditedCard={setCurrentEditedCard}
                    setUserInfo={setUserInfo}
                    type="editable"
                />
                <CompanyRolesCard
                    availableRoles={companyResponse?.companyRoles || []}
                    currentEditedCard={currentEditedCard}
                    currentRoles={userResponse.userRoles}
                    roles={roles}
                    saveInfo={requestToSaveRole}
                    setCurrentEditedCard={setCurrentEditedCard}
                    setRoles={setRoles}
                    type="editable"
                />
                {
                    isCurrentUserAdmin && (
                        <UserAccountCard
                            admin={admin}
                            disableUserButtonDisabled={false}
                            resetPasswordButtonDisabled={false}
                            selectedUserInfo={userResponse}
                            setRequiredUpdateData={setRequiredUpdateData}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default EditUser;
