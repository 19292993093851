import React, { useState, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';

import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';

type Props = {
    icon: ReactElement,
    text: string
}
const IconTooltip = ({
    icon,
    text,
}: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <CommonTooltip
            description={text}
            open={open}
        >
            <IconButton
                size="small"
                onMouseEnter={setOpen.bind(null, true)}
                onMouseLeave={setOpen.bind(null, false)}
            >
                {icon}
            </IconButton>
        </CommonTooltip>
    );
};

export default IconTooltip;
