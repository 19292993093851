import React from 'react';
import icons from 'shared-components/icons';

type Position = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft' | 'center';

export type PolygonIconType = 'hexa' | 'tetra';
export type PolygonIconColor = 'blue' | 'blue_dark' | 'grey' | 'orange' | 'red';

export const getPosition = (iconDiameter: number, position: Position) => {
    switch (position) {
    case 'topRight':
        return {
            left: iconDiameter - 5,
            top: -5,
        };
    case 'topLeft':
        return {
            right: iconDiameter - 5,
            top: -5,
        };
    case 'bottomRight':
        return {
            left: iconDiameter - 5,
            top: iconDiameter - 5,
        };
    case 'bottomLeft':
        return {
            right: iconDiameter - 5,
            top: iconDiameter - 5,
        };
    case 'center':
        return {
            left: iconDiameter / 2,
            top: iconDiameter / 2,
        };
    }
};

type Props = {
    className?: string,
    color?: PolygonIconColor,
    filled?: boolean,
    iconDiameter?: number,
    theme?: 'default' | 'light',
    type?: PolygonIconType
}

const PolygonIcon = ({
    className = '',
    color = 'blue',
    filled = false,
    iconDiameter = 22,
    theme = 'default',
    type = 'hexa',
}: Props) => {
    const iconName = `${theme === 'default' ? '' : `${theme}_`}${type}_${color}${filled ? '_filled' : ''}`;

    return (
        <img
            alt={iconName}
            className={className}
            src={icons[iconName]}
            style={{
                height: iconDiameter,
                objectFit: 'unset',
                width: iconDiameter,
            }}
        />
    );
};

export default PolygonIcon;
