import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'shared-components/Table/TableContainer';
import AddCircle from '@mui/icons-material/AddCircle';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import NoLocationsDefined from 'MyCompany/CompanyInfo/tabs/Overview/components/NoLocationsDefined';
import useGetCommonData from 'hooks/useGetCommonData';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { uniqueId } from 'utils/stringTool';
import { CircularProgress } from '@mui/material';
import {
    tableColumns,
} from 'MyCompany/CompanyInfo/tabs/Overview/components/BatteryExchangeLocationsCard/lib';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { useTheme } from '@emotion/react';
import generateStyles from './BatteryExchangeLocationsCard.styles';

type Props = {
    className: string,
    loading: boolean,

}
const loadingStyle = {
    filter: 'blur(2px)',
    pointerEvents: 'none',
    transition: '200ms ease',
};

const BatteryExchangeLocationsCard = ({
    className = '',
    loading,
}: Props) => {
    const { t } = useTranslation();
    const classes = useGeneratedStyles(generateStyles);
    const theme:SkycellThemeInterface = useTheme();
    const [workInProgress, setWorkInProgress] = useState<{
        [key: string]: boolean,
    }>({});
    const {
        data: gateways = [],
    } = useGetCommonData<GatewayInfoDTO[]>('gateways/search', {
        postProcess: (data) => data.resultList.filter(it => it.gatewayImeiMac && it.iataCode),
    });

    const {
        data: batteryLevelSettings,
        isLoading: batterySettingsLoading,
    } = useGetCommonData<{
        batteryLevelThreshold: number,
        companyId: number,
        gatewayIds: number[]
    }>('assets/battery-level-settings');
    const {
        setErrorStatus,
        setProgressStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const {
        Create: addGateway,
    } = useSkymindBackendEndpoints('assets/battery-level-settings/action/add-gateway').requests;
    const {
        Create: removeGateway,
    } = useSkymindBackendEndpoints('assets/battery-level-settings/action/remove-gateway').requests;

    const [gatewayObjects, setGatewayObjects] = useState<{
        gatewayId: number,
        oldGatewayId?: number,
        uniqueId: string,
    }[]>([]);

    useEffect(() => {
        if (!batteryLevelSettings?.gatewayIds || gateways.length === 0) {
            setGatewayObjects([]);
        } else {
            const newGatewayObjects = batteryLevelSettings.gatewayIds.map(it => {
                const gatewayFound = gateways.find(({ id }) => it === id);

                return {
                    gatewayId: gatewayFound?.id,
                    oldGatewayId: gatewayFound?.id,
                    uniqueId: uniqueId(),
                };
            });

            setGatewayObjects(newGatewayObjects);
        }
    }, [batteryLevelSettings, gateways]);

    const onSave = useCallback(async (uniqueId, gatewayId) => {
        try {
            setProgressStatus('Updating Battery Level Settings');
            const oldGatewayId = gatewayObjects.find(it => it.uniqueId === uniqueId)?.oldGatewayId;

            try {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: true,
                }));

                if (oldGatewayId) {
                    await removeGateway({
                        gatewayId: oldGatewayId,
                    });
                }
                await addGateway({
                    gatewayId,
                });
            } catch (e) {
                setErrorStatus('Something went wrong');
                return;
            } finally {
                setGatewayObjects(prev => prev.map(it => {
                    if (it.uniqueId === uniqueId) {
                        return {
                            ...it,
                            gatewayId,
                            oldGatewayId: gatewayId,
                        };
                    }
                    return it;
                }));
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: false,
                }));
                setSuccessStatus('Updated Successfully', [], true);
            }
        } catch (e) {
            global.console.log(e);
        }
    }, [gatewayObjects, gateways]);
    const onRemove = useCallback(async (uniqueId, gatewayId) => {
        const gatewayObject = gatewayObjects.find(it => it.uniqueId === uniqueId);

        if (!gatewayObject.oldGatewayId) {
            setGatewayObjects(prev => prev.filter(it => it.uniqueId !== uniqueId));
            return;
        }
        try {
            setProgressStatus('Updating Battery Level Settings');
            try {
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: true,
                }));
                await removeGateway({
                    gatewayId,
                });
            } catch (e) {
                setErrorStatus('Something went wrong');
                return;
            } finally {
                setGatewayObjects(prev => prev.filter(it => it.uniqueId !== uniqueId));
                setWorkInProgress(prev => ({
                    ...prev,
                    [uniqueId]: false,
                }));
                setSuccessStatus('Updated Successfully', [], true);
            }
        } catch (e) {
            global.console.log(e);
        }
    }, [gatewayObjects, gateways]);

    const onGatewayChange = useCallback((uniqueId, val) => {
        setGatewayObjects(prev => prev.map(it => {
            if (it.uniqueId === uniqueId) {
                return {
                    ...it,
                    gatewayId: val,
                };
            }
            return it;
        }));
    }, [gatewayObjects]);

    const columns = useMemo(() => tableColumns(
        {
            classes,
            gatewayObjects,
            gateways,
            onChange: onGatewayChange,
            onRemove,
            onSave,
            t,
            theme,
            workInProgress,
        },
    ), [
        gatewayObjects,
        gateways,
        workInProgress,
    ]);

    const appendGatewayObject = useCallback(() => {
        setGatewayObjects(prev => [...prev, {
            gatewayId: null,
            uniqueId: uniqueId(),
        }]);
    }, []);

    const onSearchHandler = useCallback(() => {
        appendGatewayObject();
    }, []);

    return (
        <CardWithEditableContent
            className={className}
            loadingIcon={loading}
            style={loading ? loadingStyle : { }}
            title={t('MY_COMPANY.BATTERY_EXCHANGE_LOCATIONS')}
            zeroSidePadding
        >
            {
                !batterySettingsLoading && (
                    <>
                        {
                            (gatewayObjects.length > 0) && (
                                <Table
                                    classNames={{
                                        bodyCell: classes.cell,
                                        headerCell: classes.hcell,
                                        tableContainerClassName: classes.table,
                                    }}
                                    columns={columns}
                                    data={gatewayObjects}
                                    embed
                                />
                            )
                        }
                        {
                            (gatewayObjects.length === 0) && (
                                <NoLocationsDefined
                                    onSearch={onSearchHandler}
                                />
                            )
                        }
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '11px 13px 0',
                            }}
                        >
                            {
                                gatewayObjects.length >= 1 && (
                                    <AddCircle
                                        className={classes.icon}
                                        color="action"
                                        fontSize="small"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={appendGatewayObject}
                                    />
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                batterySettingsLoading && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100px',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                    >
                        <CircularProgress
                            size={20}
                            style={{ color: theme.palette.secondary[600] }}
                        />
                    </div>
                )
            }

        </CardWithEditableContent>
    );
};

export default BatteryExchangeLocationsCard;
