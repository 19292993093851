import useClasses from 'hooks/useClasses';
import React from 'react';
import Card from 'shared-components/Card';
import { Slider, TextField } from '@mui/material';
import { assetsPath } from 'shared-components/constants';
import { styles } from './ContainerCard.style';

type Props = {
    containerName?: string,
    containerPicture?: string,
    onChange: (quantity:number|number[]) => void,
    quantity?: number
}

const ContainerCard = ({
    containerName,
    containerPicture = 'defaultContainer.svg',
    onChange,
    quantity = 1,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <Card
            className={classes.card}
            title={containerName}
        >
            <div className={classes.cardContents}>
                <img
                    alt={containerName}
                    className={classes.picture}
                    src={`${assetsPath}/assets${containerPicture}`}
                />
                <Slider
                    max={10}
                    min={0}
                    value={Math.min(10, quantity)}
                    onChange={(ev, val) => onChange(val)}
                />
                <TextField
                    className={classes.countText}
                    inputProps={{
                        className: classes.input,
                        maxLength: 3,
                    }}
                    value={`${quantity}x`}
                    onChange={(event) => onChange(Number(event?.target?.value?.replace('x', '')))}
                    onKeyDown={(event) => {
                        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                            event.preventDefault();
                            onChange(Math.min(Math.max(quantity + (event.key === 'ArrowDown' ? -1 : 1), 1), 99));
                        }
                    }}
                />

            </div>
        </Card>
    );
};

export default ContainerCard;
