const styles = (theme) => ({
    answerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '12px',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '12px',
    },
    divider: {
        backgroundColor: '#707070',
        height: '13px',
        marginLeft: '9.5px',
        width: '1px',
    },
    historyItemContainer: {
        alignItems: 'start',
        display: 'flex',
        justifyContent: 'start',
    },
    historyItemIcon: {
        height: '21px',
        marginRight: '8px',
        opacity: 1,
        width: '21px',
    },
    historyItemMessage: {
        margin: '6.5px 0',
    },
    previousChangesButton: {
        marginLeft: '9px',
        textTransform: 'uppercase',
    },
    submitButton: {
        margin: '9px',
        textTransform: 'uppercase',
    },
    twoColumns: {
        color: theme.palette.secondary[600],
        display: 'grid',
        font: 'normal normal normal 16px/19px Roboto',
        gridGap: '6px',
        gridTemplateColumns: '1fr 1fr',
        letterSpacing: '0.49px',
        marginTop: '8px',
        opacity: 1,
        textAlign: 'left',
    },
});

export default styles;
