const styles = (theme) => ({
    auditButton: {
        margin: '9px',
        textTransform: 'uppercase',
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '31px',
    },
    rejectButton: {
        background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: '4px',
        color: theme.palette.primary[200],
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        opacity: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    row: {
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        font: 'normal normal normal 16px/19px Roboto',
        justifyContent: 'space-between',
        letterSpacing: '0.49px',
        marginTop: '6px',
        opacity: 1,
        textAlign: 'left',
    },
});

export default styles;
