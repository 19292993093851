import { AddressDTO, AddressLocation, DistanceOptions, Milestone } from 'shared-components/dataTypes';
import { nonNullKey } from 'shared-components/common';
import React from 'react';
import { compareLocations } from './objectDefaults';

export const getFlights = (steps: Milestone[]) => {
    const flightSteps = steps
        .map(step => [step?.pickupLocation, step?.deliveryLocation])
        .flat()
        .filter(address => nonNullKey(address) === 'airport');
    const normalizedSequence = [];

    flightSteps.forEach((flight, index) => {
        if (index === flightSteps.length - 1) {
            normalizedSequence.push(flight);
            return;
        }
        if (!compareLocations(flight, flightSteps[index + 1])) normalizedSequence.push(flight);
    });
    return normalizedSequence;
};

export const durationToSeconds = (durationString = '00:00') => {
    const [hours, minutes] = durationString.split(':').map(it => Number(it));

    return (hours * 60 * 60) + (minutes * 60);
};
export const durationToSecondsHM = (durationString = '00:00', t) => {
    const [hours, minutes] = durationString
        .replace(t('TIME.M'), '')
        .replace(t('TIME.H'), '')
        .split(' ')
        .map(it => Number(it));

    return (hours * 60 * 60) + (minutes * 60);
};
export const secondsToDurationString = (duration = 0) => {
    const durationMinutes = Math.floor(duration / 60);

    return `${(Math.min(Math.floor(durationMinutes / 60), 99)).toString().padStart(2, '0')}\
:${(durationMinutes % 60).toString().padStart(2, '0')}`;
};
export const secondsToDurationStringHHMM = (duration = 0, t) => {
    const durationMinutes = Math.floor(duration / 60);

    return `${(Math.min(Math.floor(durationMinutes / 60), 99)).toString()}${t('TIME.H')}\
 ${(durationMinutes % 60)}${t('TIME.M')}`;
};

export const addressToDTO: (arg: AddressLocation) => any = (addressLocation: AddressLocation) => {
    if (!addressLocation) return null;
    if (addressLocation.type === 'address') {
        return {
            address: addressLocation.manual,
        };
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { manual, ...locationData } = addressLocation;

        return {
            [addressLocation.type]: locationData,
        };
    }
};

export const getDistanceMatrixOptionsFromGeoData = (originGeoData, destinationGeoData) => {
    if (!originGeoData || !destinationGeoData) return null;

    const optionsObject: DistanceOptions = {
        destinations: [{
            lat: destinationGeoData.latitude,
            lng: destinationGeoData.longitude,
        }],
        origins: [{
            lat: originGeoData.latitude,
            lng: originGeoData.longitude,
        }],
        travelMode: 'DRIVING',
    };

    return optionsObject;
};
export const addressToRequestDTO: (arg: AddressLocation) => any = (addressLocation: AddressLocation) => {
    if (!addressLocation) return null;
    if (addressLocation.type === 'address') {
        return {
            address: addressLocation.manual,
        };
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { manual, ...locationData } = addressLocation;
        const typeMap = {
            airport: 'airportCode',
            pharmaSite: 'pharmaSiteId',
            seaport: 'seaportCode',
            serviceProviderSite: 'serviceProviderSiteId',
        };

        return {
            [typeMap[addressLocation.type]]: locationData?.code || locationData?.id,
        };
    }
};
export const dtoAddressToDTOObject: (arg: AddressDTO) => any = (addressDTO: AddressDTO) => {
    if (!addressDTO) return null;
    const type = nonNullKey(addressDTO);
    const typeMap = {
        airport: 'airportCode',
        pharmaSite: 'pharmaSiteId',
        seaport: 'seaportCode',
        serviceProviderSite: 'serviceProviderSiteId',
    };

    if (type === 'address') {
        return {
            address: {
                ...addressDTO.address,
                countryCode: addressDTO.address?.country?.code || addressDTO.address?.countryCode,
            },
        };
    }
    return {
        [typeMap[type]]: addressDTO[type]?.code || addressDTO[type]?.id,
    };
};

export const formTitle = (icon, label) => {
    return (
        <span
            style={{
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <img
                alt="road"
                src={icon}
                style={{
                    height: '25px',
                    marginRight: '5px',
                    width: '25px',
                }}
            />
            {' '}
            {label}
        </span>
    );
};

export const deg2rad = deg => deg * (Math.PI / 180);

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
};

export const toEnum = value => ({ code: value });

export const unwrapEnum = object => Object.keys(object).map(key => ({
    [key]: (object[key]?.code && object[key]?.label) ? object[key]?.code : object[key],
})).reduce((a, b) => ({ ...a, ...b }), {});

export const reorderElement = (array, source, destination) => {
    const result = [...array];
    const [removed] = result.splice(source, 1);

    result.splice(destination, 0, removed);

    return result;
};
