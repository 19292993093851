export const styles = () => ({
    buttonStyle: {
        textTransform: 'uppercase',
    },
    cardContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '12px',
    },

    loading: {
        filter: 'blur(1px)',
    },
});
