import { useLocation } from 'react-router-dom';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useCustomTitle from 'hooks/useCustomTitle';
import menuItems from 'shared-components/menuItems';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    getTitle,
    hasTabs,
} from './lib';

const useGetCurrentPageInfo = () => {
    const { company } = useCurrentUserContext();
    const { pathname } = useLocation();
    const { getTitle: getCustomTitle } = useCustomTitle();
    const { t } = useCustomTranslation();
    const pathParts = pathname.split('/');
    const [, mainMenu = '', subMenu = ''] = pathParts;

    if (mainMenu === 'notifications') {
        return {
            link: '/notifications',
            tabs: [],
            title: 'Notifications',
        };
    } else if (mainMenu === 'my-profile') {
        return {
            link: '/my-profile',
            tabs: [],
            title: 'My Profile',
        };
    } else if (mainMenu === 'sensor-data') {
        if (mainMenu === 'sensor-data' && subMenu === 'assets') {
            return {
                link: 'sensor-data/assets',
                tabs: [],
                title: 'Asset Details',
            };
        } else if (mainMenu === 'sensor-data' && subMenu === 'loggers') {
            return {
                link: 'sensor-data/loggers',
                tabs: [],
                title: 'Logger Details',
            };
        }
    } else if (mainMenu === 'administration' && subMenu === 'shipments') {
        return {
            link: 'administration/shipments',
            tabs: [],
            title: 'Shipments',
        };
    }

    const menuItem = menuItems(t).find((item) => item.link.replace(/\/ /, '') === mainMenu
        || (item?.customPagesMask && item.customPagesMask.test(pathname))) || null;

    // eslint-disable-next-line no-extra-boolean-cast
    const currentPageInfo = Boolean(menuItem?.children)
        ? menuItem.children.find((item) => item.link === `${mainMenu}/${subMenu}`) || menuItem
        : menuItem;

    const customPage = (currentPageInfo?.customPagesMask && currentPageInfo.customPagesMask.test(pathname)) || false;

    const title = getTitle(currentPageInfo?.pageTitle || currentPageInfo?.title, pathname, company.name);

    let tabs = [];

    let activeAddTabTitle = '';

    if (customPage && currentPageInfo?.customPagesTabs) {
        tabs = currentPageInfo.customPagesTabs
            .filter(it => !it.hide)
            .map((tabItem) => ({
                ...tabItem,
                activePage: pathParts.includes(tabItem.link),
                link: pathname.replace(/(?!.*\/).+/, tabItem.link),
            }));
    } else if (hasTabs(currentPageInfo, pathname)) {
        tabs = currentPageInfo.tabs
            .filter((tabItem) => !(tabItem.joinToPath && pathname.includes(tabItem.link)))
            .map((tabItem) => ({
                ...tabItem,
                activePage: pathParts.includes(tabItem.link),
                link: tabItem.joinToPath
                    ? `${pathname}/${tabItem.link}`
                    : pathname.replace(/(?!.*\/).+/, tabItem.link),
            }));
        const activeAddTab = currentPageInfo.tabs
            .find((tabItem) => tabItem.joinToPath && pathname.includes(tabItem.link)) || null;

        if (activeAddTab) {
            activeAddTabTitle = activeAddTab.title;
        }
    }

    return currentPageInfo
        ? {
            link: currentPageInfo.link,
            tabs,
            title: getCustomTitle() || activeAddTabTitle || title,
        }
        : null;
};

export default useGetCurrentPageInfo;
