import React, { SetStateAction, ReactElement } from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import NotificationCategoryWithCheckBox from './components/NotificationCategoryWithCheckBox';
import NotificationSubject from './components/NotificationSubject';
import { ProcessedNotification } from './lib';

const getTableColumns = (
    setSelectedIds: (prev: SetStateAction<number[]>) => void,
    setClickedRowNotification: (prev: SetStateAction<ProcessedNotification>) => void,
    theme: SkycellThemeInterface,
    controlHeader: ReactElement,
): ColumnsType[] => {
    return [
        {
            Header: controlHeader,
            accessor: (row: ProcessedNotification) => {
                const {
                    hasBeenDeleted,
                    hasBeenRead,
                    id,
                    isExpanded,
                    isSelected,
                    name,
                } = row;

                return (
                    <NotificationCategoryWithCheckBox
                        category={name}
                        hasBeenDeleted={hasBeenDeleted}
                        hasBeenRead={hasBeenRead}
                        id={id}
                        isExpanded={isExpanded}
                        isSelected={isSelected}
                        setClickedRowNotification={setClickedRowNotification}
                        setSelectedIds={setSelectedIds}
                    />
                );
            },
            customCellStyle: { paddingLeft: '12px', paddingRight: '24px', width: '20%' },
            disableSortBy: true,
            id: 'status',
        },
        {
            Header: '',
            accessor: (row: ProcessedNotification) => {
                return (
                    <NotificationSubject
                        notification={row}
                    />
                );
            },
            id: 'subject',
        },
        {
            Header: 'Date',
            accessor: ({ createdOnDisplay, hasBeenRead }: ProcessedNotification) => (
                <span style={{
                    color: theme.palette.common.black,
                    font: '14px Roboto',
                    fontWeight: hasBeenRead ? 'normal' : 'bold',
                    letterSpacing: '0.49px',
                    opacity: 1,
                }}
                >
                    {createdOnDisplay}
                </span>
            ),
            customCellStyle: { paddingRight: '20px', textAlign: 'right' },
            customHeaderStyle: { display: 'flex', justifyContent: 'flex-end', paddingRight: 0 },
            customWidth: '123px',
            id: 'createdOn',
            sortType(rowA, rowB) {
                return rowB.original.createdOn - rowA.original.createdOn;
            },
        },
    ];
};

export default getTableColumns;
