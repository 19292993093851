import React from 'react';
import useClasses from 'hooks/useClasses';
import { styles } from './SwitcherHorisontal.style';

type Props = {
    active: string,
    onChange?: (value: string) => void,
    options: string[],
    setActive: (value: string) => void
}

const SwitcherHorisontal = ({
    active,
    onChange = () => {},
    options,
    setActive,
}: Props) => {
    const classes = useClasses(styles);

    return (
        <div className={classes.wrapper}>
            {options.map((option) => (
                <div
                    key={option}
                    className={[classes.option, active === option ? classes.active : ''].join(' ')}
                    onClick={() => {
                        setActive(option);
                        onChange(option);
                    }}
                >
                    {option.toLowerCase()}
                </div>
            ))}
        </div>
    );
};

export default SwitcherHorisontal;
