import React, {
    CSSProperties, forwardRef,
    ReactNode, useCallback,
    useEffect, useRef,
} from 'react';
import {
    GoogleMap,
    useJsApiLoader,
} from '@react-google-maps/api';
import {
    LIBRARIES,
    MAP_CONFIG,
} from 'shared-components/constants';

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

type Props = {
    children?: ReactNode,
    disableDefaultUI?: boolean,
    disableDrag?: boolean,
    gMap: google.maps.Map,
    heading?: number,
    mapContainerStyle?: CSSProperties,
    mapId?: string,
    onClick?: () => void,
    onContainerRefLoad?: (ref: any) => void,
    setBounds: (bounds: number[]) => void,
    setGMap: (gMap: google.maps.Map) => void,
    setZoom?: (zoom: number) => void,
    tilt?: number
}

const BaseMap = forwardRef<HTMLDivElement, Props>(({
    children = null,
    disableDefaultUI = false,
    disableDrag = false,
    gMap = null,
    heading = 0,
    mapContainerStyle = MAP_CONFIG.containerStyle,
    mapId = 'c5af2a2e4049bce5',
    onClick = null,
    onContainerRefLoad = () => {},
    setBounds,
    setGMap,
    setZoom = null,
    tilt = 0,
} : Props, ref: any) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapKey,
        libraries: LIBRARIES,
        mapIds: [mapId],
    });

    const refCopy = useRef(null);

    useEffect(() => {
        if (ref?.current) {
            refCopy.current = ref.current;
        }
    }, [ref]);

    useEffect(() => {
        if (refCopy?.current && typeof onContainerRefLoad === 'function') {
            onContainerRefLoad(refCopy?.current);
        }
    }, [refCopy, onContainerRefLoad]);

    const handleLoad = (map: any) => {
        if (map && setGMap) {
            setGMap(map);
        }
    };

    const handleBoundsChanged = useCallback(() => {
        if (gMap) {
            const newBounds = gMap.getBounds();

            setBounds([
                newBounds.getSouthWest()
                    ?.lng(),
                newBounds.getSouthWest()
                    ?.lat(),
                newBounds.getNorthEast()
                    ?.lng(),
                newBounds.getNorthEast()
                    ?.lat(),
            ]);
        }
    }, [gMap, setBounds]);

    const handleZoomChanged = () => {
        if (gMap && setZoom) {
            setZoom(gMap.getZoom());
        }
    };

    return (
        <>
            {
                isLoaded
                    ? (
                        <GoogleMap
                            key="GoogleMap"
                            center={MAP_CONFIG.center}
                            heading={heading}
                            mapContainerStyle={mapContainerStyle}
                            options={{
                                clickableIcons: false,
                                disableDefaultUI,
                                gestureHandling: disableDrag ? 'none' : 'auto',
                                mapId,
                            }}
                            tilt={tilt}
                            zoom={MAP_CONFIG.zoom}
                            onBoundsChanged={handleBoundsChanged}
                            onClick={onClick}
                            onLoad={handleLoad}
                            onZoomChanged={handleZoomChanged}
                        >
                            <div
                                ref={ref} style={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                { children }
                            </div>
                            <></>
                        </GoogleMap>
                    )
                    : <></>
            }
        </>
    );
});

BaseMap.displayName = 'BaseMap';

export default React.memo(BaseMap);
