import { initialUserFiltersAdmin, initialUserFiltersUser } from 'Administration/lib';
import UserFilterPanel from 'Administration/Users/components/UserFilters';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    useCallback, useContext, useEffect,
    useMemo, useState,
} from 'react';
import { PageWithFilter } from 'Layout';
import { FacetDTO } from 'shared-components/dataTypes';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import Table from 'shared-components/Table';

import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from 'TrackAndTrace/Assets/Assets.style';
import StandalonePagination from 'shared-components/StandalonePagination';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import DataPaginationContext from 'Contexts/DataPaginationContext/DataPaginationContext';
import useFilter, { PAGES } from 'Contexts/useFilter';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { getQuickHelpAsset } from 'shared-components/icons';
import { getOptionsFromFacets } from 'shared-components/common';
import tableColumns from './tableColumns';

const PAGE_SIZE = 20;
const Users = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const isAdmin = hasAccess(userRoles.SKYCELL_ADMIN);
    const { getFilter, setFilter } = useFilter(PAGES.USERS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [users, setUsers] = useState([]);
    const [query, setQuery] = useState(filter?.query || '');
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>(`${isAdmin ? 'admin/' : ''}users/search/facets`,
        {
            cache: true,
            postProcess: data => (Array.isArray(data) ? data : [data]),
            query: {
                initial: true,
            },
        });
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions
        || isAdmin ? initialUserFiltersAdmin : initialUserFiltersUser);
    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>(`${isAdmin ? 'admin/' : ''}users/search/facets`,
        {
            cache: true,
            postProcess: data => (Array.isArray(data) ? data : [data]),
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawUsers, status: usersStatus,
    } = useGetCommonData<any>(`${isAdmin ? 'admin/' : ''}users/search`, {
        cache: true,
        enabled: facetsStatus === 'SUCCESS',
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (usersStatus === 'SUCCESS' && rawUsers?.resultList) {
            const allUsers = rawUsers?.resultList;

            setTotalElements(rawUsers.totalElements);
            setUsers(allUsers);
        }
    }, [usersStatus, rawUsers]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            sort,
            totalElements,
        });
    }, [filterOptions, page, query, sort, totalElements]);

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets && isAdmin) {
            setFilterOptions({
                companyNames:
                    filter?.filterOptions?.companyNames || getOptionsFromFacets(rawInitialFacets, 'companyNames'),
                userRoles: filter?.filterOptions?.userRoles || getOptionsFromFacets(rawInitialFacets, 'userRoles'),
            });
        }
    }, [rawInitialFacets, isAdmin]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const columns = useMemo(() => tableColumns(t, isAdmin), [isAdmin]);

    const { setHasOnboardingTour, setOnboardingTooltipInfo } = useContext(QuickHelpContext);

    const checkAccess = useMemo(() => () => hasAccess(userRoles.CUSTOMER_ADMIN), [hasAccess]);

    useEffect(() => {
        const allowed = checkAccess();

        if (allowed && !isAdmin) {
            setHasOnboardingTour(true);
            setOnboardingTooltipInfo({
                core: ONBOARDING_TYPE.USER,
                order: 3,
                padding: 0,
                position: 'auto',
                text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
                title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
                uid: 'supportTicketCreationQuickHelpCompany',
            });

            return () => {
                setHasOnboardingTour(false);
                setOnboardingTooltipInfo(null);
            };
        }
    }, [isAdmin, t, setHasOnboardingTour, setOnboardingTooltipInfo]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: usersStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <UserFilterPanel
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    initialFacets={rawInitialFacets}
                    isAdmin={isAdmin}
                    onboarding={{
                        core: ONBOARDING_TYPE.USER,
                        image: getQuickHelpAsset('my_company_users.gif'),
                        order: 1,
                        padding: 0,
                        positionDelta: [10, 0],
                        text: t('ONBOARDING.MY_COMPANY_USERS.FILTERS_DESC'),
                        title: t('ONBOARDING.MY_COMPANY_USERS.FILTERS_TITLE'),
                        uid: 'MyCompanyUsersTableFilterOnboarding',
                    }}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setQuery={setQuery}
                />
                <div style={{ display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >

                    <Table
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={users}
                        maskForHighlight={query}
                        rowLinkTemplate={`/${isAdmin ? 'administration' : 'my-company'}/users/:id`}
                        tableMaxHeight="100%"
                        title={t('MENU_ITEMS.USERS')}
                        onSort={updateSort}
                    />

                    <StandalonePagination detached entity={t('MENU_ITEMS.USERS')} />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Users;
