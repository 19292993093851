import { PackagingType } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetPackagingTypes = (postProcess = it => it): PackagingType[] => {
    const { GetAll: getPackagingTypes } = useSkymindBackendEndpoints('packaging-types').requests;

    const {
        data: packagingTypeList = [],
    } = useCachedQueryRequest({
        key: 'packaging-types',
        onCompleted: it => postProcess(it?.data || []),
        request: getPackagingTypes,
    });

    return packagingTypeList;
};

export default useGetPackagingTypes;
