import { css, keyframes } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const generateStyles = (theme: SkycellThemeInterface) => {
    const loading = keyframes({
        '0%': {
            backgroundPositionX: '-1000px',
        },
        '100%': {
            backgroundPositionX: '100px',
        },
    });

    const cell = css({
        '& .MuiFormControl-root': {
            width: '100%',
        },
        padding: '5px 5px 5px 12px!important',
    });
    const table = css({
        margin: '0px',
        paddingBottom: '4px',
    });

    const hcell = css({
        '& div': {
            paddingLeft: '0!important',
        },
        '&::after': {
            display: 'none',
        },

        color: `${theme.palette.common.black} !important`,
        display: 'none',
    });

    const noLocations = css({
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
    });

    const filledSelect = css({
        background: theme.palette.primary[50],
    });

    const icon = css({
        '&:hover': {
            color: theme.palette.secondary[800],
        },
        color: theme.palette.secondary[600],
    });

    const caret = css({
        color: theme.palette.primary[700],
        fontSize: '30px',
        marginRight: '5px',
    });

    const loadingSkeleton = css({
        animation: `${loading} 10s ease infinite alternate`,
        background: `linear-gradient(90deg, ${theme.palette.secondary[200]} 0%, ${theme.palette.secondary[400]} 25%,\
        ${theme.palette.secondary[200]} 50%, ${theme.palette.secondary[400]} 75%,\
        ${theme.palette.secondary[200]} 100%)`,
        borderRadius: '4px',
        height: '35px',
        width: '100%',
    });

    const button = css({
        textTransform: 'capitalize',
    });

    const tableDescription = css({
        alignItems: 'center',
        color: theme.palette.secondary[600],
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        gap: '20px',
        marginBottom: '18px',
        marginLeft: '12px',
        marginTop: '8px',
    });

    return {
        button,
        caret,
        cell,
        filledSelect,
        hcell,
        icon,
        loadingSkeleton,
        noLocations,
        table,
        tableDescription,
    };
};

export default generateStyles;
