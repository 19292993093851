import useClasses from 'hooks/useClasses';
import React, { useContext, useCallback, ChangeEvent } from 'react';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import MuiPagination from '@mui/material/Pagination';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from '../../Contexts/QuickHelpContext/QuickHelpTooltip';

const styles = (theme: SkycellThemeInterface) => ({
    label: {
        color: 'black',
        display: 'inline-flex',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        marginRight: '19.5px',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            flex: '100%',
            textAlign: 'center',
        },
    },
    labelBlue: {
        color: theme.palette.primary.deepBlue,
        display: 'inline-flex',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    muiPagination: {
        margin: '3px 0',
    },
    noPages: {
        fontSize: '14px',
        opacity: '0.6',
    },
    pagination: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 1px 3px #00000033',
        boxSizing: 'border-box',
        display: 'flex',
        height: '70px',
        justifyContent: 'center',
        minWidth: '320px',
        padding: '0 9px',
        [theme.breakpoints.down('sm')]: {
            flex: '1',
            flexWrap: 'wrap',
            height: 'auto',
            justifyContent: 'center',
            padding: '5px',
        },
    },
    root: {
        '& .Mui-selected.Mui-selected': {
            backgroundColor: `${theme.palette.primary.deepBlue}`,
            color: `${theme.palette.common.white}`,
        },
        '& .MuiPaginationItem-root': {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.primary.deepBlue}`,
            borderRadius: '0',
            color: theme.palette.primary.deepBlue,
            font: 'normal normal normal 14px/17px Roboto',
            height: '100%',
            margin: '3px 1.5px',
            padding: '0 5px',
            width: 'auto',
        },
    },
    shrink: {
        width: 'min-content',
    },
    wrap: {
        '& $label': {
            whiteSpace: 'nowrap',
        },
        '& ul': {
            flexWrap: 'nowrap',
        },
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            width: 'min-content',
        },
    },
    wrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        padding: '9px',
    },
});

type Props = {
    detached?: boolean,
    entity?: string,
    shrink?: boolean,
    tooltipInfo?: QuickHelpTooltipInfo,
    wrap?: boolean
}

const StandalonePagination = ({
    detached = false,
    entity = 'Assets',
    shrink = false,
    tooltipInfo = null,
    wrap = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const {
        page,
        paginationLoading,
        perPage,
        setPage,
        totalElements,
    } = useContext(DataPaginationContext);
    const classes = useClasses(styles);
    const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);

    const pagesCount = Math.ceil(totalElements / perPage);

    const infoLabelFromTo = ` ${(page - 1) * perPage + 1}
        to ${page * perPage < totalElements
        ? page * perPage : totalElements} `;
    const infoLabelTotalNumber = `of ${totalElements}`;

    return (
        <div className={`${detached ? classes.wrapper : ''} ${shrink && classes.shrink}`}>
            <QuickHelpTooltip tooltipInfo={tooltipInfo}>
                <div
                    aria-disabled className={[
                        classes.pagination,
                        wrap && classes.wrap,
                        pagesCount === 0 && classes.noPages,
                    ].join(' ')}
                >
                    {pagesCount > 0 && (
                        <>
                            <div className={classes.label}>
                                Showing
                                {' '}
                                {entity}
                                <div className={classes.labelBlue}>{infoLabelFromTo}</div>
                                {infoLabelTotalNumber}
                            </div>
                            <div className={classes.muiPagination}>
                                <MuiPagination
                                    classes={{ root: classes.root }}
                                    count={pagesCount}
                                    disabled={paginationLoading}
                                    inputMode="numeric"
                                    page={page || 1}
                                    shape="rounded"
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}
                    {
                        pagesCount === 0 && t('COMMON.NO_OPTIONS')
                    }
                </div>
            </QuickHelpTooltip>
        </div>

    );
};

export default StandalonePagination;
