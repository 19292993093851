export { default } from './PackagingBox';
((() => {
    try {
        const e = typeof window !== 'undefined'
            ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined'
                ? self : {};
        const n = (new Error()).stack;

        if (n) {
            e.sentryDebugIds = e.sentryDebugIds || {};
            e.sentryDebugIds[n] = '032cf8e9-2770-5f1d-a4db-aff641b70ef1';
        }
    } catch (e) {
        global.console.error(e);
    }
})());
// # debugId=032cf8e9-2770-5f1d-a4db-aff641b70ef1
