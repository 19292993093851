import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useParams } from 'react-router-dom';
import BackToLink from 'shared-components/BackToLink';
import useClasses from 'hooks/useClasses';
import DataCompleteCheck from 'Administration/Shipments/components/DataCompleteCheck';
import ShipmentAuthorizationCard from 'Administration/Shipments/components/ShipmentAuthorizationCard';
import ShipmentTimestamps from 'Administration/Shipments/components/ShipmentTimestamps';
import { PathParams } from 'Administration/Shipments/lib';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import { RequestStatus } from 'dataTypes/common';
import StatusInfo from 'shared-components/StatusInfo';
import styles from './ShipmentAdmin.style';

const ShipmentAdmin = () => {
    const { t } = useCustomTranslation();
    const { id } = useParams<PathParams>();
    const classes = useClasses(styles);

    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: '',
                link: `/administration/shipments/${id}`,
                title: t('MENU_ITEMS.DETAILS'),
            },
            {
                activePage: true,
                iconSrc: '',
                link: `/administration/shipments/${id}/admin`,
                title: t('MENU_ITEMS.ADMIN'),
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.SHIPMENTS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [id]);

    const { Get: getShipment } = useSkymindBackendEndpoints('admin/v2/shipments').requests;
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [shipment, setShipment] = useState<ShipmentDetailsInterface>(null);

    useEffect(() => {
        (async () => {
            try {
                setStatus('PENDING');
                const response = (await getShipment(id)).data;

                if (response) {
                    setShipment(response);
                    setStatus('SUCCESS');
                }
            } catch (error) {
                setStatus('FAILURE');
                global.console.log(error);
            }
        })();
    }, []);

    return status === 'SUCCESS'
        ? (
            <>
                <BackToLink isGeneric to="/administration/shipments" />
                <div className={classes.wrapper}>
                    <div className={classes.cardContentData}>
                        <ShipmentAuthorizationCard loading={false} shipment={shipment} />
                    </div>
                    <div className={classes.cardContentData}>
                        <DataCompleteCheck
                            externalId={shipment.externalId}
                            isNotClosed={shipment.status !== 'CLOSED'}
                        />
                    </div>
                    <div className={classes.cardContentData}>
                        <ShipmentTimestamps
                            shipment={shipment}
                        />
                    </div>
                </div>
            </>
        )
        : <StatusInfo status={status} />;
};

export default ShipmentAdmin;
