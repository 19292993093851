import { TFunction } from 'i18next';
import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import EnergyLevel from 'shared-components/EnergyLevel';

const tableColumns: (
    t:TFunction, trackAndTrace: boolean
) => ColumnsType<ExtractedLoggerData>[] = (t, trackAndTrace) => [
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
    {
        Header: t('COMMON.TYPE'),
        accessor: 'loggerFamilyLabel',
        dataType: 'highlightedText',
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: 'lastMeasuredTempStr',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => <LastConnect timestampUnit={row.lastMeasuredTimestampUnit} />,
        dataType: 'custom',
    },
    {
        Header: t('COMMON.BATTERY'),
        accessor: (row) => (
            (
                row.batteryLevel >= 0 && row.batteryLevel <= 100 && row.batteryLevel !== null ? (
                    <EnergyLevel
                        energyLevel={Number(((row.batteryLevel) * 100).toFixed(0))}
                        threshold
                    />
                ) : ''
            )),
        customCellStyle: {
            alignItems: 'center',
            maxWidth: '230px',
            whiteSpace: 'pre-wrap',
        },
        dataType: 'custom',
        id: 'batteryLevel',
    },
    ...(!trackAndTrace ? [{
        Header: t('COMMON.PAIRING_STATUS'),
        accessor: (logger) => t(`PAIRING_STATUS.${logger?.pairingStatus}`),
        dataType: 'text',
        id: 'pairingStatus',
    }] : []),
];

export default tableColumns;
