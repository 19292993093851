export const styles = () => ({
    base: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    image: {
        height: '40px',
        marginRight: '8px',
        width: '40px',
    },
});
