import React, { MouseEventHandler } from 'react';
import { LatLng } from 'dataTypes/common';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { getCenteredPositionOffset, MapElement } from 'TrackAndTrace/Assets/lib';

type Props = {
    isSelected: boolean,
    mapElements: MapElement[],
    onHover: MouseEventHandler<HTMLDivElement>,
    position: LatLng,
}
const GroupMarker = ({
    isSelected = false,
    mapElements = [],
    onHover,
    position,
}: Props) => {
    const count = mapElements.reduce((acc, cur) => acc + cur.count, 0);

    return (
        <OverlayViewF
            getPixelPositionOffset={getCenteredPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={position}
            zIndex={isSelected ? 1000 : 5}
        >
            <div onClick={onHover} onMouseEnter={onHover}>
                <PolygonMarker
                    count={count}
                    filled={isSelected}
                    iconDiameter={35}
                    polygonIconColor="blue"
                    polygonIconType="hexa"
                    theme="light"
                />
            </div>
        </OverlayViewF>
    );
};

export default GroupMarker;
