/* eslint-disable react/jsx-key */
import useClasses from 'hooks/useClasses';
import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SelectField from 'shared-components/SelectField';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import generateStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from './PackagingsTable.style';
import { PackagingType } from '../../TemperatureSimulation';
import tableColumns from '../Packaging/tableColumns';
import PackagingTypeRow from './PackagingTypeRow';
import IconTooltip from '../IconTooltip';

const PackagingsTable = () => {
    const classes = useClasses(styles);
    const rootClasses = useGeneratedStyles(generateStyles);
    const { t } = useCustomTranslation();

    const [edit, setEdit] = useState(false);
    // const [cardHover, setCardHover] = useState(false);
    const [rowAddInitiated, setRowAddInitiated] = useState<boolean>(false);
    const {
        addPackagingType,
        deletePackagingType,
        hasError,
        lane,
        loading,
        packagingTypes,
    } = useContext(LaneTemperatureContext);

    useEffect(() => {
        setRowAddInitiated(false);
    }, [lane?.packagingTypes]);
    const selectedPackagingTypeCodes = useMemo(() => {
        return lane?.packagingTypes;
    }, [lane]);

    const availablePackagingTypes = useMemo<PackagingType[]>(() => {
        return packagingTypes?.filter(pt => !lane.packagingTypes.map(it => it.code).includes(pt.type));
    }, [lane, packagingTypes]);

    useEffect(() => {
        if (hasError) {
            setEdit(false);
        }
    }, [hasError]);

    const rows = useMemo(() => {
        return selectedPackagingTypeCodes
            ?.map(spt => {
                const pt = packagingTypes?.find(pt => pt.type === spt.code);

                if (!pt) return null;
                return {
                    ...pt,
                    isImmutable: spt.isImmutable,
                };
            })
            ?.filter(it => it)
            ?.map((pt) => {
                return (
                    <PackagingTypeRow
                        key={pt.type}
                        classes={classes}
                        edit={edit}
                        packagingType={pt}
                        onDelete={deletePackagingType}
                    />
                );
            });
    }, [
        packagingTypes,
        edit,
    ]);

    const handleClickSaveButton = () => setEdit(false);
    const handleClickEditButton = () => setEdit(true);

    return (
        <div>
            <CardWithEditableContent
                className={[rootClasses.nonPaddedCard, loading ? rootClasses.loading : ''].join(' ')}
                fullHeight
                showEditButton={!edit}
                showSaveButton={edit}
                showUndoButton={edit}
                style={{
                    minWidth: '0px',
                }}
                title={t('COMMON.PACKAGING')}
                titleClass={rootClasses.customPad}
                onClickEditButton={handleClickEditButton}
                onClickSaveButton={handleClickSaveButton}
                onClickUndoButton={handleClickSaveButton}
            >
                <div className={rootClasses.cardBody}>
                    <TableContainer>
                        <Table className={classes.productsTable}>
                            <TableHead>
                                <TableRow className={classes.productsTableRow}>
                                    {
                                        tableColumns({}, t).map((field, index) => (
                                            <TableCell key={`header_${field.Header}_${index}`}>
                                                {field.Header}
                                            </TableCell>
                                        ))
                                    }
                                    <TableCell style={{ width: '75px' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows
                                }
                                {
                                    rowAddInitiated && edit && (
                                        <TableRow
                                            className={classes.productsTableRow}
                                        >
                                            <TableCell>
                                                <SelectField
                                                    className={classes.selectShade}
                                                    id="addProductSelector"
                                                    items={availablePackagingTypes
                                                        .map(product => ({
                                                            render: `${product.temperatureSpecificName}`,
                                                            value: product.type,
                                                        }))}
                                                    onChange={addPackagingType}
                                                />
                                            </TableCell>
                                            {
                                                tableColumns(null, t).map(() => <TableCell />)
                                            }
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        edit && (
                            <div className={classes.plusWrapper}>
                                <div
                                    className={[
                                        classes.plus,
                                        classes.icon,
                                        availablePackagingTypes.length === 0 ? classes.disabledIcon : '',
                                    ].join(' ')}
                                    onClick={() => {
                                        if (availablePackagingTypes.length === 0) return;
                                        setRowAddInitiated(true);
                                    }}
                                >
                                    {
                                        availablePackagingTypes.length === 0
                                            ? (
                                                <IconTooltip
                                                    icon={<AddCircle />}
                                                    text={t('LANE_MANAGEMENT.NO_AVAILABLE_PACKAGING_TYPES')}
                                                />
                                            )
                                            : <AddCircle />
                                    }
                                </div>
                            </div>
                        )
                    }
                    <span className={classes.footerText}>
                        {t('LANE_MANAGEMENT.RESERVE_TEXT')}
                    </span>
                </div>
            </CardWithEditableContent>
        </div>

    );
};

export default PackagingsTable;
