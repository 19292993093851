import React, { useEffect, useState } from 'react';
import { OverlayView, PolygonF, PolylineF, OverlayViewF } from '@react-google-maps/api';

const CustomPolygon = ({
    isNameVisible = false,
    map,
    name,
    paths,
    position,
    systemGeofence = false,
}) => {
    const [showPolygon, setShowPolygon] = useState(false);

    useEffect(() => {
        if (map) {
            const listener = map.addListener('zoom_changed', () => {
                const zoom = map.getZoom();

                setShowPolygon(zoom >= 10);
            });

            return () => {
                google.maps.event.removeListener(listener);
            };
        }
    }, [map]);

    const polylineOptions = {
        icons: [{
            icon: {
                path: 'M 0,-1 0,1',
                scale: 3,
                strokeOpacity: 1,
            },
            offset: '0',
            repeat: '20px',
        }],
        strokeColor: systemGeofence ? '#707070' : '#FFC30B',
        strokeOpacity: 0,
        strokeWeight: 0.5,
    };

    const polygonOptions = {
        fillColor: systemGeofence ? '' : '#FFC30B',
        fillOpacity: systemGeofence ? 0 : 0.15,
        strokeColor: '#707070',
        strokeOpacity: systemGeofence ? 0 : 0.4,
        strokeWeight: 1,
    };

    return (
        <div>
            {showPolygon && (
                <PolygonF
                    options={polygonOptions}
                    paths={paths}
                />
            )}
            {systemGeofence && showPolygon && paths.map((point, index) => (
                <PolylineF
                    key={index}
                    options={polylineOptions}
                    path={[point, paths[(index + 1) % paths.length]]}
                />
            ))}
            <OverlayViewF
                mapPaneName={OverlayView.OVERLAY_LAYER}
                position={position}
            >
                <div style={{
                    marginTop: systemGeofence ? 10 : 0,
                    visibility: isNameVisible ? 'visible' : 'hidden',
                }}
                >
                    {name}
                </div>
            </OverlayViewF>
        </div>
    );
};

export default CustomPolygon;
