import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useCallback, useEffect } from 'react';
import { TextField, Select, MenuItem, useTheme } from '@mui/material';
import LoggerSelector from 'SensorDataCommon/hooks/useEditablePairingsColumns/LoggerSelector';
import DateTimePickerWithFlush from 'shared-components/DateTimePickerWithFlush';
import { Edit, Undo, Save } from '@mui/icons-material';
import { Pairing } from 'SensorDataCommon/hooks/useEditablePairingsColumns/useEditablePairingsColumns';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';
import { css } from '@emotion/css';

export type ValidationCheck = {
    field: string,
    message: string
}
export type FieldDefinition = {
    accessor?: (original: any) => any,
    editable?: boolean | ((editedItem: any) => boolean),
    flushable?: boolean,
    Header: string,
    id: string,
    mandatory?: boolean | ((editedItem: any) => boolean),
    mask?: string,
    maskChars?: { [key: number | string]: string },
    minWidth?: string | number,
    noBar?: boolean,
    onKeyDown?: (ev, onChange, restrictInput) => void,
    options?: {
        label: string,
        value: string
    }[],
    placeholder?: string,
    /**
     * restrictInput - Restricts the input based on input integrity.
     * @param newValue - The new value the user has inputted.
     * @returns The new value if it has passed the integrity test, otherwise returns the old value.
     */
    restrictInput?: (newValue) => boolean,
    type: string,
    validation?: (editedItem: any) => string | null
}

const generateStyles = theme => {
    const flushIcon = css({
        '&:hover': {
            color: theme.palette.secondary[500],
            cursor: 'pointer',
        },
        color: theme.palette.secondary[400],
        fontSize: '20px',
        opacity: 0,
        transition: '200ms ease',
    });

    const icon = css({
        '&:hover': {
            color: theme.palette.secondary[800],
            cursor: 'pointer',
        },
        color: theme.palette.secondary[600],
    });

    const invalid = css({
        cursor: 'not-allowed !important',
        opacity: 0.5,
    });

    const invalidField = css({
        '& fieldset': {
            borderColor: `${theme.palette.common.red} !important`,
        },
    });

    const input = css({
        '& input': {
            fontSize: '14px',
            padding: '5px',
            textOverflow: 'ellipsis',
        },
        width: '90%',
        [`&:focus-within .${flushIcon}`]: {
            opacity: 1,
        },
    });

    const autoCompleteInput = css({
        '& .MuiInputBase-input': {
            height: '29px',
        },
        '& input': {
            padding: '5px',
        },
        padding: '5px !important',
    });

    const autoCompleteInputRoot = css({
        padding: '0 !important',
        textOverflow: 'ellipsis',
        width: '100%',
    });

    const autoCompleteRoot = css({
        width: '90%',
        [`&:focus-within .${flushIcon}`]: {
            opacity: 1,
        },
        [`& .${flushIcon}`]: {
            marginRight: '14px',
        },
    });

    const select = css({
        '& .MuiInputBase-input': {
            padding: '5px 20px 5px 5px',
        },
        fontSize: '14px',
        width: '90%',
    });

    const flushIconShift = css({
        transform: 'translateX(-14px)',
        [theme.breakpoints.down('md')]: {
            transform: 'translateX(-2px)',
        },
        [theme.breakpoints.down('lg')]: {
            transform: 'translateX(-3px)',
        },
    });

    const mandatory = css({
        '&::after': {
            color: theme.palette.common.red,
            content: '"*"',
            position: 'absolute',
            right: '-10px',
            top: '0',
        },
        position: 'relative',
    });

    const adornedEnd = css({
        paddingRight: '0 !important',
    });

    return {
        adornedEnd,
        autoCompleteInput,
        autoCompleteInputRoot,
        autoCompleteRoot,
        flushIcon,
        flushIconShift,
        icon,
        input,
        invalid,
        invalidField,
        mandatory,
        select,
    };
};

type Props = {
    editedItem?: any,
    elementId?: string | number,
    hoveredItem?: any,
    isAdmin?: boolean,
    onSave?: (arg) => void,
    predefinedColumns?: FieldDefinition[],
    setEditedItem?: (arg: any) => void,
    validationCheck?: ValidationCheck[]
}

const useEditableTableColumns: (props: Props) => ColumnsType[] = ({
    editedItem,
    elementId,
    hoveredItem,
    isAdmin,
    onSave = () => {},
    predefinedColumns = [],
    setEditedItem,
    validationCheck = [],
}) => {
    const classes = useGeneratedStyles(generateStyles);
    const theme = useTheme<SkycellThemeInterface>();
    const [fieldFocused, setFieldFocused] = React.useState({});

    const onFocus = useCallback((field) => {
        setFieldFocused(prev => ({ ...prev, [field]: true }));
    }, []);

    useEffect(() => {
        setFieldFocused({});
    }, []);

    const onSaveCallback = useCallback(() => {
        onSave(editedItem);
        setFieldFocused({});
    }, [editedItem]);
    const onUndoCallback = useCallback(() => {
        setEditedItem(null);
        setFieldFocused({});
    }, []);

    return [
        ...predefinedColumns.map((field) => {
            const validationMessage = validationCheck.find(vCheck => vCheck.field === field.id)?.message;
            const invalid = !!validationMessage;

            return {
                Header: field.Header,
                accessor: (original: Pairing) => {
                    const isBeingEdited = original.id === editedItem?.id;
                    const {
                        editable: editableCheck = true,
                        flushable,
                        Header,
                        mandatory: mandatoryCheck = false,
                        options,
                        placeholder,
                    } = field;
                    const onChange = (value: any) => {
                        setEditedItem(prev => ({
                            ...prev,
                            [field.id]: value,
                        }));
                    };
                    const key = `${elementId}_${original.id}_${Header}`;
                    const mandatory = typeof mandatoryCheck === 'function'
                        ? mandatoryCheck(editedItem) : mandatoryCheck;
                    const editable = typeof editableCheck === 'function'
                        ? editableCheck(editedItem) : editableCheck;
                    const tooltipIsOpen = fieldFocused[field.id] || false;

                    if (editable !== false && isBeingEdited) {
                        switch (isAdmin ? 'text' : field.type) {
                        case 'text':
                            return (
                                <CommonTooltip
                                    key={key}
                                    borderColor="red"
                                    description={validationMessage}
                                    enabled={invalid}
                                    open={tooltipIsOpen}
                                    placement="top"
                                >
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        className={[
                                            classes.input,
                                            invalid ? classes.invalidField : '',
                                            mandatory ? classes.mandatory : '',
                                        ].join(' ')}
                                        id={key}
                                        placeholder={placeholder || ''}
                                        style={{
                                            minWidth: field.minWidth || 'unset',
                                        }}
                                        value={editedItem[field.id] || ''}
                                        variant="outlined"
                                        onChange={(e) => onChange(e.target.value)}
                                        onFocus={() => onFocus(field.id)}
                                    />
                                </CommonTooltip>
                            );
                        case 'select':
                            return (

                                <CommonTooltip
                                    key={key}
                                    borderColor="red"
                                    description={validationMessage}
                                    enabled={invalid}
                                    open={tooltipIsOpen}
                                    placement="top"
                                >
                                    <Select
                                        key={key}
                                        className={[
                                            classes.select,
                                            invalid ? classes.invalidField : '',
                                            mandatory ? classes.mandatory : '',
                                        ].join(' ')}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        renderValue={(value) => {
                                            const optionText = options
                                                .find(it => it.value === value)?.label || placeholder;

                                            return (
                                                <span
                                                    style={{
                                                        color: !editedItem?.[field.id]
                                                            ? theme.palette.secondary[400] : 'unset',
                                                    }}
                                                    title={optionText}
                                                >
                                                    {optionText}
                                                </span>
                                            );
                                        }}
                                        value={editedItem[field.id] || ''}
                                        variant="outlined"
                                        onChange={(ev) => onChange(ev.target.value)}
                                        onFocus={() => onFocus(field.id)}
                                    >
                                        <MenuItem value="">{placeholder || ''}</MenuItem>
                                        {options.map((it) => (
                                            <MenuItem
                                                key={`${field.id}_${it.value}`}
                                                value={it.value}
                                            >
                                                {it.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </CommonTooltip>

                            );
                        case 'datetime':
                            return (
                                <CommonTooltip
                                    key={key}
                                    borderColor="red"
                                    description={validationMessage}
                                    enabled={invalid}
                                    open={tooltipIsOpen}
                                    placement="top"
                                >
                                    <div
                                        key={key}
                                    >
                                        <DateTimePickerWithFlush
                                            key={`${key}_picker`}
                                            classes={classes}
                                            flushable={flushable}
                                            invalid={invalid}
                                            mandatory={mandatory}
                                            minWidth={field.minWidth}
                                            placeholder={placeholder || ''}
                                            value={editedItem?.[field.id] || null}
                                            onChange={onChange}
                                            onFocus={() => onFocus(field.id)}
                                        />

                                    </div>
                                </CommonTooltip>

                            );
                        case 'loggerNumber':
                            return (
                                <CommonTooltip
                                    key={key}
                                    borderColor="red"
                                    description={validationMessage}
                                    enabled
                                    open={invalid && tooltipIsOpen}
                                    placement="top"
                                >
                                    <LoggerSelector
                                        classes={classes}
                                        editedItem={editedItem}
                                        field={field}
                                        flushable={flushable}
                                        invalid={invalid}
                                        mandatory={mandatory}
                                        placeholder={placeholder}
                                        onChange={onChange}
                                        onFocus={onFocus}
                                    />
                                </CommonTooltip>

                            );
                        }
                    } else {
                        return (
                            <>
                                {field.accessor(original)}
                            </>
                        );
                    }
                },
                noBar: field.noBar,
                type: 'custom',
            };
        }),
        {
            Header: ' ',
            accessor: (row) => {
                const isBeingEdited = row.id === editedItem?.id;
                const isHovered = hoveredItem?.id === row.id;
                const isValid = validationCheck.length === 0;

                return (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '20px',
                        justifyContent: 'flex-end',
                        width: '80px',
                    }}
                    >
                        {
                            isHovered && !isBeingEdited && (
                                <Edit
                                    className={[
                                        classes.icon,
                                        editedItem ? classes.invalid : '',
                                    ].join(' ')}
                                    onClick={editedItem ? null : () => setEditedItem(row)}
                                />
                            )
                        }
                        {
                            isBeingEdited && (
                                <>
                                    <Undo
                                        className={classes.icon}
                                        onClick={onUndoCallback}
                                    />
                                    <Save
                                        className={[
                                            classes.icon,
                                            !isValid ? classes.invalid : '',
                                        ].join(' ')}
                                        onClick={isValid ? onSaveCallback : null}
                                    />
                                </>
                            )
                        }
                    </div>
                );
            },
            type: 'custom',
        },
    ];
};

export default useEditableTableColumns;
