import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from 'shared-components/CommonButton';
import generateStyles from '../BatteryExchangeLocationsCard/BatteryExchangeLocationsCard.styles';

const NoLocationsDefined = ({
    description = '',
    onSearch = () => {},
    title = '',
}) => {
    const classes = useGeneratedStyles(generateStyles);
    const { t } = useTranslation();

    return (
        <div
            className={classes.noLocations} style={{
                minHeight: '200px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '50px',
            }}
        >
            <div style={{
                fontSize: '18px',
                textAlign: 'center',
            }}
            >
                {title || t('MY_COMPANY.NO_LOCATIONS_DEFINED')}
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                gap: '20px',
                maxWidth: '300px',
                textAlign: 'center',
            }}
            >
                {description || t('MY_COMPANY.NO_LOCATIONS_DEFINED_DESC')}
                <CommonButton
                    className={classes.button}
                    onClick={onSearch}
                >
                    {t('COMMON.SEARCH')}
                </CommonButton>
            </div>
        </div>
    );
};

export default NoLocationsDefined;
