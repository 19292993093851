import React, { ReactElement, ReactNode } from 'react';
import CustomTitle from 'shared-components/CommonTooltip/CustomTitle';
import CommonTooltip from 'shared-components/CommonTooltip/CommonTooltip';
import icons from 'shared-components/icons';

type Props = {
    children?: ReactElement,
    description: string | ReactNode,
    descriptionTitle?: string,
}

const OptionItemTooltip = ({
    children = null,
    description,
    descriptionTitle = null,
} : Props) => {
    return (
        <CommonTooltip
            description={(
                <CustomTitle
                    description={description}
                    descriptionTitle={descriptionTitle}
                    iconSrc={icons.info_blue}
                />
            )}
            placement="right-start"
        >
            { children }
        </CommonTooltip>
    );
};

export default OptionItemTooltip;
