import React, {
    useEffect,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';

import useClasses from 'hooks/useClasses';
import styles from '../GoogleAddress.style';

const latLimits = {
    max: 90.0,
    min: -90.0,
};
const lngLimits = {
    max: 180.0,
    min: -180.0,
};

type Props = {
    setValue: (value: number) => void,
    type: 'Latitude' | 'Longitude',
    value: number,
}

const Coordinates = ({
    setValue,
    type,
    value = null,
} : Props) => {
    const classes = useClasses(styles);
    const [fieldValue, setFieldValue] = useState((value && value.toFixed(3)) || '');
    const [changedFieldValue, setChangedFieldValue] = useState(false);
    const limits = type === 'Latitude' ? latLimits : lngLimits;

    useEffect(() => {
        if (value !== null) {
            if (changedFieldValue) {
                setChangedFieldValue(false);
            } else {
                setFieldValue(value.toFixed(3));
            }
        } else {
            setFieldValue('');
        }
    }, [value]);

    const handleCahnge = (e) => {
        const floatValue = parseFloat(e.target.value);

        if (floatValue >= limits.min && floatValue <= limits.max) {
            setFieldValue(e.target.value);
            setValue(floatValue);
            setChangedFieldValue(true);
        }
    };

    return (
        <div>
            <div className={classes.coordinatesFieldTitle}>{ type }</div>
            <TextField
                InputProps={{ inputProps: { max: limits.max, min: limits.min } }}
                className={`${classes[`textField${type}`]} ${classes.mandatory}`}
                inputProps={{
                    className: classes.textFieldText,
                }}
                size="small"
                type="number"
                value={fieldValue}
                variant="standard"
                onChange={handleCahnge}
                // label={type}
            />
        </div>
    );
};

export default Coordinates;
