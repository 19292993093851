import React, { useRef, useMemo } from 'react';
import Card from 'shared-components/Card';
import AmbientTemperatureChart
    from 'LaneManagement/Lanes/tabs/TemperatureSimulation/components/AmbientTemperatureChart';
import TransportCard from 'LaneManagement/LaneDetails/Cards/TransportCard';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LaneObject } from 'shared-components/dataTypes';
import useClasses from 'hooks/useClasses';
import styles from '../../TemperatureSimulation.style';

type Props = {
    lane: LaneObject,
    type: 'summer' | 'winter'
}
const RELATIVE_PADDING = 16;
const AmbientTemperatureCard = ({
    lane,
    type,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const chartRef = useRef(null);
    const cardRef = useRef(null);

    const chartParent = useMemo<HTMLElement>(() => {
        if (cardRef.current) {
            return cardRef.current;
        }
    }, [cardRef.current]);

    const chartPlotArea = useMemo<HTMLElement>(() => {
        if (chartRef?.current && chartParent) {
            return chartParent.querySelector('.apexcharts-grid-borders');
        }
    }, [chartRef?.current, chartParent]);

    // Please, don't be mad about this one. Sometimes life makes us do terrible things.
    const paddingOffset = useMemo<[number, number]>(() => {
        if (chartParent && chartPlotArea) {
            const parentBox:DOMRect = chartParent.getBoundingClientRect();
            const plotBox:DOMRect = chartPlotArea.getBoundingClientRect();

            return [
                Math.abs(plotBox.left - parentBox.left - RELATIVE_PADDING),
                Math.abs(RELATIVE_PADDING - (parentBox.right - plotBox.right)),
            ];
        } else {
            return [0, 0];
        }
    }, [chartParent, chartPlotArea]);

    return (
        <div ref={cardRef} style={{ height: '100%' }}>
            <Card title={t('LANE_MANAGEMENT.AMBIENT_TEMPERATURE_IN_C')}>
                {
                    lane && <AmbientTemperatureChart chartRef={chartRef} type={type} />
                }
                <TransportCard
                    className={classes.transportEmbedded}
                    offsetPadding={paddingOffset}
                    shrink
                    stepsRaw={lane?.milestones?.sort((a, b) => (a.index > b.index ? 1 : -1))}
                />
            </Card>

        </div>
    );
};

export default AmbientTemperatureCard;
