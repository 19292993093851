const styles = (theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        // color: theme.palette.common.black,
    },
    picture: {
        background: 'transparent 0% 0% no-repeat padding-box',
        margin: 'auto',
        maxWidth: '100%',
        opacity: 1,
    },
    tempRangeIcon: {
        height: '19px',
        margin: '0 5px',
    },
    tempRangeLabel: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        opacity: 1,
        textAlign: 'left',
    },
});

export default styles;
