export const styles = (theme) => ({
    closer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        padding: '12px 12px',
    },
    document: {
        '& #proxy-renderer': {
            overflow: 'hidden',
        },
        height: '100%',
    },
    drawer: {
        height: '100vh',
        padding: '46px 0 8px 0',
        position: 'fixed',
        right: '0px',
        top: '0px',
        transition: 'width 200ms ease',
        width: '0',
        zIndex: 10005,

    },
    drawerCard: {
        background: theme.palette.common.white,
        borderRadius: '10px 0px 0px 10px',
        boxShadow: '0px 3px 6px #00000059;',
        height: '100%',
        overflow: 'hidden',
    },
    drawerCardKnBase: {
        borderRadius: '10px 10px 10px 10px',
    },
    drawerContents: {
        '& p': {
            color: theme.palette.secondary[600],
        },
        /* '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            height: '6px',
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            '&::WebkitScrollbarShadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            '&::WebkitScrollbarShadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
        }, */
        height: 'calc( 100% - 64px )',
        overflowY: 'auto',
        padding: '12px 36px',

    },
    drawerContentsKnBase: {
        height: '100%',
        overflowY: 'hidden',
        padding: 0,
    },
    visible: {
        padding: '46px 0 8px 8px',
        width: '50vw',
    },
    visibleKnBase: {
        paddingRight: '6px',
        paddingTop: '6px',
        width: '70vw',
    },
    x: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        userSelect: 'none',
    },
    xKnBase: {
        color: theme.palette.common.white,
        display: 'none',
        position: 'absolute',
        right: '23px',
        top: '23px',
    },
    xKnBaseShown: {
        display: 'block',
    },
});
