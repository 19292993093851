import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import CommonButton from 'shared-components/CommonButton';
import {
    extractOnlyNotNullFields,
    isReadyAddUserRequestBody,
} from 'Administration/lib';
import UserInfoCard from 'Administration/Users/components/UserInfoCard';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import styles from './AddUser.style';

const AddUser = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const { company } = useCurrentUserContext();
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const {
        FlexibleRequest: createUser,
    } = useSkymindBackendEndpoints(
        isAdmin ? 'admin/companies' : 'companies',
        { customSuccessMessage: t('COMMON.USER_CREATED') },
    ).statusNotificationRequests;
    const [companyId, setCompanyId] = useState(company?.id);
    const [userInfo, setUserInfo] = useState<AddUserInfo>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const [requestBody, setRequestBody] = useState({});
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const [companyRoles, setCompanyRoles] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const handleClickCreate = async () => {
        setLoading(true);
        const result = await createUser('POST', `/${companyId}/user`, requestBody);

        setLoading(false);

        if (result) {
            navigate(isAdmin ? '/administration/users' : '/my-company/users');
        }
    };

    const handleClickCancel = () => {
        navigate(isAdmin ? '/administration/users' : '/my-company/users');
    };

    useEffect(() => {
        if (userInfo !== null) {
            setRequestBody({ ...extractOnlyNotNullFields(userInfo), roles });
        }
    }, [roles, userInfo]);

    useEffect(() => {
        setReadyToSubmission(isReadyAddUserRequestBody(userInfo));
    }, [userInfo]);

    useEffect(() => {
        setRoles([]);
    }, [companyId]);

    return (
        <>
            <div className={classes.pageContent}>
                <UserInfoCard
                    admin={isAdmin}
                    currentCompany={company}
                    setCompanyId={setCompanyId}
                    setCompanyRoles={setCompanyRoles}
                    setUserInfo={setUserInfo}
                    type="new"
                />
                <CompanyRolesCard
                    availableRoles={companyRoles}
                    roles={roles}
                    setRoles={setRoles}
                    type="new"
                />
            </div>
            <div className={classes.pageContentButtons}>
                <div className={classes.buttonContainer}>
                    <CommonButton
                        autoFocus
                        className={classes.approveButton}
                        disabled={!readyToSubmission || loading}
                        loading={loading}
                        onClick={handleClickCreate}
                    >
                        {t('MY_COMPANY.CREATE')}
                    </CommonButton>
                    <CommonButton
                        className={classes.rejectButton}
                        disabled={loading}
                        variant="outlined"
                        onClick={handleClickCancel}
                    >
                        {t('COMMON.CANCEL')}
                    </CommonButton>
                </div>
            </div>
        </>
    );
};

export default AddUser;
