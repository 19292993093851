import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetGateways = (postProcess = it => it): GatewayInfoDTO[] => {
    const { GetAll: getGateways } = useSkymindBackendEndpoints('gateways').requests;

    const {
        data: gatewayList = [],
    } = useCachedQueryRequest({
        key: 'gateways',
        onCompleted: it => postProcess(it?.data || []),
        request: () => getGateways({}),
    });

    return gatewayList;
};

export default useGetGateways;
