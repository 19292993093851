import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { getFormattedDateUtc, getFormattedDateUtcToLocal } from 'utils/timeUtils';
import getTooltipAsHTML from './CustomMapHintTooltip/CustomMapHintTooltip';

type TooltipOptions = {
    dataPointIndex: number;
    series: any[];
    seriesIndex: number;
    w: {
        config: any,
        globals: {
            initialSeries: {
                color: string,
                data: any[],
                name: string,
                opacity: number,
                showValueInTooltip: boolean,
                type: string
            }[],
            seriesColors: any[],
            seriesNames: any[],
            seriesRange: any[],
            seriesX: any[]
        },
    };
}

const addSeries = ({
    dataRowStyle,
    dividerStyle,
    dotStyle,
    root,
    seriesColor = null,
    seriesTitleStyle,
    title,
    value,
    valueStyle,
}) => {
    const dataRow = root.appendChild(document.createElement('div'));

    if (seriesColor) {
        const dot = dataRow.appendChild(document.createElement('div'));

        Object.assign(dot.style, { ...dotStyle, background: seriesColor });
    }
    const text = dataRow.appendChild(document.createElement('div'));
    const divider = dataRow.appendChild(document.createElement('div'));
    const valueHolder = dataRow.appendChild(document.createElement('div'));

    text.innerText = title;
    valueHolder.innerText = value;

    Object.assign(text.style, seriesTitleStyle);
    Object.assign(divider.style, dividerStyle);
    Object.assign(valueHolder.style, valueStyle);
    Object.assign(dataRow.style, dataRowStyle);
    return dataRow;
};

const getStyles = (theme) => ({
    contentStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '0px 20px 15px 20px',
    },
    dataRowStyle: {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        marginTop: '15px',
    },
    dividerStyle: {
        flex: 1,
    },
    dotStyle: {
        borderRadius: '50%',
        height: '16px',
        marginLeft: '4px',
        width: '16px',
    },
    headerStyle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.03em',
        padding: '20px 20px 5px 20px',
        width: '100%',
    },
    seriesTitleStyle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 'normal',
        letterSpacing: '0.005em',
    },
    tooltipStyle: {
        color: theme.palette.common.black,
        fontSize: '12px',
        minWidth: '255px',
        overflow: 'hidden',
    },
    valueStyle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '0.03em',
    },
});

type Props = {
    isUTC?: boolean,
    locationHintInTooltip?: boolean,
    opts: TooltipOptions,
    series: {
        color: string,
        data: [],
        name: string,
        position: string,
        showValueInTooltip: boolean,
        type: string
    }[];
    theme: SkycellThemeInterface,
    trans: Function
}

export const generateTooltip = ({
    isUTC, locationHintInTooltip, opts, series, theme, trans,
}: Props) => {
    const { dataPointIndex, seriesIndex, w } = opts;

    const {
        config,
        globals,
    } = w;
    const {
        seriesColors,
        seriesNames,
        seriesRange,
        seriesX,
    } = globals;

    const { showMap } = config;

    const {
        contentStyle,
        dataRowStyle,
        dividerStyle,
        dotStyle,
        headerStyle,
        seriesTitleStyle,
        tooltipStyle,
        valueStyle,
    } = getStyles(theme);

    const tooltip = document.createElement('div');
    const header = tooltip.appendChild(document.createElement('div'));
    const content = tooltip.appendChild(document.createElement('div'));

    Object.assign(tooltip.style, tooltipStyle);
    Object.assign(header.style, headerStyle);
    Object.assign(content.style, contentStyle);

    header.innerHTML = isUTC ? getFormattedDateUtc(seriesX[seriesIndex][dataPointIndex])
        : getFormattedDateUtcToLocal(seriesX[seriesIndex][dataPointIndex]);

    const seriesData = seriesNames.map((seriesName, i) => {
        const selectedXValue = seriesX[seriesIndex][dataPointIndex];
        const value = seriesRange[i]?.find(it => it?.x === selectedXValue) || {};
        const initialSeriesConfig = series[i];

        const { y1 } = value?.y?.[0] || {};

        return {
            seriesColor: seriesColors[i],
            seriesName,
            showValueInTooltip: initialSeriesConfig.showValueInTooltip,
            y1,
        };
    }).filter(({ y1 }) => y1 !== undefined);

    if (seriesData.length === 1 && seriesData[0].seriesName === 'Excursion') {
        return '';
    }
    seriesData.forEach(({ seriesColor, seriesName, showValueInTooltip, y1 }) => {
        if (y1 !== undefined && y1 !== null) {
            addSeries({
                dataRowStyle,
                dividerStyle,
                dotStyle,
                root: content,
                seriesColor,
                seriesTitleStyle,
                title: showValueInTooltip === false
                    ? trans('TRACK_AND_TRACE.LOCATION') : seriesName,
                value: showValueInTooltip === false
                    ? seriesName : `${y1}`,
                valueStyle,
            });
        }
    });

    if (showMap && locationHintInTooltip) {
        const staticTextWrapper = document.createElement('div');

        staticTextWrapper.innerHTML = getTooltipAsHTML();
        content.appendChild(staticTextWrapper);
    }

    return tooltip.outerHTML;
};
