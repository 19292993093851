import {
    createContext, CSSProperties,
} from 'react';
import { QuickHelpTooltipInfo } from '../QuickHelpContext/QuickHelpTooltip';

const initialState = {
    availableHeight: '100vh',
    keysPressed: Object,
    menuTabs: [],
    setCustomPageTitle: () => {},
    setMenuTabs: () => {},
};

export type Tab = {
    activePage?: boolean,
    buttonClass?: string,
    hide?:boolean,
    iconSrc?: string,
    iconStyle?: CSSProperties,
    isAction?: boolean,
    link?: string,
    onboarding?: QuickHelpTooltipInfo,
    onClick?: Function,
    title: string
}

export type LayoutContextState = {
    availableHeight: string,
    keysPressed: {},
    menuTabs: Tab[],
    setCustomPageTitle: Function,
    setMenuTabs: (menuTabs?: Tab[]) => void
}

export const LayoutContext = createContext<LayoutContextState>(initialState);
