const styles = {
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '38px',
    },
    submitButton: {
        height: '40px',
        margin: '9px',
        width: '170px',
    },
    topCards: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 2fr',
    },
};

export default styles;
