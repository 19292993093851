import React, {
    useState,
    useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Company, ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';
import { AddCompanyRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';
import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CompanyCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/CompanyCard';
import ContactInformationCard
    from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import useClasses from 'hooks/useClasses';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import styles from 'Administration/Companies/Companies.style';
import BackToLink from 'shared-components/BackToLink';
import useCustomTranslation from 'hooks/useCustomTranslation';

type PathParams = {
    companyId: string,
}

const EditCompany = () => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddCompanyRequestBody>(null);
    const [availableData, setAvailableData] = useState<Company>(null);
    const [logoFormData, setLogoFormData] = useState<FormData>(null);
    // const [serviceProviders, setServiceProviders] = useState<Company[]>([]);
    const [roles, setRoles] = useState([]);
    const [nameCheckInProgress, setNameCheckInProgress] = useState<boolean>(false);
    const [authorizesDataSharing, setAuthorizesDataSharing] = useState(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const { companyId } = useParams() as unknown as PathParams;
    const {
        Get: getCompany,
    } = useSkymindBackendEndpoints('companies').requests;
    const {
        FlexibleRequest: uploadFile,
    } = useSkymindBackendEndpoints('admin/companies').requests;
    const {
        Patch: patchRoles,
    } = useSkymindBackendEndpoints(`admin/companies/${companyId}/allowed-roles`).requests;
    const {
        Create: postAuthorizedDataSharing,
    } = useSkymindBackendEndpoints(`admin/companies/${companyId}/action/authorize-data-sharing`).requests;
    const { FlexibleRequest: editCompany } = useSkymindBackendEndpoints('admin/companies').statusNotificationRequests;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();

    const handleClickEdit = async () => {
        try {
            if (logoFormData) {
                await uploadFile('POST', `/${companyId}/logo`, logoFormData);
                setLogoFormData(null);
            }

            const { status = null } = await editCompany('PATCH', `/${companyId}/overview-info`, addCompanyRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }

        try {
            if (authorizesDataSharing && availableData.dataSharingAuthorized !== authorizesDataSharing) {
                await postAuthorizedDataSharing();
            }
            setAvailableData((prev) => ({
                ...prev,
                dataSharingAuthorized: true,
            }));
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            setAddCompanyRequestBody({
                ...companyInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        if (requiredUpdateData) {
            (async () => {
                try {
                    const rawResponse = await getCompany(companyId);

                    const response: Company = rawResponse?.data || null;

                    if (response) {
                        setAvailableData(response);
                        setContactInfo(response.contactInfo);
                        setCompanyInfo({
                            companyName: response.name,
                            companyType: response.companyType,
                            website: response.website,
                        });
                        setAddress({
                            addressLine1: response.address.addressLine1,
                            addressLine2: response.address.addressLine2,
                            addressLine3: response.address.addressLine3,
                            city: response.address.city,
                            countryCode: response.address.country.code,
                            geolocation: response.address.geolocation,
                            zipCode: response.address.zipCode,
                        });
                        setRoles(response.companyRoles || []);
                        // setServiceProviders(response.serviceProviderCompanies || []);
                        setBreadcrumbData('customBreadcrumb', response.name);
                        setTitle(`Company Details: ${response.name}`);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData]);

    const handleRolesSave = async () => {
        try {
            const { status = null } = await patchRoles('', roles);

            if (status === 200) {
                setAvailableData(prevState => ({ ...prevState, companyRoles: roles }));
                global.console.log('Roles updated');
            }
        } catch (error) {
            setRoles(companyInfo?.companyRoles || []);
        }
    };

    return (
        availableData
            ? (
                <div className={classes.editCompanyPageContent}>
                    <BackToLink
                        marginBottom={0}
                        marginLeft={0}
                        marginRight={0}
                        marginTop={0}
                        title={t('MENU_ITEMS.COMPANIES')}
                        to="/administration/companies"
                    />
                    <div className={classes.addEditCompanyCardContentData}>
                        <CompanyCard
                            authorizesDataSharing={authorizesDataSharing}
                            currentAuthorizesDataSharing={availableData.dataSharingAuthorized}
                            currentCompanyLogo={availableData.logoImagePath}
                            currentCompanyName={availableData?.name}
                            currentCompanyType={availableData?.companyType}
                            currentEditedCard={currentEditedCard}
                            currentWebsite={availableData?.website}
                            nameCheckInProgress={nameCheckInProgress}
                            saveInfo={handleClickEdit}
                            setAuthorizesDataSharing={setAuthorizesDataSharing}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setCompanyInfo}
                            setLogoFormData={setLogoFormData}
                            setNameCheckInProgress={setNameCheckInProgress}
                            type="editable"
                        />
                        <AddressCard
                            currentAddressLine1={availableData.address?.addressLine1}
                            currentAddressLine2={availableData.address?.addressLine2}
                            currentAddressLine3={availableData.address?.addressLine3}
                            currentCity={availableData.address?.city}
                            currentCountry={availableData.address?.country.name}
                            currentCountryCode={availableData.address?.country?.code}
                            currentEditedCard={currentEditedCard}
                            currentGeolocation={availableData.address?.geolocation}
                            currentZipCode={availableData.address?.zipCode}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setAddress}
                            type="editable"
                        />
                        <ContactInformationCard
                            currentContactPerson={availableData?.contactInfo?.contactPerson}
                            currentEditedCard={currentEditedCard}
                            currentEmail={availableData?.contactInfo?.email}
                            currentPhoneNumber={availableData?.contactInfo?.phoneNumber}
                            saveInfo={handleClickEdit}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setFilter={setContactInfo}
                            type="editable"
                        />
                        {/* {
                            companyInfo?.companyType === 'PHARMA' && (
                                <DataAccessCard
                                    currentCompanyId={companyId}
                                    currentEditedCard={currentEditedCard}
                                    currentServiceProviders={serviceProviders}
                                    companyInfoFromApi={availableData}
                                    setCurrentEditedCard={setCurrentEditedCard}
                                    setRequiredUpdateData={setRequiredUpdateData}
                                    type="editable"
                                />
                            )
                        } */}
                        <CompanyRolesCard
                            currentEditedCard={currentEditedCard}
                            currentRoles={availableData?.companyRoles}
                            disabledControlOnRoles={['CUSTOMER_ADMIN']}
                            roles={roles}
                            saveInfo={handleRolesSave}
                            setCurrentEditedCard={setCurrentEditedCard}
                            setRoles={setRoles}
                            type="editable"
                        />
                    </div>
                </div>
            )
            : null
    );
};

export default EditCompany;
