import React, { ReactElement, cloneElement, forwardRef, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import CustomTitle from './CustomTitle';

const borderColors = {
    blue: '#C3E9F7',
    red: '#D44848',
};

export enum TOOLTIP_TYPE {
    BORDER,
    SHRINK,
    REGULAR
}
export type TooltipProps = {
    borderColor?: 'blue' | 'red',
    children?: ReactElement,
    className?: string,
    description: string | ReactElement,
    descriptionTitle?: string,
    enabled?: boolean,
    iconSrc?: string,
    open?: boolean | null,
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top',
    position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed',
    textAlign?: 'center' | 'left',
    type?: TOOLTIP_TYPE,
    whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces'
};
const CommonTooltip = forwardRef<HTMLSpanElement, TooltipProps>(({
    borderColor = 'blue',
    children = null,
    className = '',
    description,
    descriptionTitle = null,
    enabled = true,
    iconSrc = null,
    open = null,
    placement = 'bottom-start',
    position = 'static',
    textAlign = 'center',
    type = TOOLTIP_TYPE.REGULAR,
    whiteSpace = 'normal',
}, ref) => {
    const theme = useTheme<SkycellThemeInterface>();

    const styles = useMemo(() => {
        if (!enabled) return {};
        switch (type) {
        case TOOLTIP_TYPE.SHRINK:
            return {
                backgroundColor: theme.palette.secondary[400],
                borderRadius: '25px',
                color: theme.palette.common.white,
                fontSize: '13px',
                padding: '4px 8px',
                position,
                textAlign,
                whiteSpace,
            };
        case TOOLTIP_TYPE.BORDER:
            return {
                background: '#F7F7F7 0% 0% no-repeat padding-box',
                borderBottom: '10px solid transparent',
                borderColor: borderColors[borderColor],
                borderRadius: '10px',
                borderTop: '10px solid transparent',
                boxShadow: '0px 3px 6px #00000029',
                maxWidth: whiteSpace === 'pre' ? 'unset' : '300px',
                position,
                whiteSpace,
                width: whiteSpace === 'pre' ? 'auto' : '288px',
            };
        case TOOLTIP_TYPE.REGULAR:
            return {
                '& *': {
                    color: theme.palette.common.black,
                },
                background: theme.palette.common.white,
                borderRadius: '6px',
                boxShadow: '3px 3px 6px #00000066',
                padding: '14px',
            };
        }
    }, [type, enabled, theme, textAlign, whiteSpace, position]);

    if (!enabled) return children;

    return (
        <Tooltip
            classes={{
                tooltip: className,
            }}
            {...(open !== null ? { open } : {})}
            componentsProps={{
                popper: {
                    sx: {
                        zIndex: 10002,
                    },
                },
                tooltip: {
                    sx: {
                        opacity: 1,
                        ...styles,
                    },
                },
            }}
            disableFocusListener
            disableTouchListener
            placement={placement}
            style={{
                zIndex: `${10002}!important`,
            }}
            title={(
                type === TOOLTIP_TYPE.SHRINK ? description : (
                    <CustomTitle
                        description={description}
                        descriptionTitle={descriptionTitle}
                        iconSrc={iconSrc}
                    />
                )
            )}
        >
            {children && cloneElement(children, { ref, ...children.props })}
        </Tooltip>
    );
});

CommonTooltip.displayName = 'CommonTooltip';

export default CommonTooltip;
