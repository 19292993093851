import useClasses from 'hooks/useClasses';
import React, { useContext, useMemo } from 'react';
import Card from 'shared-components/Card';
import icons from 'shared-components/icons';
import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import useCustomTranslation from 'hooks/useCustomTranslation';

const styles = {
    profileCardContent: {
        color: '#00000099',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    profileCardTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginTop: '15px',
        opacity: 1,
        textAlign: 'left',
    },
    profilePicture: {
        // height: '130px',
        margin: '20px 0 5px',
    },
};

const cardPicture = {
    summer: icons.palm_and_sun,
    winter: icons.mountains_and_snow,
};

type Props = {
    type: 'summer' | 'winter',
}

const ProfileCard = ({
    type,
} : Props) => {
    const classes = useClasses(styles);
    const {
        lane,
    } = useContext(LaneTemperatureContext);

    const { t } = useCustomTranslation();
    const duration = useMemo(() => {
        const duration = Math.round(lane
            ?.milestones
            ?.map(m => m.activities)
            ?.flat()
            ?.map(activity => activity.duration)
            ?.reduce((a, b) => a + b, 0) / 60 / 60);

        return `${duration}h`;
    }, [lane]);

    const ambientTemperatureRange = useMemo(() => {
        const temperatures = lane
            ?.milestones
            ?.map(m => m.activities)
            ?.flat()
            ?.map(activity => activity[type === 'summer' ? 'temperatureSummer' : 'temperatureWinter']) || [];

        return `${Math.min(...temperatures).toFixed(1)}°C to ${Math.max(...temperatures).toFixed(1)}°C`;
    }, [lane]);

    return (
        <Card contentClass={classes.profileCardContent} title={t('LANE_MANAGEMENT.PROFILE')}>
            <img
                alt="profile"
                className={classes.profilePicture}
                src={cardPicture[type]}
            />
            <div className={classes.profileCardTitle}>{t('LANE_MANAGEMENT.AMBIENT_TEMPERATURE_RANGE')}</div>
            <div>{ ambientTemperatureRange }</div>
            <div className={classes.profileCardTitle}>{t('COMMON.DURATION')}</div>
            <div>{ duration }</div>
        </Card>
    );
};

export default ProfileCard;
