const styles = (theme) => ({
    cardContentData: {
        color: '#747474',
        display: 'grid',
        font: 'normal normal normal 16px/19px Roboto',
        gridGap: '12px',
        gridTemplateColumns: '1fr 1fr',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    error: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #D44848',
        borderRadius: '3px',
        opacity: 1,
    },
    fieldTitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '12px 0 2px 0',
        opacity: 1,
        textAlign: 'left',
    },
    fieldsRow: {
        display: 'grid',
        gridGap: '6px',
        gridTemplateColumns: '1fr 1fr',
    },
    listBox: {
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderLeft: '6px solid #8BD1E8',
        borderRadius: '3px',
        opacity: 1,
    },
    textField: {
        marginTop: '12px',
        width: '100%',
    },
    textFieldMargin: {
        marginTop: '4px',
    },
    textFieldText: {
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        padding: '3px 0',
        textAlign: 'left',
    },
    textFieldTextAutocomplete: {
        '& input': {
            padding: '3px 15px 3px 0!important',
        },
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        padding: '0!important',
        paddingRight: '10px!important',
        textAlign: 'left',
    },
});

export default styles;
