import React, { useContext } from 'react';
import useClasses from 'hooks/useClasses';
import CloseIcon from '@mui/icons-material/Close';
import { isKnBaseShown } from 'shared-components/constants';
import { styles } from '../UserGuide/UserGuide.style';
import { QuickHelpContext } from '../QuickHelpContext';
import UserGuide from '../UserGuide';
import { KnowledgeBase } from '../KnowledgeBase/KnowledgeBase';

export const QuickHelpPage = () => {
    const classes = useClasses(styles);
    const { enableGuide, setEnableGuide } = useContext(QuickHelpContext);

    return (
        <div className={[
            classes.drawer,
            enableGuide ? classes.visible : '',
            enableGuide && isKnBaseShown ? classes.visibleKnBase : '',
        ].join(' ')}
        >
            <div className={[classes.drawerCard, isKnBaseShown ? classes.drawerCardKnBase : ''].join(' ')}>
                <div className={isKnBaseShown ? '' : classes.closer}>
                    <span
                        className={[
                            classes.x,
                            isKnBaseShown ? classes.xKnBase : '',
                            enableGuide && isKnBaseShown ? classes.xKnBaseShown : '',
                        ].join(' ')}
                        onClick={setEnableGuide.bind(null, false)}
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div className={[
                    classes.drawerContents,
                    isKnBaseShown ? classes.drawerContentsKnBase : '',
                ].join(' ')}
                >
                    {isKnBaseShown ? <KnowledgeBase setEnableGuide={setEnableGuide} /> : <UserGuide />}
                </div>
            </div>
        </div>
    );
};
