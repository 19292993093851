import useClasses from 'hooks/useClasses';
import React, { MouseEventHandler, useMemo } from 'react';
import { FormControl, Select, MenuItem, IconButton, InputLabel } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { uniqueId } from 'utils/stringTool';
import { Cancel } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { styles } from './RegularSelect.style';

type Props = {
    borderless?: boolean,
    className?: string,
    customClasses?: {
        caretIcon?: string,
        control?: string,
        root?: string
    },
    disabled?: boolean,
    id?: string,
    onChange: Function,
    onClear?: MouseEventHandler<any>,
    options: {
        active?: boolean,
        label?: string,
        value: string | number
    }[],
    placeholder?: string,
    renderValue?: (value: number | string) => React.ReactNode,
    title?: string,
    value: string
}
const RegularSelect = ({
    borderless = false,
    className,
    customClasses,
    disabled = false,
    id = uniqueId(),
    onChange = () => {},
    onClear,
    options = [],
    placeholder = '',
    renderValue = null,
    title,
    value = '',
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const theme = useTheme();

    const shrinked = useMemo(() => {
        if (placeholder) {
            return value !== '';
        } else return true;
    }, [value, placeholder]);

    return (
        <FormControl className={customClasses?.control} sx={{ minWidth: 240 }} variant="standard">
            {title && (
                <InputLabel
                    htmlFor={`${id}_inputId`}
                    shrink={shrinked}
                    style={{
                        color: theme.palette.common.black,
                        opacity: shrinked ? 1 : 0,
                        transition: 'all 0.2s ease',
                    }}
                >
                    {title}
                </InputLabel>
            )}

            <Select
                MenuProps={{
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                    },
                    transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top',
                    },
                }}
                className={className}
                classes={{
                    disabled: classes.disabled,
                    icon: customClasses?.caretIcon || ((value !== '' || value !== null) ? classes.caretIcon : ''),
                    root: [classes.root, customClasses?.root].join(' '),
                    select: (value === '' || value === null) ? classes.withPlaceholder : '',
                }}
                disableUnderline={borderless}
                disabled={disabled}
                displayEmpty
                endAdornment={(onClear && value !== '')
                    ? <IconButton className={classes.removeIcon} onClick={onClear}><Cancel /></IconButton>
                    : <></>}
                inputProps={{
                    id: `${id}_inputId`,
                    name: `${id}_inputName`,
                }}
                renderValue={renderValue}
                type="filled"
                value={value}
                onChange={(ev) => onChange(ev.target.value)}
            >
                <MenuItem // This one is used for placeholder cheat
                    disabled
                    style={{
                        display: 'none',
                    }}
                    value=""
                >
                    {placeholder}
                </MenuItem>
                {
                    options.map((option, i) => {
                        return (
                            <MenuItem
                                key={`${id}_${option.value}_${i}`}
                                style={option.active ? {
                                    background: theme.palette.primary[400],
                                    color: theme.palette.common.white,
                                } : {}}
                                value={option.value}
                            >
                                {option.label || t(`COMMON.${option.value}`)}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
};

export default RegularSelect;
