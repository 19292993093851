import React, { ChangeEvent, SetStateAction, useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import UnreadMail from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import ReadMail from '@mui/icons-material/Drafts';
import { NotificationCategory } from 'dataTypes/SecureBackend/apiResponse';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useClasses from 'hooks/useClasses';
import { ProcessedNotification } from '../lib';
import styles from '../Notifications.style';

type Props = {
    category: NotificationCategory,
    hasBeenDeleted: boolean,
    hasBeenRead: boolean,
    id: number,
    isExpanded: boolean,
    isSelected: boolean,
    setClickedRowNotification: (prev: SetStateAction<ProcessedNotification>) => void,
    setSelectedIds: (prev: SetStateAction<number[]>) => void
}

const NotificationCategoryWithCheckBox = ({
    category,
    hasBeenDeleted,
    hasBeenRead,
    id,
    isExpanded,
    isSelected,
    setClickedRowNotification,
    setSelectedIds,
} : Props) => {
    const classes = useClasses(styles);

    const categories = useGetTranslationGroup('NOTIFICATION_CATEGORY');

    const categoryName = useMemo(() => {
        const similarKey = Object.keys(categories).find(it => it.endsWith(category));

        return categories[category] || categories[similarKey] || '';
    }, [categories, category]);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setClickedRowNotification(null);
        setSelectedIds(prev => {
            if (event.target.checked && !prev.includes(id)) {
                return [...prev, id];
            }
            if (!event.target.checked && prev.includes(id)) {
                return prev.filter(item => item !== id);
            }
            return prev;
        });
    };

    return (
        <div className={classes.formControlLabelContainer}>
            <FormControlLabel
                className={classes.formControlLabelStyle}
                control={(
                    <Checkbox
                        checked={isSelected}
                        className={classes.checkboxStyle}
                        classes={{
                            checked: classes.checked,
                            root: classes.root,
                        }}
                        disabled={isExpanded}
                        size="small"
                        onChange={handleChange}
                    />
                )}
                label={(
                    <div className={classes.formControlLabelContainer}>
                        {
                            hasBeenDeleted
                                ? <DeleteIcon fontSize="small" />
                                : hasBeenRead
                                    ? <ReadMail fontSize="small" />
                                    : <UnreadMail fontSize="small" />
                        }
                    </div>
                )}
                onClick={(ev) => { ev.stopPropagation(); }}
            />
            <div className={[classes.formControlLabelStyle,
                hasBeenRead ? '' : classes.formControlLabelStyleSelected].join(' ')}
            >
                {categoryName}
            </div>
        </div>
    );
};

export default NotificationCategoryWithCheckBox;
