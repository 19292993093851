import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useLocalStorage = <T = any>(storage = 'basicStorage') => {
    const {
        userInfo,
    } = useCurrentUserContext();
    const storageWithPrefix = useMemo(() => `${userInfo?.email || 'commonUser'}_${storage}`, [userInfo, storage]);
    const [data, setData] = useState<T>(() => {
        const storedData = localStorage.getItem(storageWithPrefix);

        return storedData ? JSON.parse(storedData) : {};
    });

    useEffect(() => {
        localStorage.setItem(storageWithPrefix, JSON.stringify(data));
    }, [data, storageWithPrefix]);

    const setItem = useCallback((key, value) => {
        setData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }, []);

    return { data, setItem };
};

export default useLocalStorage;
