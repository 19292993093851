import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import icons from 'shared-components/icons';
import ParentCompany from 'shared-components/CompanyInfoComponents/ParentCompany';
import { highlight } from 'utils/stringTool';

const tableColumns: (
    arg: ((t:string) => string),
    maskForHighlight?: string) => ColumnsType[] = (t, maskForHighlight) => [
        {
            Header: t('COMMON.COMPANY'),
            accessor: (row) => {
                return (
                    <ParentCompany
                        companyName={highlight(row.name, maskForHighlight)}
                        srcImage={row.logoImagePath || icons.placeholder_company_logo}
                    />
                );
            },
            customWidth: '30%',
            dataType: 'custom',
            id: 'name',
        },
        {
            Header: t('COMMON.COMPANY_TYPE'),
            accessor: ({ companyType }) => t(`ADMINISTRATION.${companyType}`),
            dataType: 'text',
            id: 'companyType',
        },
        {
            Header: t('COMMON.CITY'),
            accessor: 'city',
            dataType: 'highlightedText',
            disableSortBy: true,
        },
        {
            Header: t('COMMON.COUNTRY'),
            accessor: 'country',
            dataType: 'highlightedText',
            disableSortBy: true,
        },
    ];

export default tableColumns;
