const styles = () => ({
    editHoursTtitle: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        marginTop: '10px',
        opacity: 1,
        textAlign: 'left',
    },
    equalRowGrid: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '2fr 2fr 1fr 1fr',
    },
    loading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
    },
    operatingHoursContainer: {
        color: '#747474',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    row: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rowGrid: {
        display: 'grid',
        gridGap: '6px',
        gridTemplateColumns: '4fr 1fr 1fr',
    },
    textField: {
        marginTop: '-5px',
    },
    textFieldText: {
        '&::-webkit-datetime-edit-ampm-field': {
            padding: '0',
        },
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    },
    timePicker: {
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            // background: '#61C6E9!important',
            color: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
    },
    title: {
        color: '#939393',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        margin: '10px 0 5px',
        opacity: 1,
        textAlign: 'left',
    },
    weekContainer: {
        display: 'grid',
        gridGap: '6px',
        gridTemplateColumns: '1fr 1fr',
    },
});

export default styles;
