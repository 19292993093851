import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Delete, Edit, Save, Undo } from '@mui/icons-material';
import { Classes } from 'jss';
import { capitalize } from 'utils/stringTool';
import { CommonTableItem, TABLE_INPUT_TYPE } from '../CommonTableTypes';
import TableInput from '../TableInput';
import TableInputRender from '../TableInputRender';

type Props = {
    activities: any[],
    activitiesLoading: boolean,
    classes: Classes,
    editingData: any,
    focusIn: any,
    focusOut: any,
    milestoneId: any,
    primaryKey: any,
    removeItem: any,
    setEditingData: any,
    tableData: CommonTableItem[],
    validated: boolean,
}
const TableWrapper = ({
    activities,
    activitiesLoading,
    classes,
    editingData,
    focusIn,
    focusOut,
    milestoneId,
    primaryKey,
    removeItem,
    setEditingData,
    tableData,
    validated = true,
}: Props) => {
    return (
        <TableContainer>
            <Table className={[
                classes.commonStepTable,
                activitiesLoading ? classes.tableLoading : '',
            ].join(' ')}
            >
                <TableHead>
                    <TableRow className={classes.commonStepTableRow}>
                        {
                            tableData
                                .filter(it => it.inputType !== TABLE_INPUT_TYPE.PK)
                                .map((header, i) => {
                                    return (
                                        <TableCell
                                            key={`${milestoneId}_header_${header.id}`}
                                            width={i === 0 ? '25%' : 'unset'}
                                        >
                                            {header.title}
                                        </TableCell>
                                    );
                                })
                        }
                        <TableCell style={{ width: '90px' }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (activities).map(item => {
                            const elementEditing = item[primaryKey] === editingData?.[primaryKey];
                            const newItem = editingData && editingData?.index === undefined;
                            const rowKey = `row_${item[primaryKey]}_${ elementEditing ? 'read' : 'edit'}`;

                            return (
                                <TableRow
                                    key={rowKey}
                                    className={classes.commonStepTableRow}
                                >
                                    {
                                        tableData.filter(it => it.inputType !== TABLE_INPUT_TYPE.PK)
                                            .map(header => {
                                                return (
                                                    <TableCell
                                                        key={`${rowKey}_${header.id}`}
                                                    >
                                                        {elementEditing ? (
                                                            <TableInput
                                                                description={header}
                                                                proposedValue={
                                                                    item[`proposed${capitalize(header.id)}`]
                                                                }
                                                                value={editingData[header.id]}
                                                                onChange={
                                                                    (value) => setEditingData(
                                                                        prev => ({
                                                                            ...prev,
                                                                            [header.id]: value,
                                                                        }),
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <TableInputRender
                                                                description={header}
                                                                value={item[header.id]}
                                                            />
                                                        )}
                                                    </TableCell>
                                                );
                                            })
                                    }
                                    <TableCell style={{
                                        textAlign: 'end',
                                        whiteSpace: 'nowrap',
                                    }}
                                    >
                                        {
                                            !newItem && elementEditing && (
                                                <>
                                                    <Undo
                                                        className={[
                                                            classes.icon, classes.active,
                                                        ].join(' ')}
                                                        onClick={
                                                            () => {
                                                                setEditingData(null);
                                                            }
                                                        }
                                                    />
                                                    <Save
                                                        className={[
                                                            classes.icon,
                                                            classes.active,
                                                            !validated ? classes.invalid : '',
                                                        ].join(' ')}
                                                        onClick={
                                                            () => {
                                                                focusOut();
                                                            }
                                                        }
                                                    />
                                                </>
                                            )
                                        }

                                        {
                                            !newItem && !elementEditing && (
                                                <Edit
                                                    className={classes.icon}
                                                    onClick={() => {
                                                        if (editingData || elementEditing) return;
                                                        focusIn(item);
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            !newItem && (
                                                <Delete
                                                    className={[
                                                        classes.icon, elementEditing ? classes.active : '',
                                                    ].join(' ')}
                                                    onClick={(event => removeItem(event, item.index))}
                                                />
                                            )
                                        }
                                    </TableCell>
                                </TableRow>

                            );
                        })
                    }

                    {
                        editingData && editingData?.index === undefined && (
                            <TableRow
                                key={`${milestoneId}_table_new_row`}
                                className={classes.commonStepTableRow}
                            >
                                {
                                    tableData.filter(it => it.inputType !== TABLE_INPUT_TYPE.PK)
                                        .map(header => {
                                            return (
                                                <TableCell
                                                    key={`${milestoneId}_new_row_${header.id}`}
                                                >
                                                    <TableInput
                                                        description={header}
                                                        proposedValue={null}
                                                        value={editingData[header.id]}
                                                        onChange={
                                                            (value) => {
                                                                setEditingData(
                                                                    prev => ({
                                                                        ...prev,
                                                                        [header.id]: value,
                                                                    }),
                                                                );
                                                            }
                                                        }
                                                    />
                                                </TableCell>
                                            );
                                        })
                                }
                                <TableCell style={{
                                    textAlign: 'end',
                                    whiteSpace: 'nowrap',
                                }}
                                >
                                    <Undo
                                        className={[
                                            classes.icon, classes.active,
                                        ].join(' ')}
                                        onClick={
                                            () => {
                                                setEditingData(null);
                                            }
                                        }
                                    />

                                    <Save
                                        className={[
                                            classes.icon, classes.active, !validated ? classes.invalid : '',
                                        ].join(' ')}
                                        onClick={
                                            () => {
                                                focusOut();
                                            }
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        )

                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableWrapper;
