import React, { useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import icons from 'shared-components/icons';
import useClasses from 'hooks/useClasses';
import styles from './LocationHistoryTimeStep.style';

type Props = {
    localTimezone?: boolean,
    time: string
}

const LocationHistoryTimeStep = ({
    localTimezone = false,
    time,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const breakpointDate = useMemo(() => {
        const dateTime = moment(time).utc(true);

        if (localTimezone) {
            dateTime.local();
        }
        return dateTime.format('DD.MM.YYYY HH:mm Z');
    }, [time, localTimezone]);

    return (
        <div className={classes.orderStepLocation}>
            <div className={classes.locationData}>
                <div className={classes.locationDataContent}>
                    <div className={classes.dataPart}>
                        <img
                            alt="icon"
                            className={classes.dataIcon}
                            height={25}
                            src={icons.clock}
                            style={{ filter: 'invert(64%) sepia(54%) saturate(6823%)'
                            + 'hue-rotate(167deg) brightness(93%) contrast(139%)' }}
                            width={25}
                        />
                        <div className={classes.dataTextClock}>
                            {breakpointDate || t('ORDER_DETAILS.NO_DATE_TIME_YET')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationHistoryTimeStep;
