import {
    Address,
    ContactInfo,
    DayOfWeek,
} from './common';

export type Services = 'AIRLINE'
    | 'FORWARDER'
    | 'GROUND_HANDLER'
    | 'PACKAGING_PROVIDER'
    | 'WAREHOUSE_AIRSIDE'
    | 'WAREHOUSE_LANDSIDE'

export const servicesArr = [
    'AIRLINE',
    'FORWARDER',
    'GROUND_HANDLER',
    'PACKAGING_PROVIDER',
    'WAREHOUSE_AIRSIDE',
    'WAREHOUSE_LANDSIDE',
];

export type Commodities = 'DANGEROUS_GOODS_CHEMICALS'
    | 'HEALTHCARE_PHARMACEUTICALS'
    | 'HIGH_VALUE_CARGO'
    | 'LIVE_ANIMALS'
    | 'PERISHABLES'

export const commoditiesArr = [
    'DANGEROUS_GOODS_CHEMICALS',
    'HEALTHCARE_PHARMACEUTICALS',
    'HIGH_VALUE_CARGO',
    'LIVE_ANIMALS',
    'PERISHABLES',
];

export interface OperatingHours {
    dayOfWeek: DayOfWeek,
    from: string,
    to: string,
}

export interface ServiceProviderSite {
    address: Address,
    contactInfo: ContactInfo,
    id: number,
    logoImagePath: string,
    name: string,
    offers: string[],
    operatingHours: OperatingHours[],
    serviceProviderId: number,
    serviceProviderName: string,
    website: string
}
