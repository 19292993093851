import useGeneratedStyles from 'hooks/useGeneratedStyles/useGeneratedStyles';
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo, useState, useEffect,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import {
    FacetDTO,
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption,
    SwitchOptionItem,
} from 'shared-components/SideFilter';
import {
    ExtractedAssetData,
} from 'TrackAndTrace/Assets/lib';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import SwitcherHorisontal from 'shared-components/SwitcherHorisontal';
import { facetSorter } from 'utils/stringTool';
import { getQuickHelpAsset } from 'shared-components/icons';
import { ONBOARDING_TYPE } from 'Contexts/QuickHelpContext/QuickHelpContext';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import SearchPreviewTable from './SearchPreviewTable';

const filter = createFilterOptions<string>();

const options = ['More than 7 days', 'More than 14 days', 'More than 30 days'];
const lyingTimeSwitcher = { AREA: 'AREA', LOCATION: 'LOCATION' };

export const lyingTimeSwitcherOptions = [lyingTimeSwitcher.LOCATION, lyingTimeSwitcher.AREA];

type Props = {
    activeLyingTime: string,
    areaLyingTime: string,
    countsLoading: boolean,
    facets: FacetDTO[],
    filterOptions: { [optionsGroupKey: string]: string[] },
    initialFacets: FacetDTO[],
    isTableView: boolean,
    locationLyingTime: string,
    onAreaLyingTimeChange: (timestamp: number | null) => void;
    onLocationLyingTimeChange: (timestamp: number | null) => void;
    query?: string,
    setActiveLyingTime: Dispatch<SetStateAction<string>>,
    setAreaLyingTime: Dispatch<SetStateAction<string>>,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    setLocationLyingTime: Dispatch<SetStateAction<string>>,
    setQuery: Dispatch<SetStateAction<string>>,
    setShowAirportsInfo: Dispatch<SetStateAction<boolean>>,
    setShowGateways: Dispatch<SetStateAction<boolean>>,
    showAirports: boolean,
    showGateways: boolean
}

const AssetFilterPanel = ({
    activeLyingTime,
    areaLyingTime,
    countsLoading,
    facets,
    filterOptions,
    initialFacets,
    isTableView,
    locationLyingTime,
    onAreaLyingTimeChange,
    onLocationLyingTimeChange,
    query,
    setActiveLyingTime,
    setAreaLyingTime,
    setFilterOptions,
    setLocationLyingTime,
    setQuery,
    setShowAirportsInfo,
    setShowGateways,
    showAirports,
    showGateways,
}: Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const [assetsFromPreviewTable, setAssetsFromPreviewTable] = useState<ExtractedAssetData[]>([]);
    const userInfo = useCurrentUserContext();

    const handleTimeChange = ({
        newValue,
        onLyingTimeChange_,
        setLyingTime_,
    }) => {
        if (typeof newValue === 'string') {
            let formattedInput = newValue.trim();
            // If the input is just a number, format it properly

            if (/^\d+$/.test(formattedInput)) {
                formattedInput = `More than ${formattedInput} days`;
            }
            const match = formattedInput.match(/^More than (\d+) days$/);

            if (match) {
                const [, days] = match;

                setLyingTime_(`More than ${days} days`);
                onLyingTimeChange_(parseInt(days, 10));
            } else {
                // Reset if format is incorrect
                setLyingTime_('');
                onLyingTimeChange_(null);
            }
        } else {
            // Reset if newValue is not a string
            setLyingTime_('');
            onLyingTimeChange_(null);
        }
    };

    const handleAreaLyingTimeChange = useCallback((event, newValue) => {
        handleTimeChange({
            newValue,
            onLyingTimeChange_: onAreaLyingTimeChange,
            setLyingTime_: setAreaLyingTime,
        });
    }, []);

    const handleLocationLyingTimeChange = useCallback((event, newValue) => {
        handleTimeChange({
            newValue,
            onLyingTimeChange_: onLocationLyingTimeChange,
            setLyingTime_: setLocationLyingTime,
        });
    }, []);

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);
    const handleChangeShowGateways = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowGateways(event.target.checked);
    }, []);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'assetTypeCodes',
                    t,
                    tgroup: 'ASSET_TYPE_LABEL',
                }),
                optionsGroupKey: 'assetTypeCodes',
                title: t('TRACK_AND_TRACE.ASSET_TYPE'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'ownedByCompanyNames',
                    t,
                }),
                optionsGroupKey: 'ownedByCompanyNames',
                title: t('TRACK_AND_TRACE.OWNED_BY'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'uldTypes',
                }),
                optionsGroupKey: 'uldTypes',
                title: t('TRACK_AND_TRACE.ULD_TYPE'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'iataCodes',
                }),
                optionsGroupKey: 'iataCodes',
                title: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'countries',
                }),
                optionsGroupKey: 'countries',
                title: t('COMMON.COUNTRY'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'cities',
                }),
                optionsGroupKey: 'cities',
                title: t('COMMON.CITY'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'areas',
                }),
                optionsGroupKey: 'areas',
                showFully: true,
                title: t('TRACK_AND_TRACE.AREA'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'uldOwnerCodes',
                }),
                optionsGroupKey: 'uldOwnerCodes',
                title: t('TRACK_AND_TRACE.ULD_OWNER_CODE'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'pairingStatuses',
                    t,
                    tgroup: 'PAIRING_STATUS',
                }),
                optionsGroupKey: 'pairingStatuses',
                title: t('TRACK_AND_TRACE.PAIRING_STATUS'),
            },
        ];
    }, [loadedFacets, initialFacets, areaLyingTime, locationLyingTime]);

    return (
        <SideFilter
            id="AssetsPageFilter"
            tooltipInfo={{
                core: ONBOARDING_TYPE.ASSET,
                image: getQuickHelpAsset('asset_filters.gif'),
                isMain: true,
                order: 2,
                padding: 0,
                position: 'right',
                text: t('ONBOARDING.ASSET_MANAGEMENT.FILTERS_DESC'),
                title: t('ONBOARDING.ASSET_MANAGEMENT.FILTERS'),
                uid: 'assetsSideFilterGeneric',
            }}
        >
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <SearchPreviewTable
                    assetsFromPreviewTable={assetsFromPreviewTable}
                    isTableView={isTableView}
                    query={query}
                    setAssetsFromPreviewTable={setAssetsFromPreviewTable}
                    setQuery={setQuery}
                />
            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: initialFacets.find(it => it.filterName === optionsGroupKey)
                                        .counts.filter(it => it.filterValue !== null)
                                        .map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        .sort((a, b) => facetSorter(a, b, ['NOT_PAIRED']))
                                        .map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    key={option.value}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    count={option.count}
                                                    countsLoading={countsLoading}
                                                    label={option.label === userInfo?.company?.name
                                                        ? `You (${option.label})` : option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    shrinkNames={checkboxGroup.showFully}
                                                    tooltipDescription={option.description}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            <FilterBlock>
                <AccordionOptionsGroup
                    title={t('TRACK_AND_TRACE.LYING_TIME')}
                >
                    <SwitcherHorisontal
                        active={activeLyingTime}
                        options={lyingTimeSwitcherOptions}
                        setActive={setActiveLyingTime}
                        onChange={() => {
                            handleLocationLyingTimeChange(null, null);
                            handleAreaLyingTimeChange(null, null);
                        }}
                    />
                    <Autocomplete
                        clearOnBlur
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Check if the input is already in the "More than X days" format
                            const formattedMatch = inputValue.match(/^More than (\d+) days$/);

                            if (formattedMatch && !options.includes(inputValue)) {
                                filtered.push(inputValue);
                            } else if (/^\d+$/.test(inputValue)) { // Check if the input is just a number
                                const formattedInput = `More than ${inputValue} days`;

                                if (!options.includes(formattedInput)) {
                                    filtered.push(formattedInput);
                                }
                            }
                            return filtered;
                        }}
                        freeSolo
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option;
                        }}
                        handleHomeEndKeys
                        options={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Enter number of days"
                                variant="standard"
                            />
                        )}
                        selectOnFocus
                        size="small"
                        value={
                            (activeLyingTime === lyingTimeSwitcher.LOCATION
                                ? locationLyingTime
                                : areaLyingTime) || 'All'
                        }
                        onChange={
                            activeLyingTime === lyingTimeSwitcher.LOCATION
                                ? handleLocationLyingTimeChange
                                : handleAreaLyingTimeChange
                        }
                    />
                </AccordionOptionsGroup>
            </FilterBlock>

            { !isTableView
            && (
                <FilterBlock>
                    <AccordionOptionsGroup
                        dynamic={false}
                        title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                    >
                        <SwitchOptionItem
                            defaultChecked={showAirports}
                            label={t('TRACK_AND_TRACE.AIRPORTS')}
                            onChange={handleChangeShowAirports}
                        />
                        <SwitchOptionItem
                            defaultChecked={showGateways}
                            label={t('TRACK_AND_TRACE.GATEWAYS')}
                            onChange={handleChangeShowGateways}
                        />
                    </AccordionOptionsGroup>
                </FilterBlock>
            )}
        </SideFilter>
    );
};

export default AssetFilterPanel;
