import {
    useEffect,
    useState,
} from 'react';
import useCacheContext from 'hooks/useCacheContext';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';

const useGetCountries = () => {
    const { getCacheValue, setCacheValue } = useCacheContext();
    const { GetAll: getAllCountries } = useSkymindBackendEndpoints('countries').requests;

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const countriesFromSession = getCacheValue<object[]>('COUNTRIES');

                if (countriesFromSession) {
                    setCountries(countriesFromSession);
                } else {
                    const response = await getAllCountries();

                    if (response.data) {
                        const sorted = response.data.sort((a, b) => {
                            if (a.name > b.name) {
                                return 1;
                            }
                            if (a.name < b.name) {
                                return -1;
                            }
                            return 0;
                        });

                        setCountries(sorted);
                        setCacheValue('COUNTRIES', sorted);
                    }
                }
            } catch (error) {
                setCountries([]);
                global.console.log(error);
            }
        })();
    }, []);

    return countries;
};

export default useGetCountries;
