import React, { useState, useRef, useEffect, ReactElement } from 'react';
import useClasses from 'hooks/useClasses';
import { styles } from './DynamicList.style';

type DynamicListProps = {
    children: ReactElement[],
    dispersion: number,
    gap: number,
    id: string,
    itemHeight: number,
    maxHeight: number,
};

const DynamicList: React.FC<DynamicListProps> = ({
    children,
    dispersion = 5,
    gap = 10,
    id,
    itemHeight = 20,
    maxHeight = 200,
}: DynamicListProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleChildren, setVisibleChildren] = useState<ReactElement[]>([]);

    const classes = useClasses(styles);
    const itemsPerPage = Math.ceil(maxHeight / (itemHeight + gap)) + dispersion;

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop } = containerRef.current;
            const startIndex = Math.max(0, Math.floor(scrollTop / (itemHeight + gap)) - dispersion);
            const endIndex = Math.min(children.length, startIndex + itemsPerPage + dispersion);

            setVisibleChildren(children.slice(startIndex, endIndex));
        }
    };

    useEffect(() => {
        handleScroll(); // Initial render
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
            return () => containerRef.current?.removeEventListener('scroll', handleScroll);
        }
    }, [children, itemHeight, dispersion, gap, maxHeight]);

    return (
        <div
            ref={containerRef}
            className={classes.container}
            style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto' }}
        >
            <div style={{ height: `${children.length * (itemHeight + gap)}px`, position: 'relative' }}>
                {visibleChildren.map((child, index) => {
                    const actualIndex = index + Math.max(0, Math.floor((containerRef.current?.scrollTop ?? 0)
                        / (itemHeight + gap)) - dispersion);

                    return (
                        <div
                            key={`${id}_${actualIndex}`}
                            style={{
                                height: `${itemHeight}px`,
                                position: 'absolute',
                                top: `${actualIndex * (itemHeight + gap)}px`,
                                width: '100%',
                            }}
                        >
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DynamicList;
