export const styles = (theme) => ({
    cardContent: {
        '& tr>td:last-child': {
            padding: '0px',
        },
    },
    cardRoot: {
        paddingTop: '12px',
    },
    noDataContainer: {
        background: theme.palette.common.white,
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '15px',
    },
    tableCard: {
        padding: '0 15px 15px 15px',
    },
    tableContainer: {
        margin: '0px 10px 0px 24px',
    },
});
