const borderRadius = '30px';

export const styles = () => ({
    active: {
        background: 'rgb(0 156 207 / 12%)',
        borderRadius,
        color: '#009CCF',
        outline: '1px solid #009CCF',
    },
    option: {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '12px',
        letterSspacing: '0.01em',
        padding: '5px 8px',
        textAlign: 'center',
        textTransform: 'capitalize',
        transition: '300ms ease',
    },
    wrapper: {
        alignItems: 'center',
        border: '1px solid #4b4b4b',
        borderRadius,
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '13px',
        width: 'fit-content',
    },
});
