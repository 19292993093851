import React from 'react';
import Card from 'shared-components/Card';
import DynamicTable from 'shared-components/DynamicTable';
import useCustomTranslation from 'hooks/useCustomTranslation';
import tableColumns from './tableColumns';

type Props = {
    parentCompanyId: number,
}

const Locations = ({ parentCompanyId } : Props) => {
    // ---
    // TODO: remove later or refactor to useSecureBackend
    // eslint-disable-next-line max-len
    // const url = 'https://14d6ca64-1e7d-486d-b8c3-8df59dbf4069.mock.pstmn.io/business-objects/v1/serviceprovider/filter-request';
    // const { makeMockRequest: getData } = useAbstractService({
    //     method: 'GET',
    //     url,
    // });
    const getData = arg => arg;
    // ---

    const { t } = useCustomTranslation();
    const defaultIncludeFilters = {
        parentCompanyId: [parentCompanyId],
    };

    return (
        <Card
            title={t('SERVICE_PROVIDERS.LOCATIONS')}
            zeroSidePadding
        >
            <DynamicTable
                columns={tableColumns(t)}
                dataRequest={getData}
                defaultIncludeFilters={defaultIncludeFilters}
                rowLinkTemplate="/service-providers/:id/overview"
                tableMaxHeight="35vh"
            />
        </Card>
    );
};

export default Locations;
