import { useMemo, CSSProperties } from 'react';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

const initRect = {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
};

export const useCalculatedRectStyle = ({
    childOffsetPercent,
    childRect,
    customRectSize,
    offsetPx,
    padding = TOOLTIP_PADDING,
    radius,
    screenSize,
    shouldUpdate = new Date().getTime(),
}) => {
    return useMemo<CSSProperties>(() => {
        const {
            height: childHeight,
            width: childWidth,
            x: childX,
            y: childY,
        } = childRect || initRect;
        const [childXPercent = 0, childYPercent = 0] = childOffsetPercent || [];
        const [xPxOffset = 0, yPxOffset = 0] = offsetPx || [];

        let calculatedLeft: number = childX - padding;

        let calculatedTop: number = childY - padding;
        const width = (customRectSize ? customRectSize[0] : childWidth) + padding * 2;
        const height = (customRectSize ? customRectSize[1] : childHeight) + padding * 2;

        if (childXPercent) {
            calculatedLeft += childWidth * (childXPercent / 100) - customRectSize[0];
        }
        if (childYPercent) {
            calculatedTop += childHeight * (childYPercent / 100) - customRectSize[1];
        }
        if (xPxOffset) {
            calculatedLeft += xPxOffset;
        }
        if (yPxOffset) {
            calculatedTop += yPxOffset;
        }

        return {
            background: 'transparent',
            backgroundBlendMode: 'screen',
            borderRadius: radius || '0px',
            cursor: 'pointer',
            height,
            left: calculatedLeft,
            position: 'fixed',
            top: calculatedTop,
            transition: 'none',
            width,
            zIndex: 10001,
        };
    }, [childRect, childOffsetPercent, customRectSize, padding, offsetPx, screenSize, shouldUpdate]);
};
