import moment from 'moment';
import React, {
    useCallback,
    useContext,
    useMemo,
    useRef,
    useEffect,
    SetStateAction,
    Dispatch,
    useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import CustomThemeContext from 'Contexts/CustomThemeContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useApexChartLocale from 'hooks/useApexChartLocale';
import ReactApexChart from 'react-apexcharts';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { CustomAnnotation } from 'shared-components/dataTypes';
import { generateTooltip } from 'shared-components/ApexTemperatureChart/tooltipDefinition';
import useExcursionSeries from 'shared-components/ApexTemperatureChart/useExcursionSeries';
import icons from 'shared-components/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { HiddenSeries } from 'TrackAndTrace/GenericShipmentDetails/GenericShipmentDetails';
import useScreenSize from 'hooks/useScreenSize';
import { downloadFileFromString } from 'shared-components/common';
import useClasses from 'hooks/useClasses';
import { dateToNumber, getFormattedDate, nowDateToNumber } from 'utils/timeUtils';
import {
    CHART_ANNOTATION_COLORS,
    CHART_GRAPH_COLORS,
    LOCATION_COLORS,
    POSITION_TO_COLOR,
    TIME_IN_MS,
} from '../constants';
import { ApexTooltip, FixedTooltip, SensorLabels } from './lib';
import { downloadIcon } from './icons';
import styles from './ApexTemperatureChart.style';

type Props = {
    chartLimits: {
        max: number,
        min: number
    },
    csvDownload?: boolean,
    customAnnotations?: CustomAnnotation[],
    dateTimeTemperatureExcursion?: string,
    disableToolbar?: boolean,
    disableXaxisInTooltip?: boolean,
    doorInfoData?: SensorDataItem[],
    doorInfoLabels?: SensorLabels,
    entityId: string,
    excursionRange?: boolean,
    exportPng?: (base64: string, aspectRatio: number) => void,
    fixedTooltip?: FixedTooltip,
    height?: number,
    hiddenSeries?: { [key: string]: boolean },
    inLocalTimeZone?: boolean,
    isAsset?: boolean,
    isLogger?: boolean,
    isPredictedExcursion?: boolean,
    locationHintInTooltip?: boolean,
    locationSeries?: ApexOptions['series'],
    measuredDataLength?: number,
    onRefresh?: () => void,
    serialNumber?: string,
    setChartRef?: (chartRef: React.RefObject<any>) => void,
    setHiddenSeries?: Dispatch<SetStateAction<HiddenSeries>>,
    setMouseMoveDataIndex?: (elementIndex: number, timestamp?: number) => void,
    setZoomedDataLimits?: (limits: { max: number, min: number }) => void,
    shipmentNumber?: string,
    showMap?: boolean,
    showMarkers?: boolean,
    showNowAnnotation?: boolean,
    showTempRange?: boolean,
    temperatureData: SensorDataItem[],
    temperatureLabels?: SensorLabels,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    tempRangeMinimalisticStyle?: boolean,
    width?: number,
    xMax?: number,
    xMin?: number,
}
const defAspectRatio = 16 / 9;
const ApexTemperatureChart = ({
    chartLimits,
    csvDownload = true,
    customAnnotations = [],
    dateTimeTemperatureExcursion,
    disableToolbar = false,
    disableXaxisInTooltip = false,
    doorInfoData = [],
    doorInfoLabels,
    entityId = null,
    excursionRange = false,
    exportPng = null,
    fixedTooltip = {
        enabled: false,
        offsetX: 0,
        offsetY: 0,
        position: 'topRight',
    },
    height,
    hiddenSeries = {
        DOOR: false,
    },
    inLocalTimeZone = false,
    isLogger = false,
    isPredictedExcursion = false,
    locationHintInTooltip = false,
    locationSeries = [],
    measuredDataLength,
    onRefresh = null,
    serialNumber = '',
    setChartRef = null,
    setHiddenSeries = null,
    setMouseMoveDataIndex = null,
    setZoomedDataLimits = null,
    shipmentNumber = '',
    showMap = false,
    showMarkers = false,
    showNowAnnotation = true,
    showTempRange = true,
    temperatureData = [],
    temperatureLabels,
    temperatureRangeMax = null,
    temperatureRangeMin = null,
    tempRangeMinimalisticStyle = false,
    width,
    xMax = null,
    xMin = null,
}: Props) => {
    const classes = useClasses(styles);
    const chartRef = useRef(null);
    const chartId = useMemo(() => `chart-${Date.now()}`, []);

    const { t: trans } = useCustomTranslation();
    const { chartLocale } = useApexChartLocale();
    const { theme } = useContext(CustomThemeContext);
    const muiTheme = useTheme<SkycellThemeInterface>();
    const { width: screenWidth } = useScreenSize();
    const [sizeOverride, setSizeOverride] = useState(false);

    const resetZoom = useCallback(() => {
        const chart: ApexCharts = chartRef?.current?.chart;

        chart?.zoomX(xMin, xMax);
        if (setZoomedDataLimits) {
            setZoomedDataLimits({ max: null, min: null });
        }
    }, [chartId, xMin, xMax]);

    useEffect(() => {
        if (chartRef?.current && exportPng) {
            setSizeOverride(true);
            setTimeout(() => {
                chartRef?.current?.chart?.dataURI()?.then((base64) => {
                    exportPng(base64?.imgURI || '', defAspectRatio);
                });
            }, 250);
            setTimeout(() => {
                setSizeOverride(false);
            }, 500);
        }
    }, [chartRef, screenWidth]);
    const beforeZoom = (chartContext, { xaxis }) => {
        if (setZoomedDataLimits) {
            setZoomedDataLimits(xaxis);
        }
    };

    const nowAnnotation = useMemo(() => (showNowAnnotation ? [{
        borderColor: '#747474',
        borderWidth: 1,
        label: {
            borderWidth: 0,
            offsetX: -10,
            style: {
                background: 'none',
                color: '#747474',
            },
            text: trans('SENSOR_DATA.NOW'),
            textAnchor: 'start',
        },
        strokeDashArray: 5,
        x: nowDateToNumber(),
    }] : []), [
        showNowAnnotation,
        temperatureData,
        inLocalTimeZone,
    ]);

    const beforeResetZoom = () => {
        if (onRefresh) {
            onRefresh();
        }
    };

    const temperatureExcursionAnnotation = useMemo(() => (dateTimeTemperatureExcursion ? [{
        borderColor: '#D44848',
        borderWidth: 4,
        label: {
            borderWidth: 0,
            style: {
                background: 'none',
            },
            text: trans('TEMPERATURE_STATUS.EXCURSION'),
            textAnchor: 'start',
        },
        strokeDashArray: 0,
        x: nowDateToNumber(),
    }] : []), [dateTimeTemperatureExcursion, inLocalTimeZone]);

    const customAnnotationsArray = useMemo(() => {
        return customAnnotations.map(annotationData => {
            return {
                borderColor: '#747474',
                borderWidth: 2,
                label: {
                    borderWidth: 0,
                    offsetX: annotationData.offsetX || (annotationData.anchor === 'end' ? 1 : -1) * 10,
                    style: {
                        background: 'none',
                        color: '#747474',
                        font: 'normal normal normal 16px/19px Roboto',
                    },
                    text: annotationData.title,
                    textAnchor: annotationData.anchor || 'start',
                },
                strokeDashArray: 0,
                x: dateToNumber(annotationData.date, inLocalTimeZone),
            };
        });
    }, [customAnnotations, inLocalTimeZone]);

    const colors = useMemo(() => {
        const temperatureColors = temperatureLabels.dataTypes.map((type, index) => CHART_GRAPH_COLORS[index]);
        const locationColors = locationSeries.map((location, index) => LOCATION_COLORS[index]);
        const doorInfoColors = doorInfoLabels.dataTypes.map((type, index) => CHART_ANNOTATION_COLORS[index]);

        const allColors = [
            ...temperatureColors,
            ...locationColors,
            ...doorInfoColors,
        ];

        return allColors.length === 0
            ? CHART_GRAPH_COLORS.at(-1)
            : allColors;
    }, [
        temperatureLabels,
        doorInfoLabels,
        locationSeries,
    ]);

    useEffect(() => {
        if (doorInfoData.length > 0 && setHiddenSeries) {
            setHiddenSeries(prev => ({ ...prev, DOOR: true }));
        }
    }, [doorInfoData]);
    const doorAnnotations = useMemo(() => {
        if (doorInfoData.length === 0) {
            return [];
        }
        if (hiddenSeries?.DOOR === true) {
            return [];
        }

        return doorInfoData.reduce((data, { d, t }, rawDataIndex) => {
            if (d.length === 0) {
                return data;
            }

            const isLast = rawDataIndex === doorInfoData.length - 1
                && moment().valueOf() - moment(t).valueOf() < 5 * TIME_IN_MS.hour;
            const items = d.reduce((currentData, value, index) => {
                return value === null || (rawDataIndex > 0 && doorInfoData[rawDataIndex - 1].d[index] === value)
                    ? currentData
                    : [
                        ...currentData,
                        {
                            borderColor: muiTheme.palette.common.black,
                            borderWidth: 1,
                            label: {
                                borderWidth: 0,
                                offsetX: isLast ? -50 : (value === 'OPEN' ? -25 : -22),
                                offsetY: value === 'OPEN' ? 0 : 150,
                                orientation: 'vertical',
                                style: {
                                    background: 'transparent',
                                    color: muiTheme.palette.common.black,
                                    font: 'normal normal normal 16px/19px Roboto',
                                    position: 'front',
                                },
                                text: value === 'OPEN' ? 'Packaging Opened'
                                    : 'Packaging Closed',
                                textAnchor: 'start',
                            },
                            strokeDashArray: 0,
                            x: dateToNumber(t, inLocalTimeZone),
                        },
                    ];
            }, []);

            return data.concat(items);
        }, []);
    }, [doorInfoData, inLocalTimeZone, hiddenSeries]);

    const temperatureRange = useMemo(() => {
        if (!showTempRange) {
            return [];
        }

        return [
            temperatureRangeMin
                ? {
                    borderColor: '#EDAE49',
                    borderWidth: tempRangeMinimalisticStyle ? 1 : 3,
                    label: {
                        borderWidth: 0,
                        offsetX: -10,
                        offsetY: 18,
                        style: {
                            background: 'none',
                            color: '#EDAE49',
                            fontSize: tempRangeMinimalisticStyle ? '10px' : '15px',
                        },
                        text: 'Min',
                        textAnchor: 'end',
                    },
                    strokeDashArray: tempRangeMinimalisticStyle ? 0 : 3,
                    y: temperatureRangeMin,
                }
                : null,
            temperatureRangeMax
                ? {
                    borderColor: '#EDAE49',
                    borderWidth: tempRangeMinimalisticStyle ? 1 : 3,
                    label: {
                        borderWidth: 0,
                        offsetX: -10,
                        style: {
                            background: 'none',
                            color: '#EDAE49',
                            fontSize: tempRangeMinimalisticStyle ? '10px' : '15px',
                        },
                        text: 'Max',
                        textAnchor: 'end',
                    },
                    strokeDashArray: tempRangeMinimalisticStyle ? 0 : 3,
                    y: temperatureRangeMax,
                }
                : null,
        ].filter(item => item !== null);
    }, [
        showTempRange,
        temperatureRangeMin,
        temperatureRangeMax,
    ]);

    const markerOptions = useMemo(() => {
        const locationWidth = tempRangeMinimalisticStyle ? 0 : 0;

        return {
            size: [...temperatureLabels.dataTypes.map(() => (showMarkers ? 3 : 0)),
                ...locationSeries.map(() => locationWidth)],
            strokeWidth: [...temperatureLabels.dataTypes.map(() => (temperatureData?.length >= 200 ? 0 : 1)),
                ...locationSeries.map(() => locationWidth)],
        };
    }, [
        showMarkers,
        locationSeries,
        temperatureLabels,
        tempRangeMinimalisticStyle,
    ]);

    useEffect(() => {
        if (setChartRef && chartRef?.current) {
            setChartRef(chartRef?.current?.chartRef);
        }
    }, [chartRef?.current]);

    const xaxisCategories = useMemo(() => {
        return temperatureData.map(({ t }) => dateToNumber(t, false));
    }, [temperatureData]);

    const excursionRangeSeries = useExcursionSeries({
        enabled: excursionRange,
        tempIndex: temperatureLabels?.positions?.indexOf('INTERNAL') || 0,
        temperatureData,
        temperatureRangeMax,
        temperatureRangeMin,
    });

    const series = useMemo((): any[] => {
        const {
            positions = [],
        } = temperatureLabels;

        if (temperatureData.length === 0 || positions.length === 0) {
            return [{ data: [] }];
        }

        const legendData = positions.map((position) => {
            if (position === 'UNSPECIFIED') {
                return trans('SENSOR_DATA.TEMPERATURE_IN_C');
            }

            return `${trans(`LANE_MANAGEMENT.${position === 'INTERNAL'
                ? 'INTERNAL_TEMPERATURE_IN_C' : 'AMBIENT_TEMPERATURE_IN_C'}`)}`;
        }) || [];

        if (isPredictedExcursion) {
            const splitIndex = measuredDataLength + 1 || 0;
            const lineCartData: ApexOptions['series'] = legendData.flatMap((name, i) => {
                // The first part of the series
                const data1 = temperatureData.slice(0, splitIndex + 1).filter(({ d }) => d[i] !== null)
                    .map(({ d }, dI) => ({
                        x: xaxisCategories[dI],
                        y: d[i],
                    }));

                // The second part of the series
                const data2 = temperatureData.slice(splitIndex - 1)
                    .filter(({ d }) => d[i] !== null).map(({ d }, dI) => ({
                        x: xaxisCategories[dI + splitIndex],
                        y: d[i],
                    }));

                // TODO add ascending sort
                return [
                    {
                        color: POSITION_TO_COLOR[positions[i]],
                        data: data2,
                        name: `Predicted ${name}`,
                        opacity: 1,
                        position: positions[i],
                        stroke: {
                            curve: 'stepline',
                            dashArray: 3,
                            width: 5,
                        },
                        type: 'rangeArea',
                    },
                    {
                        color: POSITION_TO_COLOR[positions[i]],
                        data: data1,
                        name: `${name}`,
                        opacity: 1,
                        position: positions[i],
                        stroke: {
                            curve: 'straight',
                            width: 2,
                        },
                        type: 'rangeArea',
                    },
                ];
            });

            return [...lineCartData, ...locationSeries, ...excursionRangeSeries];
        } else {
            const lineCartData: ApexOptions['series'] = legendData.map((name, i) => {
                const data = temperatureData.map(({ d }, dI) => ({
                    x: xaxisCategories[dI],
                    y: d[i] || null,
                }));

                return {
                    color: POSITION_TO_COLOR[positions[i]],
                    data,
                    name,
                    opacity: 1,
                    position: positions[i],
                    stroke: {
                        curve: 'straight',
                        width: 2,
                    },
                    type: 'rangeArea',
                };
            });

            return [...lineCartData, ...locationSeries, ...excursionRangeSeries];
        }
    }, [
        temperatureData,
        temperatureLabels,
        excursionRangeSeries,
        locationSeries,
        xaxisCategories,
        measuredDataLength,
    ]);

    useEffect(() => {
        if (chartRef?.current && hiddenSeries) {
            Object.keys(hiddenSeries).forEach((position) => {
                const seriesName = series?.find((s) => s.position === position)?.name;
                const {
                    globals,
                } = chartRef?.current?.chart?.w || {};
                const {
                    collapsedSeries,
                    seriesNames,
                } = globals || {};

                const seriesIndex = seriesNames?.indexOf(seriesName);
                const wasCollapsed = collapsedSeries?.some(it => it.index === seriesIndex);

                if (!seriesName) return;
                if (wasCollapsed === hiddenSeries[position]) return;
                if (seriesNames.length - collapsedSeries.length === 1 && !wasCollapsed) return;
                chartRef?.current?.chart?.toggleSeries(seriesName);
            });
        }
    }, [chartRef?.current, hiddenSeries, series]);

    const customTooltip = useCallback((opts) => generateTooltip(
        {
            isUTC: !inLocalTimeZone,
            locationHintInTooltip,
            opts,
            series,
            theme: muiTheme,
            trans,
        },
    ), [muiTheme, trans, locationHintInTooltip, inLocalTimeZone, series]);

    const onCsvDownload = useCallback(() => {
        if (csvDownload) {
            const headers = `Date and time${ (!inLocalTimeZone) ? ' (UTC)' : ''}, ${Object.values(series)
                .map((it) => it.name).join(',')}`;
            const csvRows: string[] = [headers.replace('/[^\x00-\x7F]/g', '')];

            for (let i = 0; i < series.length; i++) {
                xaxisCategories.forEach((x, index) => {
                    if (csvRows[index + 1] === undefined) {
                        const dateTimeString = getFormattedDate(x, inLocalTimeZone);

                        csvRows[index + 1] = `${dateTimeString},`;
                    }
                    const currentCell = series[i].data.find(it => it.x === x);
                    const isGateway = series[i].position === 'GATEWAY';
                    const dataInCell = currentCell
                        ? (isGateway ? !!currentCell.y : (currentCell?.y?.[0] || currentCell?.y))
                        : null;

                    csvRows[index + 1] += `${dataInCell || null}${i === series.length - 1 ? '' : ','}`;
                });
            }

            downloadFileFromString(
                csvRows.join('\n'),
                `temp_report${shipmentNumber !== '' ? `_${shipmentNumber}` : ''}_${serialNumber}.csv`,
            );
        }
    }, [csvDownload, series, inLocalTimeZone]);

    const customTools = useMemo<ApexTooltip[]>(() => {
        const tools: ApexTooltip[] = [];

        if (onRefresh) {
            tools.push({
                class: classes.refreshIcon,
                click() {
                    onRefresh();
                },
                icon: `<img src="${icons.refresh}" width="17" alt="refresh"/>`,
                index: -6,
                title: trans('SENSOR_DATA.REFRESH_DATA'),
            });
        }
        if (csvDownload) {
            tools.push({
                class: classes.downloadIcon,
                click() {
                    onCsvDownload();
                },
                icon: downloadIcon,
                index: 0,
                title: trans('CHART.EXPORT_TO_CSV'),
            });
        }

        return tools;
    }, [onRefresh, csvDownload, onCsvDownload]);

    const mouseMoveEvent = useCallback((e, chartContext, { config, dataPointIndex = -1, seriesIndex }) => {
        if (dataPointIndex === -1) return;
        const timestamp = config?.series?.[seriesIndex]?.data?.[dataPointIndex]?.x;

        if (setMouseMoveDataIndex && dataPointIndex !== -1 && timestamp) {
            setMouseMoveDataIndex(dataPointIndex, timestamp || null);
        }
    }, [setMouseMoveDataIndex]);

    const options = useMemo((): ApexOptions => {
        return {
            annotations: {
                xaxis: [
                    ...temperatureExcursionAnnotation,
                    ...nowAnnotation,
                    ...doorAnnotations,
                    ...customAnnotationsArray,
                ],
                yaxis: [
                    ...temperatureRange,
                ],
            },
            chart: {
                animations: {
                    animateGradually: {
                        enabled: false,
                    },
                    dynamicAnimation: {
                        enabled: false,
                    },
                    enabled: false,

                },
                events: {
                    beforeResetZoom,
                    beforeZoom,
                    legendClick(chart: any, seriesIndex?: number, options?: any) {
                        const {
                            globals,
                        } = options;
                        const {
                            collapsedSeries,
                            seriesNames,
                        } = globals;

                        const wasCollapsed = collapsedSeries?.some(it => it.index === seriesIndex);

                        if (!(collapsedSeries?.length === seriesNames.length - 1 && !wasCollapsed)) {
                            const seriesPosition = series[seriesIndex].position;

                            chart.toggleSeries(seriesNames[seriesIndex]);
                            if (hiddenSeries && setHiddenSeries && hiddenSeries[seriesPosition] !== undefined) {
                                setHiddenSeries((prev) => ({
                                    ...prev,
                                    [seriesPosition]: !wasCollapsed,
                                }));
                            }
                        }
                    },
                    mouseMove: mouseMoveEvent,
                    zoomed: beforeZoom,
                },
                fontFamily: 'Roboto, serif',
                id: chartId,
                locales: chartLocale,
                toolbar: {
                    autoSelected: 'zoom',
                    show: !disableToolbar,
                    tools: {
                        customIcons: customTools,
                        download: false,
                        reset: true,
                    },
                },
                type: 'rangeArea',
            },
            // @ts-ignore
            colors,
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: series.map(it => it.opacity || 1),
                type: 'solid',
            },
            legend: {
                horizontalAlign: 'center',
                offsetY: 30,
                onItemClick: {
                    toggleDataSeries: false,
                },
                position: 'top',
                show: isLogger || !temperatureLabels.positions.includes('UNSPECIFIED') || locationSeries.length > 0,
                showForSingleSeries: true,
            },
            markers: {
                shape: 'circle',
                size: 1,
                ...markerOptions,
            },
            showMap,
            stroke: {
                curve: series.map(it => it?.stroke?.curve || 'straight'),
                dashArray: series.map(it => it?.stroke?.dashArray || 0),
                width: series.map(it => it?.stroke?.width || 2),
            },
            theme: {
                mode: theme === 'default' ? 'light' : 'dark',
            },
            tooltip: {
                cssClass: classes.tooltip,
                custom: customTooltip,
                fixed: fixedTooltip,
                followCursor: true,
                shared: true,
            },
            xaxis: {
                categories: xaxisCategories,
                labels: {
                    datetimeUTC: !inLocalTimeZone,
                },
                max: xMax || xaxisCategories.at(-1) + TIME_IN_MS.minute * 15,
                min: xMin || xaxisCategories[0],
                tooltip: {
                    enabled: false,
                },
                type: 'datetime',
            },
            yaxis: {
                max: chartLimits.max,
                min: chartLimits.min,
            },
        };
    }, [
        chartId,
        chartLimits,
        markerOptions,
        temperatureRange,
        xaxisCategories,
        temperatureExcursionAnnotation,
        nowAnnotation,
        doorAnnotations,
        colors,
        theme,
        muiTheme,
        inLocalTimeZone,
        chartLocale,
        disableXaxisInTooltip,
        locationSeries,
        mouseMoveEvent,
        series,
        customTooltip,
        xMin,
        xMax,
    ]);

    return (
        <QuickHelpTooltip
            tooltipInfo={{
                childOffsetPercent: [100, 0],
                customRectSize: [csvDownload ? 72 : 48, 20],
                offsetPx: [-7, 4],
                position: 'auto',
                text: trans('QUICK_HELP.SENSOR_DATA.EXPORT_CHART'),
                uid: `$exportChartButton_${entityId}`,
            }}
        >
            <div
                className={sizeOverride ? classes.sizeOverrideContainer : ''}
                onContextMenu={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    resetZoom();
                }}
            >
                <ReactApexChart
                    ref={chartRef}
                    type="rangeArea"
                    {...(width ? { width } : {})}
                    {...(height ? { height } : {})}
                    {...(sizeOverride ? { width: 600 * defAspectRatio } : {})}
                    {...(sizeOverride ? { height: 600 } : {})}
                    options={options}
                    series={series}
                />
            </div>
        </QuickHelpTooltip>
    );
};

export default ApexTemperatureChart;
