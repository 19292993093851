import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';

type Props = {
    cargoId?: number,
    currentDate?: any,
    dateTimeFrom?:string,
    dateTimeTo?:string,
    isPredictedExcursion?: boolean,
    requestOptions?: SensorDataRequestBody,
    requestType: 'packagings' | 'loggers' | 'assets',
    serialNumber?: string,
    shipmentId?: string,
    shipmentNumber?: string
}
const useLoadedInitialData = ({
    cargoId,
    isPredictedExcursion = false,
    serialNumber,
    shipmentId,
    shipmentNumber,
}: Props) => {
    const { FlexibleRequest: getShipmentSensorData } = useSkymindBackendEndpoints('').requests;

    const {
        data: rawSensorData = null,
        isError,
        status,
    } = useCachedQueryRequest({
        key: {
            cargoId,
            isPredictedExcursion,
            serialNumber,
            shipmentId,
            shipmentNumber,
        },
        onCompleted: response => response?.data || null,
        options: {
            cacheTime: 9999999,
            retry: false,
        },
        request: () => {
            return isPredictedExcursion
                ? getShipmentSensorData('GET',
                `v2/shipments/${shipmentId}/cargo/${cargoId}/sensor-data-with-prediction`)
                : null;
        },
    });

    return {
        isError,
        rawSensorData,
        status,
    };
};

export default useLoadedInitialData;
