import useGeneratedStyles from 'hooks/useGeneratedStyles/useGeneratedStyles';
import useHasAccess from 'hooks/useHasAccess';
import React, {
    Dispatch,
    SetStateAction,
    useMemo, useState, useEffect,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getFieldCheckboxData } from 'shared-components/common';
import {
    FacetDTO,
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption, AirportOptions,
} from 'shared-components/SideFilter';
import CommonSearch from 'shared-components/CommonSearch';
import generateStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';

type Props = {
    countsLoading: boolean,
    facets: FacetDTO[],
    filterOptions: { [optionsGroupKey: string]: string[] },
    initialFacets: FacetDTO[],
    query?: string,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    setQuery: Dispatch<SetStateAction<string>>
}

const LaneFilters = ({
    countsLoading,
    facets,
    filterOptions,
    initialFacets,
    query,
    setFilterOptions,
    setQuery,
}: Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const hasAccess = useHasAccess();

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'fourthPartyLogisticsCompanyNames',
                }),
                optionsGroupKey: 'fourthPartyLogisticsCompanyNames',
                title: t('COMMON.4_PL'),
            },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'temperatureRanges',
                }),
                optionsGroupKey: 'temperatureRanges',
                title: t('COMMON.RANGE_C'),
            },
            // {
            //     title: t('COMMON.ORIGIN_AIRPORT'),
            //     options: getFieldCheckboxData(loadedFacets, 'originAirportCode'),
            //     optionsGroupKey: 'originAirportCode',
            // },
            // {
            //     title: t('COMMON.DESTINATION_AIRPORT'),
            //     options: getFieldCheckboxData(loadedFacets, 'destinationAirportCode'),
            //     optionsGroupKey: 'destinationAirportCode',
            // },
            // {
            //     title: t('COMMON.ORIGIN_LOCATION'),
            //     options: getFieldCheckboxData(loadedFacets, 'loadingLocationName'),
            //     optionsGroupKey: 'loadingLocationName',
            // },
            // {
            //     title: t('COMMON.DESTINATION_LOCATION'),
            //     options: getFieldCheckboxData(loadedFacets, 'unloadingLocationName'),
            //     optionsGroupKey: 'unloadingLocationName',
            // },
            {
                options: getFieldCheckboxData({
                    facets: loadedFacets,
                    initialFacets,
                    pluralName: 'status',
                    t,
                    tgroup: 'LANE_STATUS',
                }),
                optionsGroupKey: 'status',
                title: t('TRACK_AND_TRACE.STATUS'),
            },
        ];
    }, [loadedFacets, initialFacets, hasAccess]);

    const availableAirports = useMemo(() => {
        const avO = initialFacets?.find(({ filterName }) => filterName === 'originAirportCode')
            ?.counts?.map(it => it.filterValue);
        const avD = initialFacets?.find(({ filterName }) => filterName === 'destinationAirportCode')
            ?.counts?.map(it => it.filterValue);

        return {
            destinationAirports: avD,
            originAirports: avO,
        };
    }, [initialFacets]);

    return (
        <SideFilter id="CompanyPageFilter">
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <CommonSearch
                    query={query}
                    setQuery={setQuery}
                />
            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions = [] } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: facets.find(it => it.filterName === optionsGroupKey)
                                        ?.counts?.filter(it => it.filterValue !== null)
                                        ?.map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        ?.sort((a, b) => b.count - a.count || a.label.localeCompare(b.label))
                                        ?.map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    key={option.value}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    count={option.count}
                                                    countsLoading={countsLoading}
                                                    label={option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            {
                (availableAirports?.destinationAirports?.length > 0
                    || availableAirports?.originAirports?.length > 0) && (
                    <FilterBlock>
                        <AccordionOptionsGroup dynamic={false} title={t('LANE_MANAGEMENT.ADDRESS.AIRPORT')}>
                            <AirportOptions
                                availableDestinationAirports={availableAirports.destinationAirports}
                                availableOriginAirports={availableAirports.originAirports}
                                selectedDestinationAirports={filterOptions['destinationAirportCode']}
                                selectedOriginAirports={filterOptions['originAirportCode']}
                                setDestinationAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    destinationAirportCode: value,
                                }))}
                                setOriginAirports={(value) => setFilterOptions((prev) => ({
                                    ...prev,
                                    originAirportCode: value,
                                }))}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }

        </SideFilter>
    );
};

export default LaneFilters;
