import React, { useMemo } from 'react';
import {
    TIME_IN_MS,
} from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useClasses from 'hooks/useClasses';
import styles from './LastConnect.style';

type Props = {
    fontSize?: 'small' | 'medium' | 'large',
    fullText?: boolean,
    timestamp?: number,
    timestampUnit?: {
        unit: string,
        value: number
    },
    various?: boolean
}

const LastConnect = ({
    fontSize = 'large',
    fullText = false,
    timestamp = null,
    timestampUnit = null,
    various = false,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const now = moment().utcOffset(0, false).valueOf();

    const content = useMemo(() => {
        if (timestampUnit?.unit === 'MINUTES') {
            const mPlural = timestampUnit?.value !== 1;

            return {
                backgroundClass: classes.backgroundGreen,
                statusDotClass: classes.statusDotBackgroundGreen,
                text: fullText
                    ? `${t('SENSOR_DATA.MEASURED')} ${timestampUnit?.value} ${t(mPlural
                        ? 'SENSOR_DATA.MINUTES_AGO' : 'SENSOR_DATA.MINUTE_AGO')}`
                    : `${timestampUnit?.value}${t('TIME.M')}`,
            };
        }

        if (timestampUnit?.unit === 'HOURS') {
            const hPlural = timestampUnit?.value !== 1;

            return {
                backgroundClass: classes.backgroundYellow,
                statusDotClass: classes.statusDotBackgroundYellow,
                text: fullText
                    ? `${t('SENSOR_DATA.MEASURED')} ${timestampUnit.value} ${t(hPlural
                        ? 'SENSOR_DATA.HOURS_AGO' : 'SENSOR_DATA.HOUR_AGO')}`
                    : `${timestampUnit.value}${t('TIME.H')}`,
            };
        }
        if (timestampUnit?.unit === 'DAYS') {
            const dPlural = timestampUnit?.value !== 1;

            return {
                backgroundClass: classes.backgroundRed,
                statusDotClass: classes.statusDotBackgroundRed,
                text: fullText
                    ? `${t('SENSOR_DATA.MEASURED')} ${timestampUnit.value} ${t(dPlural
                        ? 'SENSOR_DATA.DAYS_AGO' : 'SENSOR_DATA.DAY_AGO')}`
                    : `${timestampUnit.value}d`,
            };
        }

        if (!timestamp
            || Number.isNaN(timestamp)
            || typeof timestamp !== 'number'
            || timestamp < 0
        ) {
            return null;
        }

        if (various) {
            return {
                backgroundClass: classes.backgroundBlue,
                statusDotClass: classes.statusDotBackgroundBlue,
                text: t('COMMON.VARIOUS'),
            };
        }

        const timePassed = Math.abs(moment(now).valueOf() - timestamp);

        if (timePassed <= TIME_IN_MS.minute * 50) {
            const minutes = Math.round(timePassed / TIME_IN_MS.minute / 10) * 10;
            const mPlural = minutes !== 1;

            return {
                backgroundClass: classes.backgroundGreen,
                statusDotClass: classes.statusDotBackgroundGreen,
                text: fullText
                    ? `${t('SENSOR_DATA.MEASURED')} ${minutes} ${t(mPlural
                        ? 'SENSOR_DATA.MINUTES_AGO' : 'SENSOR_DATA.MINUTE_AGO')}`
                    : `${minutes}${t('TIME.M')}`,
            };
        }

        if (timePassed < TIME_IN_MS.day) {
            const hours = Math.round(timePassed / TIME_IN_MS.hour);
            const hPlural = hours !== 1;

            return {
                backgroundClass: classes.backgroundYellow,
                statusDotClass: classes.statusDotBackgroundYellow,
                text: fullText
                    ? `${t('SENSOR_DATA.MEASURED')} ${hours} ${t(hPlural
                        ? 'SENSOR_DATA.HOURS_AGO' : 'SENSOR_DATA.HOUR_AGO')}`
                    : `${hours}${t('TIME.H')}`,
            };
        }

        const days = Math.round(timePassed / TIME_IN_MS.day);
        const dPlural = days !== 1;

        return {
            backgroundClass: classes.backgroundRed,
            statusDotClass: classes.statusDotBackgroundRed,
            text: fullText
                ? `${t('SENSOR_DATA.MEASURED')} ${days} ${t(dPlural
                    ? 'SENSOR_DATA.DAYS_AGO' : 'SENSOR_DATA.DAY_AGO')}`
                : `${days}d`,
        };
    }, [timestamp, various, fullText, now, classes, t]);

    if (!content) {
        return null;
    }

    return (
        <div className={`${classes.background} ${content.backgroundClass}`}>
            <span className={`${classes.statusDot} ${content.statusDotClass}`} />
            <div className={`${classes.text} ${classes[fontSize]}`}>
                {content.text}
            </div>
        </div>
    );
};

export default LastConnect;
