import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { AddPharmaSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CommonButton from 'shared-components/CommonButton';
import PharmaSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/PharmaSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import { isReadyPharmaSiteRequestBody } from 'Administration/lib';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import styles from 'Administration/PharmaSites/PharmaSites.style';

const AddPharmaSite = () => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddPharmaSiteRequestBody>(null);
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const [pharmaCompanyId, setPharmaCompanyId] = useState<number>(null);
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const { FlexibleRequest: createCompany } = useSkymindBackendEndpoints(
        isAdmin ? 'admin/pharma-companies' : 'pharma-companies',
    )
        .statusNotificationRequests;
    const [siteName, setSiteName] = useState('');
    const { t } = useCustomTranslation();

    const handleClickCreate = async () => {
        try {
            const url = `${pharmaCompanyId}/pharma-sites`;
            const { status = null } = await createCompany('POST', url, addCompanyRequestBody);

            if (status === 201) {
                navigate('/administration/pharma-sites');
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const handleClickCancel = () => {
        navigate('/administration/pharma-sites');
    };

    const handleNameChange = (name) => {
        setSiteName(name);
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            const { name = '', pharmaCompanyId = null } = companyInfo;

            setPharmaCompanyId(pharmaCompanyId);
            setAddCompanyRequestBody({
                address,
                contactInfo,
                name,
            });
        } else {
            setAddCompanyRequestBody(null);
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        setReadyToSubmission(isReadyPharmaSiteRequestBody(addCompanyRequestBody));
    }, [addCompanyRequestBody, siteName]);

    return (
        <>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyCardContentData}>
                    <PharmaSiteCard
                        currentCompanyType="PHARMA"
                        setFilter={setCompanyInfo}
                        type="new"
                        onNameChange={handleNameChange}
                    />
                    <AddressCard
                        setFilter={setAddress}
                        type="new"
                    />
                    <ContactInformationCard
                        setFilter={setContactInfo}
                        type="new"
                    />
                </div>
            </div>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyButtonContainer}>
                    <CommonButton
                        autoFocus
                        className={classes.addEditCompanyApproveButton}
                        disabled={!readyToSubmission || siteName.trim() === ''}
                        onClick={handleClickCreate}
                    >
                        {t('MY_COMPANY.CREATE')}
                    </CommonButton>
                    <CommonButton
                        className={classes.addEditCompanyRejectButton}
                        variant="outlined"
                        onClick={handleClickCancel}
                    >
                        {t('COMMON.CANCEL')}
                    </CommonButton>
                </div>
            </div>
        </>
    );
};

export default AddPharmaSite;
