import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import Assets from './Assets';
import Loggers from './Loggers';
import AddShipment from './GenericShipments/AddShipment';
import GenericShipments from './GenericShipments/GenericShipments';
import GenericShipmentDetails from './GenericShipmentDetails/GenericShipmentDetailsContainer';

const getTrackAndTraceRouter = (hasAccess: (...accessRoles: string[]) => boolean): RouteItem[] => {
    const redirectPath = (hasAccess(userRoles.TEMPERATURE_CHECK) || hasAccess(userRoles.SHIPMENT_MANAGEMENT))
        ? '/track-and-trace/generic-shipments'
        : hasAccess(userRoles.LOGGER_MANAGEMENT)
            ? '/track-and-trace/loggers'
            : '/track-and-trace/assets';

    return [
        {
            element: <Navigate to={redirectPath} />,
            path: '/track-and-trace',
        },
        {
            element: <GenericShipments />,
            path: '/track-and-trace/generic-shipments',
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
        },
        {
            element: <AddShipment />,
            path: '/track-and-trace/shipments/new',
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
        },
        {
            element: <GenericShipmentDetails />,
            path: '/track-and-trace/generic-shipments/:id',
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
        },
        {
            element: <Loggers />,
            path: '/track-and-trace/loggers',
            sufficientRoles: [
                userRoles.LOGGER_MANAGEMENT, userRoles.ASSET_MANAGEMENT,
            ],
        },
        {
            element: <Loggers core="asset-management" />,
            path: '/asset-management/loggers',
            sufficientRoles: [
                userRoles.LOGGER_MANAGEMENT, userRoles.ASSET_MANAGEMENT,
            ],
        },
        {
            element: <Assets />,
            necessaryRoles: [userRoles.ASSET_MANAGEMENT],
            path: '/asset-management/assets',
        },
        {
            element: <div><Navigate to="/asset-management/assets" /></div>,
            necessaryRoles: [userRoles.ASSET_MANAGEMENT],
            path: '/asset-management',
        },
    ];
};

export default getTrackAndTraceRouter;
