import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    PROCESS_EVIDENCE_STEP_NAME,
    PROCESS_EVIDENCE_STEP_TYPE,
    TEMPERATURE_STATUS,
} from 'shared-components/constants';
import {
    ContainerPartsDelivery,
    DeliveryPointsInfo,
    IconAndMessage,
    IconTooltipInfo,
    ProcessEvidenceDeliveryData,
} from 'shared-components/dataTypes';

export const getIconByStatus = (status: string): string => {
    switch (status) {
    case APPROVAL_STATUS.APPROVED:
        return icons.success;
    case APPROVAL_STATUS.REJECTED:
        return icons.warning_round;
    case APPROVAL_STATUS.PREDICTED_EXCURSION:
        return icons.warning_triangle;
    case TEMPERATURE_STATUS.IN_RANGE:
        return icons.success;
    case TEMPERATURE_STATUS.EXCURSION:
        return icons.warning_round;
    default:
        return icons.dots_round_grey;
    }
};

const getIconTooltipInfoDescription = (status: string): string => {
    switch (status) {
    case APPROVAL_STATUS.APPROVED:
        return `The picture-based check was
                    approved by ground staff.`;
    case APPROVAL_STATUS.NOT_APPLICABLE:
        return `Picture-based comparison is not
                    applicable for this packaging.`;
    case APPROVAL_STATUS.NOT_CHECKED:
        return `The picture-based comparison or
                    manual check at destination was
                    not yet done. This is typically
                    because the shipment has not yet
                    arrived.`;
    case APPROVAL_STATUS.REJECTED:
        return `The picture-based comparison or
                    manual check at destination was
                    rejected by ground staff. Please
                    check the evidence to see more
                    details.`;
    default:
        return 'No data';
    }
};

export const getIconTooltipInfo = (status: string, labels = {}): IconTooltipInfo => {
    return {
        description: getIconTooltipInfoDescription(status),
        descriptionTitle: labels[status] || status,
    };
};

const getComparisonMatchingInfo = (step): IconAndMessage => {
    if (step?.userInput?.comparisonMatching !== undefined) {
        const isSeal = step.stepName === PROCESS_EVIDENCE_STEP_NAME.SEAL.SEAL
            || step.stepName === PROCESS_EVIDENCE_STEP_NAME.SEAL.SEAL_NUMBER;

        if (step.userInput.comparisonMatching === true) {
            return ({
                icon: icons.success,
                statusMessage: `Ground Staff: ${isSeal ? 'Matching' : 'No Damage Detected'}`,
            });
        }
        return ({
            icon: icons.warning_round,
            statusMessage: `Ground Staff: ${isSeal ? 'Not Matching' : 'Damage Detected'}`,
        });
    }
    return ({
        icon: icons.dots_round_grey,
        statusMessage: 'Ground Staff: Comparison Pending',
    });
};

const getFilteredProcessEvidenceDeliveryData = (requiredStepNames, process, stepType) => {
    if (process?.progressGroups) {
        return process.progressGroups.reduce((steps, progressGroup) => {
            if (progressGroup.steps) {
                const filtered = progressGroup.steps.filter((step) => (
                    requiredStepNames.includes(step.stepName)
                    && step.stepType === stepType
                    && step.userInput?.picture?.attachmentUrl !== undefined
                    && step.userInput.picture.createdOn !== undefined
                ));

                return [...steps, ...filtered];
            }
            return steps;
        }, []);
    }
    return [];
};

const getFullNameByEmail = (email: string, users = []): string => {
    if (email && !email.includes('@')) {
        return email;
    }

    const selectedUser = users.find((user) => user.email === email);

    return selectedUser?.fullName || email;
};

const getPartsDeliveryInfo = (step, users, labels): ContainerPartsDelivery => {
    if (step) {
        const { stepSubtitle = '', userInput = {} } = step;
        const { picture = {} } = userInput;
        const {
            attachmentUrl = '',
            createdBy = '',
            createdOn = '',
            description = '',
        } = picture;

        return {
            comment: description,
            picture: attachmentUrl,
            pictureTimestamp: createdOn,
            side: labels[stepSubtitle] || stepSubtitle,
            takenBy: getFullNameByEmail(createdBy, users),
        };
    }
    return ({
        picture: icons.hourglass_pending,
        pictureTimestamp: '-',
        takenBy: '-',
    });
};

export const getProcessEvidenceDeliveryData = (partTypes,
    sendingProcess, receivingProcess, users, labels): ProcessEvidenceDeliveryData => {
    const filteredProcessEvidenceSending = getFilteredProcessEvidenceDeliveryData(
        Object.keys(partTypes),
        sendingProcess,
        PROCESS_EVIDENCE_STEP_TYPE.PICTURE,
    );

    const filteredProcessEvidenceReceiving = getFilteredProcessEvidenceDeliveryData(
        Object.keys(partTypes),
        receivingProcess,
        PROCESS_EVIDENCE_STEP_TYPE.PICTURE_COMPARISON,
    );

    const sendingParts = filteredProcessEvidenceSending.map((sendingStep) => (
        getPartsDeliveryInfo(sendingStep, users, labels)
    ));

    const receivingParts = filteredProcessEvidenceSending.length > 0
        ? filteredProcessEvidenceSending.map((sendingStep) => {
            const selectedReceivingStep = filteredProcessEvidenceReceiving.find((receivingStep) => (
                receivingStep.stepName === sendingStep.stepName
            ));

            return getPartsDeliveryInfo(selectedReceivingStep, users, labels);
        })
        : filteredProcessEvidenceReceiving.map((sendingStep) => (
            getPartsDeliveryInfo(sendingStep, users, labels)
        ));

    const comparisonMatchingInfo = filteredProcessEvidenceSending.map((sendingStep) => {
        const selectedReceivingStep = filteredProcessEvidenceReceiving.find((receivingStep) => (
            receivingStep.stepName === sendingStep.stepName
        ));

        return getComparisonMatchingInfo(selectedReceivingStep);
    });

    return {
        comparisonMatchingInfo,
        receivingParts,
        sendingParts,
    };
};

export const getDeliveryPointsInfo = (order): DeliveryPointsInfo => {
    const {
        collectionDropoffPoint: cP = {},
        handoverPoint: hP = {},
    } = order;

    const getLocationName = (point) => {
        switch (point.locationType) {
        case 'ADDRESS':
            return point.locationName;
        case 'AIRPORT':
            return `${point.iataCode} | ${point.locationName}`;
        case 'SEAPORT':
            return `${point.unloCode} | ${point.locationName}`;
        default:
            return '';
        }
    };

    const getAddress = (point) => {
        if (point.locationType === 'ADDRESS') {
            return `${point.addressLine1}\n${point.zip} ${point.city} ${point.country.countryName}`;
        }
        return point?.country?.countryName || '';
    };

    const getContacts = (rawContacts = []) => (
        rawContacts.map((contact) => ({
            contact: `${contact.email || ''}\n${contact.mobilePhone || ''}`,
            person: contact.contactName || '',
            personIcon: icons.person,
        }))
    );

    return ({
        collectionPoint: {
            address: getAddress(cP),
            contacts: getContacts(order?.collectionDropoffPointContacts),
            locationIcon: icons.location_type[cP?.locationType],
            locationName: getLocationName(cP),
        },
        handoverPoint: {
            address: getAddress(hP),
            contacts: getContacts(order?.handoverPointContacts),
            locationIcon: icons.location_type[hP?.locationType],
            locationName: getLocationName(hP),
        },
    });
};
