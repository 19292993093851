import React, { MouseEventHandler } from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { getCenteredPositionOffset, MapElement } from 'TrackAndTrace/Assets/lib';

type Props = {
    isSelected: boolean,
    mapElement: MapElement,
    onHover: MouseEventHandler<HTMLDivElement>
}
const AreaMarker = ({
    isSelected = false,
    mapElement,
    onHover,
}: Props) => {
    const position = {
        lat: mapElement.geolocation?.latitude,
        lng: mapElement.geolocation?.longitude,
    };

    return (
        <OverlayViewF
            getPixelPositionOffset={getCenteredPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={position}
            zIndex={isSelected ? 1000 : 5}
        >
            <div onClick={onHover} onMouseEnter={onHover}>
                <PolygonMarker
                    count={mapElement?.count}
                    filled={isSelected}
                    iconDiameter={35}
                    polygonIconColor="blue"
                    polygonIconType="hexa"
                    theme="light"
                />
            </div>
        </OverlayViewF>
    );
};

export default AreaMarker;
