import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Chip, FormControl, MenuProps, SelectChangeEvent, CircularProgress, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useMemo } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const PropsForMenu: Partial<MenuProps> = {
    sx: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        maxWidth: '200px',
        scrollbarWidth: '5px',
    },
};

type Props = {
    data: {
        [key: string]: any;
        id?: number;
    }[];
    disabled?: boolean;
    handleDelete: (id: number) => void;
    hasError?: boolean;
    labelId?: string;
    loading?: boolean;
    onChange: (event: SelectChangeEvent<number[]>) => void;
    placeholder?: string;
    selectedIds: number[];
    titlePlural?: string;
};

const rootClass = css({
    '&&.Mui-disabled': {
        opacity: 0.6,
    },
    '&&.Mui-disabled:before': {
        borderBottomStyle: 'solid',
    },
});

const rootErrorClass = css({
    '&&.Mui-disabled:before': {
        borderColor: 'red',
    },
});

const MultiSelect = ({
    data,
    disabled = false,
    handleDelete,
    hasError = false,
    labelId = 'name',
    loading = false,
    onChange,
    placeholder,
    selectedIds,
    titlePlural = 'Items',
}: Props) => {
    const theme = useTheme();

    const shrinked = useMemo(() => {
        if (placeholder) {
            return selectedIds.length > 0;
        } else return true;
    }, [selectedIds, placeholder]);

    return (
        <FormControl sx={{ m: 0, width: '100%' }} variant="standard">
            {
                titlePlural && (
                    <InputLabel
                        color="secondary"
                        htmlFor={`${titlePlural}_chips_${labelId}`}
                        shrink={shrinked}
                        style={{
                            color: theme.palette.common.black,
                            opacity: shrinked ? 1 : 0,
                            transition: 'all 0.2s ease',
                        }}
                    >
                        {titlePlural}
                    </InputLabel>
                )
            }
            {loading ? (
                <Box sx={{ alignItems: 'center', display: 'flex', height: '32px', justifyContent: 'center' }}>
                    <CircularProgress size={24} />
                </Box>
            ) : (
                <Select
                    MenuProps={PropsForMenu}
                    classes={{
                        root: [rootClass, hasError ? rootErrorClass : ''].join(' '),
                    }}
                    disabled={disabled}
                    displayEmpty={!!placeholder}
                    id={`${titlePlural}_chips_${labelId}`}
                    multiple
                    placeholder={placeholder || `Select ${titlePlural}`}
                    renderValue={(selected) => {
                        if (selected.length === 0 && placeholder) {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                        margin: '0',
                                    }}
                                    zIndex={99999999}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    onMouseDown={(event) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <span style={{ color: '#707070' }}>{placeholder}</span>
                                </Box>
                            );
                        }

                        return (
                            <Box
                                sx={{
                                    '& > *': {
                                        margin: '0',
                                    },
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                                zIndex={99999999}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                {selected.map(id => data.find(it => it.id === id)).map((value) => (
                                    <Chip
                                        key={`${value.id}_${value.name}_chip`}
                                        deleteIcon={(
                                            <Close sx={{
                                                color: '#707070',
                                                fontSize: '14px!important',
                                            }}
                                            />
                                        )}
                                        label={value?.name}
                                        sx={{
                                            ':hover': {
                                                backgroundColor: '#F4F4F4',
                                            },
                                            backgroundColor: '#F4F4F4',
                                            color: '#000000DE',
                                            height: '24px',
                                            margin: '2px 4px',
                                        }}
                                        onDelete={() => handleDelete(value.id)}
                                    />
                                ))}
                            </Box>
                        );
                    }}
                    value={selectedIds}
                    onChange={onChange}
                >
                    {data.map((item) => (
                        <MenuItem
                            key={`${item.id}_${item[labelId]}_chip`}
                            sx={{
                                backgroundColor: selectedIds.includes(item.id)
                                    ? `${theme.palette.primary[100]}!important` : '',
                                color: selectedIds.includes(item.id)
                                    ? `${theme.palette.common.white}!important` : '',
                            }}
                            value={item.id}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export default MultiSelect;
