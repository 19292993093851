import Users from 'Administration/Users';
import Incoming from 'MyCompany/AccessRequests/Incoming';
import Outgoing from 'MyCompany/AccessRequests/Outgoing';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import EditUser from 'Administration/Users/tabs/EditUser';
import AddUser from 'Administration/Users/tabs/AddUser';
import CompanyInfoOverview from './CompanyInfo/tabs/Overview';

import Sites from './Sites';
import AddPharmaSite from './Sites/tabs/AddPharmaSite';
import EditPharmaSite from './Sites/tabs/EditPharmaSite';
import LocationCapabilities from './Sites/tabs/Capabilities';
import LocationQuestionnaire from './Sites/tabs/Questionnaire';
import LocationGallery from './Sites/tabs/Gallery';

const MyCompanyRouter: RouteItem[] = [
    {
        element: <Navigate to="/my-company/company/overview" />,
        necessaryRoles: [],
        path: '/my-company',
    },
    {
        element: <Navigate to="/my-company/company/overview" />,
        necessaryRoles: [],
        path: '/my-company/company',
    },
    {
        element: <CompanyInfoOverview />,
        necessaryRoles: [],
        path: '/my-company/company/overview',
    },
    {
        element: <div>Capabilities</div>,
        path: '/my-company/company/capabilities',
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
    },
    {
        element: <div>Questionnaire</div>,
        path: '/my-company/company/questionnaire',
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
    },
    {
        element: <div>Monitoring</div>,
        path: '/my-company/company/monitoring',
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
    },
    {
        element: <Sites />,
        path: '/my-company/sites',
        sufficientRoles: [
            userRoles.SITE_MANAGEMENT,
            userRoles.CUSTOMER_ADMIN,
        ],
    },
    {
        element: <Users />,
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users',
    },
    {
        element: <AddUser />,
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users/new',
    },
    {
        element: <EditUser />,
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        path: '/my-company/users/:userId',
    },
    {
        element: <AddPharmaSite />,
        path: '/my-company/sites/new',
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
    },
    {
        element: <EditPharmaSite />,
        path: '/my-company/sites/:siteId/overview',
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
    },
    {
        element: <LocationCapabilities />,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/capabilities',
    },
    {
        element: <LocationQuestionnaire />,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/questionnaire',
    },
    {
        element: <LocationGallery />,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/gallery',
    },
    {
        element: <div>Documents</div>,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/documents',
    },
    {
        element: <div>Information Sharing</div>,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/information-sharing',
    },
    {
        element: <div>Lanes</div>,
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        path: '/my-company/sites/:providerId/lanes',
    },
    {
        element: <Navigate to="/my-company/access-requests/outgoing-requests" />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests',
    },
    {
        element: <Outgoing />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests/outgoing-requests',
    },
    {
        element: <Incoming />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: '/my-company/access-requests/incoming-requests',
    },

];

export default MyCompanyRouter;
