export type CompanyType = 'SERVICE_PROVIDER' | 'PHARMA'

export type energyLevelThreshold = number

export interface Geolocation {
    latitude: number,
    longitude: number
}

export interface LabeledEnumDto {
    code: string,
    label: string,
}

export interface Country {
    code: string,
    name: string,
}

export interface Address {
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    city: string,
    country: Country,
    geolocation: Geolocation,
    zipCode: string
}

export interface ContactInfo {
    contactPerson: string,
    email: string,
    phoneNumber: string
}

export interface Airport {
    airportName: string,
    geolocation: Geolocation,
    iataCode?: string
}

export interface AirportExtended {
    category: number,
    city: string,
    code: string,
    country:Country,
    geolocation: Geolocation,
    name: string,
}

export interface Seaport {
    code: string,
    country: Country,
    geolocation: Geolocation,
    name: string
}
export interface SeaportExtended {
    category: number,
    city: string,
    code: string,
    country:Country,
    geolocation: Geolocation,
    name: string,
}

export interface RentalInformation {
    rentalPeriodFrom: string,
    rentalPeriodTo: string,
    rentedBy: {
        address: Address,
        companyType: CompanyType,
        contactInfo: ContactInfo,
        id: number,
        logoImagePath: string,
        name: string,
        website: string
    }
}

export type DayOfWeek = 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'

export const weekDaysWithLabel = {
    FRIDAY: 'Friday',
    MONDAY: 'Monday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    THURSDAY: 'Thursday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
};

export const weekDaysWithLabelT = (t): { day: DayOfWeek, label: string }[] => ([
    { day: 'MONDAY', label: t('SERVICE_PROVIDERS.MONDAY') },
    { day: 'TUESDAY', label: t('SERVICE_PROVIDERS.TUESDAY') },
    { day: 'WEDNESDAY', label: t('SERVICE_PROVIDERS.WEDNESDAY') },
    { day: 'THURSDAY', label: t('SERVICE_PROVIDERS.THURSDAY') },
    { day: 'FRIDAY', label: t('SERVICE_PROVIDERS.FRIDAY') },
    { day: 'SATURDAY', label: t('SERVICE_PROVIDERS.SATURDAY') },
    { day: 'SUNDAY', label: t('SERVICE_PROVIDERS.SUNDAY') },
]);
