import React from 'react';
import Card from 'shared-components/Card';

import { PICTURES } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';

const NoOpenEntity = () => {
    const { t } = useCustomTranslation();

    return (
        <Card>
            <div style={{ display: 'flex' }}>
                <img alt="robot" src={PICTURES.searching_robot} />
                <div
                    style={{
                        color: '#191919',
                        font: 'normal normal 100 36px/48px Roboto',
                        letterSpacing: '0.46px',
                        margin: '10% 10%',
                        opacity: 1,
                    }}
                >
                    {t('SENSOR_DATA.NO_OPEN_SHIPMENT_OR_PACKAGING')}
                </div>
            </div>
        </Card>
    );
};

export default NoOpenEntity;
