import useClasses from 'hooks/useClasses';
import React, { useMemo } from 'react';
import { Collapse } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Classes } from 'jss';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess from 'hooks/useHasAccess';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import icons from 'shared-components/icons';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import TemperatureInTempRange from 'shared-components/TemperatureInTempRange';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import TemperatureCheckIcon from 'shared-components/TemperatureCheckIcon';
import {
    getRejectedStatuses,
} from './lib';
import {
    styles,
} from '../ClusterBodyItem.style';
import TemperatureInfoBlock from './TemperatureInfoBlock';
import LastMeasuredBlock from './LastMeasuredBlock';

type Props = {
    breach?: boolean,
    cargo?: GenericCargo,
    energyLevel?: number,
    packagingInfoOpen: string[],
    togglePackagingInfo: Function
}

interface StatusElementParams {
    classes: Classes;
    temperatureCheckStatus: string;
    text: string;
}

const StatusElement = ({ classes, temperatureCheckStatus, text }: StatusElementParams) => {
    return (
        <div className={classes.temperatureStatus}>
            <div style={{ marginRight: 3 }}>
                <TemperatureCheckIcon
                    size="16px"
                    temperatureCheckStatus={temperatureCheckStatus || 'NO_DATA'}
                />
            </div>
            <div>
                {text}
            </div>
        </div>
    );
};

const PackagingInfo = ({
    breach,
    cargo,
    energyLevel,
    packagingInfoOpen,
    togglePackagingInfo,
}: Props) => {
    const classes = useClasses(styles);
    const hasAccess = useHasAccess();
    const { t } = useCustomTranslation();
    const {
        lastMeasuredData,
        packaging,
        skyCoreProductRelease,
        temperatureCheckResult,
    } = cargo || {};
    const {
        jypId,
        packagingTypeCode,
        serialNumber,
    } = packaging || {};
    const {
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
        sealStatus,
    } = skyCoreProductRelease || {};
    const {
        temperatureMax,
        temperatureMin,
        temperatureRangeMax,
        temperatureRangeMin,
        temperatureStatus,
    } = temperatureCheckResult || {};
    const {
        temperatureAmbient,
        temperatureAmbientTimestampUnit,
        temperatureInternal,
        temperatureInternalTimestampUnit,
    } = lastMeasuredData || {};
    const temperatureRange = [
        temperatureRangeMin,
        temperatureRangeMax,
    ];

    const infoOpen = useMemo(() => {
        return packagingInfoOpen.includes(serialNumber);
    }, [packagingInfoOpen, serialNumber]);
    const rejectedStatuses = useMemo(() => getRejectedStatuses({
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
        sealStatus,
    }), [
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    ]);

    const hasCollapse = useMemo(() => temperatureInternal && temperatureAmbient,
        [temperatureInternal, temperatureAmbient]);

    return (
        <div className={classes.division}>
            <div className={classes.packagingTop}>
                <div className={classes.packagingTopLeft}>
                    <img alt="container_black" src={icons.container_box_black} />
                </div>
                <div className={classes.packagingTopMiddle}>
                    <div className={classes.containerName}>
                        <CommonTooltip
                            description={t('LABEL_TOOLTIPS.PACKAGING_NUMBER_TYPE')}
                            placement="left"
                            type={TOOLTIP_TYPE.SHRINK}
                        >
                            <div className={classes.containerNumber}>
                                {serialNumber}
                            </div>
                        </CommonTooltip>
                        <div className={classes.containerLabel}>
                            {`${jypId} | ${t(`PACKAGING_CODE_LABEL.${packagingTypeCode}`)}`}
                        </div>
                    </div>
                    <CommonTooltip
                        description={t('EXPLANATION_TOOLTIPS.PACKAGING_TEMPERATURE_MAX_MIN')}
                        placement="top"
                    >
                        <div className={classes.containerStatus}>
                            <div className={classes.containerStatusTitle}>
                                {
                                    rejectedStatuses.length === 1 && (
                                        <StatusElement
                                            classes={classes}
                                            temperatureCheckStatus={TEMPERATURE_STATUS.UNKNOWN}
                                            text={t(`DAMAGE_STATUS.${rejectedStatuses[0]}`)}
                                        />
                                    )
                                }
                                {
                                    rejectedStatuses.length > 1 && (
                                        <StatusElement
                                            classes={classes}
                                            temperatureCheckStatus={TEMPERATURE_STATUS.UNKNOWN}
                                            text={t('DAMAGE_STATUS.SEVERAL_DAMAGES')}
                                        />
                                    )
                                }
                                <StatusElement
                                    classes={classes}
                                    temperatureCheckStatus={packaging?.serialNumber === 'JNN-52236'
                                        ? 'PREDICTED_EXCURSION' : temperatureStatus}
                                    text={packaging?.serialNumber === 'JNN-52236'
                                        ? t('TEMPERATURE_STATUS.PREDICTED_EXCURSION')
                                        : t(`TEMPERATURE_STATUS.${temperatureStatus}`)}
                                />
                            </div>
                            <div className={classes.containerStatusDescription}>
                                <TemperatureInfoBlock
                                    temperatureMax={temperatureMax}
                                    temperatureMin={temperatureMin}
                                    temperatureRangeMax={temperatureRangeMax}
                                    temperatureRangeMin={temperatureRangeMin}
                                />
                            </div>
                            {packaging?.serialNumber === 'JNN-52236'
                                && (
                                    <div className={classes.containerStatusDescription}>
                                        Predicted:&nbsp;
                                        <TemperatureInTempRange
                                            tempRange={temperatureRange}
                                            temperature={9.0}
                                        />
                                        (expected in 3d 10h)
                                    </div>
                                )}
                            {
                                hasAccess('INTELLIGENT_MONITORING')
                                && breach && (
                                    <div className={classes.status}>
                                        <img
                                            key="icon_support"
                                            alt="support"
                                            className={classes.icon}
                                            src={Number(((energyLevel / 1) * 100)
                                                .toFixed(0)) === 0 ? icons.red_triangle : icons.warning_triangle}
                                        />
                                        <div>
                                            {`${t('COMMON.LOW_ENERGY_LEVEL')}: 
                                        ${((energyLevel / 1) * 100).toFixed(0)}%`}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </CommonTooltip>
                </div>
                <div className={classes.packagingTopRight}>
                    <div
                        className={classes.containerExpand}
                        onClick={(e) => togglePackagingInfo(e, serialNumber)}
                        onTouchEnd={(e) => {
                            e.stopPropagation();
                            return togglePackagingInfo(e, serialNumber);
                        }}
                        onTouchStart={(e) => {
                            e.stopPropagation();
                            return togglePackagingInfo(e, serialNumber);
                        }}
                    >
                        {
                            hasCollapse && (
                                <KeyboardArrowDown
                                    className={packagingInfoOpen.includes(serialNumber) ? classes.rotate : ''}
                                />
                            )
                        }
                    </div>
                </div>
            </div>

            {
                hasCollapse && (
                    <Collapse in={infoOpen}>
                        <div className={classes.packagingBottom}>
                            <LastMeasuredBlock
                                lastMeasuredTempAmbient={temperatureAmbient}
                                lastMeasuredTempAmbientTimestampUnit={temperatureAmbientTimestampUnit}
                                lastMeasuredTempInternal={temperatureInternal}
                                lastMeasuredTempInternalTimestampUnit={temperatureInternalTimestampUnit}
                                temperatureRange={temperatureRange}
                            />
                        </div>
                    </Collapse>
                )
            }

        </div>
    );
};

export default PackagingInfo;
