const styles = {
    button: {
        margin: '15px 7px',
        textTransform: 'uppercase',
    },
    picture: {
        background: 'transparent 0% 0% no-repeat padding-box',
        opacity: 1,
        paddingTop: '30px',
        width: '45%',
    },
    row: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    text: {
        color: '#000000DE',
        float: 'right',
        font: 'normal normal 300 36px/43px Roboto',
        letterSpacing: '-0.32px',
        opacity: 1,
        textAlign: 'right',
        width: '50%',
    },
    title: {
        color: '#000000DE',
        font: 'normal normal 300 56px/67px Roboto',
        letterSpacing: '-0.49px',
        opacity: 1,
        textAlign: 'center',
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
};

export default styles;
