import useGeneratedStyles from 'hooks/useGeneratedStyles';
import React, { useContext } from 'react';
import Milestones from 'shared-components/Milestones';
import generateStyles from 'LaneManagement/LaneDetails/LaneDetails.style';
import { AddressDTO } from 'shared-components/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import Card from 'shared-components/Card';

type Props = {
    flightsData: AddressDTO[]
}

// eslint-disable-next-line no-empty-pattern
const Flights = ({
    flightsData = [],
}: Props) => {
    const classes = useGeneratedStyles(generateStyles);
    const { t } = useCustomTranslation();
    const {
        editingCard,
    } = useContext(LaneCardsContext);

    return (
        <Card
            className={classes.flightsCard}
            contentClass={classes.flightsCardRoot}
            disabled={!!editingCard}
            title={t('LANE_MANAGEMENT.FLIGHTS')}
        >
            <div className={[classes.cardBody, classes.flightsCardContent].join(' ')}>

                {
                    flightsData.length === 0 && (
                        <div className={classes.simpleText}>
                            {t('LANE_MANAGEMENT.FLIGHTS_DESCRIPTION')}
                        </div>
                    )
                }
                {
                    flightsData.length !== 0 && (
                        <Milestones data={
                            flightsData
                        }
                        />
                    )
                }
            </div>
        </Card>
    );
};

export default Flights;
