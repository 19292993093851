/* eslint-disable react/jsx-key */
import useClasses from 'hooks/useClasses';
import React, { MouseEventHandler, useMemo } from 'react';
import Card from 'shared-components/Card';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { WarningOutlined } from '@mui/icons-material';
import CommonButton from 'shared-components/CommonButton';
import { styles } from './RequestResultCard.style';

type Props = {
    error?: boolean,
    onTryAgain?: MouseEventHandler,
}
const RequestResultCard = ({
    error = false,
    onTryAgain = null,
}: Props) => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const { t } = useCustomTranslation();

    const additionalText = useMemo(() => {
        const s = t('TRACK_AND_TRACE.ADD_SHIPMENT.WE_ARE_HERE_FOR_YOU_IF_YOU_NEED_HELP');

        if (!s) return '';
        const strings = s.split(/[#]+/g);

        return strings.map((it, i) => {
            if (i === 1) {
                return (
                    <Link
                        href={`mailto:${it}`}
                    >
                        {it}
                    </Link>
                );
            } else {
                return <>{it}</>;
            }
        });
    }, [t]);

    return (
        <Card
            className={classes.card}
            contentClass={classes.root}
            title=""
        >
            <div className={classes.textWrapper}>
                {
                    !error && (
                        <span className={classes.message}>
                            {t('TRACK_AND_TRACE.ADD_SHIPMENT.WE_HAVE_RECEIVED_YOUR_REQUEST')}
                        </span>
                    )
                }
                {
                    error && (
                        <span className={[classes.message, classes.error].join(' ')}>
                            <WarningOutlined style={{
                                fontSize: '42px',
                                marginRight: 20,
                            }}
                            />
                            {t('TRACK_AND_TRACE.ADD_SHIPMENT.WE_RAN_INTO_PROBLEMS')}
                        </span>
                    )
                }
                <span className={classes.description}>
                    {additionalText}
                </span>
            </div>
            <div className={classes.buttonWrapper}>
                {
                    !onTryAgain && (
                        <CommonButton
                            className={classes.button}
                            onClick={() => navigate('/track-and-trace/shipments')}
                        >
                            {t('TRACK_AND_TRACE.ADD_SHIPMENT.GO_BACK')}
                        </CommonButton>
                    )
                }
                {
                    onTryAgain && (
                        <CommonButton
                            className={classes.button}
                            onClick={onTryAgain}
                        >
                            {t('COMMON.TRY_AGAIN')}
                        </CommonButton>
                    )
                }
            </div>
        </Card>
    );
};

export default RequestResultCard;
