import useGetCommonData from 'hooks/useGetCommonData';
import { useCallback, useMemo } from 'react';
import { Seaport, AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import { AddressDTO } from 'shared-components/dataTypes';
import { nonNullObject, nonNullKey } from 'shared-components/common';

export enum ENTITY_TYPE {
    ADDRESS='address',
    AIRPORT='airport',
    COMPANY='company',
    PHARMA_SITE='pharmaSite',
    SEAPORT='seaport',
    SERVICE_PROVIDER_SITE='serviceProviderSite'
}
export enum ENTITY_TYPE_ID {
    ADDRESS='address',
    AIRPORT='airportCode',
    PHARMA_SITE='pharmaSiteId',
    SEAPORT='seaportCode',
    SERVICE_PROVIDER_SITE='serviceProviderSiteId'
}

export const extractId = (entity: AddressDTO) => {
    const obj = nonNullObject(entity);

    return obj?.code || obj?.id;
};
const useGetEntity: () => {
    dataLoaded: boolean,
    entityFromAddressId: (id: string | number | any) => any,
    getEntity: (id: number | string, type: ENTITY_TYPE) => any,
    replaceWithAddressDTO: (obj: any, replaces: {[key:string]: ENTITY_TYPE}) => any
} = () => {
    const {
        data: airports,
        status: airportsStatus,
    } = useGetCommonData<AirportExtended[]>('airports');
    const {
        data: seaports,
        status: seaportsStatus,
    } = useGetCommonData<Seaport[]>('seaports');
    const {
        data: pharmaSites,
        status: pharmaStatus,
    } = useGetCommonData('pharma-sites', { postProcess: it => it.resultList });
    const {
        data: serviceProviderSites,
        status: serviceProviderStatus,
    } = useGetCommonData('service-provider-sites', { postProcess: it => it.resultList });
    const {
        data: companies,
        status: companiesStatus,
    } = useGetCommonData('companies', { postProcess: it => it.resultList });
    const dataLoaded = useMemo(() => {
        return [companiesStatus, serviceProviderStatus, pharmaStatus, airportsStatus, seaportsStatus]
            .every(it => it === 'SUCCESS');
    }, [
        airports,
        seaports,
        pharmaSites,
        serviceProviderSites,
        companies,
    ]);

    const getEntity = useCallback((id: number | string | object, type: ENTITY_TYPE) => {
        switch (type) {
        case ENTITY_TYPE.AIRPORT:
            return airports.find(it => it.code === id);
        case ENTITY_TYPE.SEAPORT:
            return seaports.find(it => it.code === id);
        case ENTITY_TYPE.PHARMA_SITE:
            return pharmaSites.find(it => it.id === id);
        case ENTITY_TYPE.SERVICE_PROVIDER_SITE:
            return serviceProviderSites.find(it => it.id === id);
        case ENTITY_TYPE.ADDRESS:
            return id;
        case ENTITY_TYPE.COMPANY:
            return companies.find(it => it.id === id);
        default:
            return null;
        }
    },
    [
        airports,
        seaports,
        pharmaSites,
        serviceProviderSites,
        companies,
    ]);
    const replaceWithAddressDTO = useCallback((obj: any, replaces: {[key:string]: ENTITY_TYPE}) => {
        const newObj = { ...obj };

        Object.keys(replaces).forEach((key) => {
            const addressType = replaces[key];

            newObj[key] = {
                [addressType]: getEntity(obj[key], addressType),
            };
        });
        return newObj;
    },
    [getEntity]);

    const entityFromAddressId = useCallback((obj: any) => {
        if (!obj) return null;
        const reverseMap = Object
            .entries(ENTITY_TYPE_ID)
            .reduce((acc, [key, value]) => ({
                ...acc,
                [value]: key,
            }), {});
        const key = nonNullKey(obj);

        if (key) {
            const entityId = obj?.[key];

            const type:ENTITY_TYPE = ENTITY_TYPE[reverseMap[key]];

            return {
                [type]: getEntity(entityId, type),
            };
        } else {
            return null;
        }
    },
    [getEntity, dataLoaded]);

    return {
        dataLoaded,
        entityFromAddressId,
        getEntity,
        replaceWithAddressDTO,
    };
};

export default useGetEntity;
