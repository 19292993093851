import { css } from '@emotion/css';

const generateStyles = (theme) => {
    const body = css({
        display: 'grid',
        gridTemplateColumns: '260px 1fr',
        minWidth: '30vw',
    });

    const bodyLeft = css({
        display: 'flex',
        flexDirection: 'column',
    });

    const addressCard = css({
        boxShadow: 'none',
    });

    const addressCardRoot = css({
        maxWidth: '600px',
        padding: '0px',
    });
    const checked = css({});

    const autocomplete = css({});

    const radio = css({
        [`&.${checked}`]: {
            color: theme.palette.primary[200],
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    });

    const radioItem = css({
        padding: '0 4px !important',
    });

    const textField = css({
        '& > div': {
            paddingRight: '1px!important',
        },
    });

    const buttonLabel = css({
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
        textAlign: 'left',
    });

    const topTitle = css({
        color: theme.palette.secondary[500],
        fontSize: '12px',
        letterSpacing: '0.4px',
    });

    const addressBody = css({
        display: 'flex',
        flexDirection: 'column',
    });

    return {
        addressBody,
        addressCard,
        addressCardRoot,
        autocomplete,
        body,
        bodyLeft,
        buttonLabel,
        checked,
        radio,
        radioItem,
        textField,
        topTitle,
    };
};

export default generateStyles;
