import { css } from '@emotion/css';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const CELL_LINE_PADDING = 7;
const CELL_LINE_OFFSET = 11;

const generateStyles = (theme: SkycellThemeInterface, props: { noDivider: string }) => {
    const headerCell = css({
        '&:hover': {
            cursor: 'pointer',
        },
        '&:not(:last-child)::after': (props.noDivider ? {} : {
            background: theme.palette.common.dividerColor,
            content: '""',
            height: `calc(100% - ${CELL_LINE_PADDING * 2}px)`,
            position: 'absolute',
            right: CELL_LINE_OFFSET,
            top: CELL_LINE_PADDING,
            width: '2px',
        }),
        color: `${theme.palette.common.black}!important`,
        font: 'normal normal bold 16px/19px Roboto!important',
        fontSize: '14px!important',
        letterSpacing: '0.01em!important',
        opacity: 1,
        padding: '0!important',
        position: 'relative',
    });

    const cellContainer = css({
        '&:first-child': {
            paddingLeft: '8px',
        },
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '8px!important',
        paddingRight: '20px!important',
        paddingTop: '8px!important',
    });

    const headerData = css({
        display: 'inline-block',
        whiteSpace: 'nowrap',
        wordBreak: 'keep-all',
    });

    return {
        cellContainer,
        headerCell,
        headerData,
    };
};

export default generateStyles;
