import { LayoutContext } from 'Contexts/LayoutContext';
import React, {
    ChangeEvent,
    Dispatch, ReactElement,
    ReactNode,
    SetStateAction,
    useCallback, useContext,
} from 'react';
import {
    Checkbox,
    FormControlLabel, CircularProgress,
} from '@mui/material';
import useClasses from 'hooks/useClasses';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import OptionItemTooltip from './OptionItemTooltip';
import styles from '../SideFilter.style';

type Props = {
    checked?: boolean,
    count?: number,
    countsLoading?: boolean,
    customTooltip?: ReactElement,
    disabled?: boolean,
    label: string,
    optionKey: string,
    optionsGroupKey: string,
    setCheckboxGroupOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    showTooltip?: boolean,
    shrinkNames?: boolean,
    tooltipDescription?: string | ReactNode
}

const CheckboxOption = ({
    checked = false,
    count = null,
    countsLoading = false,
    customTooltip = null,
    disabled = false,
    label = null,
    optionKey,
    optionsGroupKey,
    setCheckboxGroupOptions,
    showTooltip = false,
    shrinkNames = false,
    tooltipDescription = '',
} : Props) => {
    const classes = useClasses(styles);
    const {
        keysPressed,
    } = useContext(LayoutContext);
    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (keysPressed['ControlLeft']) {
            setCheckboxGroupOptions(prev => {
                return { ...prev, [optionsGroupKey]: [optionKey] };
            });
        } else {
            setCheckboxGroupOptions(prev => {
                const { [optionsGroupKey]: selectedOptions } = prev;

                const updatedSelectedOptions = event.target.checked
                    ? selectedOptions?.includes(optionKey) ? selectedOptions : [...selectedOptions || [], optionKey]
                    : selectedOptions?.filter(option => option !== optionKey);

                return { ...prev, [optionsGroupKey]: updatedSelectedOptions };
            });
        }
    }, [keysPressed]);

    if (showTooltip) {
        return (
            <OptionItemTooltip
                description={customTooltip || tooltipDescription}
                descriptionTitle={customTooltip ? '' : label}
            >
                <div className={classes.checkboxRow}>
                    <FormControlLabel
                        className={classes.formControlLabelStyle}
                        classes={{
                            label: classes.labelEllipsis,
                        }}
                        control={(
                            <Checkbox
                                checked={checked}
                                className={classes.checkboxStyle}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.root,
                                }}
                                size="small"
                                onChange={handleChange}
                            />
                        )}
                        disabled={disabled}
                        label={(
                            <CommonTooltip
                                description={label}
                                enabled={label?.length >= 21 && shrinkNames}
                                placement="right"
                                type={TOOLTIP_TYPE.SHRINK}
                            >
                                <div className={classes.formControlLabelStyle}>{label}</div>
                            </CommonTooltip>
                        )}
                    />
                    {count !== null && !countsLoading && <div className={classes.count}>{count}</div>}
                    {countsLoading && <div className={classes.count}><CircularProgress size={15} /></div>}
                </div>
            </OptionItemTooltip>
        );
    }

    return (
        <div className={classes.checkboxRow}>
            <FormControlLabel
                className={classes.formControlLabelStyle}
                classes={{
                    label: classes.labelEllipsis,
                }}
                control={(
                    <Checkbox
                        checked={checked}
                        className={classes.checkboxStyle}
                        classes={{
                            checked: classes.checked,
                            root: classes.root,
                        }}
                        size="small"
                        onChange={handleChange}
                    />
                )}
                disabled={disabled}
                label={(
                    <CommonTooltip
                        description={label}
                        enabled={label?.length >= 21 && shrinkNames}
                        placement="right"
                        type={TOOLTIP_TYPE.SHRINK}
                    >
                        <div className={classes.formControlLabelStyle}>
                            {
                                (label?.length >= 21 && shrinkNames) ? label.slice(0, 21).concat('...') : label
                            }
                        </div>
                    </CommonTooltip>
                )}
            />
            {count !== null && !countsLoading && <div className={classes.count}>{count}</div>}
            {countsLoading && <div className={classes.count}><CircularProgress size={15} /></div>}
        </div>
    );
};

export default React.memo(CheckboxOption);
