import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import ReactApexChart from 'react-apexcharts';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import Card from 'shared-components/Card';
import StatusInfo from 'shared-components/StatusInfo';
import useClasses from 'hooks/useClasses';
import {
    getDailyForecastDataLink,
    DailyForecastInfo,
    getProcessedDailyForecastData,
    get3HourForecastData,
    getProcessedPeriodicForecastData,
    PeriodicForecastInfo,
    getApexOptions,
} from './lib';
import styles from './AirportInfoTooltip.style';

const {
    REACT_APP_OPENWEATHERMAP_API: openWeatherMapApiKey = 'a5cde18878f791b399610a4a42cd8d30',
} = process.env;

const getPixelPositionOffset = () => ({
    x: 8,
    y: 8,
});

type Props = {
    airportInfo: AirportExtended,
    cancelTooltipClosing: () => void,
}

const AirportInfoTooltip = ({ airportInfo, cancelTooltipClosing } : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [dailyForecastInfo, setDailyForecastInfo] = useState<DailyForecastInfo[]>([]);
    const [periodicForecastInfo, setPeriodicForecastInfo] = useState<PeriodicForecastInfo[]>([]);

    const {
        code,
        geolocation = null,
        name,
    } = airportInfo || {};

    const { latitude: lat, longitude: lng } = geolocation || {};

    useEffect(() => {
        (async () => {
            try {
                const link = getDailyForecastDataLink(openWeatherMapApiKey, lat, lng);
                const response = (await fetch(link).then(result => result.json()));

                setDailyForecastInfo(getProcessedDailyForecastData(response));
            } catch (error) {
                setDailyForecastInfo([]);
                global.console.log(error);
            }
        })();
    }, [lat, lng]);

    useEffect(() => {
        (async () => {
            try {
                const link = get3HourForecastData(openWeatherMapApiKey, lat, lng);
                const response = (await fetch(link).then(result => result.json()));

                setPeriodicForecastInfo(getProcessedPeriodicForecastData(response));
            } catch (error) {
                setPeriodicForecastInfo([]);
                global.console.log(error);
            }
        })();
    }, [lat, lng]);

    const options = useMemo(() => {
        return getApexOptions(periodicForecastInfo);
    }, [periodicForecastInfo]);

    const series = useMemo(() => {
        return [{ data: periodicForecastInfo, name: t('COMMON.TEMPERATURE') }];
    }, [periodicForecastInfo]);

    return (
        <OverlayViewF
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{ lat, lng }}
            zIndex={1005}
        >
            <Card
                className={classes.card}
                contentClass={classes.cardContentClass}
                fullHeight
                title={`${code} | ${name}`}
                titleClass={classes.cardTitle}
                onClick={cancelTooltipClosing}
            >
                {
                    periodicForecastInfo.length === 0 || dailyForecastInfo.length === 0
                        ? <StatusInfo status="PENDING" />
                        : (
                            <div>
                                <ReactApexChart
                                    height={120}
                                    options={options}
                                    series={series}
                                    type="area"
                                />
                                <div className={classes.dailyForecastContainer}>
                                    {
                                        dailyForecastInfo.map((forecast, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`${classes.dailyForecastItem} ${
                                                        index === 0 ? classes.currentDailyForecastItem : ''}`}
                                                >
                                                    <div className={classes.forecastDay}>{forecast.day}</div>
                                                    <img
                                                        alt="weather"
                                                        className={classes.forecastIcon}
                                                        src={forecast.icon}
                                                    />
                                                    <div className={classes.dailyForecastContainer}>
                                                        <div className={classes.tempMax}>{`${forecast.tempMax}°`}</div>
                                                        <div className={classes.tempMin}>{`${forecast.tempMin}°`}</div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                }
            </Card>
        </OverlayViewF>
    );
};

export default AirportInfoTooltip;
