import { ColumnsType } from 'shared-components/Table/dataTypes';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import RegularSelect from 'shared-components/RegularSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { TFunction } from 'i18next';
import { Save } from '@mui/icons-material';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CircularProgress } from '@mui/material';

type Props = {
    classes: any,
    companies: Company[],
    companyObjects: {
        companyId: number,
        uniqueId: string,
    }[],
    onChange: (uniqueId: string, gatewayId: number) => void,
    onRemove: (uniqueId: string, gatewayId: number) => void,
    onSave: (uniqueId: string, gatewayId: number) => void,
    t: TFunction,
    theme: SkycellThemeInterface,
    workInProgress: {
        [key: string]: boolean,
    },
}

export const tableColumns = ({
    classes,
    companies,
    companyObjects,
    onChange,
    onRemove,
    onSave,
    theme,
    workInProgress,
}: Props): ColumnsType[] => {
    const companiesInUse = companyObjects.map(({ companyId }) => companyId);

    return [{
        Header: ' ',
        accessor: ({ companyId, oldCompanyId, uniqueId }) => {
            // const currentGateway = gateways.find(g => g.id === gatewayId);

            return (
                <div
                    style={{
                        display: 'inline-grid',
                        gridTemplateColumns: '1fr auto',
                        width: '100%',
                    }}
                >
                    <RegularSelect
                        borderless
                        className={(companyId === null || oldCompanyId !== companyId) && classes.filledSelect}
                        customClasses={{
                            caretIcon: companyId === null && classes.caret,
                        }}
                        disabled={workInProgress[uniqueId] || (companyId !== null && companyId === oldCompanyId)}
                        id={uniqueId}
                        options={companies.filter(g => companiesInUse.indexOf(g.id) === -1)
                            .map(({ id, name }) => ({
                                active: id === oldCompanyId,
                                label: name,
                                value: id,
                            }))}
                        placeholder="Select a company"
                        renderValue={value => {
                            const selectedCompany = companies.find(g => g.id === value);

                            return selectedCompany?.name;
                        }}
                        value={companyId || null}
                        onChange={it => onChange(uniqueId, it)}
                    />
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '5px',
                        paddingLeft: '5px',
                    }}
                    >
                        {
                            !workInProgress[uniqueId] && companyId !== null && (
                                <DeleteIcon
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                    onClick={() => onRemove(uniqueId, companyId)}
                                />
                            )
                        }
                        {
                            !workInProgress[uniqueId] && companyId !== oldCompanyId && companyId && (
                                <Save
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                    onClick={() => onSave(uniqueId, companyId)}
                                />
                            )
                        }
                        {
                            workInProgress[uniqueId] && (
                                <CircularProgress
                                    size={20}
                                    style={{
                                        color: theme.palette.secondary[600],
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            );
        },
        dataType: 'custom',
        disableSortBy: true,
    },
    ];
};
