import React, {
    useCallback, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';

import { NotificationDto } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useAvailableHeight from 'hooks/useAvailableHeight';
import CommonPagination from 'shared-components/CommonPagination';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LayoutContext } from 'Contexts/LayoutContext';
import useClasses from 'hooks/useClasses';
import { CustomSort } from 'TrackAndTrace/lib';
import {
    ClientSideFilter,
    initialClientSideFilter,
    ProcessedNotification,
    getProcessedNotifications,
    getUpdatedNotifications,
} from './lib';
import ControlHeader from './components/ControlHeader';
import NotificationContent from './components/NotificationContent';
import getTableColumns from './getTableColumns';

import NotificationClientSideFilter, {
    STATUS_OPTIONS,
} from './components/NotificationsClientSideFilter/NotificationClientSideFilter';
import styles from './Notifications.style';

// Filtration logic for 'none' option on notificationStatus
export const defaultStatusFilterLogic = (notification: ProcessedNotification) => !notification.hasBeenDeleted;
const PER_PAGE = 50;

const Notifications = () => {
    const classes = useClasses(styles);
    const {
        FlexibleRequest: updateNotificationInfo,
        GetAll: getNotifications,
    } = useSkymindBackendEndpoints('notifications').requests;
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState<CustomSort | null>(null);
    const { t } = useCustomTranslation();
    const {
        GetAll: getNotificationsTrash,
    } = useSkymindBackendEndpoints('notifications/trash').requests;
    const { updateNotificationsCount, userInfo } = useCurrentUserContext();

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [notifications, setNotifications] = useState<ProcessedNotification[]>([]);
    const [updatedNotifications, setUpdatedNotifications] = useState<ProcessedNotification[]>([]);
    const [clickedRowNotification, setClickedRowNotification] = useState<ProcessedNotification>(null);
    const [refreshData, setRefreshData] = useState(true);
    const theme = useTheme<SkycellThemeInterface>();
    const [filters, setFilters] = useState<ClientSideFilter>(initialClientSideFilter);
    const availableHeight = useAvailableHeight();
    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: '',
                link: '/my-profile',
                title: 'My Profile',
            },
            {
                activePage: true,
                iconSrc: '',
                link: '/notifications',
                title: 'Notifications',
            },
        ]);
        setCustomPageTitle(userInfo.name);
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, []);

    const sortFunc = useCallback((a: ProcessedNotification, b: ProcessedNotification) => {
        if (sort === null) {
            return b.createdOn - a.createdOn;
        }

        if (sort.direction === 'asc') {
            return a[sort.columnId] < b[sort.columnId] ? 1 : -1;
        }

        return a[sort.columnId] > b[sort.columnId] ? 1 : -1;
    }, [sort]);

    useEffect(() => {
        if (refreshData) {
            updateNotificationsCount();
            (async () => {
                try {
                    const nonTrashed:
                        NotificationDto[] = (await getNotifications(
                            { page: 0, pageSize: 9999 },
                        )).data || [];
                    const trashed:
                        NotificationDto[] = (await getNotificationsTrash(
                            { page: 0, pageSize: 9999 },
                        )).data || [];

                    setNotifications(
                        getProcessedNotifications([...nonTrashed, ...trashed]).sort(sortFunc),
                    );
                    setRefreshData(false);
                } catch (error) {
                    setNotifications([]);
                }
            })();
        }
    }, [refreshData]);

    useEffect(() => {
        setUpdatedNotifications(getUpdatedNotifications(notifications, selectedIds));
    }, [notifications, selectedIds]);

    useEffect(() => {
        setNotifications(curNotifications => [...curNotifications].sort(sortFunc));
    }, [sort]);

    const filteredNotifications = useMemo<ProcessedNotification[]>(() => {
        return updatedNotifications
            .map(it => (it.id === clickedRowNotification?.id ? { ...it, isExpanded: true } : it))
            .filter(notification => {
                const { logic } = STATUS_OPTIONS.find(it => it.value === filters.notificationStatus)
                || { logic: defaultStatusFilterLogic };

                return logic(notification);
            })
            .filter(notification => filters.group.includes(notification.group));
    }, [filters, updatedNotifications, clickedRowNotification]);

    useEffect(() => {
        if (clickedRowNotification !== null) {
            (async () => {
                try {
                    const { hasBeenRead, id } = clickedRowNotification;

                    if (!hasBeenRead) {
                        const result = await updateNotificationInfo(
                            'POST', `${id}/action/mark-as-read`, null, {
                                'Content-Type': 'application/json',
                            },
                        );

                        if (result.status === 200) {
                            setNotifications(prev => prev.map(it => (clickedRowNotification.id === it.id ? {
                                ...it,
                                hasBeenRead: true,
                            } : it)));
                            setSelectedIds(prev => prev.filter(it => it !== id));
                        }
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    updateNotificationsCount();
                }
            })();
        }
    }, [clickedRowNotification]);

    const handleRowClick = useCallback(async (data: ProcessedNotification) => {
        setClickedRowNotification(prev => {
            const { id } = prev || {};

            return data.id === id
                ? null
                : data;
        });
        setSelectedIds(prev => prev.filter(it => it !== data.id));
    }, []);

    const NotificationContentItem = useMemo(() => {
        if (clickedRowNotification === null) {
            return null;
        }

        return (
            <NotificationContent
                notification={clickedRowNotification}
                userInfo={userInfo}
            />
        );
    }, [clickedRowNotification, selectedIds]);

    useEffect(() => {
        setClickedRowNotification(null);
        setSelectedIds([]);
    }, [filters]);
    const columns = useMemo(() => {
        return getTableColumns(setSelectedIds,
            setClickedRowNotification,
            theme,
            <ControlHeader
                notifications={filteredNotifications}
                restoreMode={filters.notificationStatus === 'DELETED'}
                selectedIds={selectedIds}
                setNotifications={setNotifications}
                setRefreshData={setRefreshData}
                setSelectedIds={setSelectedIds}
            />);
    }, [selectedIds, filteredNotifications, filters.notificationStatus]);

    useEffect(() => {
        setClickedRowNotification(null);
    }, [currentPage]);

    useEffect(() => {
        if (currentPage * PER_PAGE > filteredNotifications.length) {
            setCurrentPage(Math.max(Math.ceil(filteredNotifications.length / PER_PAGE), 1));
        }
    }, [filteredNotifications]);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    return (
        <PageWithFilter>
            <NotificationClientSideFilter
                filteredNotifications={filteredNotifications}
                filters={filters}
                notifications={updatedNotifications}
                setFilters={setFilters}
            />
            <div style={{ display: 'block', maxHeight: availableHeight, padding: '10px', width: '100%' }}>
                <div style={{ height: 'calc(100% - 90px)' }}>
                    <Table
                        classNames={{
                            headerCell: classes.noBars,
                            rowExtensionClassName: classes.expansionRoot,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={filteredNotifications.filter((it, i) => {
                            const page = currentPage - 1;

                            return i >= page * PER_PAGE && i < page * PER_PAGE + PER_PAGE;
                        })}
                        embed
                        rowExtension={NotificationContentItem}
                        tableMaxHeight="100%"
                        onRowClick={handleRowClick}
                        onSort={updateSort}
                    />
                </div>
                {
                    filteredNotifications?.length > 0 && (
                        <div style={{ alignItems: 'flex-end', display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <CommonPagination
                                currentPage={currentPage}
                                objectNamePlural={t('COMMON.NOTIFICATIONS')}
                                pageSize={PER_PAGE}
                                setCurrentPage={setCurrentPage}
                                shipmentsCount={filteredNotifications?.length}
                            />
                        </div>
                    )
                }
            </div>
        </PageWithFilter>
    );
};

export default Notifications;
