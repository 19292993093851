import { ReactElement } from 'react';

export enum TABLE_INPUT_TYPE {
    PK,
    TEMPERATURE,
    TEXT,
    NUMBER,
    WITH_POSTFIX,
    TIME_24,
    SELECT
}

export type CommonTableItem = {
    id: string,
    inputType?: TABLE_INPUT_TYPE,
    required?: boolean,
    selectData?: {
        render: number | string | ReactElement,
        value: string | number
    }[],
    title?: ReactElement | string,
    width?: string
}
