import React, { useState, useCallback } from 'react';
import { Period, TimeRange } from 'dataTypes/common';
import DateRangePickerMini from 'shared-components/DateRangePickerMini';
import PeriodSelector from 'shared-components/PeriodSelector';

type Props = {
    defaultPeriod?: Period;
    isFullAccess?: boolean;
    maxDateRange: number;
    setTimeRange: (timeRange: TimeRange) => void;
    timeRange: TimeRange;
};

const PeriodSelectorWithDatePicker = ({
    defaultPeriod,
    isFullAccess = true,
    maxDateRange = null,
    setTimeRange,
    timeRange,
}: Props) => {
    const [isManual, setIsManual] = useState<boolean>(false);

    const onPeriodChange = useCallback((targetPeriod) => {
        setIsManual(() => targetPeriod === 'Manual');
    }, []);

    return (
        <div>
            <div style={{ fontSize: '14px', ...(isManual && { marginBottom: '10px' }) }}>
                <PeriodSelector
                    defaultPeriod={defaultPeriod}
                    getPeriod={onPeriodChange}
                    isFullAccess={isFullAccess}
                    setTimeRange={setTimeRange}
                    timeRange={timeRange}
                />
            </div>
            {isManual && (
                <DateRangePickerMini
                    maxDateRange={maxDateRange}
                    setTimeRange={setTimeRange}
                    timeRange={timeRange}
                />
            )}
        </div>
    );
};

export default PeriodSelectorWithDatePicker;
