import { css, keyframes } from '@emotion/css';

const generateStyles = (theme) => {
    const opacityIn = keyframes({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const dummyHolder = css({
        maxHeight: '0px',
        overflow: 'visible',
    });

    const itemsList = css({
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.secondary[100],
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
        },
        background: theme.palette.common.white,
        boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
        listStyle: 'none',
        margin: '0px',
        maxHeight: '200px',
        opacity: 0,
        overflowY: 'auto',
        paddingInlineStart: '0px',
        position: 'absolute',
        transition: '200ms ease',
        visibility: 'hidden',
        zIndex: 100,
    });

    const visible = css({
        '&&': {
            opacity: 1,
            visibility: 'visible',
        },
    });

    const listLoading = css({
        filter: 'blur(2px)',
        pointerEvents: 'none',
        transition: '200ms ease',
    });

    const searchContainer = css({
        width: '100%',
    });

    const listItem = css({
        '&:first-child': {
            marginTop: '10px',
        },
        '&:hover': {
            background: theme.palette.secondary[100],
        },
        color: theme.palette.common.black,
        cursor: 'pointer',
        padding: '6px',
        transition: 'background 200ms ease',
        userSelect: 'none',
    });

    const appearingLi = css({
        animation: `${opacityIn} 200ms forwards ease-in-out`,
        opacity: 0,
    });

    const iconButton = css({
        height: '20px',
        width: '20px',
    });

    const icon = css({
        fontSize: '14px',
    });

    const smallFont = css({
        '& input': {
            fontSize: '12px',
        },
        fontSize: '12px',
    });

    const mediumFont = css({
        '& input': {
            fontSize: '14px',
        },
        fontSize: '14px',
    });

    return {
        appearingLi,
        dummyHolder,
        icon,
        iconButton,
        itemsList,
        listItem,
        listLoading,
        mediumFont,
        searchContainer,
        smallFont,
        visible,
    };
};

export default generateStyles;
