import { useCallback } from 'react';
import { GatewayInfoDTO, SensorDataItem, SensorDataResponse } from 'dataTypes/SecureBackend/apiResponse/index';
import useCachedQueryRequest from '../useCachedQueryRequest/index';
import useSkymindBackendEndpoints from '../useSkymindBackendEndpoints/index';
import { getQueriesUnwrapped } from '../useSkymindBackendEndpoints/lib';

type Props = {
    enabled?: boolean,
    isAdmin?: boolean,
    query?: object
}

export const extractLatestSensorDataWithUniqueGatewayNumbers = (
    sensorDataResponse: SensorDataResponse, last: number = 3,
) => {
    if (!sensorDataResponse || !sensorDataResponse.data || sensorDataResponse.data.length === 0) return [];
    const gatewayNumberIndex = sensorDataResponse.dataTypes.indexOf('GATEWAY_NUMBER');
    const isHistoricalIndex = sensorDataResponse.dataTypes.indexOf('IS_HISTORICAL');
    const historicalSensorData = sensorDataResponse.data.filter(({ d }) => d && !d[isHistoricalIndex]);

    historicalSensorData.reverse();
    if (gatewayNumberIndex === -1) return [];

    const uniqueSensorData:{
        gatewayNumber: string,
        sensorData: SensorDataItem
    }[] = [];

    for (let i = 0; i <= historicalSensorData.length - 1; i++) {
        const gatewayNumber = historicalSensorData[i]?.d[gatewayNumberIndex];
        const nextGatewayNumber = historicalSensorData[i + 1]?.d[gatewayNumberIndex];

        if (i === historicalSensorData.length - 1
            || (uniqueSensorData?.at(-1)?.gatewayNumber !== gatewayNumber
                && gatewayNumber !== nextGatewayNumber)) {
            uniqueSensorData.push({
                gatewayNumber,
                sensorData: historicalSensorData[i],
            });

            if (uniqueSensorData.length === last) break;
        }
    }

    return uniqueSensorData;
};

export const extractLatestSensorDataWithUniqueGatewayNumbersHistorical = (
    sensorDataResponse: SensorDataResponse,
) => {
    if (!sensorDataResponse || !sensorDataResponse.data || sensorDataResponse.data.length === 0) return [];
    const gatewayNumberIndex = sensorDataResponse.dataTypes.indexOf('GATEWAY_NUMBER');
    const isHistoricalIndex = sensorDataResponse.dataTypes.indexOf('IS_HISTORICAL');
    const historicalSensorData = [...sensorDataResponse.data].reverse();

    if (gatewayNumberIndex === -1) return [];

    const uniqueSensorData: {
        gatewayNumber: string,
        isHistorical?: boolean,
        isNotShared: boolean,
        sensorData: SensorDataItem
    }[] = [];

    for (let i = 0; i < historicalSensorData.length; i++) {
        const currentData = historicalSensorData[i]?.d;

        const gatewayNumber = currentData ? currentData[gatewayNumberIndex] : null;
        const isHistorical = currentData ? currentData[isHistoricalIndex] : null;

        const newEntry = {
            gatewayNumber,
            isHistorical,
            isNotShared: currentData === null || currentData === undefined,
            sensorData: historicalSensorData[i],
        };

        const lastIndUniqueSensorData = uniqueSensorData.length - 1;

        if (i === 0 || uniqueSensorData[lastIndUniqueSensorData]?.gatewayNumber !== gatewayNumber
            || uniqueSensorData[lastIndUniqueSensorData]?.isHistorical !== isHistorical) {
            uniqueSensorData.push(newEntry);
        } else {
            uniqueSensorData[lastIndUniqueSensorData] = newEntry;
        }
    }

    return uniqueSensorData;
};

const useGetGatewaysByGatewayNumbers = ({
    enabled = true,
    isAdmin = false,
    query = {},
}: Props) => {
    const {
        GetAll: getGateways,
    } = useSkymindBackendEndpoints(isAdmin ? 'admin/gateways/search' : 'gateways/search').requests;

    const getGatewaysByQuery = useCallback(() => {
        if (!query || Object.keys(query).length === 0 || Object.values(query).some(it => !it)) return () => {};
        return getGateways({
            page: 0,
            pageSize: 1000,
            ...query,
        }, false);
    }, [query]);

    const {
        data: gateways = [], error, isLoading, status,
    }: {
        data: GatewayInfoDTO[],
        error: unknown,
        isLoading: boolean,
        status: 'idle' | 'loading' | 'error' | 'success'
    } = useCachedQueryRequest({
        key: getQueriesUnwrapped(query),
        onCompleted: it => it?.data?.resultList || [],
        options: {
            enabled,
        },
        request: getGatewaysByQuery,
    });

    return {
        error, gateways, isLoading, status,
    };
};

export default useGetGatewaysByGatewayNumbers;
