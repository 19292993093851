import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const styles = (theme: SkycellThemeInterface) => ({
    fixedPosition: {
        position: 'fixed',
        top: '0px',
        width: '100%',
        zIndex: 9,
    },
    logoutBtn: {
        // @ts-ignore
        border: `1px solid ${theme.palette.common.menuTextWhite}`,
        borderRadius: '6px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        cursor: 'pointer',
        fontSize: '13px',
        height: 'max-content',
        marginLeft: theme.spacing(2),
        padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
        textTransform: 'uppercase',
        transition: 'opacity 200ms ease',
        [theme.breakpoints.up('sm')]: {
            opacity: '1!important',
        },
    },
    menuBar: {
        alignItems: 'center',
        background: `linear-gradient(90deg, ${theme.palette.primary.darkBlue} 0%,\
${theme.palette.primary.deepBlue} 100%)`,
        boxShadow: `0px 2px 4px ${theme.palette.secondary[300]}`,
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'visible',
        transition: 'height 200ms ease',
        [theme.breakpoints.up('sm')]: {
            height: 'auto!important',
        },
    },
    rightContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        padding: `${theme.spacing(0.65)} ${theme.spacing(3)}`,
        position: 'relative',
        width: '100%',
    },
    userWrapper: {
        alignItems: 'center',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        display: 'flex',
        fontSize: '14px',
        justifyContent: 'flex-end',
        marginLeft: theme.spacing(5),
        transition: 'opacity 200ms ease',
        [theme.breakpoints.up('sm')]: {
            opacity: '1!important',
        },
    },
});

export default styles;
