import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import icons from 'shared-components/icons';
import { STATUS, CargoResult } from 'Administration/Shipments/lib';

const InternalDataCheck = ({ cargos, marginBottom: marginBottomProp }) => {
    const { t } = useCustomTranslation();

    return (
        <>
            {cargos.map((cargo: CargoResult, i) => {
                const { cargoId, dataCompleteCheckResult } = cargo;

                if (!dataCompleteCheckResult?.internalDataCompleteStatus) {
                    return null;
                }

                const marginBottom = i !== cargos.length - 1 ? marginBottomProp : 0;
                const status = dataCompleteCheckResult?.internalDataCompleteStatus
                    ? t(`INTERNAL_DATA_CHECK_STATUS.${dataCompleteCheckResult?.internalDataCompleteStatus}`)
                    : '';

                if (dataCompleteCheckResult?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE) {
                    return (
                        <div
                            key={cargoId}
                            style={{
                                alignItems: 'center',
                                color: '#B92C2C',
                                display: 'flex',
                                marginBottom,
                            }}
                        >
                            <img alt="tasks" src={icons.warning} style={{ marginRight: 5 }} />
                            {' '}
                            {status}
                        </div>
                    );
                }

                const percentage = parseInt(dataCompleteCheckResult?.internalDataCompletePercentage, 10) || 0;

                return (
                    <div
                        key={cargoId}
                        style={{ alignItems: 'center', display: 'flex', marginBottom }}
                    >
                        {dataCompleteCheckResult?.internalDataCompleteStatus === STATUS.NOT_COMPLETE && (
                            <img alt="tasks" src={icons.warning_triangle} style={{ marginRight: 5 }} />
                        )}
                        {`${status} (${percentage} %)`}
                    </div>
                );
            })}
        </>
    );
};

export default InternalDataCheck;
