import { useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';

const useApexChartLocale: () => {chartLocale: ApexLocale[]} = () => {
    const { t } = useCustomTranslation();

    return useMemo(() => {
        return {
            chartLocale: [{
                name: 'en',
                options: {
                    days: [
                        t('SERVICE_PROVIDERS.SUNDAY'),
                        t('SERVICE_PROVIDERS.MONDAY'),
                        t('SERVICE_PROVIDERS.TUESDAY'),
                        t('SERVICE_PROVIDERS.WEDNESDAY'),
                        t('SERVICE_PROVIDERS.THURSDAY'),
                        t('SERVICE_PROVIDERS.FRIDAY'),
                        t('SERVICE_PROVIDERS.SATURDAY'),
                    ],
                    months: [
                        t('CHART.JANUARY'),
                        t('CHART.FEBRUARY'),
                        t('CHART.MARCH'),
                        t('CHART.APRIL'),
                        t('CHART.MAY'),
                        t('CHART.JUNE'),
                        t('CHART.JULY'),
                        t('CHART.AUGUST'),
                        t('CHART.SEPTEMBER'),
                        t('CHART.OCTOBER'),
                        t('CHART.NOVEMBER'),
                        t('CHART.DECEMBER'),
                    ],
                    shortDays: [
                        t('CHART.SUN'),
                        t('CHART.MON'),
                        t('CHART.TUE'),
                        t('CHART.WED'),
                        t('CHART.THU'),
                        t('CHART.FRI'),
                        t('CHART.SAT'),
                    ],
                    shortMonths: [
                        t('CHART.JAN'),
                        t('CHART.FEB'),
                        t('CHART.MAR'),
                        t('CHART.APR'),
                        t('CHART.MAY'),
                        t('CHART.JUN'),
                        t('CHART.JUL'),
                        t('CHART.AUG'),
                        t('CHART.SEP'),
                        t('CHART.OCT'),
                        t('CHART.NOV'),
                        t('CHART.DEC'),
                    ],
                    toolbar: {
                        download: t('CHART.DOWNLOAD_SVG'),
                        // exportToCSV: t('CHART.EXPORT_TO_CSV'),
                        exportToPNG: t('CHART.EXPORT_TO_PNG'),
                        exportToSVG: t('CHART.EXPORT_TO_SVG'),
                        pan: t('CHART.PAN'),
                        reset: t('CHART.RESET'),
                        selection: t('CHART.SELECTION'),
                        selectionZoom: t('CHART.SELECTION_ZOOM'),
                        zoomIn: t('CHART.ZOOM_IN'),
                        zoomOut: t('CHART.ZOOM_OUT'),
                    },
                },
            }],
        };
    }, []);
};

export default useApexChartLocale;
