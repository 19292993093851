import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import { RequestStatus } from 'dataTypes/common';
import { Query, defaultGetAllQuery } from 'hooks/useSkymindBackendEndpoints/lib';
import useCachedQueryRequest from '../useCachedQueryRequest';

const statusMap: {
    [key: string]: RequestStatus
} = {
    error: 'FAILURE',
    idle: 'INIT',
    loading: 'PENDING',
    success: 'SUCCESS',
};

type AdditionalOptions<T> = {
    cache?: boolean,
    enabled?: boolean,
    mutateParam?: any,
    postProcess?: (data: any) => T,
    query?: Query,
    queryWrap?: boolean,
    refetchInterval?: number | false,
    refetchOnMount?: boolean,
    retry?: boolean,
}
const useGetCommonData: <T>(dataName: string, options?: AdditionalOptions<T>) => {
    data: T, isError: boolean, isLoading: boolean, status: RequestStatus
} = (dataName, {
    cache = false,
    enabled = true,
    mutateParam = null,
    postProcess = it => it,
    query = null,
    queryWrap = true,
    refetchInterval = false,
    refetchOnMount = false,
    retry = true,
} = {}) => {
    const { GetAll: getData } = useSkymindBackendEndpoints(dataName).requests;

    const {
        data: dataList = [],
        isError,
        isLoading,
        status: rawStatus,
    } = useCachedQueryRequest({
        key: { dataName, query },
        mutateParam,
        onCompleted: it => postProcess(it?.data || []),
        options: {
            cacheTime: cache ? 1000 * 60 : 0,
            enabled,
            refetchInterval,
            refetchOnMount,
            retry,
        },
        request: async () => getData(query || defaultGetAllQuery, queryWrap),
    });

    return { data: dataList, isError, isLoading, status: statusMap[rawStatus] };
};

export default useGetCommonData;
