import React, { ReactElement, ReactNode, useMemo } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import DynamicList from 'shared-components/DynamicList';
import styles from '../SideFilter.style';

type Props = {
    alwaysOpen?: boolean,
    children: ReactNode | ReactNode[],
    className?: string,
    dynamic?: boolean,
    onSelectDeselect?: () => void,
    title?: string,
    tooltipInfo?: QuickHelpTooltipInfo
}

const AccordionOptionsGroup = ({
    alwaysOpen = false,
    children = null,
    className,
    dynamic = true,
    onSelectDeselect = null,
    title = '',
    tooltipInfo = { text: '', uid: 'accordionTooltip' },
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const additionalProps = useMemo(() => ({
        ...(alwaysOpen ? { expanded: true } : {}),
    }), [alwaysOpen]);

    return (
        <QuickHelpTooltip
            tooltipInfo={tooltipInfo}
        >
            <Accordion
                className={classes.accordionStyle}
                defaultExpanded
                {...additionalProps}
            >
                <AccordionSummary
                    className={classes.accordionSummaryStyle}
                    expandIcon={alwaysOpen ? <></> : <ExpandMore className={classes.expandMoreStyle} />}
                >
                    <div className={classes.title}>{title}</div>
                </AccordionSummary>
                <AccordionDetails className={[classes.accordionDetailsStyle, className || ''].join(' ')}>
                    {
                        dynamic ? (
                            <DynamicList
                                dispersion={2}
                                gap={12}
                                id={`${title}-dynamic-list`}
                                itemHeight={20}
                                maxHeight={160}
                            >
                                {children as ReactElement[]}
                            </DynamicList>
                        ) : (
                            <>{children}</>
                        )
                    }
                </AccordionDetails>
                {
                    onSelectDeselect && (
                        <div className={classes.selectDeselect} onClick={onSelectDeselect}>
                            {t('COMMON.SELECT_DESELECT_ALL')}
                        </div>
                    )
                }
            </Accordion>
        </QuickHelpTooltip>

    );
};

export default AccordionOptionsGroup;
