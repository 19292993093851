import useFilter, { PAGES } from 'Contexts/useFilter';
import Requests from 'MyCompany/AccessRequests/AccessRequests';
import React from 'react';

const Outgoing = () => {
    const memoFilter = useFilter(PAGES.ACCESS_REQUESTS_OUTGOING);

    return (
        <Requests key="outgoing_page" memoFilter={memoFilter} requestType="outgoing" />
    );
};

export default Outgoing;
