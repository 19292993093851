import { Theme } from '@mui/material';
import { GatewayInfoDTO, SensorDataItem, SensorDataResponse } from 'dataTypes/SecureBackend/apiResponse';
import { LatLng, LatLngTimeTimestamp } from 'dataTypes/common';
import { ApexOptions } from 'apexcharts';
import { TIME_IN_MS } from 'shared-components/constants';
import { dateToNumber } from 'utils/timeUtils';

const LOCATION_LATITUDE = 'LOCATION_LATITUDE';
const LOCATION_LONGITUDE = 'LOCATION_LONGITUDE';

export const cutDateString = (dateString: string) => {
    const dateArr = dateString.split(':');

    dateArr.pop();

    return dateArr.join(':');
};

export interface FixedTooltip {
    enabled: boolean,
    offsetX: number,
    offsetY: number,
    position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}

export interface SensorLabels {
    dataTypes: string[],
    loggerTypes: string[],
    positions: string[]
}

export interface ApexTooltip {
    class?: string,
    click?: (chart?: any, options?: any, e?: any) => any,
    icon?: string,
    index?: number,
    title?: string
}

export interface ZoomedDataLimits {
    max: number, min: number
}

export const defaultPolylineOptions = {
    geodesic: true,
    strokeColor: '#009CCF',
    strokeOpacity: 1.0,
    strokeWeight: 3,
};

export const prepareRequestDataTypes = (rawDataTypes: string[]): string[] => {
    const dataTypes = rawDataTypes.reduce((accumulator, type) => {
        if (type === 'GEOLOCATION') {
            return [...accumulator, 'LOCATION_LATITUDE', 'LOCATION_LONGITUDE'];
        }
        if (type === 'LOCATION') {
            return [...accumulator, 'GATEWAY_NUMBER', 'IS_HISTORICAL'];
        }
        return [...accumulator, type];
    }, []);

    return (dataTypes.includes(LOCATION_LATITUDE) && dataTypes.includes(LOCATION_LONGITUDE))
        ? dataTypes
        : [...dataTypes, LOCATION_LATITUDE, LOCATION_LONGITUDE];
};

export const getDataTypeIndexes = (rawDataTypes: string[], dataTypeName: string): number[] => {
    return rawDataTypes.reduce((data, type, index) => {
        return type === dataTypeName ? [...data, index] : data;
    }, []) || [];
};

interface DataLimitIndex { maxIndex: number, minIndex: number }

export const getDataLimitIndexes = (rawData: SensorDataItem[], indexList: number[]): DataLimitIndex[] => {
    // const reversedRawData = [...rawData];
    return indexList.map((index) => {
        let maxIndex = -1;
        const min = rawData.findIndex(({ d }) => d[index]);

        for (let i = rawData.length - 1; i >= 0; i--) {
            if (rawData[i].d[index]) {
                maxIndex = i;
                break;
            }
        }
        return {
            maxIndex,
            minIndex: min > -1 ? min : Infinity,
            // maxIndex: rawData.slice().reverse().findIndex(({ d }) => d[index]),
        };
    });
};

export const getDoorData = (rawData: SensorDataItem[], doorIndexes: number[]): SensorDataItem[] => {
    return rawData.reduce((data, { d, t }) => {
        const requiredData = doorIndexes.map((index) => d[index]) || [];

        if (requiredData.filter((item) => item !== null).length > 0) {
            return [
                ...data,
                {
                    d: requiredData,
                    t,
                },
            ];
        }
        return data;
    }, []) || [];
};

type GetLocationSeriesProps = {
    constantValue: number,
    gateways: GatewayInfoDTO[],
    historicalIndex: number,
    inLocalTimeZone: boolean,
    locationIndex: number,
    rawData: SensorDataItem[],
    theme: Theme,
    trans: (key: string) => string
}

export const getLocationSeries = (
    {
        constantValue,
        gateways,
        historicalIndex,
        inLocalTimeZone,
        locationIndex,
        rawData,
        theme,
        trans,
    }: GetLocationSeriesProps,
): ApexOptions['series'] => {
    const correctedRawData = rawData.map(it => {
        if (!it.d[historicalIndex]) return it;
        else {
            const newD = [...it.d];

            newD[locationIndex] = 'not_connected'; // for non-historical data, we don't want to show location
            return {
                ...it,
                d: newD,
            };
        }
    });

    const locationData = correctedRawData.reduce((data, { d }) => {
        const locationName = d[locationIndex] || null;

        return [...data, locationName];
    }, []);

    const locationImeiCodes = [...new Set(locationData)].filter(name => name !== null);

    const getNameByImei = imei => {
        const infoEntry = gateways.find(it => it.gatewayImeiMac?.toLowerCase() === imei?.toLowerCase());

        if (!infoEntry) return `${trans('SENSOR_DATA.NOT_CONNECTED')}`;
        return `${infoEntry.iataCode ? `${infoEntry.iataCode} | ` : ''}${infoEntry.area || infoEntry.locationName}`;
    };

    const colors = [
        theme.palette.primary[100],
        theme.palette.common['beige'],
        theme.palette.primary[200],
        theme.palette.primary[400],
        theme.palette.primary[600],
        theme.palette.primary[800],
        theme.palette.primary[900],
    ];

    const seriesData = locationImeiCodes.map((code, index) => ({
        color: code === 'not_connected' ? theme.palette.secondary[500] : colors[index % colors.length],
        data: [],
        imei: code,
        name: getNameByImei(code),
        opacity: 1,
        position: 'GATEWAY',
        showValueInTooltip: false,
        stroke: {
            curve: 'straight',
            width: 12,
        },
    }));

    correctedRawData.forEach(({ d, t }, index) => {
        const locationName = d[locationIndex];
        const nextLocationName = correctedRawData?.[index + 1]?.d?.[locationIndex];
        const nextLocationTime = correctedRawData?.[index + 1]?.t;

        const series = seriesData.find(it => it.imei === locationName);

        series.data.push({
            x: dateToNumber(t, inLocalTimeZone),
            y: constantValue,
        });

        if (series.data.length === 1 && correctedRawData.length - 1 === index) {
            series.data.push({
                x: dateToNumber(t, inLocalTimeZone) + 10 * TIME_IN_MS.minute - 10,
                y: constantValue,
            });
            series.data.push({
                x: dateToNumber(t, inLocalTimeZone) + 10 * TIME_IN_MS.minute,
                y: null,
            });
        }

        if (nextLocationName && (locationName !== nextLocationName)) {
            series.data.push({
                x: dateToNumber(nextLocationTime, inLocalTimeZone) - 10,
                y: constantValue,
            });
            series.data.push({
                x: dateToNumber(nextLocationTime, inLocalTimeZone),
                y: null,
            });
        }
    });

    return seriesData;
};

export const getTemperatureData = (
    rawData: SensorDataItem[],
    temperatureIndexes: number[],
    dataLimitIndexes: DataLimitIndex[],
): SensorDataItem[] => {
    return rawData.reduce((data, { d, t }, dataIndex) => {
        return [
            ...data,
            {
                d: temperatureIndexes.map((temperatureIndex: number, index: number) => {
                    const { maxIndex, minIndex } = dataLimitIndexes[index];

                    if (dataIndex >= minIndex && dataIndex <= maxIndex) {
                        return d[temperatureIndex];
                    }
                    return null;
                }),
                t,
            },
        ];
    }, []) || [];
};

export const getSensorLabels = (
    dataTypeIndexes: number[],
    dataTypes: string[],
    loggerTypes: string[],
    positions: string[],
): SensorLabels => {
    return {
        dataTypes: dataTypeIndexes.map((index: number) => dataTypes[index] || ''),
        loggerTypes: dataTypeIndexes.map((index: number) => loggerTypes[index] || ''),
        positions: dataTypeIndexes.map((index: number) => positions[index] || ''),
    };
};

const degreeTypeLimits = {
    lat: { max: 90, min: -90 },
    lng: { max: 180, min: -180 },
};

const isCorrectDegree = (degree, type: ('lat' | 'lng')) => {
    const { max, min } = degreeTypeLimits[type];

    return !Number.isNaN(Number.parseFloat(degree)) && degree >= min && degree <= max;
};

const getTruncedDegree = (degree: number = null) => (
    degree === null ? null : (Math.trunc(degree * 10000) / 10000)
);

export const getFirstExistedLocation = (data: SensorDataItem[], latLngIndexes: LatLng): LatLng => {
    const { d: firstItem = [] } = data.find(({ d }) => d[latLngIndexes.lat] && d[latLngIndexes.lng]) || {};

    return firstItem.length === 0
        ? { lat: null, lng: null }
        : {
            lat: getTruncedDegree(firstItem[latLngIndexes.lat]),
            lng: getTruncedDegree(firstItem[latLngIndexes.lng]),
        };
};

export const getLatLngTimeTimestamp = (
    data: SensorDataItem[],
    latLngIndexes: LatLng,
    firstItem: LatLng,
): LatLngTimeTimestamp[] => {
    return data.reduce((data, { d, t }, index) => {
        if (index === 0) {
            return [{
                location: firstItem,
                time: t,
                timeStamp: dateToNumber(t),
            }];
        }

        const location = isCorrectDegree(d[latLngIndexes.lat], 'lat') && isCorrectDegree(d[latLngIndexes.lng], 'lng')
            ? {
                lat: getTruncedDegree(d[latLngIndexes.lat]),
                lng: getTruncedDegree(d[latLngIndexes.lng]),
            }
            : data[index - 1].location;

        return [...data, {
            location,
            time: t,
            timeStamp: dateToNumber(t),
        }];
    }, []);
};

export const getCoordinates = (rawSensorData: SensorDataResponse): LatLngTimeTimestamp[] => {
    const {
        data = [],
        dataTypes: rawDataTypes = [],
    } = rawSensorData;

    const geolocationIndexes = {
        lat: rawDataTypes.findIndex((type) => type === 'LOCATION_LATITUDE'),
        lng: rawDataTypes.findIndex((type) => type === 'LOCATION_LONGITUDE'),
    };

    if (geolocationIndexes.lat !== -1 && geolocationIndexes.lng !== -1) {
        const firstExistedLocation = getFirstExistedLocation(data, geolocationIndexes);

        return getLatLngTimeTimestamp(data, geolocationIndexes, firstExistedLocation);
    }

    return [];
};

export const getPolylinePath = (coordinates: LatLngTimeTimestamp[], zoomedDataLimits: ZoomedDataLimits): LatLng[] => {
    if (!coordinates || coordinates.length === 0) {
        return [];
    }

    // Established empirically that chart shows elements with indexes around - 13
    const firstElementIndex = zoomedDataLimits.min === null || zoomedDataLimits.min <= coordinates[13]?.timeStamp
        ? 0
        : coordinates.filter(it => it).findIndex(({ timeStamp }) => zoomedDataLimits.min <= timeStamp) - 13;

    const lastElementIndex = zoomedDataLimits.max === null
        ? coordinates.length
        : coordinates.filter(it => it).findIndex(({ timeStamp }) => zoomedDataLimits.max <= timeStamp) - 13;

    return coordinates
        .slice(firstElementIndex, lastElementIndex)
        // .map(({ location }) => location);
        .reduce((data: LatLng[], { location }) => {
            const [lastItem = null] = data.slice(-1);
            const { lat: lastLat = null, lng: lastLng = null } = lastItem || {};

            return location.lat === lastLat && location.lng === lastLng
                ? data
                : [...data, location];
        }, []);
};
