import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import LaneComparison from 'Insights/LaneComparison/LaneComparison';
import Lanes from './Lanes';
import TemperatureSimulation from './Lanes/tabs/TemperatureSimulation';
import LaneDetails from './LaneDetails';

const LaneManagementRouter: RouteItem[] = [
    {
        element: <Navigate to="/lane-management/lanes" />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management',
    },
    {
        element: <Lanes />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes',
    },
    {
        element: <LaneDetails />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/add',
    },

    {
        element: <div>Lane Products</div>,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/products',
    },
    {
        element: <div>Lane Comparisons</div>,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lane-comparisons',
    },
    {
        element: <LaneComparison />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lane-comparison',
    },
    {
        element: <TemperatureSimulation />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/:laneId/temperature-simulation',
    },
    {
        element: <LaneDetails />,
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        path: '/lane-management/lanes/:laneId/definition',
    },
];

export default LaneManagementRouter;
