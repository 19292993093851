const polylineType = (dottedLine: boolean) => {
    return dottedLine
        ? {
            geodesic: true,
            icons: [{
                icon: {
                    path: 'M 0 -1 L 0 0 L 1 0 L 1 -1 Z',
                    scale: 3,
                    strokeOpacity: 1,
                },
                offset: '0',
                repeat: '12px',
            }],
            strokeOpacity: 0,
        }
        : {
            geodesic: true,
            strokeOpacity: 1.0,
            strokeWeight: 5,
        };
};

export default polylineType;
