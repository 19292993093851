import React, {
    createContext, Dispatch,
    ReactElement, ReactNode, SetStateAction,
    useState,
} from 'react';
import { AlertColor } from '@mui/material/Alert';

const initialState = {
    code: null,
    color: null,
    message: '',
    relatedStatuses: [],
    status: null,
    time: null,
};

export type StatusState = {
    code?: number,
    color: AlertColor | 'info_static' | null,
    message: string,
    relatedStatuses?: number[],
    single?: boolean,
    time?: number
}

export type Alert = {
    alertColor?: AlertColor | 'info_static',
    alertTime?: number,
    icon?: ReactNode,
    message?: string,
    single?: boolean
}

export type SetStatusState = (statusState: StatusState) => void

export interface StatusInfoContextInterface {
    alerts: Alert[],
    flushStatusState?: () => void,
    setAlerts: Dispatch<SetStateAction<Alert[]>>,
    setStatusState?: SetStatusState,
    shouldFlush?: number,
    statusState: StatusState
}

export const StatusInfoContext = createContext<StatusInfoContextInterface>({
    alerts: [],
    flushStatusState: () => {},
    setAlerts: () => {},
    setStatusState: () => {},
    shouldFlush: 0,
    statusState: initialState,
});

type Props = {
    children: ReactElement,
}

export const StatusInfoProvider = ({ children }: Props) => {
    const [statusState, setStatusState] = useState<StatusState>(initialState);
    const [shouldFlush, setShouldFlush] = useState(0);
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const setStatusStateWithDefaultParams = (statusStateObj: StatusState) => {
        const { time = Date.now() } = statusStateObj;

        setStatusState({ ...statusStateObj, time });
    };

    return (
        <StatusInfoContext.Provider value={{
            alerts,
            flushStatusState: () => setShouldFlush(Date.now()),
            setAlerts,
            setStatusState: setStatusStateWithDefaultParams,
            shouldFlush,
            statusState,
        }}
        >
            { children }
        </StatusInfoContext.Provider>
    );
};
