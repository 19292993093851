import Switch from '@mui/material/Switch';
import useClasses from 'hooks/useClasses';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import { Milestone } from 'shared-components/dataTypes';
import { styles } from './PackagingViewOptions.style';

type Props = {
    hiddenSeries: { [key: string]: boolean },
    laneId?: number,
    milestones: Milestone[] | null,
    packagings: GenericPackaging[],
    setShowAmbientTemp: Function,
    setShowDoors: Function,
    setShowExpTemp?: Dispatch<SetStateAction<boolean>>,
    setShowInUTC?: Dispatch<SetStateAction<boolean>>,
    setShowMarkers: Dispatch<SetStateAction<boolean>>,
    setShowMeasuredOnly: Dispatch<SetStateAction<boolean>>,
    setShowTempRange: Dispatch<SetStateAction<boolean>>,
    showAmbientTemp: boolean,
    showDoors: boolean,
    showExpTemp?: boolean,
    showInUTC?: boolean,
    showMarkers: boolean,
    showMeasuredOnly: boolean,
    showMeasuredOnlyDisabled: boolean,
    showTempRange: boolean,
    status?: string,
}
const PackagingViewOptions = ({
    hiddenSeries,
    laneId = null,
    milestones = [],
    setShowAmbientTemp,
    setShowDoors,
    setShowExpTemp,
    setShowInUTC,
    setShowMarkers,
    setShowMeasuredOnly,
    setShowTempRange,
    showAmbientTemp,
    showDoors,
    showExpTemp,
    showInUTC,
    showMarkers,
    showMeasuredOnly,
    showMeasuredOnlyDisabled,
    showTempRange,
    status,
}:Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const hasLaneManagement = useMemo(() => hasAccess(userRoles.LANE_MANAGEMENT), [hasAccess]);
    const handleChangeTemperatureRange = useCallback(() => setShowTempRange(prev => !prev), []);
    const handleChangeMeasuredOnly = useCallback(() => setShowMeasuredOnly(prev => !prev), []);
    const handleChangeShowInUTC = useCallback(() => setShowInUTC(prev => !prev), []);
    const handleChangeExpectedTemperature = useCallback(() => setShowExpTemp(prev => !prev), []);
    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);
    const handleChangeAmbient = useCallback(() => setShowAmbientTemp(), []);
    const handleChangeDoors = useCallback(() => setShowDoors(), []);

    return (
        <div className={classes.root}>
            <div className={classes.options}>
                <div className={classes.title}>
                    {t('TRACK_AND_TRACE.CHART_OPTIONS')}
                </div>
                <div className={classes.switches}>
                    {hasLaneManagement && laneId !== null
                    && milestones.length !== 0 && status === 'IN_TRANSIT'
                        ? (
                            <CommonTooltip
                                description={t('COMMON.EXPECTED_TEMPERATURE')}
                                type={TOOLTIP_TYPE.SHRINK}
                            >
                                <div className={classes.switch}>
                                    <label htmlFor="showExpTemp">{t('COMMON.EXPECTED_TEMPERATURE')}</label>
                                    <Switch
                                        checked={showExpTemp}
                                        color="primary"
                                        id="showExpTemp"
                                        size="medium"
                                        onChange={handleChangeExpectedTemperature}
                                    />
                                </div>
                            </CommonTooltip>
                        )
                        : ''}
                    <CommonTooltip
                        description={t('LABEL_TOOLTIPS.MEASURED_ONLY')}
                        type={TOOLTIP_TYPE.SHRINK}
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showMeasuredOnly">{t('COMMON.MEASURED_ONLY')}</label>
                            <Switch
                                checked={showMeasuredOnly && !showExpTemp}
                                color="primary"
                                disabled={showMeasuredOnlyDisabled}
                                id="showMeasuredOnly"
                                size="medium"
                                onChange={handleChangeMeasuredOnly}
                            />
                        </div>
                    </CommonTooltip>
                    <CommonTooltip
                        description={t('LABEL_TOOLTIPS.SHOW_IN_UTC')}
                        type={TOOLTIP_TYPE.SHRINK}
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showInUTC">{t('SENSOR_DATA.SHOW_IN_UTC')}</label>
                            <Switch
                                checked={showInUTC}
                                color="primary"
                                id="showInUTC"
                                size="medium"
                                onChange={handleChangeShowInUTC}
                            />
                        </div>
                    </CommonTooltip>
                    <CommonTooltip
                        description={t('LABEL_TOOLTIPS.TEMPERATURE_RANGE')}
                        type={TOOLTIP_TYPE.SHRINK}
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showTempRange">{t('COMMON.TEMPERATURE_RANGE')}</label>
                            <Switch
                                checked={showTempRange}
                                color="primary"
                                id="showTempRange"
                                size="medium"
                                onChange={handleChangeTemperatureRange}
                            />
                        </div>
                    </CommonTooltip>
                    <CommonTooltip
                        description={t('LABEL_TOOLTIPS.MARKERS')}
                        type={TOOLTIP_TYPE.SHRINK}
                    >
                        <div className={classes.switch}>
                            <label htmlFor="showMarkers">{t('SENSOR_DATA.MARKERS')}</label>
                            <Switch
                                checked={showMarkers}
                                color="primary"
                                id="showMarkers"
                                size="medium"
                                onChange={handleChangeMarkers}
                            />
                        </div>
                    </CommonTooltip>
                    <div className={classes.switch}>
                        <label htmlFor="showMarkers">{t('COMMON.AMBIENT_TEMPERATURE')}</label>
                        <Switch
                            checked={showAmbientTemp}
                            color="primary"
                            id="showMarkers"
                            size="medium"
                            onChange={handleChangeAmbient}
                        />
                    </div>
                    {
                        hiddenSeries.DOOR !== null && (
                            <CommonTooltip
                                description={t('LABEL_TOOLTIPS.DOOR')}
                                type={TOOLTIP_TYPE.SHRINK}
                            >
                                <div className={classes.switch}>
                                    <label htmlFor="showMarkers">{t('SENSOR_DATA.DOOR')}</label>
                                    <Switch
                                        checked={showDoors}
                                        color="primary"
                                        id="showMarkers"
                                        size="medium"
                                        onChange={handleChangeDoors}
                                    />
                                </div>
                            </CommonTooltip>
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default PackagingViewOptions;
