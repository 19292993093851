import React, { useMemo, useEffect, useContext, useState } from 'react';
import BackToLink from 'shared-components/BackToLink';
import useCacheContext from 'hooks/useCacheContext';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Table from 'shared-components/Table/Table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import parseQueryParams from 'utils/parsePathQueries';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import AddCircle from '@mui/icons-material/AddCircle';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { Asset } from 'dataTypes/SecureBackend/apiResponse';
import useGetCommonData from 'hooks/useGetCommonData';
import { LayoutContext } from 'Contexts/LayoutContext';
import ShortPackagingInfo from 'shared-components/ShortPackagingInfo';
import NoOpenEntity from 'SensorDataCommon/components/NoOpenEntity';
import CommonTooltip, { TOOLTIP_TYPE } from 'shared-components/CommonTooltip/CommonTooltip';
import useClasses from 'hooks/useClasses';
import { getAssetPicture } from 'shared-components/common';
import useConfig from 'hooks/useConfig';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
    ASSET_TYPES,
} from './dataTypes';
import useEditablePairingsColumns from './hooks/useEditablePairingsColumns/useEditablePairingsColumns';
import styles from './components/AssetUnit/AssetUnit.style';
import LatestPairingPictures from './components/LatestPairingPictures';
import Options from './components/Options';
import {
    initializeTimeRange,
    initializeRequestBody, fetchAssets, initializeEntityList,
} from './lib';
import useLoadSensorData from './hooks/useLoadSensorData';
import { SensorDataRequestBody } from '../dataTypes/SecureBackend/processedData';
import { TimeRange } from '../dataTypes/common';
import useGetTranslationGroup from '../hooks/useGetTranslationGroup';
import useHasAccess, { userRoles } from '../hooks/useHasAccess';

const AssetPairings = () => {
    const { getCacheValue } = useCacheContext();
    const { entityNumber: entityNumberInPath = '' } = useParams() as unknown as PathParams;
    const queryParams = parseQueryParams(location.search);
    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );
    const hasAccess = useHasAccess();
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const [firstEntry, setFirstEntry] = useState(true);
    const { entityType } = entitySelectorItem ?? {};
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const { t } = useCustomTranslation();
    const config = useConfig();
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(true, queryParams));
    const classes = useClasses(styles);
    const [localTimezone, setLocalTimezone] = useState(false);
    const [isCurrentTime, setIsCurrentTime] = useState(true);
    const theme = useTheme<SkycellThemeInterface>();
    const {
        userInfo,
    } = useCurrentUserContext();
    const navigate = useNavigate();
    const {
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);
    const [assetListAll, setAssetListAll] = useState(initializeEntityList(entitySelectorItem, 'assets'));

    useEffect(() => {
        setMenuTabs([
            {
                activePage: false,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/location/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/location/${entitySelectorItem.entityNumber}`,
                title: t('MENU_ITEMS.LOCATION_HISTORY'),
            },
            {
                activePage: false,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/readout/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/readout/${entitySelectorItem.entityNumber}`,
                title: t('COMMON.TEMPERATURE'),
            },
            {
                activePage: true,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/pairings/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/pairings/${entitySelectorItem.entityNumber}`,
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setMenuTabs([]);
            setCustomPageTitle('');
        };
    }, [entitySelectorItem]);

    const {
        assetList,
        isFetchComplete,
    } = useLoadSensorData(entitySelectorItem, 'assets', entityNumberInPath, isAdmin);

    useEffect(() => {
        if (entityNumberInPath && entitySelectorItem.entityNumber !== entityNumberInPath) {
            if (assetList.length > 0) {
                const asset = assetList.find(item => item.entityNumber === entityNumberInPath) || null;

                if (asset) setEntitySelectorItem(asset);
            }
        }
    }, [assetList]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            navigate({
                pathname: `/assets/location/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true, queryParams, true));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    const {
        data: rawAssets,
    } = useGetCommonData<Asset[]>(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: true,
        postProcess: it => it.resultList,
        query: entitySelectorItem ? {
            q: entitySelectorItem.entityNumber,
        } : {},
    });

    const {
        data: rawAssetsAll,
    } = useGetCommonData<Asset[]>(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: true,
        postProcess: it => it.resultList,
        query: {
            page: 0,
            pageSize: 1000,
        },
    });

    useEffect(() => {
        if (rawAssetsAll) {
            setAssetListAll(fetchAssets(rawAssetsAll, assetTypeLabels));
        }
    }, [rawAssetsAll]);

    const selectedAsset = useMemo<Asset>(() => rawAssets
        ?.find(it => it.assetNumber.toLowerCase() === entitySelectorItem.entityNumber
            .toLowerCase()), [rawAssets, entitySelectorItem]);

    const {
        data: currentPairingPhotos = [],
        isLoading: isCurrentPairingPhotosLoading,
        status: isCurrentPairingPhotosStatus,
    } = useGetCommonData<string[]>(`assets/${selectedAsset?.id}/current-pairing/photos`, {
        enabled: !!selectedAsset,
        postProcess: it => it?.values,
        query: {},
        retry: false,
    });

    const {
        assetPairings = [],
        columns,
        editedItem,
        newField,
        setEditedItem,
        setHoveredItem,
        users,
    } = useEditablePairingsColumns({
        assetId: selectedAsset?.id,
        isAdmin,
    });

    const preparedAssetPairings = useMemo(() => (newField
        ? assetPairings.concat(newField) : assetPairings), [assetPairings, newField]);

    const assetTypeLabel = !selectedAsset ? '' : t(`ASSET_TYPE_LABEL.${selectedAsset?.assetTypeCode}`);

    const assetPicture = useMemo(() => getAssetPicture({
        assetType: selectedAsset?.assetTypeCode || ASSET_TYPES.CONTAINER,
        configPictures: config?.locationHistory?.configPictures,
    }), [config, selectedAsset]);

    return (
        <div style={{
            padding: '0 12px 12px 12px',
        }}
        >
            <BackToLink
                marginBottom={10}
                marginLeft={0}
                marginRight={12}
                marginTop={10}
                title={t('COMMON.ASSETS_LIST')}
                to={isAdmin ? '/administration/assets'
                    : `/asset-management/assets?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
            />
            <div className={classes.pageContent}>
                <div style={{ display: 'grid' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {
                            entitySelectorItem ? (
                                <>
                                    <Table
                                        afterTable={isAdmin ? null : (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                padding: '10px',
                                            }}
                                            >

                                                <CommonTooltip
                                                    description={t('LOGGER_PAIRINGS.ADD_NEW_PAIRING')}
                                                    placement="bottom"
                                                    type={TOOLTIP_TYPE.SHRINK}
                                                >
                                                    <AddCircle
                                                        style={{
                                                            color: theme.palette.secondary[600],
                                                            cursor: editedItem ? 'not-allowed' : 'pointer',
                                                            opacity: editedItem ? 0.5 : 1,
                                                            transition: 'all 0.3s ease',
                                                        }}
                                                        onClick={!editedItem ? () => setEditedItem({
                                                            id: '+',
                                                            updatedBy: Number(
                                                                users.find(it => Object
                                                                    .values(it.fullName)
                                                                    .join(' ') === userInfo?.name)?.id,
                                                            ),
                                                            updatedOn: moment().utcOffset(0, false).utc(),
                                                        }) : null}
                                                    />
                                                </CommonTooltip>

                                            </div>
                                        )}
                                        bodyCellVerticalAlign="middle"
                                        classNames={{
                                            tableClassName: classes.table,
                                            tableContainerClassName: classes.tableContainer,
                                        }}
                                        clientSideFilter={{}}
                                        columns={columns}
                                        data={preparedAssetPairings}
                                        embed
                                        setClientSideFilter={() => {}}
                                        title={
                                        `${assetTypeLabel} ${
                                            entitySelectorItem.entityNumber} | ${t('SENSOR_DATA.LOGGER_PAIRINGS')}`
                                        }
                                        onRowHover={({ original, state }) => (isAdmin ? null
                                            : setHoveredItem(state ? original : null))}
                                    />
                                </>
                            ) : <NoOpenEntity />
                        }
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                    }}
                >
                    <Options
                        assetList={assetListAll}
                        disabled={entityType === null}
                        entitySelectorItem={entitySelectorItem}
                        isCurrentTime={isCurrentTime}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setIsCurrentTime={setIsCurrentTime}
                        setLocalTimezone={setLocalTimezone}
                        setRequestBody={setRequestBody}
                        setShowMap={() => {}}
                        setTimeRange={setTimeRange}
                        showTimeRange={false}
                        timeRange={timeRange}
                    />
                    <ShortPackagingInfo
                        model={
                            selectedAsset?.assetTypeCode ? (selectedAsset?.assetTypeCode === 'CONTAINER'
                                ? t('ASSET_TYPE_LABEL.CONTAINER')
                                : selectedAsset?.assetTypeCode === 'PALLET'
                                    ? t('ASSET_TYPE_LABEL.PALLET')
                                    : t('ASSET_TYPE_LABEL.OTHER'))
                                : null
                        }
                        picture={
                            selectedAsset?.assetTypeCode ? assetPicture : null
                        }
                    />
                    {!isAdmin && (
                        <LatestPairingPictures
                            imageUrls={currentPairingPhotos}
                            loading={
                                isCurrentPairingPhotosLoading
                                || !['SUCCESS', 'FAILURE'].includes(isCurrentPairingPhotosStatus)
                            }
                        />
                    )}
                </div>

            </div>

        </div>

    );
};

export default AssetPairings;
