import React, { useState } from 'react';
import useGetServiceProvider from 'hooks/useGetServiceProvider';

import Questionnaire from './Questionnaire';

function QuestionnaireContainer() {
    const provider = useGetServiceProvider();
    const [answers, setAnswers] = useState([]);
    const [questionnaireBasedOnAudit, setQuestionnaireBasedOnAudit] = useState([]);

    if (provider) {
        return (
            <Questionnaire
                answers={answers}
                companyInfo={provider}
                questionnaireBasedOnAudit={questionnaireBasedOnAudit}
                setAnswers={setAnswers}
                setQuestionnaireBasedOnAudit={setQuestionnaireBasedOnAudit}
            />
        );
    }

    return null;
}

export default QuestionnaireContainer;
