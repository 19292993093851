import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useCustomTranslation from 'hooks/useCustomTranslation';
import PhoneNumberTextField from 'shared-components/PhoneNumberTextField';
import useClasses from 'hooks/useClasses';
import styles from './ContactInformationCard.style';

type Props = {
    currentContactPerson?: string,
    currentEmail?: string,
    currentPhoneNumber?: string
}

const ReadMode = ({
    currentContactPerson = '',
    currentEmail = '',
    currentPhoneNumber = '',
}: Props) => {
    const classes = useClasses(styles);

    const { t } = useCustomTranslation();

    return (
        <>
            <div className={classes.fieldTitle}>{t('COMMON.CONTACT_INFORMATION')}</div>
            <div className={classes.iconContainer}>
                <PersonIcon color="action" fontSize="small" />
                <div className={classes.filedInfo}>{currentContactPerson}</div>
            </div>
            <div className={classes.iconContainer}>
                <LocalPhoneIcon color="action" fontSize="small" />
                <PhoneNumberTextField
                    inputPropsClassName={classes.textFieldText}
                    phoneNumber={currentPhoneNumber}
                    placeholder={t('SERVICE_PROVIDERS.CONTACT_PHONE')}
                />
            </div>
            <div className={classes.iconContainer}>
                <MailOutlineIcon color="action" fontSize="small" />
                <div className={classes.filedInfo}>{currentEmail}</div>
            </div>
        </>
    );
};

export default ReadMode;
