/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Card from 'shared-components/Card';
import useClasses from 'hooks/useClasses';

const styles = {
    contentClass: {
        height: '100%',
        padding: 0,
        width: '100%',
    },
};

const LaneRisk = () => {
    const classes = useClasses(styles);

    return (
        <Card contentClass={classes.contentClass} fullHeight>
            <iframe
                allowFullScreen
                frameBorder="0"
                height="100%"
                src="https://xd.adobe.com/embed/6d23b78c-b802-4292-85ec-02400c6463eb-3870/screen/ed0b73f1-379e-41db-8f85-9968cda51408"
                width="100%"
            />
        </Card>
    );
};

export default LaneRisk;
